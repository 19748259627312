import React, { useState } from 'react'
import { Button, Form, Row, Col } from 'antd'
import { get, isEmpty } from 'lodash'

import Section from 'components/layout/content/section'
import Input from 'components/antd/input'
import ResourcesPreview from './preview'
import TagFilters from './tags'
import ServiceFilters, { ALL_SERVICES } from './services'
import { combineFilters } from '../edit'
import { useResourceGroupPreviewQuery } from 'hooks/api'

const prepareFilters = (filters) => {
  if (!filters?.expressions?.length) return {}

  return filters.expressions.reduce((prepared, expression) => {
    if (!expression.expressions?.length) return prepared
    const type = get(expression, 'expressions.0.type')
    if (type === 'service') {
      prepared.service = { operator: expression.operator, values: expression.expressions.map(s => s.value) }
    } else {
      prepared[type] = { operator: expression.operator, values: expression.expressions }
    }
    return prepared
  }, {})
}

const ProjectForm = ({ group, loading, onSubmit, onCancel }) => {
  const [form] = Form.useForm()

  const [previewPayload, setPreviewPayload] = useState(undefined)

  useResourceGroupPreviewQuery(previewPayload)

  const filters = prepareFilters(group?.filters)

  const initialValues = {
    title: group?.title,
    description: group?.description,
    tagOperator: filters.tag?.operator || 'and',
    tags: filters.tag?.values,
    serviceOperator: isEmpty(filters.service?.values) ? ALL_SERVICES : 'or',
    services: filters.service?.values,
    selectedResources: group?.fixedResources
  }

  const resourcesValidator = (rule, value) => {
    const tagFilters = form.getFieldValue('tags')
    const serviceFilters = form.getFieldValue('services')
    const selectedResources = form.getFieldValue('selectedResources')

    if (selectedResources?.length > 0 || tagFilters?.length > 0 || serviceFilters?.length > 0) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(rule.message))
  }

  const handleResourcePreview = () => {
    const values = form.getFieldsValue(true)

    const payload = {
      resources: values?.selectedResources || [],
      filters: combineFilters(values) || {}
    }

    setPreviewPayload(payload)
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Section title='Name' titleUpperCase>
        <Form.Item
          name='title'
          style={{ margin: 0 }}
          rules={[{ max: 250, required: true }, {
            message: 'Name must not contain any spaces or special characters',
            pattern: /^[a-zA-Z0-9_-]*$/
          }]}
        >
          <Input />
        </Form.Item>
      </Section>
      <Section title='Description' titleUpperCase>
        <Form.Item name='description' rules={[{ max: 250, message: 'Description too long' }]}>
          <Input />
        </Form.Item>
      </Section>
      <Row gutter={32}>
        <Col span={8}>
          <TagFilters handlePreview={handleResourcePreview} filters={filters?.tag} />
          <ServiceFilters form={form} handlePreview={handleResourcePreview} filters={filters.service} />
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
            >
              Save changes
            </Button>
            <Button type='link' size='small' onClick={() => onCancel()}>Cancel</Button>
          </Form.Item>
        </Col>
        <Col span={16}>
          <ResourcesPreview form={form} payload={previewPayload} group={group} handlePreview={handleResourcePreview} />
        </Col>
      </Row>
      <Form.Item name='dummy' rules={[{ validator: resourcesValidator, message: 'Resource group with no resources is not allowed!' }]} />
    </Form>
  )
}

export default ProjectForm
