import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { PUT_CUSTOMER } from 'actions/billing.actions'
import { API } from 'actions/api'

import Loading from 'components/layout/content/loading'
import BillingInfoForm from './form'
import validateBillingInfo from './validate'
import { useOrganization } from 'hooks/context/organization-context'
import styles from './styles.less'

const AddBillingInfoModal = ({ visible, customer, onCancel, onOk }) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()

  const billingInfoFormRef = useRef()
  const [error, setError] = useState(null)

  const updatingCustomer = useSelector(state => state.waiting.list[PUT_CUSTOMER])

  const handleOk = async () => {
    const billingInfo = await validateBillingInfo(billingInfoFormRef?.current)
    if (!billingInfo) {
      return null
    }

    setError(null)

    try {
      await dispatch(API.organizations.id(organization.id).billing.customers.active().put(billingInfo))

      return onOk && onOk()
    } catch (error) {
      setError(error.message)
    }
  }

  const handleCancel = async () => {
    return onCancel && onCancel()
  }

  const validateVAT = API.organizations.id(organization.id).billing.VAT.validate

  return (
    <Modal
      open={visible}
      className={styles.modal}
      onOk={handleOk}
      confirmLoading={updatingCustomer}
      onCancel={handleCancel}
    >
      <div>
        {updatingCustomer
          ? (
            <Loading theme='light' />
          )
          : (
            <>
              <BillingInfoForm validateVat={(country, vat) => dispatch(validateVAT({ country, vat }))} customer={customer} ref={billingInfoFormRef} />
              {error && <div className={styles.errorText}>{error}</div>}
            </>
          )}
      </div>
    </Modal>
  )
}

export default AddBillingInfoModal
