// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width: 529px) {\n  .fp_CIH6TI4wn_6Wx07IL {\n    width: 100%;\n  }\n}\n@media only screen and (min-width: 530px) {\n  .fp_CIH6TI4wn_6Wx07IL {\n    width: 50%;\n  }\n}\n@media only screen and (min-width: 950px) {\n  .fp_CIH6TI4wn_6Wx07IL {\n    width: 33.3%;\n  }\n}\n@media only screen and (min-width: 1129px) {\n  .fp_CIH6TI4wn_6Wx07IL {\n    width: 25%;\n  }\n}\n@media only screen and (min-width: 1400px) {\n  .fp_CIH6TI4wn_6Wx07IL {\n    width: 20%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/dashboards/styles.less"],"names":[],"mappings":"AAGE;EAAA;IACE,WAAA;EADF;AACF;AAGE;EAAA;IACE,UAAA;EAAF;AACF;AAEE;EAAA;IACE,YAAA;EACF;AACF;AACE;EAAA;IACE,UAAA;EAEF;AACF;AAAE;EAAA;IACE,UAAA;EAGF;AACF","sourcesContent":["@import '../../styles/main';\n\n.group_width {\n  @media @mobile {\n    width: 100%;\n  }\n\n  @media @tablet {\n    width: 50%;\n  }\n\n  @media @desktop {\n    width: 33.3%\n  }\n\n  @media @desktop-xl {\n    width: 25%;\n  }\n\n  @media @desktop-xxl {\n    width: 20%;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group_width": "fp_CIH6TI4wn_6Wx07IL"
};
export default ___CSS_LOADER_EXPORT___;
