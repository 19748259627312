import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Table, Select } from 'antd'

import styles from './styles.less'

const { Option } = Select

const SelectRole = ({ onChange, defaultVal, permission }) => {
  const additionalProps = permission ? { value: permission.role } : {}
  return (<Select defaultValue={defaultVal} {...additionalProps}
    onSelect={permission ? (value) => onChange(value, permission) : onChange}
    className={styles.select_all}
    dropdownClassName={styles.dropdown}
    dropdownMatchSelectWidth={false}
    disabled={permission?.role === 'owner'}
  >
    <Option value='no-access'>No access</Option>
    <Option value='viewer'>View only</Option>
    <Option value='admin'>Admin</Option>
    <Option value='user'>User</Option>
  </Select>)
}

const UsersPermissionsTable = ({ permissions, setPermissions }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const rowSelection = {
    selectedRowKeys,
    onChange: changedKeys => setSelectedRowKeys(changedKeys)
  }

  const changePermission = (role, currentPermission) => {
    setPermissions(permissions.map(permission => {
      return permission.account.id === currentPermission.account.id
        ? { account: currentPermission.account, role: role }
        : permission
    }))
  }

  const changeSelectedPermissions = (role) => {
    setPermissions(permissions.map(permission => {
      return selectedRowKeys.find(accountId => accountId === permission.account.id)
        ? { account: permission.account, role: role }
        : permission
    }))
  }

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account.name',
      key: 'account.id',
      sorter: (a, b) => a.account.name.localeCompare(b.account.name),
      sortDirections: ['descend', 'ascend'],
      width: '50%',
      render: (record, item) => item.account.name
    },
    {
      title: <>Role{!isEmpty(selectedRowKeys) && <SelectRole defaultVal='no-access' onChange={changeSelectedPermissions} />}</>,
      dataIndex: 'role',
      key: 'role',
      width: '50%',
      render: (record, item) => {
        // const defaultVal = isEmpty(item.role) ? 'no-access' : item.role
        return (<SelectRole defaultVal={item.role} permission={item} onChange={changePermission} />)
      }
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={permissions}
      pagination={false}
      rowSelection={rowSelection}
      rowKey={record => record.account.id}
      size='small'
      bordered
      className={styles.container}
    />
  )
}

export default UsersPermissionsTable
