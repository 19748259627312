import React from 'react'

import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'

import classnames from 'classnames'
import { useUser } from 'hooks/context/user-context'
import styles from './styles.less'

const Profile = ({ className }) => {
  const { session } = useUser()

  const identity = session?.idToken.payload

  return (
    <div className={classnames(styles.profile, className)}>
      <Avatar icon={<UserOutlined />} />
      <div className={styles.details}>
        <span>{identity && identity.given_name} {identity && identity.family_name}</span>
        <span>{identity && identity.email}</span>
      </div>
    </div>
  )
}

export default Profile
