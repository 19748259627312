import { usersApi as orgUsers, invitationsApi as orgInvitations } from './organization.actions'
import { api as billing } from './billing.actions'
import { api as billingV2 } from './billing-v2'
import { api as events } from './events.actions'
import { api as integrations } from './integrations.actions'
import { api as logSources } from './log-sources.actions'
import { api as analytics } from './analytics'

export const API = {
  accounts: {
    id: (account) => {
      return {
        delegations: {
          id: (delegation) => {
            return {
              integrations: integrations(account, delegation)
            }
          }
        },
        events: events(account), // still used in dashboard
        integrations: integrations(account),
        logSources: logSources(account)
      }
    }
  },
  organizations: {
    id: (organization) => {
      return {
        billing: billing(organization),
        billingV2: billingV2(organization),
        invitations: orgInvitations(organization),
        users: orgUsers(organization),
        analytics: analytics(organization)
      }
    }
  }
}
