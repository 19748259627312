import React from 'react'
import { countBy } from 'lodash'

import Section from 'components/layout/content/section'
import { Resource } from 'components/items/progress-item'

import useQueryParams from 'lib/hooks/use-query-params'
import { findInventoryByType } from 'lib/resources/constants'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { resourceTitle } from 'lib/resources/transformations'
import { useErrorsQuery, useAllResourcesQuery } from 'hooks/api'

import styles from './styles.less'

const ErroredResourcesList = () => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('resource', [])

  const { isLoading, data: errors } = useErrorsQuery()
  const { isLoading: resourcesLoading, data: resources } = useAllResourcesQuery()

  const countByResources = countBy(errors, 'resource.id')

  const resourceItems = Object.keys(countByResources).map(id => {
    const count = countByResources[id]
    if (count <= 1) return null
    const resource = resources && resources[id]

    if (!resource) return null

    const delegationName = resource?.delegationName ? `| ${resource?.delegationName}` : ''
    const region = resource?.region || 'no region'

    return ({
      title: `${region} ${delegationName}`,
      description: resourceTitle(resource),
      service: findInventoryByType(resource?.type)?.service,
      id,
      count
    })
  })?.filter(item => item)

  return (
    <Section className={resourceItems?.length > 2 ? styles.wrapper_hide : ''} title='Resources' titleUpperCase loading={isLoading || resourcesLoading || !resources}>
      {resourceItems?.length !== 0
        ? resourceItems?.sort((a, b) => b.count - a.count)?.map(item => (
          <Resource
            {...item}
            key={item?.id}
            onSelect={(item) => setSelected(filterHelper(item, selected))}
            selected={selected}
          />))
        : <p>No resources with more than one error was found</p>
      }
    </Section>
  )
}

export default ErroredResourcesList
