import React from 'react'
import { Skeleton, Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import TrendlineChart from './chart'

import styles from './styles.less'

import { useTotalCountQuery } from 'hooks/api'
import { Link } from 'react-router-dom'

const TitleWrapper = ({ url, children }) => {
  if (!url) return <>{children}</>
  return <Link to={url}>{children}</Link>
}

const TrendlineContainer = ({ height, load, span, start, end }) => {
  const { data, isLoading } = useTotalCountQuery(load, start, end)

  const mapData = () => {
    return data?.datapoints?.default?.map(datapoint => ({
      metric: data?.metric,
      date: datapoint.date,
      value: datapoint?.[data?.stats?.[0]],
      stat: data?.stats?.[0]
    }))
  }

  return (
    <Card title={(
      <TitleWrapper url={load?.url}>
        <span className={classNames({ [styles.trendline_title]: !load.icon, [styles.link]: load?.url })}>
          {load?.icon && <FontAwesomeIcon icon={load.icon} className={styles.icon} />}
          {load.name}
        </span></TitleWrapper>)}
      className={styles.wrapper}>
      <div className={styles.separator} />
      <div className={styles.trendline_block}>
        <div className={styles.trendline_chart}>
          {isLoading
            ? <Skeleton className={styles.trendline_empty} title={false} active />
            : <TrendlineChart data={mapData()} allOpts={[load]} height={height} span={span} />}
        </div>
      </div>
    </Card>

  )
}

export default TrendlineContainer
