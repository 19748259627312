import React from 'react'
import { Row, Col, Typography, Tooltip } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'
import { QuestionIcon } from 'components/icons'

import styles from '../styles.less'

const { Text } = Typography

const TotalTooltip = ({ text } = {}) => {
  if (!text) return null
  return (
    <Tooltip title={text}>
      <QuestionIcon className={styles.help} />
    </Tooltip>
  )
}

const Total = ({ amount, currency, tooltip } = {}) => {
  return (
    <Row>
      <Col span={12}>
        <Text strong>Total</Text>
      </Col>
      <Col span={12}>
        <Text strong>{formatStripePrice(amount, currency)}</Text>
        <TotalTooltip text={tooltip} />
      </Col>
    </Row>
  )
}

export default Total
