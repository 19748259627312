import React, { useContext } from 'react'
import numeral from 'numeral'
import { format } from 'date-fns'
import { merge, sumBy } from 'lodash'

import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faText } from '@fortawesome/pro-light-svg-icons/faText'
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'

import Metrics from 'components/metrics'
import { useErrorMetricsQuery } from 'hooks/api'
import { ScrollContext } from 'components/layout/content'

const metrics = {
  errors: {
    icon: faBug,
    title: 'Errors'
  },
  severity: {
    icon: faExclamationTriangle,
    title: 'Severity'
  },
  first: {
    icon: faArrowToLeft,
    title: 'First'
  },
  last: {
    icon: faArrowToRight,
    title: 'Last'
  },
  type: {
    icon: faText,
    title: 'Type'
  }
}

const getItems = (item, count, loading, fixed, onFixedSelect) => {
  const { errorType, openedAt, lastOccurredAt, severity } = item

  return [
    merge({}, metrics.errors,
      {
        value: numeral(count).format('0.[00] a'),
        selected: !fixed,
        loading,
        onClick: fixed && (() => onFixedSelect())
      }),
    merge({}, metrics.severity, {
      value: severity.toUpperCase()
    }),
    merge({}, metrics.first, {
      value: format(openedAt, 'HH:mm'),
      subvalue: format(openedAt, 'dd.MM.yyyy')
    }),
    merge({}, metrics.last, {
      value: format(lastOccurredAt, 'HH:mm'),
      subvalue: format(lastOccurredAt, 'dd.MM.yyyy')
    }),
    merge({}, metrics.type, {
      value: errorType.toUpperCase()
    })
  ]
}

const ErrorMetrics = ({ error, start, end, onFixedSelect }) => {
  const { data, isLoading } = useErrorMetricsQuery({ key: 'errors', options: { dimensions: { resources: [{ id: error?.errorHash }] } } }, start, end)
  const { fixed: fixedHeader } = useContext(ScrollContext)
  const count = sumBy(data?.datapoints?.[error?.errorHash], 'sum')

  const items = getItems(error, count, isLoading, fixedHeader, onFixedSelect)

  return (
    <>
      <Metrics items={items} tabNarrow />
      {fixedHeader && <Metrics items={items} tabNarrow fixed />}
    </>
  )
}

export default ErrorMetrics
