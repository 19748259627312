import React from 'react'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import { Select } from 'antd'

import styles from './styles.less'

const { Option } = Select

const ResourceGroupListFilters = ({ filters, onFilterChange, delegations, availableServices }) => {
  const delegationFilters = delegations?.filter(item => item.provider.account)?.map(del => ({ type: 'delegation', id: del.id, title: del.name || del.provider.account })).concat([{ type: 'delegation', id: '1234', title: 'Test' }]) || []
  const servicesFilters = availableServices?.map(item => ({ type: 'service', id: item.service, title: item.title }))

  const handleDelegationPick = (id) => {
    onFilterChange(delegationFilters.find(del => del?.id === id))
  }

  return (
    <div className={styles.filters_dropdown}>
      <div className={styles.filters_wrapper}>
        <span className={styles.filters_title}>Services:</span>
        <>
          {servicesFilters?.map(item =>
            <button className={classnames(styles.service_btn, !isEmpty(filters.filter(fil => fil.id === item.id)) ? styles.active : '')}
              onClick={() => onFilterChange({ type: item.type, id: item.id })} key={item.id}>
              {item.title}</button>
          )}
        </>
      </div>
      {!isEmpty(delegationFilters) &&
        <div className={styles.filters_wrapper}>
          <span className={styles.filters_title}>AWS accounts:</span>
          <Select mode='multiple' onSelect={handleDelegationPick} onDeselect={handleDelegationPick} value={filters.filter(item => item.type === 'delegation').map(item => item.id)}>
            {delegationFilters.map(del => <Option key={del.id} value={del.id}>{del.title}</Option>)}
          </Select>
        </div>
      }
      <button className={classnames(styles.service_btn, styles.clear)} onClick={() => onFilterChange('clear')}>Clear all filters</button>
    </div >
  )
}

export default ResourceGroupListFilters
