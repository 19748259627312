import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { flatten, isEmpty, orderBy } from 'lodash'

import { Button, Dropdown } from 'antd'
import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import ListHeader from 'features/side-list/header'
import ListSearch from 'features/side-list/search'
import AlarmsListItem from './item'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { AggregationTypes, AlarmTermDurations, TermOperators } from 'lib/alerting-constants'
import { NameAlphabetical, Namespace, SeverityCount, Status } from 'features/side-list/sorter'
import { useAccountRoutes } from 'containers/routes'
import { getInventoryResources } from 'lib/resources/filters'
import useQueryParams, { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'

import { AddIcon } from 'components/icons/font-awesome'
import { ALARMS_FILTERS } from './filters'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import { useAlarmsRulesQuery, useAllResourcesQuery, useCreateAlarmQuery, useResourceGroupsQuery } from 'hooks/api'

import styles from './styles.less'

const mutations = ({ createAlarm }) => {
  return {
    create: (key) => {
      const service = INVENTORY_SERVICES[key]
      const { title, namespace, metrics } = service
      const payload = {
        name: `${title} alarm`,
        namespace: title === 'Lambda' ? namespace[0] : namespace,
        resourceType: service.type,
        severity: 'critical',
        metric: metrics[0].value,
        stat: AggregationTypes[0].value,
        operator: TermOperators[0].value,
        threshold: 10,
        duration: AlarmTermDurations[0].value
      }

      createAlarm(payload)
    }
  }
}

const AlarmsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()
  const { alarmId: selected } = useParams()
  const { getValue: groupsInUrl } = useQueryParams('group', [])

  const { data: alarmRules, isLoading } = useAlarmsRulesQuery()
  const { mutate: createAlarm } = useCreateAlarmQuery()
  const { data: resourceGroups } = useResourceGroupsQuery()
  const { data: resourcesAll } = useAllResourcesQuery()
  const resources = getInventoryResources(resourcesAll)

  const loading = isLoading || !alarmRules

  const sorters = [SeverityCount, NameAlphabetical, Namespace, Status]
  const [sorter, setSorter] = useState(sorters[0])
  const [searchItems, setSearchItems] = useState(null)
  const handlers = mutations({ createAlarm })

  const onSelect = (identity) => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })

    history.push({ pathname: routes.alarms.alarm.url({ alarmId: identity }), search: newValue })
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  const searchUrl = () => {
    if (!alarmRules) return

    const groupsResourceIds = flatten(groupsInUrl?.map(id => resourceGroups?.find(item => item.id === id)?.resources))
    const results = filterListFromQuery(alarmRules, ALARMS_FILTERS, search, groupsResourceIds)
    setSearchItems(sortItems(results))
  }

  const sortItems = (items) => {
    return sorter.sort
      ? sorter.sort(items)
      : orderBy(items, sorter.apply, (sorter?.order || 'asc'))
  }

  const sortAlarms = () => {
    if (!searchItems) return

    const sortedResults = sortItems(searchItems)
    setSearchItems(sortedResults)
  }

  useEffect(searchUrl, [search, alarmRules, resources?.length])
  useEffect(sortAlarms, [sorter])

  return (
    <List
      loading={loading}
      items={searchItems}
      selected={selected}
      renderItem={AlarmsListItem}
      onSelect={(item) => onSelect(item.id)}
      search={<ListSearch />}
      header={
        <ListHeader
          sorters={sorters}
          onSorterChange={onSorterChange}
          selectedSorter={sorter.key}
          total={alarmRules?.length}
          searchTotal={searchItems?.length}
          overviewURL={routes.alarms.url()}
          extra={
            <Dropdown
              menu={{
                items: Object.values(INVENTORY_SERVICES).filter(item => !isEmpty(item.metrics)).map(service => ({ key: service.id, label: service.title })),
                onClick: ({ key }) => handlers.create(key)
              }}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode?.parentNode}
              overlayClassName={styles.add_dropdown}
            >
              <Button className={styles.action}><AddIcon /></Button>
            </Dropdown>
          }
          filters={ALARMS_FILTERS.reduce((acc, item) => {
            acc[item.key] = item.key
            return acc
          }, {})}
          view='alarms'
        />
      }
      renderEmpty={<ListEmpty title='No alarms found' />}
    />
  )
}

export default AlarmsList
