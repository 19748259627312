import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFetchConditionally } from 'lib/hooks/utils'
import { API } from 'actions/api'
import { GET_BILLING, GET_CUSTOMER_V2, PUT_CUSTOMER_V2 } from 'actions/billing-v2'

import Content from 'components/layout/content'
import BillingDetails from './billing-details'
import PaymentMethods from './payment-methods'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const PaymentContainer = () => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()

  const savingCustomer = useSelector(state => state.waiting.list[PUT_CUSTOMER_V2])
  const loadingCustomer = useSelector(state => state.waiting.list[GET_CUSTOMER_V2])

  const { data: billing, loading } = useFetchConditionally({
    action: API.organizations.id(organization.id).billingV2.billing.get,
    wait: GET_BILLING,
    selector: 'billingV2.billing',
    condition: organization
  })

  useEffect(() => {
    if (!billing?.customer?.id || savingCustomer) return

    dispatch(API.organizations.id(organization.id).billingV2.customer.id(billing?.customer?.id).get())
  }, [billing, savingCustomer])

  const Title = () => <>Payment for <span className={styles.highlighted}>Dashbird.io</span> organization</>

  return (
    <Content item title={<Title />} breadcrumbs={['settings', 'payment methods']} loading={loading || loadingCustomer}>
      <BillingDetails />
      <PaymentMethods />
    </Content>
  )
}

export default PaymentContainer
