import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { AngleDownIcon } from 'components/icons'
import styles from './styles.less'

const Collapsable = ({ className, bordered, gap, content, children }) => {
  const [collapsed, setCollapse] = useState(true)

  return (
    <div className={classnames(styles.collapsable, {
      [styles.bordered]: !!bordered,
      [styles[`gap_${gap}`]]: !!gap
    })}>
      <button onClick={() => setCollapse(!collapsed)} className={className}>
        {children}
        <div className={styles.arrow}>
          <AngleDownIcon className={classnames(!collapsed && 'fa-rotate-180', styles.transition)} />
        </div>
      </button>
      {!collapsed && <div className={styles.content}>{content}</div>}
    </div>
  )
}

const Container = ({ url, onClick, content, bordered, gap, className, children }) => {
  if (url) return <Link className={className} to={url}>{children}</Link>
  if (onClick) return <button className={className} onClick={onClick}>{children}</button>
  if (content) return <Collapsable className={className} bordered={bordered} gap={gap} content={content}>{children}</Collapsable>

  return <div className={className}>{children}</div>
}

const Icon = ({ separator, children }) => {
  if (!children) return null

  return (
    <div className={classnames(styles.icon, separator && styles.separate)}>
      {children}
    </div>
  )
}

const Item = ({ size, gap, bordered, outlined, icon, separator, url, hoverable, selected, count, total, content, onClick, layout, status, solid, className, statusBar, children }) => {
  return (
    <Container
      url={url}
      content={content}
      bordered={bordered}
      gap={gap}
      onClick={onClick}
      className={classnames(styles.item, {
        [styles[`size_${size}`]]: !!size,
        [styles.bordered]: !!bordered,
        [styles.progress]: count && total,
        [styles.hoverable]: hoverable !== false,
        [styles[`gap_${gap}`]]: !!gap,
        [styles[`status_${status}`]]: !!status,
        [styles.selected]: selected,
        [styles.outlined]: outlined,
        [styles.solid]: solid,
        [styles[`status_bar_${statusBar}`]]: !!statusBar
      }, className)}
    >
      {count && total ? <div style={{ width: `${count / total * 100}%` }} className={styles.progressbar} /> : null}
      <Icon separator={separator}>{icon}</Icon>
      <div className={classnames(styles.content, {
        [styles.rows]: layout === 'rows',
        [styles.columns]: layout !== 'rows'
      })}>
        {children}
      </div>
    </Container>
  )
}

export { Item }
