import React from 'react'
import { countBy } from 'lodash'
import { Row, Col } from 'antd'

import Section from 'components/layout/content/section'
import OverviewGroupItem from 'components/overview-group-item'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { ERROR_TYPES } from 'lib/event-constants'
import { useErrorsQuery } from 'hooks/api'

import styles from './styles.less'

const ErrorTypeGroups = () => {
  const { getValue: selected, setValue: onSelect } = useQueryParams('type', [])
  const { data: errors, isLoading } = useErrorsQuery()

  const countByType = countBy(errors, 'error')

  const items = Object.entries(ERROR_TYPES).map(([key, name]) => ({
    title: name, id: key.toLowerCase(), count: countByType[key]
  }))

  return (
    <Section loading={isLoading} loadingHeight={1} cancelMargin>
      <Row gutter={[8, 8]} align='center' className={styles.group_wrapper} wrap={false}>
        {items?.map(group => (
          <Col flex={1} key={group.title} className={styles.group}>
            <OverviewGroupItem {...group} selected={selected} onSelect={(item) => onSelect(filterHelper(item, selected))} />
          </Col>
        ))}
      </Row>
    </Section>
  )
}

export default ErrorTypeGroups
