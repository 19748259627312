export const getLastAuthUserKey = () => {
  const webClientId = process.env.CONFIG['AWS_COGNITO_USER_POOL_WEB_CLIENT_ID']
  if (!webClientId) return

  return `CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`
}

export const getEmailConfirmedKey = (email) => {
  return `${email}_confirmed`
}

export const getOnEmailConfirmedAction = (listenKey, listenAction) => {
  return (newValue) => {
    listenAction(newValue === 'true')
    window.localStorage.removeItem(listenKey)
  }
}

export const listen = (listenKey, listenAction) => {
  const onStorageUpdate = (e) => {
    const { key, newValue } = e
    if (key === listenKey) {
      listenAction(newValue)
    }
  }

  window.addEventListener('storage', onStorageUpdate)
  return () => {
    window.removeEventListener('storage', onStorageUpdate)
  }
}
