import React, { createContext, useEffect, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { sub } from 'date-fns'

import SplitView from 'components/split-pane'
import ScheduledSearchDetails from './details'
import ScheduledSearchList from './list'
import ScheduledSearchDashboard from './dashboard'
import NewScheduledQuery from './details/new-query'

export const SearchMetricsContext = createContext({
  dashboardStart: null,
  dashboardEnd: null,
  relativeSpan: null,
  onChangeDashboardDates: () => { }
})

const ScheduledSearch = () => {
  const { queryId } = useParams()
  const match = useRouteMatch()

  const [dashboardStart, setDashboardStart] = useState(sub(new Date(), { hours: 25 }))
  const [dashboardEnd, setDashboardEnd] = useState(new Date())
  const [dashboardRelativeSpan, setDashboardRelativeSpan] = useState(24 * 60)

  const onChangeDashboardDates = (start, end, relativeSpan) => {
    setDashboardStart(start)
    setDashboardEnd(end)
    setDashboardRelativeSpan(relativeSpan)
  }

  useEffect(() => {
    document.title = 'Dashbird.io - Scheduled search'
  }, [])

  return (
    <SearchMetricsContext.Provider value={{ dashboardStart, dashboardEnd, dashboardRelativeSpan, onChangeDashboardDates }}>
      <SplitView
        left={<ScheduledSearchList />}
        right={<Switch>
          <Route path={`${match.path}/new`}>
            <NewScheduledQuery />
          </Route>
          <Route path={match.path}>
            {queryId ? <ScheduledSearchDetails /> : <ScheduledSearchDashboard />}
          </Route>
        </Switch>}
      />
    </SearchMetricsContext.Provider>
  )
}

export default ScheduledSearch
