import React, { useEffect } from 'react'
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom'

import SplitPane from 'components/split-pane'
import List from './list'
import InventoryDetails from './details/resource'
import InventoryDashboard from './dashboard'
import ModifyGroup from 'features/resource-groups-list/edit'

const Inventory = () => {
  const { resourceId } = useParams()

  const match = useRouteMatch()

  useEffect(() => {
    document.title = 'Dashbird.io - Inventory'
  }, [resourceId])

  return (
    <SplitPane
      left={
        <List />
      }
      right={
        <Switch>
          <Route path={`${match.path}/resource-groups/:groupId?/edit`}>
            <ModifyGroup view='inventory' />
          </Route>
          <Route path={`${match.path}/resource-groups/new`}>
            <ModifyGroup view='inventory' />
          </Route>
          <Route path={match.path}>
            {resourceId
              ? <InventoryDetails />
              : <InventoryDashboard />}
          </Route>
        </Switch>

      }
    />
  )
}

export default Inventory
