import { useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'

import { MSG_PASSWORD_RESET_REQUIRED, MSG_VERIFICATION_LINK } from 'containers/auth/messages'
import { useUser } from '../context/user-context'

export const useLogin = () => {
  const { setUser, setSession, setAuthenticated, setAuthMessage } = useUser()

  return useMutation(
    ({ email, password }) => Auth.signIn(email, password),
    {
      onSuccess: cognitoUser => {
        setUser(cognitoUser)
        setAuthMessage(false)

        if (!cognitoUser?.challengeName) {
          setAuthenticated(true)
          setSession(cognitoUser?.signInUserSession)
        }
        return cognitoUser
      },
      onError: err => {
        if (err.code === 'UserNotFoundException') return setAuthMessage({ message: 'User not found' })
        if (err.code === 'UserNotConfirmedException') return setAuthMessage(MSG_VERIFICATION_LINK)
        if (err.code === 'PasswordResetRequiredException') return setAuthMessage(MSG_PASSWORD_RESET_REQUIRED)
        if (err.code === 'LimitExceededException') return setAuthMessage({ message: err.message })
        return setAuthMessage({ message: err.message })
      }
    }
  )
}

export const useConfirmLogin = () => {
  const { user, refreshCurrentSession, setAuthMessage } = useUser()

  return useMutation(
    (code) => Auth.confirmSignIn(user, code, user.challengeName),
    {
      onSuccess: () => {
        refreshCurrentSession()
        setAuthMessage(false)
      },
      onError: (err) => {
        setAuthMessage({ message: err.message })
      }
    }
  )
}
