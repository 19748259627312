// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tu_FQma66o3VskTgFgSM {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.Tu_FQma66o3VskTgFgSM .OCqWhVuLqaSnlXAeyEKW {\n  padding: 0 0 0 9px;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/layout/navigation/profile/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AADF;AAFA;EAMI,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;AADJ","sourcesContent":["@import '../../../../styles/main';\n\n.profile {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  .details {\n    padding: 0 0 0 @spacing[small];\n    display: flex; \n    flex-grow: 1;\n    flex-direction: column;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": "Tu_FQma66o3VskTgFgSM",
	"details": "OCqWhVuLqaSnlXAeyEKW"
};
export default ___CSS_LOADER_EXPORT___;
