import { apiFetch, getDispatch } from '../lib/action-helpers'

const paths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  integrations: (account, delegation) => {
    return delegation
      ? `${paths.account(account)}/delegations/${delegation}/integrations`
      : `${paths.account(account)}/integrations`
  },
  integration: (account, delegation, id) => `${paths.integrations(account, delegation)}/${id}`,
  blackList: (account, delegation, id) => `${paths.integration(account, delegation, id)}/blacklist`
}

export const api = (account, delegation) => {
  return {
    get: () => getIntegrations(account, delegation),
    post: (payload) => postIntegrations(account, delegation, payload),
    id: (integration) => {
      return {
        get: () => getIntegration(account, delegation, integration),
        put: (payload) => updateIntegration(account, delegation, integration, payload),
        blacklist: {
          put: (payload) => updateIntegrationBlacklist(account, delegation, integration, payload)
        }
      }
    }
  }
}

export const GET_INTEGRATIONS = 'GET_INTEGRATIONS'
export const GET_INTEGRATIONS_ERROR = 'GET_INTEGRATIONS_ERROR'
export const getIntegrations = (account, delegation) => {
  return getDispatch(
    apiFetch(paths.integrations(account, delegation)),
    GET_INTEGRATIONS,
    GET_INTEGRATIONS_ERROR
  )
}

export const POST_INTEGRATIONS = 'POST_INTEGRATIONS'
export const POST_INTEGRATIONS_ERROR = 'POST_INTEGRATIONS_ERROR'
export const postIntegrations = (account, delegation, payload) => {
  return getDispatch(
    apiFetch(paths.integrations(account, delegation), { method: 'POST', body: JSON.stringify(payload) }),
    POST_INTEGRATIONS,
    POST_INTEGRATIONS_ERROR
  )
}

export const GET_INTEGRATION = 'GET_INTEGRATION'
export const GET_INTEGRATION_ERROR = 'GET_INTEGRATION_ERROR'
export const getIntegration = (account, delegation, id) => {
  return getDispatch(
    apiFetch(paths.integration(account, delegation, id)),
    GET_INTEGRATION,
    GET_INTEGRATION_ERROR
  )
}

export const PUT_INTEGRATION = 'PUT_INTEGRATION'
export const PUT_INTEGRATION_ERROR = 'PUT_INTEGRATION_ERROR'
export const updateIntegration = (account, delegation, integrationId, body) => {
  return getDispatch(
    apiFetch(paths.integration(account, delegation, integrationId), { method: 'PUT', body: JSON.stringify(body) }),
    PUT_INTEGRATION,
    PUT_INTEGRATION_ERROR
  )
}

export const PUT_INTEGRATION_BLACKLIST = 'PUT_INTEGRATION_BLACKLIST'
export const PUT_INTEGRATION_BLACKLIST_ERROR = 'PUT_INTEGRATION_BLACKLIST_ERROR'
export const updateIntegrationBlacklist = (account, delegation, integrationId, body) => {
  return getDispatch(
    apiFetch(paths.blackList(account, delegation, integrationId), { method: 'PUT', body: JSON.stringify(body) }),
    PUT_INTEGRATION_BLACKLIST,
    PUT_INTEGRATION_BLACKLIST_ERROR
  )
}
