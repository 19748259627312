import React from 'react'
import { Alert } from 'antd'
import moment from 'moment'
import classnames from 'classnames'

import { useAccountRoutes } from 'containers/routes'
import { useOrganization } from 'hooks/context/organization-context'
import { STATUSES } from 'lib/organization-constants'
import RouterLink from 'components/router-link'

import styles from './styles.less'

const StatusAlert = ({ status, routes } = {}) => {
  if (!status || status !== STATUSES.suspended) return null

  return (
    <Alert
      className={classnames(styles.alert, styles.alert_error)}
      message={<div><strong>Account suspended!</strong> To continue using Dashbird, please <RouterLink to={routes.settings['manage-accounts'].path}>unsuspend</RouterLink> your account.</div>}
      type='error'
    />
  )
}

const TrialEndingAlert = ({ trialEndsAt, routes } = {}) => {
  const now = moment()
  const end = trialEndsAt && Number(trialEndsAt)
  if (!end || end < now) return null

  const daysUntilEnd = moment(end).diff(now, 'days')
  const message = daysUntilEnd > 0 ? `Your trial ends in ${daysUntilEnd} days.` : 'Your trial ends today.'
  return (
    <Alert
      className={styles.alert}
      message={<div className={styles.trial_notice}>{message}<RouterLink to={routes.settings.subscriptions.path}>Make sure you have upgraded your account!</RouterLink></div>}
    />
  )
}

const IssuesAlert = () => {
  return (
    <Alert
      message={'We are currently experiencing some issues with our data. Temporarily some measurements and invocations might be missing!'}
      type='error'
    />
  )
}

const AlertBar = () => {
  const { organization } = useOrganization()
  const routes = useAccountRoutes()

  if (!organization) return null

  return (
    <div className={styles.wrapper}>
      {/* <IssuesAlert /> */}
      <StatusAlert status={organization.status} routes={routes} />
      <TrialEndingAlert trialEndsAt={organization.trialEndsAt} routes={routes} />
    </div>
  )
}

export default AlertBar
