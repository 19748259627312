import { unionBy } from 'lodash'
import { combineReducers } from 'redux'
import { GET_EVENT, GET_EVENTS, UPDATE_EVENT, UPDATE_EVENTS } from 'actions/events.actions'

const temporaryMapping = ({ data: events }) => {
  return { data: events.map(event => {
    if (event?.rule?.startsWith('aws.kinesis.data.')) return ({ ...event, rule: event.rule.replace('data', 'data-streams') })
    if (event?.rule?.startsWith('aws.apigateway.') &&
       !event?.rule?.startsWith('aws.apigateway.http.') &&
       !event?.rule?.startsWith('aws.apigateway.rest.')) return ({ ...event, rule: event.rule.replace('apigateway.', 'apigateway.rest.') })
    return event
  }) }
}

const list = (state = {}, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return temporaryMapping(action.payload)
    case UPDATE_EVENT:
      return {
        data: unionBy([action.payload], state.data, item => item.id)
      }
    case UPDATE_EVENTS:
      return {
        data: unionBy(action.payload, state.data, event => event.id)
      }
  }
  return state
}

const active = (state = null, action) => {
  switch (action.type) {
    case GET_EVENT:
      return action.payload
    case UPDATE_EVENT:
      return action.payload
    case UPDATE_EVENTS:
      return action.payload.find(item => item.id === state.id) || state
  }

  return state
}

export default combineReducers({
  list, active
})
