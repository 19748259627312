import React from 'react'

import { Dropdown, Popconfirm, Switch } from 'antd'
import { ActionsDropdownButton } from 'components/buttons'

const Extra = ({ item, onDelete }) => {
  return (
    <Dropdown
      menu={{
        items: [{
          key: 'delete',
          label: (
            <Popconfirm title={`Are you sure you want to delete ${item.name}?`} onConfirm={() => onDelete(item.id)}>
              <span>Delete</span>
            </Popconfirm>
          )
        }]
      }}>
      <ActionsDropdownButton />
    </Dropdown >
  )
}

const Actions = ({ item, onSwitch, onDelete }) => {
  if (!item) return null

  return (
    <React.Fragment>
      <Switch defaultChecked={!item.disabled} checked={!item.disabled} onChange={onSwitch} />
      <Extra item={item} onDelete={onDelete} />
    </React.Fragment>
  )
}

export default Actions
