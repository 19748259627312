import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, Route } from 'react-router-dom'
import classnames from 'classnames'

import { Button, Dropdown, Popconfirm, Switch, Tooltip } from 'antd'
import { Typography } from 'components/typography'
import { NotificationChannelIcon } from 'components/icons'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import EditableTitle from 'features/editable-title'
import Condition from './condition'
import StyledDrawer from 'components/drawer'
import AddChannelForm from './add-channel-form'
import IconButton from 'components/buttons/icon'
import Select from 'components/antd/select'
import { AlertCircleIcon, CloseIcon } from 'components/icons/font-awesome'

import mutations from './mutations'
import { useAccountRoutes } from 'containers/routes'
import { ActionsDropdownButton } from 'components/buttons'
import { useAllResourcesQuery, useResourceGroupsQuery, useAlarmsRulesQuery, useNotificationChannelsQuery, useNotificationPolicyQuery, useDeleteNotificationPolicyQuery, useUpdateNotificationPolicyQuery, useCreateNotificationPolicyChannelQuery, useDeleteNotificationPolicyChannelQuery, useCreateNotificationPolicyConditionQuery, useDeleteNotificationPolicyConditionQuery, useUpdateNotificationPolicyConditionQuery, useCreateNotificationPolicyConditionTargetQuery, useDeleteNotificationPolicyConditionTargetQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'
import { getInventoryResources, getResourcesByNotType, getResourcesByType } from 'lib/resources/filters'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import styles from './styles.less'

const DrawerTitle = ({ onClose }) => {
  return (
    <>
      <IconButton icon={<CloseIcon />} onClick={onClose} className={styles.close_icon} />
      <span>Connect new channel</span>
    </>
  )
}

const Details = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { policyId } = useParams()
  const channelSelectRef = useRef()
  const channelSelectWrapperRef = useRef()

  const { account } = useOrganization()
  const { data: policy, isLoading } = useNotificationPolicyQuery(policyId)
  const { mutateAsync: deletePolicy } = useDeleteNotificationPolicyQuery()
  const { mutate: updatePolicy } = useUpdateNotificationPolicyQuery()

  const { mutate: addPolicyChannel } = useCreateNotificationPolicyChannelQuery()
  const { mutate: deletePolicyChannel } = useDeleteNotificationPolicyChannelQuery()

  const { mutate: addCondition, isLoading: creatingCondition } = useCreateNotificationPolicyConditionQuery()
  const { mutate: updateCondition } = useUpdateNotificationPolicyConditionQuery()
  const { mutate: deleteCondition } = useDeleteNotificationPolicyConditionQuery()
  const { mutate: addTarget } = useCreateNotificationPolicyConditionTargetQuery()
  const { mutate: deleteTarget } = useDeleteNotificationPolicyConditionTargetQuery()

  const { data: channels, isLoading: loadingChannels } = useNotificationChannelsQuery()
  const { data: resources, isLoading: loadingResources } = useAllResourcesQuery()
  const { data: groups, isLoading: loadingResourceGroups } = useResourceGroupsQuery()
  const { data: alarms, isLoading: loadingAlarms } = useAlarmsRulesQuery()

  const [channelSearchValue, setChannelSearchValue] = useState()
  const [policyChannels, setPolicyChannels] = useState([])

  const inventoryResources = getInventoryResources(resources)

  const loading = isLoading || loadingResources || loadingChannels || loadingResourceGroups || loadingAlarms

  const IS_OWNER = account.role === 'owner'
  const IS_ADMIN = account.role === 'admin'
  const DISABLE_EDIT = !(IS_OWNER || IS_ADMIN)

  const handlers = policy ? mutations({ history, account, policy, routes, groups, deletePolicy, updatePolicy, addPolicyChannel, deletePolicyChannel, addCondition, updateCondition, deleteCondition, addTarget, deleteTarget, setChannelSearchValue }) : {}

  const openChannelDrawer = (e) => {
    e.stopPropagation()
    channelSelectRef?.current?.blur()
    history.push(routes.settings.notifications.policies.newChannel.url({ policyId }))
    setChannelSearchValue('')
  }

  const handleBlur = (e) => {
    if (channelSelectWrapperRef?.current?.contains(e.target)) {
      return
    }
    setChannelSearchValue('')
    channelSelectRef?.current?.blur()
  }

  useEffect(() => {
    if (!policy) return
    setPolicyChannels(policy?.channels)
  }, [policy])

  return (
    <>
      <Content
        item
        loading={loading}
        breadcrumbs={['Settings', 'Notifications', 'Policy']}
        backRoute={routes.settings.notifications.url()}
        title={(policy && !DISABLE_EDIT)
          ? <EditableTitle
            value={policy?.name}
            onSubmit={(name) => handlers.update({ name })}
            displayContent={policy?.name}
          />
          : policy?.name}
        actions={
          <>
            {policy && <Switch
              onChange={(value) => handlers.update({ disabled: !value })}
              checked={policy?.id && !policy?.disabled}
              defaultChecked={policy?.id && !policy?.disabled}
              disabled={DISABLE_EDIT}
            />}
            <Dropdown
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: 'delete',
                    disabled: DISABLE_EDIT,
                    label: (
                      <Popconfirm
                        title={`Are you sure you want to delete ${policy?.name}?`}
                        onConfirm={handlers.remove}
                      >
                        <span>Delete</span>
                      </Popconfirm>)
                  }
                ]
              }}
            >
              <ActionsDropdownButton />
            </Dropdown>
          </>
        }
      >
        <Section title='Destinations'>
          <Typography.Paragraph>Select channels, where would you like to receive notifications</Typography.Paragraph>
          <div ref={channelSelectWrapperRef} className={classnames(styles.channels_wrapper, { [styles.alert]: policy?.channels?.length === 0 })}>
            <Select
              ref={channelSelectRef}
              key={`${policy?.id}-channels`}
              mode='multiple'
              searchValue={channelSearchValue}
              onSearch={value => setChannelSearchValue(value)}
              defaultValue={policy?.channels}
              value={policyChannels}
              placeholder='Select notification channels'
              className={styles.channels}
              onBlur={handleBlur}
              onSelect={(value) => handlers.channels(value).add()}
              onDeselect={(value) => handlers.channels(value).remove()}
              popupClassName={styles.select}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              options={channels?.map(item => ({ value: item.id, id: item.id, title: item.name, disabled: DISABLE_EDIT, type: item.type }))}
              optionRender={({ item }) => <div className={styles.channel}>
                <NotificationChannelIcon type={item.type} />
                <span className={styles.title}>{item.title}</span>
              </div>}
              dropdownRender={menu => (
                <>
                  {menu}
                  <div className={styles.add_container}>
                    <span>Don't find what you are looking for?</span>
                    <Button onClick={openChannelDrawer} size='small' type='primary' className={styles.add_new_btn}>Connect a new channel</Button>
                  </div>
                </>
              )}
            />
            {policy?.channels?.length === 0 && <div className={styles.alert_wrapper}>
              <Tooltip title='Connect channels to get notifications from this policy'><AlertCircleIcon className={styles.alert_icon} /></Tooltip>
            </div>}
          </div>
        </Section>

        <div className={styles.sectionHeader}>
          <Typography.Title level={2}>Conditions</Typography.Title>
          <Dropdown
            disabled={DISABLE_EDIT}
            trigger={['click']}
            menu={{
              items: [
                { label: 'Alarm', key: 'alarm' },
                { label: 'Insight', key: 'insight' },
                { label: 'Error', key: 'error' },
                { label: 'Log match', key: 'log-match' }
              ],
              onClick: ({ key }) => handlers.condition({}).create({ type: key })
            }}
          >
            <Button type='primary' loading={creatingCondition}>Add condition</Button>
          </Dropdown>
        </div>
        {
          policy?.conditions?.map(item => (
            <Condition
              key={item.id}
              condition={item}
              groups={groups}
              lambdas={getResourcesByType(inventoryResources, INVENTORY_SERVICES.Lambda.type)}
              resources={getResourcesByNotType(inventoryResources, INVENTORY_SERVICES.Lambda.type)}
              alarms={alarms}
              mutations={handlers.condition}
              disableEditing={DISABLE_EDIT}
            />
          ))
        }
      </Content >
      <Route path={routes.settings.notifications.policies.newChannel.url({ policyId })}>
        <StyledDrawer
          open
          closable={false}
          onClose={() => history.push(routes.settings.notifications.policies.policy.url({ policyId }))}
        >
          <Content
            title={<DrawerTitle onClose={() => history.push(routes.settings.notifications.policies.policy.url({ policyId }))} />}
            item
            className={styles.drawer}
            loading={isLoading}
          >
            <AddChannelForm policy={policy} />
          </Content>
        </StyledDrawer>
      </Route>
    </>
  )
}

export default Details
