import React from 'react'
import { countBy } from 'lodash'
import { Row, Col } from 'antd'

import useQueryParams from 'lib/hooks/use-query-params'
import { getInventoryResources } from 'lib/resources/filters'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { filterInventoryByService, INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import Section from 'components/layout/content/section'
import OverviewGroupItem from 'components/overview-group-item'
import { AwsServiceIcon } from 'components/icons'
import { useAllResourcesQuery } from 'hooks/api'

import styles from './styles.less'

const InventoryServiceGroups = () => {
  const { data: resourcesAll, isLoading: loading } = useAllResourcesQuery()
  const resources = getInventoryResources(resourcesAll)

  const { getValue: services, setValue: setServices } = useQueryParams('service', [])

  const loadingResources = loading || !resources

  const countByType = countBy(resources, 'type')

  const countByServiceGroup = Object.values(INVENTORY_SERVICE_GROUPS).map(item => {
    const count = filterInventoryByService(item.service).reduce((acc, item) => {
      const addition = countByType[item.type] || 0
      return acc + addition
    }, 0)

    return { service: item.service, title: item.title, count }
  })

  const handleServiceFilter = (item) => {
    setServices(filterHelper(item, services))
  }

  return (
    <Section loading={loadingResources} loadingHeight={3}>
      <Row gutter={[8, 8]}>
        {countByServiceGroup.map(group =>
          group.count !== 0
            ? <Col className={styles.group_width} key={group.service}>
              <OverviewGroupItem
                title={group.title?.slice(4)}
                id={group.service}
                icon={<AwsServiceIcon service={group.service} />}
                count={group.count}
                onSelect={handleServiceFilter}
                selected={services}
                large
              />
            </Col>
            : null
        )}
      </Row>
    </Section>
  )
}

export default InventoryServiceGroups
