import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { isEmpty } from 'lodash'

import { Typography } from 'components/typography'

import styles from './styles.less'
import { useHistory } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'

const BillingDetails = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const customer = useSelector(state => state.billingV2.customer)

  return (
    <>
      <Typography.Title level={2}>Billing information</Typography.Title>
      <Typography.Paragraph>Manage your billing details</Typography.Paragraph>
      <section className={styles.info_container}>
        <div>
          <div className={styles.row}>
            <p className={styles.label}>Name: </p>
            <p className={styles.info}>{customer?.name}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.label}>Email: </p>
            <p className={styles.info}>{customer?.email}</p>
          </div>
          {!isEmpty(customer?.taxIds) && <div className={styles.row}>
            <p className={styles.label}>VAT number: </p>
            <p className={styles.info}>{customer?.taxIds[0].value}</p>
          </div>}
          <p className={styles.label}>Address</p>
          <p className={styles.info}>{customer?.address?.line1} {customer?.address?.line2}</p>
          <p className={styles.info}>{customer?.address?.city} {customer?.address?.state}</p>
          <p className={styles.info}>{customer?.address?.postalCode}</p>
          <p className={styles.info}>{customer?.address?.Country}</p>
        </div>
        <Button type='primary' onClick={() => history.push(routes.settings.payment.billing.url({ customerId: customer?.id }))}>Update</Button>
      </section>
    </>
  )
}

export default BillingDetails
