import React from 'react'
import { compact, uniqueId } from 'lodash'
import { mapStr, extractTime, removeLineStart, removeReqIdFromLine, isJsonString, transformJson, stripMatchTags } from './log-helper'
import PrettyPrinter from 'components/json-pretty'
import Highlighter from 'react-highlight-words'
import { format, parseISO } from 'date-fns'

import styles from './styles.less'

function mapString (content, isError, timestamp, simplify, isSameLineAsJson = false, invocationStartTime, highlighted) {
  let time = null
  let timeDiff = null
  let linesProcessed = 0

  const splitLines = content.split('\n')
  return compact(splitLines.map((row, key) => {
    let line = row
    linesProcessed++
    if (invocationStartTime && linesProcessed === 1) {
      timeDiff = timestamp - invocationStartTime
    } else {
      timeDiff = null
    }

    if (simplify) {
      time = extractTime(row)
      line = removeLineStart(row)
    }

    if (time) {
      time = format(parseISO(time), 'HH:mm:ss.SS dd/M/y')
    }

    if (!line.trim() && !time) { return '' }

    const classNames = [
      styles.line,
      !isSameLineAsJson && linesProcessed === splitLines.length - 1 ? styles.border : '',
      isError ? styles.error : '',
      simplify ? styles.simplified : ''
    ].join(' ')

    line = removeReqIdFromLine(line)

    let infoString = ''
    if (timeDiff === null) {
      infoString = time ? `  ${line}` : line
    } else {
      infoString = <span key={uniqueId('log-')}>{line}<span className={styles.time_diff}>{` [+${timeDiff}ms]`}</span></span>
    }

    return (
      <span className={classNames} key={uniqueId('log-')}>
        {time && <span>{time}</span>}
        {
          line.indexOf('START') !== -1 || line.indexOf('REPORT') !== -1
            ? <span className={styles.start_stop_line} key={uniqueId('log-')}>{infoString}</span>
            : (
              <Highlighter
                autoEscape
                textToHighlight={infoString}
                searchWords={highlighted || []}
                className={styles.log}
                highlightClassName={styles.hightlightedLog}
              />)
        }
      </span>
    )
  }))
}

function renderLogline (logline, { isError = false, key = '' } = {}, timestamp, simplify, invocationStartTime, collapsed, collapseStringAfterLength, isStructured, highlighted) {
  const strippedString = stripMatchTags(logline)

  const potentialJson = strippedString.substring(strippedString.indexOf('{'), strippedString.lastIndexOf('}') + 1)
  const transformedJson = transformJson(potentialJson)

  let finalJson = null

  if (isJsonString(potentialJson) || transformedJson) {
    const beforeJson = strippedString.substr(0, strippedString.indexOf('{'))
    const afterJson = strippedString.substr(strippedString.lastIndexOf('}') + 1)

    try {
      finalJson = transformedJson ? JSON.parse(transformedJson) : JSON.parse(potentialJson)
    } catch (e) {
      finalJson = null
    }

    return (
      <PrettyPrinter
        key={logline.id || key}
        collapsed={collapsed}
        jsonString={finalJson}
        beforeJson={beforeJson.length > 0 && isStructured ? mapString(beforeJson, isError, timestamp, simplify, true, invocationStartTime) : beforeJson}
        afterJson={afterJson.length > 0 && isStructured ? mapString(afterJson, isError, timestamp, simplify, false, invocationStartTime) : afterJson}
        collapseStringAfterLength={collapseStringAfterLength}
      >
        {(timestamp && invocationStartTime && beforeJson.length < 1) ? <span className={styles.time_diff}>{`  [+${timestamp - invocationStartTime}ms]`}</span> : null}
      </PrettyPrinter>
    )
  }

  const mappedStr = mapStr(strippedString)

  return mappedStr.map(({ content, type }, index) => {
    if (type === 'raw') {
      return isStructured ? mapString(content, isError, timestamp, simplify, false, invocationStartTime, highlighted) : content
    }
    return (
      <span className={styles.search_term} key={index}>{content}</span>
    )
  })
}

function renderStructuredLogs (logs, errorId, simplify, invocationStartTime, collapsed, collapseStringAfterLength, highlighted) {
  if (!logs) return null
  const isStructured = true
  return logs.map(({ logline, id, eventId, timestamp }, index) => {
    return renderLogline(logline, {
      isError: errorId && (id === errorId || eventId === errorId),
      key: index
    }, timestamp, simplify, invocationStartTime, collapsed, collapseStringAfterLength, isStructured, highlighted)
  })
}

const PrettyLog = ({
  dataToDisplay = '',
  isText = false,
  logStyle = '',
  errorId = null,
  simplify = true,
  invocationStartTime = 0,
  collapsed,
  collapseStringAfterLength,
  highlighted = []
}) => {
  const logToDisplay = isText
    ? renderLogline(dataToDisplay, {}, null, null, null, collapsed, collapseStringAfterLength, highlighted)
    : renderStructuredLogs(dataToDisplay, errorId, simplify, invocationStartTime, collapsed, collapseStringAfterLength, highlighted)
  return (
    <pre className={logStyle}>
      {logToDisplay}
    </pre>
  )
}

export default PrettyLog
