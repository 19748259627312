import React from 'react'
import numeral from 'numeral'
import { get, lowerCase, merge } from 'lodash'
import { format } from 'date-fns'

import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faPennant } from '@fortawesome/pro-light-svg-icons/faPennant'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft'
import Metrics from 'components/metrics'

import { formatDuration } from 'components/charts/utils'
import { ColdstartIcon, RetryIcon, BugIcon } from 'components/icons/font-awesome'
import styles from './styles.less'

const metrics = {
  errors: {
    icon: faBug,
    title: 'Error'
  },
  start: {
    icon: faArrowToLeft,
    title: 'Start'
  },
  duration: {
    icon: faClock,
    title: 'Duration'
  },
  memory: {
    icon: faMemory,
    title: 'Memory'
  },
  flags: {
    icon: faPennant,
    title: 'Flags'
  }
}

const Coldstart = ({ item }) => {
  if (!get(item, 'data.initDuration')) return null
  return <div className={styles.flag}><ColdstartIcon /></div>
}

const Retry = ({ item }) => {
  if (!item.isRetry) return null
  return <div className={styles.flag}><RetryIcon className={styles.retry} /></div>
}

const Warning = ({ item }) => {
  if (!item.hasWarningErrors) return null
  return <div className={styles.flag}><BugIcon className={styles.warning} /></div>
}

const getItems = (item) => {
  const data = item.data || {}
  const duration = formatDuration(data?.duration)
  const memory = numeral(data.maxMemoryUsed / data.memorySize).format('0%')
  const flags = (
    <React.Fragment>
      <Retry key='retry' item={item} />
      <Coldstart key='coldstart' item={item} />
      <Warning key='warning' item={item} />
    </React.Fragment>
  )

  const showFlags = get(item, 'data.initDuration') || item.isRetry || item.hasWarningErrors
  return [
    merge({}, metrics.start, {
      value: format(item.timestamp, 'HH:mm'),
      subvalue: format(item.timestamp, 'dd.MM.yyyy')
    }),
    merge({}, metrics.duration, { value: duration }),
    merge({}, metrics.memory, {
      value: memory,
      subvalue: `${numeral(item.data.memorySize).format('0')}MB`
    }),
    merge({}, metrics.errors, {
      value: lowerCase(item.errorType || 'none'),
      critical: !!item.errorType
    }),
    merge({}, metrics.flags, { value: showFlags ? flags : 'none' })
  ]
}

const InvocationMetrics = ({ loading, item, tabNarrow, drawer }) => {
  if (loading || !item) return null

  const items = getItems(item)
  return <Metrics items={items} tabNarrow={tabNarrow} drawer={drawer} />
}

export default InvocationMetrics
