import React from 'react'

import ListItem from 'features/side-list/item'
import { ErrorIcon, AwsServiceIcon, MutedIcon, SpinnerIcon } from 'components/icons'
import { findInventoryByType } from 'lib/resources/constants'
import { formatDistanceToNowStrict } from 'date-fns'
import shorten from './format-time-distance'
import SeverityBadge from 'components/badge/severity'
import SkeletonLine from 'components/skeleton-line'

import styles from './styles.less'

export const renderTimestamp = (item) => {
  const openedTimestamp = formatDistanceToNowStrict(item.openedAt)

  if (item.type === 'error') {
    const lastOccurredTimestamp = item.lastOccurredAt
      ? formatDistanceToNowStrict(item.lastOccurredAt)
      : openedTimestamp

    return `${shorten(lastOccurredTimestamp)} - ${shorten(openedTimestamp)} ago`
  }

  return openedTimestamp
}

export const DetailsBelow = ({ loading, item }) => (
  <div className={styles.details}>
    {loading ? <SkeletonLine className={styles.loading} size='xs' /> : <span>{item.resource?.title || item.resource?.name}</span>}
    <span>{renderTimestamp(item)}</span>
  </div>
)

const DetailsAbove = ({ item, loading }) => {
  const delegation = item.resource?.delegationName ? `${item.resource?.delegationName} |` : ''

  return (
    <>
      <SeverityBadge severity={item?.severity} />
      {loading
        ? <SkeletonLine className={styles.loading} size='xs' />
        : <span>{`${delegation} ${item?.resource?.region?.toUpperCase()}`}</span>
      }
    </>
  )
}

const RightIcon = ({ muted, count, severity }) => {
  if (muted) return <MutedIcon />

  if (!count && count !== 0) return <SpinnerIcon />

  return count > 0 ? <SeverityBadge severity={severity} text={count} /> : null
}

const ErrorListItem = ({ data, index, style }) => {
  const item = data?.items[index]
  const service = findInventoryByType(item?.resource?.type)

  return (
    <div style={style}>
      <ListItem
        key={item.id}
        icon={item?.resource?.type ? <AwsServiceIcon service={service.service} /> : <ErrorIcon status={item.severity} />}
        title={item.name}
        selected={item.id.toString() === data?.selected}
        left={<DetailsAbove loading={data.loadingMetrics} item={item} />}
        right={<RightIcon muted={item?.mutedAt} count={item.countLastDay} severity={item.severity} />}
        detailsBelowTitle
        details={<DetailsBelow loading={data.loadingMetrics} item={item} />}
        checked={data?.bulkSelected?.includes(item.id)}
        onBulkSelect={value => data?.onBulkSelect(item.id, value)}
        onClick={() => data?.onSelect(item)}
        style={style}
      />
    </div>
  )
}

export default ErrorListItem
