import Cache from 'timed-cache'
import queryString from 'query-string'
import { getDispatch, apiFetch, omitEmpty } from '../lib/action-helpers'

// 1 minute cache
const cache = new Cache({ defaultTtl: 60 * 1000 })

const paths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  events: (account) => `${paths.account(account)}/events`,
  event: (account, identity) => `${paths.events(account)}/${identity}`
}

export const api = (account) => {
  return {
    get: (filter) => getEvents(account, filter),
    patch: (payload) => updateEvents(account, payload),
    id: (event) => {
      return {
        get: () => getEvent(account, event),
        put: (payload) => updateEvent(account, event, payload)
      }
    }
  }
}

export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR'
export const getEvents = (account, filters = { status: 'open' }) => {
  const query = queryString.stringify(omitEmpty(filters))
  const cacheKey = { account, query, type: 'events' }
  const cached = cache.get(cacheKey)

  if (cached) {
    return getDispatch(
      cached,
      GET_EVENTS,
      GET_EVENTS_ERROR
    )
  }

  const request = async () => {
    const data = await apiFetch(paths.filter(paths.events(account), query))
    return data
  }

  return getDispatch(
    request(),
    GET_EVENTS,
    GET_EVENTS_ERROR
  )
}

export const UPDATE_EVENTS = 'UPDATE_EVENTS'
export const UPDATE_EVENTS_ERROR = 'UPDATE_EVENTS_ERROR'
export const updateEvents = (account, payload) => {
  return getDispatch(
    apiFetch(paths.events(account), { method: 'PATCH', body: JSON.stringify(payload) }),
    UPDATE_EVENTS,
    UPDATE_EVENTS_ERROR
  )
}

export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR'
export const getEvent = (account, event) => {
  return getDispatch(
    apiFetch(paths.event(account, event)),
    GET_EVENT,
    GET_EVENT_ERROR
  )
}

export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR'
export const updateEvent = (account, event, payload) => {
  return getDispatch(
    apiFetch(paths.event(account, event), { method: 'PUT', body: JSON.stringify(payload) }),
    UPDATE_EVENT,
    UPDATE_EVENT_ERROR
  )
}
