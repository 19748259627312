import React from 'react'
import NamesForm from './form'
import Section from 'components/layout/content/section'

const NamesFormContainer = ({ whitelist, blacklist, handlers }) => {
  return (
    <div>
      <Section title='Whitelist resources by name'>
        <NamesForm onChange={whitelist => handlers.update({ whitelist })} names={whitelist} />
      </Section>
      <Section title='Blacklist resources by name'>
        <NamesForm onChange={blacklist => handlers.update({ blacklist })} names={blacklist} />
      </Section>
    </div>
  )
}

export default NamesFormContainer
