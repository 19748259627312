// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zagTu0_eRhYW54wNEhjw {\n  padding: 0 6px;\n}\n.f7lP_TrCMGapr_heXhF1 {\n  display: flex;\n  flex-grow: 1;\n  justify-content: flex-end;\n}\n.wf42mOZI0IMuBvHvK_hi {\n  display: flex;\n}\n.MdyAUkd5JTGy3K7WZkAR {\n  height: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/scheduled-search/list/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAIA;EACE,aAAA;EACA,YAAA;EACA,yBAAA;AAFF;AAKA;EACE,aAAA;AAHF;AAMA;EACE,YAAA;AAJF","sourcesContent":["@import '../../../styles/main.less';\n\n.add_btn {\n  padding: 0 @spacing[smaller];\n}\n\n.details {\n  display: flex;\n  flex-grow: 1;\n  justify-content: flex-end;\n}\n\n.right_details {\n  display: flex;\n}\n\n.date_filler {\n  height: 14px;\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add_btn": "zagTu0_eRhYW54wNEhjw",
	"details": "f7lP_TrCMGapr_heXhF1",
	"right_details": "wf42mOZI0IMuBvHvK_hi",
	"date_filler": "MdyAUkd5JTGy3K7WZkAR"
};
export default ___CSS_LOADER_EXPORT___;
