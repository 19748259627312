import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { isEmpty, uniqBy } from 'lodash'

import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tooltip, Tag } from 'antd'
import { API } from 'actions/api'
import { GET_INVOICES_V2 } from 'actions/billing-v2'
import { formatStripePrice } from 'lib/billing-helpers'
import Content from 'components/layout/content'
import LoadMoreButton from 'components/buttons/load-more'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const FETCH_MORE_COUNT = 5

const Invoices = () => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()
  const loading = useSelector(state => state.waiting.list[GET_INVOICES_V2])
  const [next, setNext] = useState('')
  const [invoices, setInvoices] = useState([])

  const fetch = async (filters) => {
    const { payload } = await dispatch(API.organizations.id(organization.id).billingV2.invoices.get(filters))
    setInvoices(uniqBy(invoices.concat(payload?.data), 'number'))
    setNext(payload?.next)
  }

  useEffect(async () => {
    if (!organization.id) return

    fetch()
  }, [organization])

  const Title = () => <>Invoices for <span className={styles.org_name}>{organization.name}</span> organization</>

  return (
    <Content item title={<Title />} breadcrumbs={['settings', 'invoices']} loading={loading}>
      {!isEmpty(invoices) && invoices.map(item => {
        return (
          <div className={styles.item_container} key={item.number}>
            <div>
              <div className={styles.row}>
                <p className={styles.item_no}>Invoice no {item.number}</p>
                <Tag color={item.isPaid ? 'purple' : 'red'} className={styles.paid}>{item.isPaid ? 'Paid' : 'Not paid'}</Tag>
              </div>
              <div className={styles.row}>
                <p className={styles.label}>Billing period:  </p><p className={styles.text}>{moment.unix(item.start).format('MMMM Do YYYY')} - {moment.unix(item.end).format('MMMM Do YYYY')}</p>
              </div>
              <div className={styles.row}>
                <p className={styles.label}>Total: </p><p className={styles.text}>{formatStripePrice(item.total, item.currency)}</p>
              </div>
            </div>
            <div className={styles.actions}>
              <Button type='link' size='small' className={styles.invoice_link} href={item.hostedInvoiceUrl} target='_blank' rel='noopener noreferrer'>View invoice</Button>
              <Tooltip title='Download invoice' mouseEnterDelay={0} placement='topRight'>
                <Button icon={<DownloadOutlined />} size='small' className={styles.download_icon} href={item.invoiceUrl} />
              </Tooltip>
            </div>
          </div>
        )
      })}

      <LoadMoreButton onClick={() => fetch({ limitStr: FETCH_MORE_COUNT, next })} isLoading={loading} hidden={isEmpty(next)} />
    </Content>
  )
}

export default Invoices
