import React from 'react'
import { Form } from 'antd'

import Submit from 'components/antd/form/submit'
import GenericFormButton from 'components/antd/form/generic-form-button'

import styles from '../styles.less'

const GenericAction = ({ loading, onSubmit, text, icon, altLoading, onAltClick, altText, altIcon }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return <Form form={form} onFinish={onSubmitWrapper} layout='vertical' className={styles.submit_wrapper}>
    <Submit loading={loading} text={text} icon={icon} titleUpperCase />
    {onAltClick && <GenericFormButton onClick={onAltClick} loading={altLoading} text={altText} icon={altIcon} titleUpperCase />}
  </Form>
}

export default GenericAction
