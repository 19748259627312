export const DASHBOARDS = 'dashboards'
export const DASHBOARD_ACTIVE = [DASHBOARDS, 'active']
export const DASHBOARDS_LIST = [DASHBOARDS, 'list']
export const WIDGETS_LIST = [DASHBOARDS, 'widgets', 'list']

export * from './get-dashboards'
export * from './get-dashboard'
export * from './create-dashboard'
export * from './delete-dashboard'
export * from './update-dashboard'

export * from './get-widgets'
export * from './create-widget'
export * from './delete-widget'
export * from './update-widget'

export const dashboardsPaths = {
  account: (idenitity) => `/v1/accounts/${idenitity}`,
  dashboards: (account) => `${dashboardsPaths.account(account)}/dashboards/dashboards`,
  dashboard: (account, identity) => `${dashboardsPaths.dashboards(account)}/${identity}`,
  widgets: (account, identity) => `${dashboardsPaths.dashboards(account)}/${identity}/widgets`,
  widget: (account, dashboardId, widgetId) => `${dashboardsPaths.widgets(account, dashboardId)}/${widgetId}`
}
