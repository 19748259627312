import React from 'react'

import UsageReport from 'containers/settings/usage/content'
import { Typography } from 'components/typography'

import styles from './styles.less'

const Usage = () => {
  return (
    <section className={styles.usage_container}>
      <Typography.Title level={2}>Usage report</Typography.Title>
      <UsageReport />
    </section>
  )
}

export default Usage
