import React, { useEffect, useState } from 'react'
import { Checkbox, Radio } from 'antd'

import styles from './styles.less'
import { ChevronDownIcon } from 'components/icons/font-awesome'

const CheckboxGroup = Checkbox.Group

const AccountsOptions = ({ onChange, checkedList, checkAll, onChangeAll, allAccounts, indeterminate, changeGrouping, selectedChart }) => {
  const [showOptions, setShowOptions] = useState(false)

  const rotate = showOptions ? { rotation: 180 } : ''
  const defaultGrouping = selectedChart === 'usage' ? 'type' : 'lambda'

  useEffect(() => {
    changeGrouping(defaultGrouping)
  }, [selectedChart])

  return (
    <section className={styles.checkbox_container}>
      <div className={styles.filters}>
        <Checkbox
          className={styles.check_all}
          indeterminate={indeterminate}
          checked={checkAll}
          onChange={onChangeAll}>All accounts</Checkbox>
        <button className={styles.arrow_btn} onClick={() => setShowOptions(!showOptions)}><ChevronDownIcon {...rotate} /></button>
      </div>
      {showOptions && <CheckboxGroup
        options={allAccounts.map(item => item.name)}
        value={checkedList.map(item => item.name)}
        onChange={onChange}
      />}
      <div className={styles.group_by}>
        <p className={styles.tag}>Group by: </p>
        <Radio.Group defaultValue={defaultGrouping} defaultChecked={defaultGrouping} onChange={(e) => changeGrouping(e.target.value)} size='small'>
          {selectedChart !== 'usage' && <Radio.Button value='lambda'>Lambda</Radio.Button>}
          {selectedChart === 'usage' && <Radio.Button value='type'>Type</Radio.Button>}
          {selectedChart !== 'invocations' && <Radio.Button value='region'>Region</Radio.Button>}
          <Radio.Button value='account'>Account</Radio.Button>
        </Radio.Group>
      </div>
    </section>
  )
}

export default AccountsOptions
