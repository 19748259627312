import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty, uniq } from 'lodash'
import { Row, Col } from 'antd'

import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'

import IconButton from 'components/buttons/icon'
import Section from 'components/layout/content/section'
import Empty from 'components/layout/content/empty'
import RadioSmall from 'components/antd/radio/small'
import { FilterIcon } from 'components/icons/font-awesome'
import ViolationItem from './item'
import ViolationFilters from './filter'
import { useViolationQuery } from 'hooks/api'
import { parseInsightRule } from 'containers/insights'

import styles from './styles.less'

const Violations = ({ loading, configRule, violations, handleCount, threshold }) => {
  const { ruleId, alarmId } = useParams()
  const rule = parseInsightRule(ruleId) || alarmId

  const { getValue: selected, setValue: setSelected } = useQueryParams('selected', [])
  const { getValue: regionsUrl } = useQueryParams('region', [])
  const { getValue: delegationsUrl } = useQueryParams('delegation', [])

  const [showOpenViolations, setShowOpenViolations] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [selectedRegions, setSelectedRegions] = useState([])
  const [selectedDelegations, setSelectedDelegations] = useState([])
  const [selectedInitial, setSelectedInitial] = useState(null)
  const [filtered, setFiltered] = useState([])

  const { data: violationFromUrl, isSuccess, isLoading, isError } = useViolationQuery(selectedInitial)

  const firstMount = useRef(true)

  const setInitialFilterStates = () => {
    const filterAppliedFromUrl = !isEmpty(regionsUrl) || !isEmpty(delegationsUrl)
    setSelectedDelegations(delegationsUrl || selectedRegions)
    setSelectedRegions(regionsUrl || selectedRegions)
    setShowFilters(filterAppliedFromUrl)
  }

  const handleComponentMount = () => {
    if (firstMount?.current) {
      firstMount.current = false
      if (!isEmpty(selected)) {
        setSelectedInitial(selected[0])
        setShowOpenViolations(true)
      } else {
        setShowOpenViolations(false)
      }
    }
    setInitialFilterStates()
  }
  const addResolvedViolation = !violations?.find(item => item.id === violationFromUrl?.id) && violationFromUrl?.rule === rule

  const filterViolations = () => {
    if (!violations) return

    const filters = {
      region: selectedRegions,
      delegation: selectedDelegations,
      selected: showOpenViolations ? selected : []
    }

    const violationsTotal = addResolvedViolation ? [violationFromUrl, ...violations] : violations

    const items = violationsTotal?.filter(item => {
      const conditions = ['region', 'delegation', 'selected'].map(type => {
        if (!filters[type] || isEmpty(filters[type])) return true
        else if (type === 'selected') return filters?.selected?.includes(item?.id)
        return filters[type]?.includes(item.resource[type])
      })

      return conditions.every(item => item)
    })

    setFiltered(items)
    handleCount && handleCount(items?.length)
  }

  const handleFetchError = () => {
    if (firstMount?.current || !isError) return

    setSelected(undefined)
    setSelectedInitial(null)
  }

  useEffect(handleComponentMount, [])
  useEffect(handleFetchError, [isError])
  useEffect(filterViolations, [selectedDelegations, selectedRegions, showOpenViolations, violations, violations && violations[0]?.resource?.delegationName, ruleId, isSuccess])

  return (
    <Section
      title='violations'
      titleUpperCase
      titleClassName={styles.section_title}
      actionsClassName={styles.filter_btn_wrapper}
      loading={isLoading || loading}
      actions={
        <>
          <IconButton className={styles.filter_btn} onClick={() => setShowFilters(!showFilters)} icon={<FilterIcon />} title='Filters' />
          {(!configRule || (selectedInitial && configRule)) &&
            <RadioSmall
              onChange={() => setShowOpenViolations(!showOpenViolations)} value={showOpenViolations}
              items={[
                { label: 'All', value: false },
                { label: 'Selected', value: 1 }
              ]}
            />
          }
        </>
      }
    >
      {showFilters &&
        <ViolationFilters
          regions={uniq(violations?.map(item => item?.resource?.region))}
          selectedRegions={selectedRegions}
          onChangeRegion={(value) => setSelectedRegions(value)}
          delegations={uniq(violations?.map(item => item?.resource?.delegation))}
          selectedDelegations={selectedDelegations}
          onChangeDelegation={(value) => setSelectedDelegations(value)}
        />}
      {isEmpty(filtered)
        ? <Empty title='No violations found for this rule' />
        : <Row gutter={[8, 8]}>
          {filtered?.map(violation => (
            <Col span={configRule && filtered?.length > 1 ? 12 : 24} key={violation?.id}>
              <ViolationItem
                violation={violation?.type === 'alarm'
                  ? { ...violation, metrics: [{ metric: violation.metric, namespace: violation.namespace.split('/').shift(), stat: violation.stat }], threshold }
                  : violation}
                onClick={() => setSelected(filterHelper(violation.id, selected))}
                selected={selected?.includes(violation?.id)}
              />
            </Col>))}
        </Row>}
    </Section>
  )
}

export default Violations
