export const EVENT_TYPES = {
  ERROR: 'error',
  INSIGHT: 'insight',
  ALARM: 'alarm'
}

export const SEVERITY_TYPES = {
  CRITICAL: 'critical',
  WARNING: 'warning',
  INFO: 'info'
}

export const INSIGHT_RULE_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disabled',
  SUSPENDED: 'suspended'
}

export const ERROR_TYPES = {
  EARLY_EXIT: 'early exit',
  TIMEOUT: 'timeout',
  CRASH: 'crash',
  OUT_OF_MEMORY: 'out of memory',
  CONFIGURATION_ERROR: 'configuration error'
}

export const VIOLATION_STATUSES = {
  RESOLVED: 'resolved',
  OPEN: 'open'
}

export const PILLARS = [
  {
    key: 'operational',
    name: 'OPERATIONAL EXCELLENCE'
  }, {
    key: 'cost',
    name: 'COST OPTIMIZATION'
  }, {
    key: 'performance',
    name: 'PERFORMANCE EFFICIENCY'
  }, {
    key: 'reliability',
    name: 'RELIABILITY'
  }, {
    key: 'security',
    name: 'SECURITY'
  }]
