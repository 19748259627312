import React from 'react'
import { last } from 'lodash'

import Items from 'components/inventory/items'
import { SuccessIcon, AlertIcon } from 'components/icons/font-awesome'
import { useChartMetricsQuery } from 'hooks/api'
import { ChartMetrics } from '../../metrics'

import styles from './styles.less'

const Icon = ({ status }) => {
  return status === 'success' ? <SuccessIcon className={styles[status]} /> : <AlertIcon className={styles[status]} />
}

const HEALTH_METRICS_IDS = [
  'OPENSEARCH_STATUS_GREEN',
  'OPENSEARCH_STATUS_YELLOW',
  'OPENSEARCH_STATUS_RED',
  'OPENSEARCH_WRITES_BLOCKED',
  'OPENSEARCH_MASTER_CONNECTION_STATUS',
  'OPENSEARCH_AUTOMATED_SNAPSHOT_FAILURE'
]

const OpenSearchHealth = ({ item, start, end, service }) => {
  const payloads = ChartMetrics[service].filter(item => HEALTH_METRICS_IDS.includes(item.id)).map(metric => {
    return {
      id: metric.id,
      payload: {
        dimensions: {
          resources: [
            { id: item.id, ...(item?.parent ? { parent: item.parent } : []) }
          ]
        },
        ...metric.filter,
        start: new Date(start),
        end: new Date(end)
      }
    }
  })

  const metricsData = useChartMetricsQuery(payloads)

  const loading = metricsData.some(item => item.isLoading)

  const getLast = (metric) => {
    const response = metricsData?.find(item => item?.data?.metricId === metric)
    const data = response?.data
    if (!data?.datapoints) return 0

    if (!data?.stats) return 0
    const stat = data?.stats[0]
    const values = data?.datapoints?.default?.map(item => item[stat])

    return last(values)
  }

  const formatted = [
    {
      id: 'domain-status',
      title: 'Domain ',
      status: getLast('OPENSEARCH_STATUS_GREEN') === 1 ? 'success' : getLast('OPENSEARCH_STATUS_YELLOW') === 1 ? 'warning' : 'error',
      icon: <Icon status={getLast('OPENSEARCH_STATUS_GREEN') === 1 ? 'success' : getLast('OPENSEARCH_STATUS_YELLOW') === 1 ? 'warning' : 'error'} />
    }, {
      id: 'writes-status',
      title: 'Cluster writes',
      status: getLast('OPENSEARCH_WRITES_BLOCKED') === 0 ? 'success' : 'error',
      icon: <Icon status={getLast('OPENSEARCH_WRITES_BLOCKED') === 0 ? 'success' : 'error'} />
    }, {
      id: 'master-connection-status',
      title: 'Master instance connection',
      status: getLast('OPENSEARCH_MASTER_CONNECTION_STATUS') === 1 ? 'success' : 'error',
      icon: <Icon status={getLast('OPENSEARCH_MASTER_CONNECTION_STATUS') === 1 ? 'success' : 'error'} />
    }, {
      id: 'snapshot-failure-status',
      title: 'Snapshot failure',
      icon: <Icon status={getLast('OPENSEARCH_AUTOMATED_SNAPSHOT_FAILURE') === 0 ? 'success' : 'error'} />,
      status: getLast('OPENSEARCH_AUTOMATED_SNAPSHOT_FAILURE') === 0 ? 'success' : 'error'
    }
  ]

  return <Items title=' ' items={formatted} loading={loading} type='overview' />
}

export default OpenSearchHealth
