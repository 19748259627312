import React from 'react'
import { Row, Col } from 'antd'

import Select from 'components/antd/select'
import { GlobeIcon, AwsIcon } from 'components/icons/font-awesome'
import { useDelegationsQuery } from 'hooks/api'

import styles from './styles.less'

const ViolationFilters = ({ regions, selectedRegions = [], onChangeRegion, delegations, selectedDelegations = [], onChangeDelegation }) => {
  const { data: delegationsList } = useDelegationsQuery()

  const delegationsItems = delegations?.map(delId => {
    const delegation = delegationsList?.find(item => item.id === delId)

    return {
      key: delId,
      value: delId,
      title: delegation?.name || delegation?.provider?.account || delId
    }
  })

  return (
    <div className={styles.action_filters}>
      <Row gutter={8}>
        <Col span={12}>
          <Select
            mode='multiple'
            allowClear
            defaultValue={selectedRegions}
            value={selectedRegions}
            options={regions}
            optionRender={({ item }) => (<><GlobeIcon className={styles.filter_icon} /> {item}</>)}
            placeholder='Filter by regions'
            onChange={onChangeRegion}
          />
        </Col>
        <Col span={12}>
          <Select
            mode='multiple'
            allowClear
            defaultValue={selectedDelegations}
            value={selectedDelegations}
            options={delegationsItems}
            optionRender={({ item }) => (<><AwsIcon className={styles.filter_icon} /> {item?.title}</>)}
            placeholder='Filter by delegations'
            onChange={onChangeDelegation}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ViolationFilters
