// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CzKXCvT50OsqhUSnCqX3 label {\n  text-transform: uppercase;\n  font-size: 8px;\n}\n.CzKXCvT50OsqhUSnCqX3 label.ant-radio-button-wrapper {\n  height: 20px;\n  line-height: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/antd/radio/small/styles.less"],"names":[],"mappings":"AAEA;EAEI,yBAAA;EACA,cAAA;AAFJ;AAII;EACE,YAAA;EACA,iBAAA;AAFN","sourcesContent":["@import '../../../../styles/main';\n\n.radio {\n  label {\n    text-transform: uppercase;\n    font-size: 8px;\n\n    &:global(.ant-radio-button-wrapper) {\n      height: 20px;\n      line-height: 18px;\n    }\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": "CzKXCvT50OsqhUSnCqX3"
};
export default ___CSS_LOADER_EXPORT___;
