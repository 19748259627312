import React from 'react'
import classnames from 'classnames'
import { Input as AntdInput } from 'antd'

import styles from './styles.less'

const Input = React.forwardRef(({ className, bordered = false, solid, ...props }, ref) => {
  return <AntdInput
    {...props}
    ref={ref}
    bordered={bordered}
    className={classnames(styles.input, {
      [styles.bordered]: bordered,
      [styles.solid]: solid
    }, className)}
  />
})

export default Input
