// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pW7XurRqKKe4n5TPU6Tw {\n  background-color: #48A860;\n}\n.JFBQ_eM7vWPJm6pkGgWE {\n  font-size: 120%;\n}\n.RJV4jHnQme__gwXkkhjH {\n  margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/billing/stripe/usage/calculator/styles.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;AAIA;EACE,eAAA;AAFF;AAKA;EACE,mBAAA;AAHF","sourcesContent":["@import '../../../../../styles/main';\n\n.billingSwitch {\n  background-color: @named-colors[chateau];\n}\n\n.footerTotals {\n  font-size: 120%;\n}\n\n.marginBottom {\n  margin-bottom: @spacing[largest]\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billingSwitch": "pW7XurRqKKe4n5TPU6Tw",
	"footerTotals": "JFBQ_eM7vWPJm6pkGgWE",
	"marginBottom": "RJV4jHnQme__gwXkkhjH"
};
export default ___CSS_LOADER_EXPORT___;
