import React from 'react'
import { List } from 'antd'
import moment from 'moment'

import CardItem from 'components/billing/stripe/card-item'
import RemoveButton from './buttons/remove'
import PrimaryButton from './buttons/primary'

import styles from './styles.less'

const expiration = method => {
  return `${method.expiryMonth}/${moment().year(method.expiryYear).format('YY')}`
}

const Method = ({ data, canDelete }) => {
  return (
    <List.Item
      className={styles.listItem}
      actions={[
        <PrimaryButton key={`primary-${data.stripeId}`} entityId={data.stripeId} isPrimary={data.isPrimary} />,
        <RemoveButton key={`delete-${data.stripeId}`} entityId={data.stripeId} disabled={!canDelete} />
      ]}
    >
      <List.Item.Meta
        title={<CardItem card={data} />}
        description={<div>Expires <b>{ expiration(data) }</b></div>}
      />
    </List.Item>
  )
}

export default Method
