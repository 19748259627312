import React from 'react'
import { isEmpty } from 'lodash'

import { Button } from 'antd'

import { MutedIcon, MutedSolidIcon, ResolvedIcon, ResolvedSolidIcon } from 'components/icons'

import styles from './styles.less'

const Resolve = ({ item, onResolve, onReopen }) => {
  if (item.status === 'resolved') {
    return (
      <Button type='link' size='large' className={styles.button} onClick={() => onReopen(item)}>
        <ResolvedSolidIcon className={styles.resolved} />
      </Button>
    )
  }

  return (
    <Button type='link' size='large' className={styles.button} onClick={() => onResolve(item)}>
      <ResolvedIcon />
    </Button>
  )
}

const Mute = ({ item, onMute, onUnmute }) => {
  if (item.mutedAt) {
    return (
      <Button type='link' size='large' className={styles.button} onClick={() => onUnmute(item)}>
        <MutedSolidIcon className={styles.muted} />
      </Button>
    )
  }

  return (
    <Button type='link' size='large' className={styles.button} onClick={() => onMute(item)}>
      <MutedIcon />
    </Button>
  )
}

const Actions = ({ item, loading, onResolve, onReopen, onMute, onUnmute }) => {
  if (isEmpty(item) || loading) return null

  return (
    <div className={styles.actions}>
      <Resolve item={item} onResolve={onResolve} onReopen={onReopen} />
      <Mute item={item} onMute={onMute} onUnmute={onUnmute} />
    </div>
  )
}

export default Actions
