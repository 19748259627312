import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'

export const useFetch = ({ action, wait, selector, params = [] }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => get(state, selector))
  const loading = useSelector(state => state.waiting.list[wait])

  const fetch = (params = []) => dispatch(action(...params))

  useEffect(() => {
    fetch(params)
  }, [])

  return { data, loading, fetch }
}

export const useFetchConditionally = ({ action, wait, selector, params = [], condition = false }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => get(state, selector))
  const loading = useSelector(state => state.waiting.list[wait])

  const fetch = () => dispatch(action(...params))

  useEffect(() => {
    if (condition) {
      fetch()
    }
  }, [condition])

  return { data, loading }
}

export const useRequestSelector = ({ wait, selector }) => {
  const data = useSelector(state => get(state, selector))
  const loading = useSelector(state => state.waiting.list[wait])
  return { data, loading }
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
