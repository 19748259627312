import React from 'react'
import { Line, Bar } from 'recharts'
import Numeral from 'numeral'
import colors from 'lib/colors'
import { defaultBarSize, defaultLineWidth, formatDuration } from 'components/charts/utils'
import { startCase } from 'lodash'

export const getDefaultMetricComponent = (metric) => {
  const key = `${metric?.metric}.${metric.stat}`
  const name = startCase(metric?.metric?.split('.').join(' '))

  return {
    component: data => <Line key={key} dataKey={key} stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type={'monotone'} name={name} dot={false} />
  }
}

export const defaultMetricFormatter = (val) => {
  return Numeral(val).format('0.[00]')
}

export const defaultMetricDataFormatter = (val) => {
  return val
}

const Metrics = {
  messages: {
    in: {
      sum: {
        component: data => <Bar key='messages.in.sum' dataKey='messages.in.sum' fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Messages in' dot={false} />,
        formatter: val => val
      }
    },
    out: {
      sum: {
        component: data => <Bar key='messages.out.sum' dataKey='messages.out.sum' fill={colors('chart', 'red')} barSize={defaultBarSize} name='Messages out' dot={false} />,
        formatter: val => val
      }
    },
    empty: {
      sum: {
        component: data => <Line key='messages.empty.sum' dataKey='messages.empty.sum' stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type={'monotone'} name='Empty messages' dot={false} />,
        formatter: val => val
      }
    },
    age: {
      avg: {
        component: data => <Line key='messages.age.avg' dataKey='messages.age.avg' stroke={colors('chart', 'primaryLight')} type={'monotone'} strokeWidth={defaultLineWidth} name='Messages age' dot={false} yAxisId='auto' />,
        formatter: val => formatDuration(val),
        yAxisFormatter: val => formatDuration(val)
      }
    },
    max: {
      component: data => <Line key='messages.max' dataKey='messages.max' stroke={colors('chart', 'primary')} type={'monotone'} strokeWidth={defaultLineWidth} name='Messages' dot={false} />,
      formatter: val => val
    },
    size: {
      max: {
        component: data => <Line key='messages.size.max' dataKey='messages.size.max' stroke={colors('chart', 'red')} type={'monotone'} strokeWidth={defaultLineWidth} name='Messages size' dot={false} yAxisId='auto' />,
        formatter: val => val
      }
    }
  },
  read: {
    max: {
      component: data => <Bar key='read.max' dataKey='read.max' stackId='a' fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Reads' />,
      formatter: val => val
    }
  },
  write: {
    max: {
      component: data => <Bar key='write.max' dataKey='write.max' stackId='a' fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Writes' />,
      formatter: val => val
    }
  },
  capacity: {
    read: {
      max: {
        component: data => <Line key='capacity.read.max' dataKey='capacity.read.max' stroke={colors('chart', 'red')} type={'monotone'} strokeWidth={defaultLineWidth} name='Capacity' dot={false} />,
        formatter: val => val
      }
    },
    write: {
      max: {
        component: data => <Line key='capacity.write.max' dataKey='capacity.write.max' stroke={colors('chart', 'red')} type={'monotone'} strokeWidth={defaultLineWidth} name='Capacity' dot={false} />,
        formatter: val => val
      }
    }
  },
  memory: {
    max: {
      component: data => <Line key='memory.max' dataKey='memory.max' stroke={colors('chart', 'red')} type={'monotone'} strokeDasharray='5 5' name='Maximum memory' dot={false} />,
      formatter: val => `${Numeral(val).format('0.[0]')}%`
    }
  },
  cpu: {
    max: {
      component: data => <Line key='cpu.max' dataKey='cpu.max' stroke={colors('chart', 'red')} type={'monotone'} strokeDasharray='5 5' name='Maximum CPU' dot={false} />,
      formatter: val => `${Numeral(val).format('0.[0]')}%`
    }
  },
  reservation: {
    memory: {
      max: {
        component: data => <Line key='reservation.memory.max' dataKey='reservation.memory.max' stroke={colors('chart', 'red')} type={'monotone'} strokeDasharray='5 5' name='Maximum memory' dot={false} />,
        formatter: val => `${Numeral(val).format('0.[0]')}%`
      }
    }
  },
  requests: {
    sum: {
      component: data => <Bar key='requests.sum' dataKey='requests.sum' fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Requests' dot={false} />,
      formatter: val => val
    }
  },
  errors: {
    '5xx': {
      sum: {
        component: data => <Bar key='errors.5xx.sum' dataKey='errors.5xx.sum' fill={colors('chart', 'red')} barSize={defaultBarSize} name='Errors (5xx)' dot={false} />,
        formatter: val => val
      }
    },
    '4xx': {
      sum: {
        component: data => <Bar key='errors.4xx.sum' dataKey='errors.4xx.sum' fill={colors('chart', 'redLight')} barSize={defaultBarSize} name='Errors (4xx)' dot={false} />,
        formatter: val => val
      }
    },
    default: {
      component: data => <Line key={'errors.' + data.stat} dataKey={'errors.' + data.stat} stroke={colors('chart', 'red')} strokeWidth={defaultLineWidth} type={'monotone'} name={'Errors'} dot={false} />,
      formatter: val => val
    }
  },
  latency: {
    avg: {
      component: data => <Line key='latency.avg' dataKey='latency.avg' stroke={colors('chart', 'yellow')} strokeWidth={defaultLineWidth} type={'monotone'} name='Average Latency' dot={false} />,
      formatter: val => formatDuration(val),
      yAxisFormatter: val => formatDuration(val)
    },
    p95: {
      component: data => <Line key='latency.p95' dataKey='latency.p95' stroke={colors('chart', 'red')} strokeDasharray='5 5' dot={false} type={'monotone'} name='Latency 95th percentile' />,
      formatter: val => formatDuration(val),
      yAxisFormatter: val => formatDuration(val)
    },
    scan: {
      avg: {
        component: data => <Line key='latency.scan.avg' dataKey='latency.scan.avg' stroke={colors('chart', 'primaryDark')} strokeWidth={defaultLineWidth} type={'monotone'} name='Average Scan Latency' />,
        formatter: val => formatDuration(val),
        yAxisFormatter: val => formatDuration(val)
      }
    },
    query: {
      avg: {
        component: data => <Line key='latency.query.avg' dataKey='latency.query.avg' stroke={colors('chart', 'primaryLight')} strokeWidth={defaultLineWidth} type={'monotone'} name='Average Query Latency' />,
        formatter: val => formatDuration(val),
        yAxisFormatter: val => formatDuration(val)
      }
    },
    item: {
      get: {
        avg: {
          component: data => <Line key='latency.item.get.avg' dataKey='latency.item.get.avg' stroke={colors('chart', 'yellow')} type={'monotone'} strokeWidth={defaultLineWidth} name='Average Item GET Latency' />,
          formatter: val => formatDuration(val),
          yAxisFormatter: val => formatDuration(val)
        }
      },
      put: {
        avg: {
          component: data => <Line key='latency.item.put.avg' dataKey='latency.item.put.avg' stroke={colors('chart', 'green')} type={'monotone'} strokeWidth={defaultLineWidth} name='Average Item PUT Latency' />,
          formatter: val => formatDuration(val),
          yAxisFormatter: val => formatDuration(val)
        }
      },
      update: {
        avg: {
          component: data => <Line key='latency.item.update.avg' dataKey='latency.item.update.avg' stroke={colors('chart', 'red')} type={'monotone'} strokeWidth={defaultLineWidth} name='Average Item UPDATE Latency' />,
          formatter: val => formatDuration(val),
          yAxisFormatter: val => formatDuration(val)
        }
      }
    }
  },
  throttles: {
    write: {
      sum: {
        component: data => <Bar key='throttles.write.sum' dataKey='throttles.write.sum' fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Write Throttles' dot={false} />,
        formatter: val => val
      }
    },
    read: {
      sum: {
        component: data => <Bar key='throttles.read.sum' dataKey='throttles.read.sum' fill={colors('chart', 'primaryLight')} barSize={defaultBarSize} name='Read Throttles' dot={false} />,
        formatter: val => val
      }
    }
  },
  incoming: {
    success: {
      sum: {
        component: data => <Bar key='incoming.success.sum' dataKey='incoming.success.sum' fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Incoming Success' dot={false} />,
        formatter: val => val
      }
    },
    records: {
      max: {
        component: data => <Bar key={`${data.metric}.${data.stat}`} dataKey={`${data.metric}.${data.stat}`} fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Incoming records' dot={false} />,
        formatter: val => val
      }
    }
  },
  outgoing: {
    success: {
      sum: {
        component: data => <Bar key='outgoing.success.sum' dataKey='outgoing.success.sum' fill={colors('chart', 'red')} barSize={defaultBarSize} name='Outgoing Success' dot={false} />,
        formatter: val => val
      }
    },
    records: {
      max: {
        component: data => <Bar key={`${data.metric}.${data.stat}`} dataKey={`${data.metric}.${data.stat}`} fill={colors('chart', 'red')} barSize={defaultBarSize} name='Outgoing records' dot={false} />,
        formatter: val => val
      }
    },
    age: {
      avg: {
        component: data => <Line key={`${data.metric}.${data.stat}`} dataKey={`${data.metric}.${data.stat}`} stroke={colors('chart', 'primary')} type={'monotone'} strokeWidth={defaultLineWidth} name='Records age' yAxisId={'auto'} dot={false} />,
        formatter: val => formatDuration(val),
        extraYAxisFormatter: val => formatDuration(val)
      }
    }
  },
  threshold: {
    default: {
      component: data => <Line key={'threshold.' + data.stat} dataKey={'threshold.' + data.stat} stroke={colors('chart', 'redLight')} strokeWidth={2} type={'monotone'} name='Threshold' dot={false} />,
      formatter: val => val
    }
  },
  max_memory_used: {
    avg: {
      component: data => <Line key={'max_memory_used.' + data.stat} dataKey={'max_memory_used.' + data.stat} stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type='monotone' name='Average Memory Used' dot={false} />,
      formatter: val => `${Numeral(val).format('0.[0]')}%`,
      yAxisFormatter: val => Numeral(val).format('0.[0]'),
      yAxisDomain: [0, dataMax => dataMax > 100 ? dataMax : 100],
      percentageChart: true
    }
  },
  percentage_memory_used: {
    max: {
      component: data => <Line key={'percentage_memory_used.' + data.stat} dataKey={'percentage_memory_used.' + data.stat} stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type={'monotone'} name={'Max Memory Used'} dot={false} />,
      formatter: val => `${Numeral(val).format('0.[0]')}%`,
      dataFormatter: val => val * 100,
      yAxisDomain: [0, dataMax => dataMax > 100 ? dataMax : 100],
      percentageChart: true
    },
    avg: {
      component: data => <Line key={'percentage_memory_used.' + data.stat} dataKey={'percentage_memory_used.' + data.stat} stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type={'monotone'} name={'Average Memory Used'} dot={false} />,
      formatter: val => `${Numeral(val).format('0.[0]')}%`,
      yAxisFormatter: val => Numeral(val).format('0.[0]'),
      dataFormatter: val => val * 100,
      yAxisDomain: [0, dataMax => dataMax > 100 ? dataMax : 100],
      percentageChart: true
    }
  },
  invocations: {
    sum: {
      component: data => <Bar key={'invocations.' + data.stat} dataKey={'invocations.' + data.stat} fill={colors('chart', 'primary')} barSize={defaultBarSize} name={'Invocations'} dot={false} />,
      formatter: val => `${Numeral(val).format('0.[00] a')}`
    },
    max: {
      component: data => <Line key={'invocations.' + data.stat} dataKey={'invocations.' + data.stat} stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type={'monotone'} name={'Invocations'} dot={false} />,
      formatter: val => `${Numeral(val).format('0.[00] a')}`
    }
  },
  duration: {
    max: {
      component: data => <Line key={`duration.${data.stat}`} dataKey={`duration.${data.stat}`} stroke={colors('chart', 'primary')} strokeWidth={defaultLineWidth} type={'monotone'} name={'Duration'} dot={false} />,
      formatter: val => formatDuration(val),
      yAxisFormatter: val => formatDuration(val)
    }
  },
  executions: {
    started: {
      sum: {
        component: data => <Bar key={`executions.${data.stat}`} dataKey={`${data.metric}.${data.stat}`} fill={colors('chart', 'primary')} barSize={defaultBarSize} name='Executions started' dot={false} />
      }
    }
  }
}

export default Metrics
