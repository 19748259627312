import React from 'react'

import { Dropdown, Switch } from 'antd'
import { EllipsisVIcon } from 'components/icons'

import styles from './styles.less'

const Actions = ({ item, handlers, disableEditing }) => {
  if (!item) return null
  return (
    <>
      <Switch size='small' defaultChecked={!item.disabled} checked={!item.disabled} onChange={(value) => handlers.update({ disabled: !value })} disabled={disableEditing} />
      <Dropdown
        trigger={['click']}
        menu={{
          items: [
            {
              key: 'delete',
              disabled: disableEditing,
              label: 'Delete'
            }
          ],
          onClick: handlers.remove
        }}
      >
        <span className={styles.extra}><EllipsisVIcon /></span>
      </Dropdown>
    </>
  )
}

export default Actions
