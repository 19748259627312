import React, { useState } from 'react'
import { Button } from 'antd'
import { isEmpty } from 'lodash'

import { useInterval } from 'lib/hooks/utils'
import LoadingDots from 'components/loading-dots'
import { Typography } from 'components/typography'
import {
  useOnboarderQuery,
  useDelegationsQuery
} from 'hooks/api'

import styles from './styles.less'

const TEXTS = [
  'creating notifications',
  'fetching resources',
  'creating insights'
]

const PollingScreen = ({ cancelStackCreation }) => {
  const [text, setText] = useState('creating stack')
  const { data: delegations } = useDelegationsQuery()
  const { data: onboarder } = useOnboarderQuery({ delegation: !isEmpty(delegations) && delegations[0].id })

  const stackCreated = delegations && onboarder && onboarder?.delegation === delegations[0]?.id

  useInterval(() => {
    if (!stackCreated) return setText('creating stack')
    const index = TEXTS.indexOf(text)
    setText(index === 2 ? 'getting ready' : TEXTS[index + 1])
  }, 3000)

  return (
    <>
      <LoadingDots />
      <Typography.Paragraph size='sm' align='center' className={styles.loading_text}>
        {text}
      </Typography.Paragraph>
      {!stackCreated && <Button type='link' onClick={() => cancelStackCreation()} className={styles.action_btn}>Cancel stack creation</Button>}
    </>
  )
}

export default PollingScreen
