import React from 'react'
import { uniqBy, uniq } from 'lodash'

import Section from 'components/layout/content/section'
import { Delegation } from 'components/items/progress-item'
import { useDelegationsQuery, useInsightsRulesQuery } from 'hooks/api'

import styles from './styles.less'

const DelegationsList = ({ insights, loading, selected = [], onSelect }) => {
  const { data: delegations, isLoading: loadingDelegations } = useDelegationsQuery()
  const { data: rules } = useInsightsRulesQuery()

  const ruleIds = rules?.filter(item => item?.count > 0)?.map(item => item.id)

  const allDelegations = insights?.map(item => item.resource.delegation)
  const uniqDelegations = uniq(allDelegations)

  const items = uniqDelegations?.map(item => {
    const delegation = delegations?.find(del => del.id === item)
    if (!delegation) return null
    const filteredInsights = uniqBy(insights.filter(violation => violation?.resource?.delegation === delegation.id && ruleIds?.includes(violation?.rule)), 'rule')

    return {
      title: delegation?.provider?.account,
      description: delegation?.name,
      count: filteredInsights?.length,
      total: ruleIds?.length,
      id: item
    }
  })?.filter(item => item)

  return (
    <Section title='Delegations' loading={loading || loadingDelegations} className={styles.wrapper} titleUpperCase>
      {items.length !== 0
        ? items.map(item =>
          <Delegation
            key={item.id}
            {...item}
            selected={selected}
            onSelect={onSelect}
          />)
        : <p>No violations found</p>}
    </Section>
  )
}

export default DelegationsList
