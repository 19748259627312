import React, { createContext, useContext, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'

export const CURRENT_ACCOUNT = 'current_account_context'
export const CURRENT_ORGANIZATION = 'current_organization_context'

export const OrganizationContext = createContext({
  account: null,
  organization: null,
  accountError: false,
  setOrgAndAccount: () => { }
})

export const findById = (permissions, identity) => {
  return permissions.map(permission => {
    const account = permission.accounts.find(item => item.id === identity)

    if (account) return [permission.organization, account]
  }).find(item => item) || []
}

export const setLocalStorage = (organization, account) => {
  localStorage.setItem(CURRENT_ACCOUNT, JSON.stringify(account))
  localStorage.setItem(CURRENT_ORGANIZATION, JSON.stringify(organization))
}

export const getLocalStorage = (organizations = []) => {
  const isUndefined = (value) => value === 'undefined' || !value
  const account = !isUndefined(localStorage.getItem(CURRENT_ACCOUNT)) ? JSON.parse(localStorage.getItem(CURRENT_ACCOUNT)) : {}

  // if account is in localstorage and check if account is in this user's organization
  const [fetchedOrganization, fetchedAccount] = findById(organizations, account?.id)

  if (account?.id && !isEmpty(fetchedAccount) && !isEmpty(fetchedOrganization)) {
    return {
      account: fetchedAccount,
      organization: fetchedOrganization
    }
  }

  return {
    account: {},
    organization: {}
  }
}

export const OrganizationProvider = (props) => {
  const [account, setContextAccount] = useState(null)
  const [organization, setContextOrganization] = useState(null)
  const [accountError, setAccountError] = useState(false)

  const setOrgAndAccount = (organization, account) => {
    if (!account && organization.onboarded) setAccountError(true)
    else setAccountError(false)
    setLocalStorage(organization, account)
    setContextAccount(account)
    setContextOrganization(organization)
  }

  const values = useMemo(() => ({ account, organization, accountError, setOrgAndAccount }), [account, organization])

  return (
    <OrganizationContext.Provider value={values}>
      {props.children}
    </OrganizationContext.Provider>
  )
}

export const useOrganization = () => {
  const context = useContext(OrganizationContext)

  if (context === undefined) throw new Error('`useOrganization` must be within a `UserProvider`')

  return context
}
