import React from 'react'
import { get, sortBy } from 'lodash'
import classnames from 'classnames'
import Numeral from 'numeral'
import { Card } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons/faGlobeAmericas'

import { useAllResourcesQuery, useResourcesMetricsQuery } from 'hooks/api'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import ListItem from 'components/dashboards/list-item'
import SkeletonLine from 'components/skeleton-line'
import { getResourcesByType } from 'lib/resources/filters'

import styles from './styles.less'

const Info = ({ lambda, loadingMetrics }) => {
  if (loadingMetrics) return <SkeletonLine size='xs' />
  return (
    <>
      <p className={styles.info}>
        <FontAwesomeIcon icon={faSigma} className={styles.icon} />
        {Numeral(lambda?.invocations).format('0.[00] a')}
      </p>
      <p className={styles.info}>
        <FontAwesomeIcon icon={faBug} className={classnames(styles.icon, styles.icon_red)} />
        {Numeral(lambda?.errors).format('0.[00] a')}
      </p>
    </>
  )
}

const DashboardLambdas = ({ type, title, className }) => {
  const { data: resources, isLoading } = useAllResourcesQuery()
  const { isLoading: loadingMetrics } = useResourcesMetricsQuery()
  const lambdas = getResourcesByType(resources, INVENTORY_SERVICES.Lambda.type)

  const sortByLastInvocation = item => -1 * get(item, 'lastInvocation.start')

  const sortedErrors = sortBy(lambdas, [item => -1 * get(item, 'errors', 0), sortByLastInvocation]).slice(0, 5)
  const sortedMost = sortBy(lambdas, [item => -1 * get(item, 'invocations', 0), sortByLastInvocation]).slice(0, 5)

  const formatted = (lambda) => {
    const delegation = lambda.delegationName ? `${lambda.delegationName} | ` : ''
    return {
      id: lambda.id,
      title: lambda.name,
      icon: '/static/vendor/icons/lambda.svg',
      url: `/inventory/${lambda.id}`,
      priority: type === 'errors' ? 'high' : '',
      type: 'lambda',
      info: <Info loadingMetrics={loadingMetrics} lambda={lambda} />,
      details: <span className={styles.region}>{delegation}<FontAwesomeIcon icon={faGlobeAmericas} className={styles.icon} />{lambda.region}</span>
    }
  }

  const items = type === 'errors' ? sortedErrors : sortedMost

  return <>
    <Card loading={isLoading} title={title} className={className}>
      {items.map(item => <ListItem item={formatted(item)} key={item.id} />)}
    </Card>
  </>
}

export default DashboardLambdas
