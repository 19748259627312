import React from 'react'
import { get } from 'lodash'
import { Table, Tooltip, Typography } from 'antd'

import { QuestionIcon } from 'components/icons'

import styles from './styles.less'

const { Text } = Typography

const Title = ({ text, help } = {}) => {
  if (!text) return null
  return (
    <>
      <Text strong>{text}</Text>
      {help ? <Tooltip title={help}><QuestionIcon className={styles.help} /></Tooltip> : null}
    </>
  )
}

const UsageTable = ({ data } = {}) => {
  const columns = [
    {
      title: <Text strong>Data ingestion</Text>,
      dataIndex: 'name',
      key: 'type'
    },
    {
      title: <Title text='Current usage' help='Since the start of the billing cycle' />,
      dataIndex: 'current',
      key: 'current',
      render: (value, item) => <Tooltip title={`${get(item, 'currentNotFixed')} GB`}>{`${value} GB`}</Tooltip>
    },
    {
      title: <Title text='Estimated usage' help='At the end of the billing cycle' />,
      dataIndex: 'estimated',
      key: 'estimated',
      render: (value, item) => <Tooltip title={`${get(item, 'estimatedNotFixed')} GB`}>{`${value} GB`}</Tooltip>
    }
  ]
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  )
}

export default UsageTable
