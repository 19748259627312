import React from 'react'

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import IconButton from 'components/buttons/icon'
import { Tooltip } from 'antd'

import styles from './styles.less'

const RemoveButton = (props) => {
  const title = props
  return (
    <Tooltip title={title}>
      <IconButton {...props} icon={faTimes} className={styles.remove} />
    </Tooltip>
  )
}

export default RemoveButton
