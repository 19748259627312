import React from 'react'
import { get } from 'lodash'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import Tag from './index'
import MinimalInput from 'components/minimal-input'

import styles from './styles.less'

const extractValue = val => {
  const split = (val || '').split(':')
  return {
    value: get(split, '[1]', ''),
    key: get(split, '[0]', '')
  }
}

class TagForm extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      ...extractValue(props.value),
      editing: !props.value,
      closeTimeout: null
    }
  }

  toggleEdit () {
    const { editing } = this.state
    this.setState({ editing: !editing })
  }

  static getDerivedStateFromProps (props, state) {
    if (!state.editing) {
      return extractValue(props.value)
    }
    return null
  }

  onBlur () {
    const { onChange, isNew } = this.props
    const { key, value } = this.state
    if (!key || !value) return

    const newState = {
      editing: isNew,
      value: !isNew ? value : '',
      key: !isNew ? key : ''
    }

    this.setState({
      closeTimeout: setTimeout(() => this.setState(newState, () => onChange(`${key}:${value}`)), 50)
    })
  }

  onFocus () {
    clearTimeout(this.state.closeTimeout)
  }

  render () {
    const { onChange, isNew } = this.props
    const { key, value, editing } = this.state

    const keyChanged = e => this.setState({ key: e.target.value })
    const valChanged = e => this.setState({ value: e.target.value })
    const deleteTag = () => onChange(null)

    if (!editing) {
      return <Tag name={key} value={value} className={styles.clickable} onClick={this.toggleEdit.bind(this)} />
    }

    return (
      <div className={classnames(styles.container, styles.edit_tag)} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)}>
        <MinimalInput
          placeholder='key'
          className={styles.name}
          value={key}
          onChange={keyChanged} />
        <MinimalInput
          placeholder='value'
          className={styles.value}
          value={value}
          onChange={valChanged} />
        {editing && !isNew && <FontAwesomeIcon onClick={deleteTag} className={styles.delete} icon={faTimes} />}
      </div>
    )
  }
}

export default TagForm
