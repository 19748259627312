import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, USER_ACTIVE } from '../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'

const getUser = async (organizationId, userId) => {
  const data = await apiFetch(paths.user(organizationId, userId))

  return data
}

export const useUserQuery = (enabled) => {
  const { organization } = useOrganization()
  const { user } = useUser()

  return useQuery(
    [...USER_ACTIVE, user?.attributes?.sub],
    () => getUser(organization.id, user?.attributes?.sub),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!user?.attributes?.sub && enabled,
      refetchOnWindowFocus: false
    }
  )
}
