import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import { useDispatch } from 'react-redux'
import { capitalize } from 'lodash'
import { formatDistanceToNowStrict } from 'date-fns'

import ListItem from 'components/dashboards/list-item'
import SeverityBadge from 'components/badge/severity'
import SkeletonLine from 'components/skeleton-line'
import { findInventoryByType } from 'lib/resources/constants'
import { API } from 'actions/api'
import { useAccountRoutes } from 'containers/routes'
import { stringifyQuery } from 'lib/hooks/use-query-params'
import { parseInsightRule } from 'containers/insights'
import { ErrorIcon, AlarmIcon, AwsServiceIcon, InsightIcon } from 'components/icons'
import { useIsMounted } from 'hooks'
import { useAllResourcesQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const EventListItems = (props) => {
  const { className, types, limit } = props
  const dispatch = useDispatch()
  const routes = useAccountRoutes()
  const isMounted = useIsMounted()

  const { account } = useOrganization()
  const { data: resourcesById, isLoading: resourcesLoading } = useAllResourcesQuery()

  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(true)

  const icon = (event) => {
    if (event.type === 'insight') {
      return event.resource.type
        ? <AwsServiceIcon service={findInventoryByType(event.resource.type)?.service} />
        : <InsightIcon status={event?.severity} />
    }

    if (event.type === 'error') {
      return <ErrorIcon status='critical' />
    }

    return <AlarmIcon status={event?.severity} />
  }

  const getEventUrl = (event) => {
    if (event.type === 'alarm') return { pathname: routes.alarms.alarm.url({ alarmId: event.rule }), search: stringifyQuery({ selected: event.id }) }
    if (event.type === 'insight') return { pathname: routes.insights.rule.url({ ruleId: parseInsightRule(event.rule) }), search: stringifyQuery({ selected: event.id }) }
    return routes.errors.error.url({ errorId: event.id })
  }

  const item = (event) => {
    const resource = resourcesById ? resourcesById[event?.resource] : {}
    const delegation = resource?.delegationName ? `| ${resource.delegationName}` : ''

    return {
      title: event?.name,
      info: (<>
        {resourcesLoading ? <SkeletonLine /> : <p className={styles.info}>{`${resource?.name || '<resource deleted or missing>'} ${delegation}`}</p>}
        {event.count && <p className={styles.error_count}>{event.count}</p>}
        {event.severity && <SeverityBadge severity={event.severity} />}
      </>),
      details: `${formatDistanceToNowStrict(event.lastOccurredAt || event.openedAt)} ago`,
      icon: icon(event),
      url: getEventUrl(event),
      type: event.type
    }
  }

  const pluralize = (str) => `${str}s`

  const getTitle = () => {
    const plurals = types.map(pluralize)

    return capitalize(plurals.join(' and '))
  }

  const getNoEventsTitle = () => {
    const plurals = types.map(pluralize)

    return `No ${plurals.join(' or ')} found`
  }

  const getNoEventsIcon = () => {
    if (types[0] === 'error') {
      return <ErrorIcon status='critical' />
    }

    return types[0] === 'alarm' ? <AlarmIcon /> : <InsightIcon />
  }

  useEffect(() => {
    if (!account.id) return
    const promises = types.map(type => dispatch(API.accounts.id(account.id).events.get({ type })))

    Promise.all(promises).then(results => {
      // Merge all responses into one events array
      isMounted.current && setEvents(results.reduce((events, result) => {
        return events.concat(result.payload?.data?.sort((a, b) => b.lastOccurredAt - a.lastOccurredAt)?.slice(0, limit || 5))
      }, []))

      isMounted?.current && setEventsLoading(false)
    })
  }, [account.id])

  return (
    <Card className={className} title={getTitle()} loading={eventsLoading || resourcesLoading}>
      {events.length
        ? events.map(event => <ListItem key={event?.id} item={item(event)} />)
        : <ListItem item={{ title: getNoEventsTitle(), type: types[0], icon: getNoEventsIcon() }} />
      }
    </Card>
  )
}

export default EventListItems
