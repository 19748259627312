import React from 'react'
import classnames from 'classnames'

import { formatStripePrice } from 'lib/billing-helpers'

import styles from '../styles.less'

const InvoiceLine = ({ description, amount, currency, interval, total }) => {
  return (
    <div className={styles.invoice_line}>
      <p className={classnames(total ? styles.total_title : '')}>{description}: </p>
      <p className={classnames(styles.text, styles.align_right, total && styles.total_title)}>{formatStripePrice(amount, currency)}{interval && `/${interval === 'month' ? 'mo' : 'yr'}`}</p>
    </div>
  )
}

export default InvoiceLine
