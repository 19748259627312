import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { DatePicker as AntdDatePicker } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'

import QuickSelectDate from './QuickSelectDate'
import QuickOptionsDate from './QuickOptionsDate'
import styles from './styles.less'

const { RangePicker } = AntdDatePicker

const recentDatesValueKey = 'recently_used_dates'

const DatePicker = ({ onChange, start, end, min, max, className, usage, dashboard, search, ...props }) => {
  const [fromStart, setFromStart] = useState('')
  const [untilEnd, setUntilEnd] = useState('')
  const [openSelectRange, setOpenSelectRange] = useState(false)
  const [opened, setOpened] = useState(false)
  const recentlyUsed = JSON.parse(window.sessionStorage.getItem(recentDatesValueKey))

  const minuteRangeStart = val => {
    if (val === 'startOfToday') return moment().hour(0).startOf('hour').valueOf()
    else if (val === 'startOfWeek') {
      return moment().day('Monday').startOf('hour').valueOf()
    }
    return moment().subtract(val, 'minutes').startOf('minutes').valueOf()
  }

  const minuteValue = moment(end).diff(moment(start), 'minutes')

  const handleSessionStorage = (start, end, range) => {
    const data = {
      start: start,
      end: end,
      range: range
    }
    if (!recentlyUsed) {
      window.sessionStorage.setItem(recentDatesValueKey, JSON.stringify([data]))
    } else {
      if (!(moment(recentlyUsed[recentlyUsed.length]?.start).startOf('minutes').format() === moment(data?.start).startOf('minutes').format())) {
        recentlyUsed.push(data)
      }
      const last4FromPrev = (recentlyUsed.length > 4)
        ? recentlyUsed.slice(1, 5)
        : recentlyUsed
      window.sessionStorage.setItem(recentDatesValueKey
        , JSON.stringify(last4FromPrev))
    }
  }

  const onMinutesChange = (val) => {
    onChange(minuteRangeStart(val), moment().valueOf())
    let range
    if (val === 'startOfToday') range = moment().diff(moment().hour(0).startOf('hour').valueOf(), 'minutes')
    else if (val === 'startOfWeek') {
      range = moment().diff(moment().day('Monday').startOf('hour').valueOf(), 'minutes')
    } else range = val
    handleSessionStorage(minuteRangeStart(val), moment().valueOf(), range)
    setOpenSelectRange(false)
  }

  const handleCalChange = (start, end, range) => {
    onChange(start, end)
    handleSessionStorage(start, end, range)
    setOpenSelectRange(false)
  }

  const onChangeDates = (dates) => {
    const start = dates[0].valueOf()
    const end = dates[1]?.valueOf() || Date.now()
    onChange(start.valueOf(), end.valueOf(), 'Absolute')
    setOpened(false)
    setOpenSelectRange(false)
  }

  useEffect(() => {
    const formattedEnd = moment(end).format() === moment().format() ? 'now' : moment(end).format('MMM Do, HH:mm')
    setFromStart(moment(start).format('MMM Do, HH:mm'))
    setUntilEnd(formattedEnd)
  }, [start, end])

  const isDisabledDate = date => date && (date.isBefore(min) || date.isAfter(max))

  return (
    <>
      <button type='button' onClick={e => setOpenSelectRange(true)} className={classnames(styles.cal_btn, search && styles.cal_btn_active)}>
        <div className={classnames(styles.range_container, className, dashboard && styles['range_container-dashboard'])}>
          <p className={classnames(styles.range_display, dashboard && styles['range_display-dashboard'])}>{fromStart}
            <FontAwesomeIcon icon={faLongArrowRight} className={classnames(styles.arrow_iconRight)} />
            {untilEnd}
          </p>
          <FontAwesomeIcon icon={faCalendar} className={styles.cal_icon} />
          <FontAwesomeIcon icon={faAngleDown} className={styles.arrow_icon} />
        </div>
      </button>

      {openSelectRange && (
        <div className={classnames(styles.wrapper, dashboard && styles.dashboard)}>

          <div className={classnames(styles.rangepicker_container, !opened && styles.closed, usage && styles.usage, dashboard && styles.dashboard)}>
            <RangePicker
              dropdownClassName={styles.rangepicker_wrapper}
              open={opened}
              showTime={{ format: 'HH:mm' }}
              disabledDate={date => isDisabledDate(date)}
              onCancel={() => setOpened(false)}
              onOpenChange={() => setOpened(false)}
              onOk={dates => onChangeDates(dates, 'Absolute')}
            />
          </div>
          <div className={classnames(styles.container, className, usage && styles.usage, dashboard && styles.dashboard)}>
            <p className={classnames(styles.range_display, styles.range_displayMobile, dashboard && styles['range_displayMobile-dashboard'])}>
              <span className={classnames(styles.title)}>NOW: </span>
              {fromStart}
              <FontAwesomeIcon icon={faLongArrowRight} className={classnames(styles.arrow_iconRight)} />
              {untilEnd}
            </p>
            <QuickSelectDate
              setOpenSelectRange={setOpenSelectRange}
              handleCalChange={handleCalChange}
              dashboard
            />
            <QuickOptionsDate onMinutesChange={onMinutesChange} minuteValue={minuteValue} />

            <button className={styles.custom_range_btn} onClick={() => setOpened(true)}>
              Select custom range
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCog}
              />
            </button>

            {recentlyUsed && <div className={classnames(styles.recent_container)}>
              <p className={classnames(styles.title)}>Recently used date ranges</p>
              {recentlyUsed.map((item, index) => {
                const formatted = `${moment(item.start).format('MMM D @ HH:mm')} to ${moment(item.end).format('MMM D, YYYY @ HH:mm')}`
                return <button key={index} className={classnames(styles.date_option)} onClick={e => handleCalChange(item.start, item.end, 'Absolute')}>{formatted}</button>
              }
              )}
            </div>}
          </div>
        </div>)
      }
    </>
  )
}

export default DatePicker
