import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Section from 'components/layout/content/section'
import { useAccountRoutes } from 'containers/routes'
import { AwsServiceIcon } from 'components/icons'
import { findInventoryByType } from 'lib/resources/constants'
import ListItem from 'features/side-list/item'
import { DetailsBelow } from '../list/item'
import { useErrorsQuery, useErrorQuery, useAllResourcesQuery } from 'hooks/api'

import styles from './styles.less'

const SimilarErrorsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()
  const { errorId } = useParams()

  const { data: errors, isLoading } = useErrorsQuery()
  const { isLoading: resourcesLoading } = useAllResourcesQuery()
  const { data: activeError } = useErrorQuery(errorId)

  const similar = errors?.filter(item => item.group === activeError?.group && item.id !== activeError.id)

  const items = similar?.map(error => {
    const delegation = error?.resource?.delegationName ? `${error?.resource?.delegationName} |` : ''
    return ({
      icon: <AwsServiceIcon service={findInventoryByType(error?.resource?.type)?.service} />,
      id: error.id,
      title: error.name,
      onClick: () => history.push({ pathname: routes.errors.error.url({ errorId: error.id }), search }),
      left: `${delegation} ${error?.resource?.region?.toUpperCase()}`,
      detailsBelowTitle: true,
      details: <DetailsBelow loading={resourcesLoading} item={error} />
    })
  })

  return (
    <Section title='Similar errors' titleUpperCase loading={isLoading}>
      {items?.length !== 0
        ? items?.map(error => <ListItem {...error} key={error.id} iconDivider className={styles.item} />)
        : <p>No similar errors found</p>
      }
    </Section>
  )
}

export default SimilarErrorsList
