import React from 'react'
import { Skeleton, Card, Row, Col } from 'antd'

import Totals from './totals'
import Prices from '../manage-subscription-modal/prices'

import styles from './styles.less'

const SubscriptionTotals = ({ invoice, title, price } = {}) => {
  return (
    <Col span={12}>
      <Prices data={price} className={styles.price} />
      <Card title={title} bordered={false} size='small' headStyle={{ border: 'none' }}>
        <Totals invoice={invoice} helpText={'Usage is reported once per day. Any changes in monitoring may take up to 24h to reflect in your billing. However, if the subscription\'s billing period ends sooner, your current usage data will always be taken into account.'} />
      </Card>
    </Col>
  )
}

const NextPayments = ({ onDemandInvoice, reservedInvoice, prices = {}, loading, className }) => {
  return (
    <Skeleton loading={loading}>
      <Card
        title='Upcoming payments'
        className={className}
      >
        <Row gutter={48}>
          <SubscriptionTotals title='On demand (billed monthly)' invoice={onDemandInvoice} price={prices.onDemand} />
          <SubscriptionTotals title='Reserved (billed yearly)' invoice={reservedInvoice} price={prices.reserved} />
        </Row>
      </Card>
    </Skeleton>
  )
}

export default NextPayments
