import React from 'react'
import { Button, Popconfirm, Typography } from 'antd'

import RouterLink from 'components/router-link'

import { useSuspendOrganizationQuery, useUnSuspendOrganizationQuery } from 'hooks/api'

import styles from './styles.less'

const { Text } = Typography

const PaymentMethodDisclaimer = ({ show }) => {
  if (!show) return null
  return (
    <>
      <RouterLink to={'/settings/payment'}>Add a payment method</RouterLink>
      <Text type='secondary'> before you can unsuspend you account</Text>
    </>
  )
}

const SuspensionButton = ({ suspended, paymentMethodExists, loading }) => {
  const { mutate: suspend, isLoading: suspending } = useSuspendOrganizationQuery()
  const { mutate: unSuspend, isLoading: unSuspending } = useUnSuspendOrganizationQuery()

  const onClick = () => suspended ? unSuspend() : suspend()

  const loadingSuspension = suspending || unSuspending

  const title = suspended ? 'Unsuspend' : 'Suspend'
  const disabled = suspended && !paymentMethodExists
  return (
    <>
      <Popconfirm
        title={`Are you sure you want to ${title.toLowerCase()} ?`}
        onConfirm={onClick}
        okText='Yes'
        cancelText='No'
        disabled={disabled}
      >
        <Button
          type='danger'
          block
          loading={loading || loadingSuspension}
          disabled={disabled}
          className={styles.suspendButton}
        >{title}</Button>
      </Popconfirm>
      <PaymentMethodDisclaimer show={disabled} />
    </>
  )
}

export default SuspensionButton
