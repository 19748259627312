import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classnames from 'classnames'
import styles from './styles.less'

export const IconWrapper = ({ icon, size, color, solid, className }) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      className={classnames(
        styles.icon,
        styles.aws,
        styles.default_aws,
        {
          [styles[color]]: !!color,
          [styles[size]]: !!size,
          [styles.solid]: !!solid
        }, className
      )}
    />
  )
}
