import { differenceInMinutes } from 'date-fns'
import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [
  //   {
  //   id: 'INVOCATIONS_TOTAL_DURATION',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'duration',
  //     stats: [STATISTICS.AVERAGE]
  //   },
  //   period: (start, end) => differenceInMinutes(end, start) * 60
  // }, {
  //   id: 'INVOCATIONS_TOTAL_CONCURRENCY',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'invocations.concurrent',
  //     stats: [STATISTICS.AVERAGE],
  //     fill: 'gapfill'
  //   },
  //   period: (start, end) => differenceInMinutes(end, start) * 60
  // }
]

export default metrics
