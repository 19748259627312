import { API } from 'actions/api'
import { message } from 'antd'

const mutations = ({ dispatch, organization, history }) => {
  return {
    setPrimary: async (paymentMethod) => {
      await dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.id(paymentMethod).setPrimary())
      await dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.get())

      message.success('Payment method added as primary method')
    },
    remove: async (paymentMethod) => {
      await dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.id(paymentMethod).delete())
      await dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.get())

      message.success('Payment method deleted')
    }
  }
}

export default mutations
