import React from 'react'

import Metadata from 'components/inventory/metadata'
import Attribute from './attribute'

const SecondaryIndex = ({ indexName, keySchema, className, inverse }) => {
  const data = [{
    title: `${indexName} (LSI)`,
    value: keySchema.map((schema) => <Attribute name={schema.name} value={schema.value} />)
  }]

  return <Metadata items={data} inverse={inverse} />
}

export default SecondaryIndex
