import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { MSG_VERIFICATION_SUCCEEDED } from 'containers/auth/messages'
import { Alert } from 'antd'
import { useQuery } from 'lib/hooks/utils'
import { useHistory } from 'react-router-dom'
import AuthTitle from '../title/index'
import { SpinnerIcon } from 'components/icons/font-awesome'

import { useConfirmSignup } from 'hooks/auth/signup'
import { useUser } from 'hooks/context/user-context'
import styles from '../styles.less'

const ConfirmContainer = () => {
  const query = useQuery()
  const history = useHistory()

  const { authenticated } = useUser()
  const { mutate: confirmSignUp, isSuccess: confirmSignUpSuccess, isError } = useConfirmSignup()

  const [countDown, setCountDown] = useState(null)

  const queryEmail = query.get('email')
  const queryVerification = query.get('verification')

  const confirmUser = () => {
    if (authenticated || !queryEmail || !queryVerification) return
    confirmSignUp({ email: queryEmail, code: queryVerification })
  }

  useEffect(confirmUser, [])
  useEffect(() => {
    if (!confirmSignUpSuccess) return

    window.localStorage.setItem(`${queryEmail}_confirmed`, 'true')

    ReactGA.event({
      category: 'Signup',
      action: 'Signup successful',
      label: queryEmail.replace('@', '.at.')
    })
    setCountDown(4000)
  }, [confirmSignUpSuccess])

  useEffect(() => {
    if (!countDown) return
    if (countDown === 1000) return history.push({ pathname: '/', query: queryEmail })

    const interval = setInterval(() => {
      setCountDown(countDown - 1000)
    }, 1000)

    return () => clearInterval(interval)
  }, [countDown])

  if (!queryEmail || !queryVerification || isError) return null

  return (
    <>
      {confirmSignUpSuccess
        ? <>
          <AuthTitle title='Registration confirmation' />
          <Alert
            message={MSG_VERIFICATION_SUCCEEDED.title}
            description={MSG_VERIFICATION_SUCCEEDED.message(countDown)} showIcon />
        </>
        : (
          <div className={styles.confirm_row}>
            <AuthTitle title='Confirming email' />
            <SpinnerIcon className={styles.spin} />
          </div>)}
    </>
  )
}

export default ConfirmContainer
