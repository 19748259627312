import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBellSlash } from '@fortawesome/pro-light-svg-icons/faBellSlash'
import { faBellSlash as faBellSlashSolid } from '@fortawesome/pro-solid-svg-icons/faBellSlash'
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faCrosshairs } from '@fortawesome/pro-light-svg-icons/faCrosshairs'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faEllipsisV as faEllipsisVlight } from '@fortawesome/pro-light-svg-icons/faEllipsisV'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRightSolid } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft as faChevronLeftSolid } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'
import { faCube } from '@fortawesome/pro-light-svg-icons/faCube'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer'
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faTrashClock } from '@fortawesome/pro-light-svg-icons/faTrashClock'
import { faMapPin } from '@fortawesome/pro-light-svg-icons/faMapPin'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faShare } from '@fortawesome/pro-light-svg-icons/faShare'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faGlobeAmericas } from '@fortawesome/pro-regular-svg-icons/faGlobeAmericas'
import { faGlobeEurope } from '@fortawesome/pro-regular-svg-icons/faGlobeEurope'
import { faGlobeAsia } from '@fortawesome/pro-regular-svg-icons/faGlobeAsia'
import { faAws } from '@fortawesome/free-brands-svg-icons/faAws'
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack'
import { faSortAmountDown } from '@fortawesome/pro-light-svg-icons/faSortAmountDown'
import { faStream } from '@fortawesome/pro-light-svg-icons/faStream'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faSnowflake } from '@fortawesome/pro-light-svg-icons/faSnowflake'
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo'
import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'
import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip'
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows'
import { faLinux } from '@fortawesome/free-brands-svg-icons/faLinux'
import { faPlug } from '@fortawesome/pro-light-svg-icons/faPlug'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faChartMixed } from '@fortawesome/pro-light-svg-icons/faChartMixed'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import { faFunction } from '@fortawesome/pro-regular-svg-icons/faFunction'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate'
import { faArrowsLeftRight } from '@fortawesome/pro-light-svg-icons/faArrowsLeftRight'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass'
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar'
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons/faClockRotateLeft'
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb'
import { faPenField } from '@fortawesome/pro-light-svg-icons/faPenField'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faSlidersV } from '@fortawesome/pro-light-svg-icons/faSlidersV'
import { faMonitorWaveform } from '@fortawesome/pro-light-svg-icons/faMonitorWaveform'
import { faArrowsUpDownLeftRight } from '@fortawesome/pro-light-svg-icons/faArrowsUpDownLeftRight'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'

import styles from './styles.less'

const Icon = (props) => <FontAwesomeIcon {...props} icon={props.icon} className={props.className} />

export const MutedIcon = (props) => <Icon {...props} icon={faBellSlash} />
export const MutedSolidIcon = (props) => <Icon {...props} icon={faBellSlashSolid} />
export const AlarmIcon = (props) => <Icon {...props} icon={faBell} />
export const DeleteIcon = (props) => <Icon {...props} icon={faTrashAlt} />
export const FilterIcon = (props) => <Icon {...props} icon={faFilter} />
export const TargetIcon = (props) => <Icon {...props} icon={faCrosshairs} />
export const BugIcon = (props) => <Icon {...props} icon={faBug} />
export const EllipsisVIcon = (props) => <Icon {...props} icon={faEllipsisV} />
export const EllipsisVLightIcon = (props) => <Icon {...props} icon={faEllipsisVlight} />
export const ChevronRightIcon = (props) => <Icon {...props} icon={faChevronRight} />
export const ChevronRightSolidIcon = (props) => <Icon {...props} icon={faChevronRightSolid} />
export const ChevronLeftIcon = (props) => <Icon {...props} icon={faChevronLeft} />
export const ChevronLeftSolidIcon = (props) => <Icon {...props} icon={faChevronLeftSolid} />
export const ChevronDownIcon = (props) => <Icon {...props} icon={faChevronDown} />
export const AccountIcon = (props) => <Icon {...props} icon={faCube} />
export const ResourceGroupIcon = (props) => <Icon {...props} icon={faLayerGroup} />
export const ResourceIcon = (props) => <Icon {...props} icon={faServer} />
export const SigmaIcon = (props) => <Icon {...props} icon={faSigma} />
export const ClockIcon = (props) => <Icon {...props} icon={faClock} />
export const TrashClockIcon = (props) => <Icon {...props} icon={faTrashClock} />
export const PinIcon = (props) => <Icon {...props} icon={faMapPin} />
export const ResolvedIcon = (props) => <Icon {...props} icon={faCheck} />
export const ResolvedSolidIcon = (props) => <Icon {...props} icon={faCheckSolid} />
export const ExternalLink = (props) => <Icon {...props} icon={faExternalLink} />
export const LinkIcon = (props) => <Icon {...props} icon={faLink} />
export const RedirectIcon = (props) => <Icon {...props} icon={faShare} />
export const LockIcon = (props) => <Icon {...props} icon={faLock} />
export const KeyIcon = (props) => <Icon {...props} icon={faKey} />
export const GlobeIcon = (props) => <Icon {...props} icon={faGlobe} />
export const GlobeAmericaIcon = (props) => <Icon {...props} icon={faGlobeAmericas} />
export const GlobeAsiaIcon = (props) => <Icon {...props} icon={faGlobeAsia} />
export const GlobeEuropeIcon = (props) => <Icon {...props} icon={faGlobeEurope} />
export const SuccessIcon = (props) => <Icon {...props} icon={faCheckCircle} />
export const AlertIcon = (props) => <Icon {...props} icon={faExclamationTriangle} />
export const AlertCircleIcon = (props) => <Icon {...props} icon={faExclamationCircle} />
export const InfoIcon = (props) => <Icon {...props} icon={faInfoCircle} />
export const AwsIcon = (props) => <Icon {...props} icon={faAws} />
export const SlackIcon = (props) => <Icon {...props} icon={faSlack} />
export const TeamsIcon = (props) => <Icon {...props} icon={faMicrosoft} />
export const SortIcon = (props) => <Icon {...props} icon={faSortAmountDown} />
export const StreamIcon = (props) => <Icon {...props} icon={faStream} />
export const AddIcon = (props) => <Icon {...props} icon={faPlus} />
export const ColdstartIcon = (props) => <Icon className={styles.coldstart} {...props} icon={faSnowflake} />
export const RetryIcon = (props) => <Icon {...props} icon={faRedo} />
export const MemoryIcon = (props) => <Icon {...props} icon={faMemory} />
export const ArrowLeftIcon = (props) => <Icon {...props} icon={faArrowToLeft} />
export const LongArrowRightIcon = (props) => <Icon {...props} icon={faLongArrowRight} />
export const ArrowBackIcon = (props) => <Icon {...props} icon={faArrowLeft} />
export const ArrowDownIcon = (props) => <Icon {...props} icon={faAngleDown} />
export const DoubleSidedArrow = (props) => <Icon {...props} icon={faArrowsLeftRight} />
export const MicroChipIcon = (props) => <Icon {...props} icon={faMicrochip} />
export const PlugIcon = (props) => <Icon {...props} icon={faPlug} />
export const WindowsIcon = (props) => <Icon {...props} icon={faWindows} />
export const LinuxIcon = (props) => <Icon {...props} icon={faLinux} />
export const QuestionIcon = (props) => <Icon {...props} icon={faQuestionCircle} />
export const BadgeCheckIcon = (props) => <Icon {...props} icon={faBadgeCheck} />
export const CopyIcon = (props) => <Icon {...props} icon={faCopy} />
export const EyeIcon = (props) => <Icon {...props} icon={faEye} />
export const CodeIcon = (props) => <Icon {...props} icon={faCode} />
export const ChartMixedIcon = (props) => <Icon {...props} icon={faChartMixed} />
export const SpinnerIcon = (props) => <Icon {...props} icon={faSpinnerThird} spin />
export const RefetchIcon = (props) => <Icon {...props} icon={faArrowsRotate} />
export const FunctionIcon = (props) => <Icon {...props} icon={faFunction} />
export const AngleDownIcon = (props) => <Icon {...props} icon={faAngleDown} />
export const CloseIcon = (props) => <Icon {...props} icon={faTimes} />
export const CalendarIcon = (props) => <Icon {...props} icon={faCalendar} />
export const SearchIcon = (props) => <Icon {...props} icon={faMagnifyingGlass} />
export const StarIcon = (props) => <Icon {...props} icon={faStar} />
export const StarSolidIcon = (props) => <Icon {...props} icon={faStarSolid} />
export const HistoryIcon = (props) => <Icon {...props} icon={faClockRotateLeft} />
export const SuggestionIcon = (props) => <Icon {...props} icon={faLightbulb} />
export const EditIcon = (props) => <Icon {...props} icon={faPenField} />
export const EmailIcon = (props) => <Icon {...props} icon={faEnvelope} />
export const SlidersIcon = (props) => <Icon {...props} icon={faSlidersV} />
export const DashboardIcon = (props) => <Icon {...props} icon={faMonitorWaveform} />
export const DragIcon = (props) => <Icon {...props} icon={faArrowsUpDownLeftRight} />
