import React, { useState } from 'react'
import { Form, Button } from 'antd'
import AuthTitle from 'containers/auth/title'
import Input from 'components/antd/input'
import Submit from 'components/antd/form/submit/index'
import { Typography } from 'components/typography/index'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const ConnectAws = ({
  handlePollingDelegations,
  createDelegation,
  creatingDelegation,
  externalId,
  delegationName,
  addDelegation = false,
  customAccountId
}) => {
  const [form] = Form.useForm()
  const { account } = useOrganization()

  const [manualConnect, setManualConnect] = useState(false)

  const onSubmit = (values) => {
    const { delegationName } = values
    if (!manualConnect) {
      const href = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?stackName=dashbird-delegation&param_ExternalId=${externalId}&param_DashbirdAccountId=${customAccountId || account?.id}${delegationName && `&param_DashbirdDelegationName=${delegationName}`}&templateURL=${process.env.CONFIG.CLOUDFORMATION_URL}`

      const win = window.open(href, '_blank')
      if (win != null) {
        win.focus()
      }
      // show loading state and wait for delegations to be created along cloud formation stack
      handlePollingDelegations(delegationName)
      return
    }
    // create delegation with given externalId and roleArn
    createDelegation(values)
  }

  return (
    <>
      {!addDelegation && <AuthTitle title='Connecting to your AWS account' />}
      <>
        <Form layout='vertical' onFinish={onSubmit} form={form} initialValues={{ delegationName }}>
          <Form.Item label='Name (Optional)' name='delegationName'>
            <Input solid={!addDelegation} />
          </Form.Item>
          <Typography.Paragraph size='xs' align='center'>Give a name to your AWS account for easier identification later on</Typography.Paragraph>
          <Form.Item
            label='External id'
            name='externalId'
            hidden={!manualConnect}
            rules={[
              { required: manualConnect, message: 'ExternalId must be defined' }
            ]}>
            <Input solid={!addDelegation} />
          </Form.Item>
          <Form.Item
            hidden={!manualConnect}
            name='arn'
            label='role arn'
            className={styles.arn_inputContainer}
            validateFirst
            rules={[
              { required: manualConnect, message: 'Arn must be defined' },
              { pattern: /^arn:aws:iam::\d+:role\/[a-zA-Z]+$/, message: 'Invalid role arn' }
            ]}>
            <Input solid={!addDelegation} autoFocus
              placeholder='arn:aws:iam::XXXXXXXXXXXX:role/dashbird-integration-stack-DashbirdIntegrationRole-XXXXXXXXXXXXX' />
          </Form.Item>
          <Submit loading={creatingDelegation} titleUpperCase text={manualConnect ? 'Create new delegation' : 'Launch aws cloudformation stack'} />
          {!manualConnect && <Typography.Paragraph size='xs' align='center'>{'Check "I Acknowledge that AWS CloudFormation might create IAM resources" before clicking "Create" in AWS CloudFormation stack creation'}</Typography.Paragraph>}
        </Form>
        <Button className={styles.action_btn} type='link' onClick={() => setManualConnect(!manualConnect)}>
          {manualConnect ? 'Automatic onboarding' : 'Submit Role ARN manually'}
        </Button>
      </>
    </>
  )
}

export default ConnectAws
