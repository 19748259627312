import React from 'react'

import styles from './styles.less'

const EmptyInvocations = ({ filter }) => {
  return (
    <div className={styles.empty}>{`No ${filter || 'occurrences'} to show`}</div>
  )
}

export default EmptyInvocations
