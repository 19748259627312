import { sub } from 'date-fns'
import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const end = end => new Date(end)
const start = (start, end) => sub(new Date(end), { minutes: 5 })

const metrics = [{
  id: 'ECS_CPU_UTILIZATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'cpu',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'ECS_MEM_UTILIZATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'memory',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'ECS_CPU_RESERVATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'reservation.cpu',
    stats: STATISTICS.MAX
  }
}, {
  id: 'ECS_MEM_RESERVATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'reservation.memory',
    stats: STATISTICS.MAX
  }
}, {
  id: 'ECS_SERVICE_TASKS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'tasks',
    stats: [STATISTICS.COUNT],
    period: 60
  },
  start,
  end,
  overrideRelative: true
}]

export default metrics
