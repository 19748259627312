import { combineReducers } from 'redux'
import { CREATE_PAYMENT_METHOD_SETUP_V2, GET_BILLING, GET_CUSTOMER_V2, POST_INVOICES_PREVIEW, GET_INVOICES_UPCOMING, GET_INVOICES_V2, GET_PAYMENT_METHODS_V2, GET_PRICES_V2, GET_SUBSCRIPTION_V2, GET_PROMOTION_V2 } from 'actions/billing-v2'

function billing (state = null, action) {
  switch (action.type) {
    case GET_BILLING:
      return action.payload
  }
  return state
}

function invoices (state = {}, action) {
  switch (action.type) {
    case GET_INVOICES_V2:
      return action.payload
  }
  return state
}

function invoiceUpcoming (state = {}, action) {
  switch (action.type) {
    case GET_INVOICES_UPCOMING:
      return action.payload
  }
  return state
}

function invoicePreview (state = {}, action) {
  switch (action.type) {
    case POST_INVOICES_PREVIEW:
      return action.payload
  }
  return state
}

function paymentMethods (state = {}, action) {
  switch (action.type) {
    case GET_PAYMENT_METHODS_V2:
      return action.payload
  }
  return state
}

function subscription (state = null, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_V2:
      return action.payload
  }
  return state
}

function customer (state = null, action) {
  switch (action.type) {
    case GET_CUSTOMER_V2:
      return action.payload
  }
  return state
}

function setup (state = null, action) {
  switch (action.type) {
    case CREATE_PAYMENT_METHOD_SETUP_V2:
      return action.payload
  }
  return state
}

function prices (state = {}, action) {
  switch (action.type) {
    case GET_PRICES_V2:
      return action.payload
  }
  return state
}

function promotion (state = {}, action) {
  switch (action.type) {
    case GET_PROMOTION_V2:
      return action.payload
  }
  return state
}

export default combineReducers({
  billing, invoices, invoiceUpcoming, invoicePreview, paymentMethods, subscription, customer, setup, prices, promotion
})
