import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Switch, Tooltip } from 'antd'

import { INSIGHTS_DESCRIPTIONS } from 'lib/insights-descriptions'
import { INSIGHT_RULE_STATUSES } from 'lib/event-constants'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import Violations from 'features/violations'
import RuleDescription from './rule-description'
import InsightMetrics from './metrics'
import { parseInsightRule } from '../index'
import { Typography } from 'components/typography'
import { useAccountRoutes } from 'containers/routes'
import { useInsightRuleQuery, useInsightsQuery, useUpdateInsightRule, useAllResourcesQuery, useDelegationsQuery } from 'hooks/api'

import styles from './styles.less'

const ToggleEnabled = ({ status }) => {
  if (!status) return null

  const { mutate: updateStatus, isLoading } = useUpdateInsightRule()

  const handleStatusUpdate = (value) => {
    updateStatus({ status: value ? INSIGHT_RULE_STATUSES.ACTIVE : INSIGHT_RULE_STATUSES.DISABLED })
  }

  const switchEnabled = status === INSIGHT_RULE_STATUSES.ACTIVE || status === INSIGHT_RULE_STATUSES.INACTIVE

  return (
    <Tooltip title={`${switchEnabled ? 'Disable' : 'Enable'} insight rule`} placement='bottomRight'>
      <Switch disabled={status === INSIGHT_RULE_STATUSES.SUSPENDED} defaultChecked={switchEnabled} checked={switchEnabled} onChange={handleStatusUpdate} loading={isLoading} />
    </Tooltip>
  )
}

const InsightDetails = () => {
  const { ruleId } = useParams()
  const routes = useAccountRoutes()
  const rule = parseInsightRule(ruleId)

  const { data: activeRule, isLoading: loadingRule } = useInsightRuleQuery(rule)
  const { data: insights, isLoading } = useInsightsQuery()
  useAllResourcesQuery()
  useDelegationsQuery()

  const violations = insights?.filter(item => item.rule === rule)

  const [filteredCount, setFilteredCount] = useState(0)

  const description = INSIGHTS_DESCRIPTIONS[rule]

  const configRule = isEmpty(activeRule?.metrics)

  const violationsCount = filteredCount > violations?.length ? filteredCount : violations?.length

  return (
    <Content
      title={activeRule?.name}
      breadcrumbs={['insights']}
      actions={<ToggleEnabled status={activeRule?.status} ruleId={rule} />}
      item={{}}
      loading={loadingRule || !activeRule}
      backRoute={routes.insights.url()}
      className={styles.scroll_wrapper}
      fixed
    >
      <InsightMetrics rule={activeRule} count={filteredCount !== violations?.length ? `${filteredCount} / ${violationsCount}` : violationsCount} loading={isLoading} />
      <Section solid contentWrapperClassName={styles.wrapper}>
        <Typography.Title level={4} upperCase>Description</Typography.Title>
        <RuleDescription text={activeRule?.description || description?.content || 'No description found'} />
      </Section>
      <Violations violations={violations} configRule={configRule} loading={isLoading} handleCount={setFilteredCount} />
    </Content>
  )
}

export default InsightDetails
