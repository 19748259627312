import queryString from 'query-string'
import { apiFetch, getDispatch, omitEmpty, waitingState } from '../lib/action-helpers'

const paths = {
  filter: (path, query) => `${path}?${query}`,
  organization: (identity) => `/v1/organizations/${identity}/billing/v2`,
  billing: (organization) => `${paths.organization(organization)}/billing`,
  invoices: (organization) => `${paths.organization(organization)}/invoices`,
  customer: (organization, customerId) => `${paths.organization(organization)}/customers/${customerId}`,
  invoicesUpcoming: (organization) => `${paths.invoices(organization)}/upcoming`,
  invoicesPreview: (organization) => `${paths.invoices(organization)}/preview`,
  paymentMethods: (organization) => `${paths.organization(organization)}/payment-methods`,
  paymentMethod: (organization, identity) => `${paths.paymentMethods(organization)}/${identity}`,
  paymentSetups: (organization) => `${paths.organization(organization)}/setup-intents`,
  paymentSetup: (organization, identity) => `${paths.paymentSetups(organization)}/${identity}`,
  subscription: (organization, subscriptionId) => `${paths.organization(organization)}/subscriptions/${subscriptionId}`,
  validateVAT: (organization) => `${paths.organization(organization)}/validate/vat`,
  discountPromotion: (organization) => `${paths.organization(organization)}/discounts/promotions`,
  prices: (organization) => `${paths.organization(organization)}/prices`
}

export const api = (organization) => {
  return {
    billing: {
      get: () => getBilling(organization)
    },
    customer: {
      id: (identity) => {
        return {
          get: () => getCustomer(organization, identity),
          put: (payload) => updateCustomer(organization, identity, payload)
        }
      }
    },
    invoices: {
      get: () => getInvoices(organization),
      upcoming: {
        get: (id) => getInvoicesUpcoming(organization, id)
      },
      preview: {
        post: (payload) => postInvoicePreview(organization, payload)
      }
    },
    paymentMethods: {
      get: () => getPaymentMethods(organization),
      post: (payload) => postPaymentMethod(organization, payload),
      id: (paymentMethod) => {
        return {
          delete: () => deletePaymentMethod(organization, paymentMethod),
          setPrimary: () => setPrimaryPaymentMethod(organization, paymentMethod)
        }
      },
      setup: {
        post: () => createPaymentMethodSetup(organization),
        delete: (identity) => cancelPaymentMethodSetup(organization, identity)
      }
    },
    subscription: {
      id: (subscriptionId) => {
        return {
          get: () => getSubscription(organization, subscriptionId),
          put: (payload) => putSubscription(organization, subscriptionId, payload)
        }
      }
    },
    validation: {
      VAT: (payload) => validateVAT(organization, payload)
    },
    discount: {
      promotion: (payload) => getPromotion(organization, payload)
    },
    prices: {
      get: (filters) => getPrices(organization, filters)
    }
  }
}

export const GET_BILLING = 'GET_BILLING'
export const GET_BILLING_ERROR = 'GET_BILLING_ERROR'
export const getBilling = (organizationId) => {
  return getDispatch(
    apiFetch(paths.billing(organizationId)),
    GET_BILLING,
    GET_BILLING_ERROR
  )
}

export const GET_CUSTOMER_V2 = 'GET_CUSTOMER_V2'
export const GET_CUSTOMER_V2_ERROR = 'GET_CUSTOMER_V2_ERROR'
export const getCustomer = (organization, identity) => {
  return getDispatch(
    apiFetch(paths.customer(organization, identity)),
    GET_CUSTOMER_V2,
    GET_CUSTOMER_V2_ERROR
  )
}

export const PUT_CUSTOMER_V2 = 'PUT_CUSTOMER_V2'
export const PUT_CUSTOMER_V2_ERROR = 'PUT_CUSTOMER_V2_ERROR'
export const updateCustomer = (organization, identity, payload) => {
  const { email, name, vat, country, city, line1, line2, state, postalCode } = payload
  const extraLine = line2 !== '' ? { line2: line2 } : {}
  const address = omitEmpty({ country, city, line1, ...extraLine, postalCode, state })
  const body = JSON.stringify({ email, name, vat, address })
  return getDispatch(
    apiFetch(paths.customer(organization, identity), { method: 'PUT', body }),
    PUT_CUSTOMER_V2,
    PUT_CUSTOMER_V2_ERROR
  )
}

export const GET_SUBSCRIPTION_V2 = 'GET_SUBSCRIPTION_V2'
export const GET_SUBSCRIPTION_V2_ERROR = 'GET_SUBSCRIPTION_V2_ERROR'
export const getSubscription = (organizationId, subscriptionId) => {
  return getDispatch(
    apiFetch(paths.subscription(organizationId, subscriptionId)),
    GET_SUBSCRIPTION_V2,
    GET_SUBSCRIPTION_V2_ERROR
  )
}

export const PUT_SUBSCRIPTION_V2 = 'PUT_SUBSCRIPTION_V2'
export const PUT_SUBSCRIPTION_V2_ERROR = 'PUT_SUBSCRIPTION_V2_ERROR'
export const putSubscription = (organizationId, subscriptionId, payload) => {
  return getDispatch(
    apiFetch(paths.subscription(organizationId, subscriptionId), { method: 'PUT', body: JSON.stringify(payload) }),
    PUT_SUBSCRIPTION_V2,
    PUT_SUBSCRIPTION_V2_ERROR
  )
}

export const GET_INVOICES_V2 = 'GET_INVOICES_V2'
export const GET_INVOICES_V2_ERROR = 'GET_INVOICES_ERROR_V2'
export const getInvoices = (organizationId, { limit, next } = {}) => {
  const query = queryString.stringify(omitEmpty({ limit, next }))

  return getDispatch(
    apiFetch(paths.filter(paths.invoices(organizationId), query)),
    GET_INVOICES_V2,
    GET_INVOICES_V2_ERROR
  )
}

export const GET_INVOICES_UPCOMING = 'GET_INVOICES_UPCOMING'
export const GET_INVOICES_UPCOMING_ERROR = 'GET_INVOICES_UPCOMING_ERROR'
export const getInvoicesUpcoming = (organizationId, id = '') => {
  const query = queryString.stringify({ subscriptionId: id })
  return getDispatch(
    apiFetch(paths.filter(paths.invoicesUpcoming(organizationId), query)),
    GET_INVOICES_UPCOMING,
    GET_INVOICES_UPCOMING_ERROR
  )
}

export const POST_INVOICES_PREVIEW = 'POST_INVOICES_PREVIEW'
export const POST_INVOICES_PREVIEW_ERROR = 'POST_INVOICES_PREVIEW_ERROR'
export const postInvoicePreview = (organizationId, payload) => {
  return getDispatch(
    apiFetch(paths.invoicesPreview(organizationId), { method: 'POST', body: JSON.stringify(payload) }),
    POST_INVOICES_PREVIEW,
    POST_INVOICES_PREVIEW_ERROR
  )
}

export const GET_PAYMENT_METHODS_V2 = 'GET_PAYMENT_METHODS_V2'
export const GET_PAYMENT_METHODS_V2_ERROR = 'GET_PAYMENT_METHODS_V2_ERROR'
export const getPaymentMethods = (organizationId) => {
  return getDispatch(
    apiFetch(paths.paymentMethods(organizationId)),
    GET_PAYMENT_METHODS_V2,
    GET_PAYMENT_METHODS_V2_ERROR
  )
}

export const CREATE_PAYMENT_METHOD_V2 = 'CREATE_PAYMENT_METHOD_V2'
export const CREATE_PAYMENT_METHOD_V2_ERROR = 'CREATE_PAYMENT_METHOD_V2_ERROR'
export const postPaymentMethod = (organizationId, payload) => {
  return getDispatch(
    apiFetch(paths.paymentMethods(organizationId), { method: 'POST', body: JSON.stringify(payload) }),
    CREATE_PAYMENT_METHOD_V2,
    CREATE_PAYMENT_METHOD_V2_ERROR
  )
}

export const DELETE_PAYMENT_METHOD_V2 = 'DELETE_PAYMENT_METHOD_V2'
export const DELETE_PAYMENT_METHOD_V2_ERROR = 'DELETE_PAYMENT_METHOD_V2_ERROR'
export const deletePaymentMethod = (organization, identity) => {
  const action = async () => {
    await apiFetch(paths.paymentMethod(organization, identity), { method: 'DELETE' })
    return { id: identity }
  }

  return getDispatch(
    action(),
    DELETE_PAYMENT_METHOD_V2,
    DELETE_PAYMENT_METHOD_V2_ERROR
  )
}

export const SET_PRIMARY_PAYMENT_METHOD_V2 = 'SET_PRIMARY_PAYMENT_METHOD_V2'
export const SET_PRIMARY_PAYMENT_METHOD_V2_ERROR = 'SET_PRIMARY_PAYMENT_METHOD_V2_ERROR'
export const setPrimaryPaymentMethod = (organization, identity, payload = { setPrimary: true }) => {
  const query = queryString.stringify(payload)
  const action = async () => {
    await apiFetch(paths.filter(paths.paymentMethod(organization, identity), query), { method: 'PUT' })
    return { id: identity }
  }

  return getDispatch(
    action(),
    SET_PRIMARY_PAYMENT_METHOD_V2,
    SET_PRIMARY_PAYMENT_METHOD_V2_ERROR
  )
}

export const CREATE_PAYMENT_METHOD_SETUP_V2 = 'CREATE_PAYMENT_METHOD_SETUP_V2'
export const CREATE_PAYMENT_METHOD_SETUP_V2_ERROR = 'CREATE_PAYMENT_METHOD_SETUP_V2_ERROR'
export const createPaymentMethodSetup = (organization) => {
  return getDispatch(
    apiFetch(paths.paymentSetups(organization), { method: 'POST' }),
    CREATE_PAYMENT_METHOD_SETUP_V2,
    CREATE_PAYMENT_METHOD_SETUP_V2_ERROR
  )
}

export const CANCEL_PAYMENT_METHOD_SETUP_V2 = 'CANCEL_PAYMENT_METHOD_SETUP_V2'
export const CANCEL_PAYMENT_METHOD_SETUP_V2_ERROR = 'CANCEL_PAYMENT_METHOD_SETUP_V2_ERROR'
export const cancelPaymentMethodSetup = (organization, intentId) => {
  return getDispatch(
    apiFetch(paths.paymentSetup(organization, intentId), { method: 'DELETE' }),
    CANCEL_PAYMENT_METHOD_SETUP_V2,
    CANCEL_PAYMENT_METHOD_SETUP_V2_ERROR
  )
}

export const VALIDATE_VAT_V2 = 'VALIDATE_VAT_V2'
export const VALIDATE_VAT_V2_ERROR = 'VALIDATE_VAT_V2_ERROR'
export const validateVAT = (organization, payload) => {
  return getDispatch(
    apiFetch(paths.validateVAT(organization), { method: 'POST', body: JSON.stringify(payload) }),
    VALIDATE_VAT_V2,
    VALIDATE_VAT_V2_ERROR
  )
}

export const GET_PROMOTION_V2 = 'GET_PROMOTION_V2'
export const GET_PROMOTION_V2_ERROR = 'GET_PROMOTION_V2_ERROR'
export const getPromotion = (organization, payload) => {
  const query = queryString.stringify(omitEmpty(payload))
  return getDispatch(
    apiFetch(paths.filter(paths.discountPromotion(organization), query)),
    GET_PROMOTION_V2,
    GET_PROMOTION_V2_ERROR
  )
}

export const GET_PRICES_V2 = 'GET_PRICES_V2'
export const GET_PRICES_V2_ERROR = 'GET_PRICES_V2_ERROR'
export const getPrices = (organization, filters) => {
  const query = queryString.stringify(omitEmpty(filters))
  return getDispatch(
    apiFetch(paths.filter(paths.prices(organization), query)),
    GET_PRICES_V2,
    GET_PRICES_V2_ERROR
  )
}

export const PAYMENT_METHOD_ADDED = 'PAYMENT_METHOD_ADDED'
export const isMethodBeingAdded = added => async dispatch => {
  return dispatch(waitingState(PAYMENT_METHOD_ADDED, added))
}
