import React from 'react'
import { Row, Col, Typography } from 'antd'
import { get } from 'lodash'

import Tag from '../status/tag'

import { formatStripePrice } from 'lib/billing-helpers'

import styles from './styles.less'

const { Text } = Typography

const formatPrice = (amount = 0, annual, currency) => {
  const monthly = annual ? amount / 12 : amount
  return formatStripePrice(monthly, currency)
}

const asterix = show => show ? '*' : ''

const FinePrint = ({ show, className }) => {
  if (!show) return null
  return (
    <Row type='flex' justify='center' style={{ marginTop: 10 }} className={className}>
      <Col><Text type='secondary'>* billed annually</Text></Col>
    </Row>
  )
}

const getTieredLabel = (prices, unit) => {
  return prices.map((price, idx) => {
    const text = price.limit ? `${price.amount} for up to ${price.limit} ${unit}` : `${price.amount} onwards`
    return <p key={idx} className={styles.tooltipText}>{text}</p>
  })
}

const PriceTag = ({ price = {}, unitLabel, annual }) => {
  const tiers = price.tiers
  if (tiers && tiers.length) {
    const prices = tiers.map(tier => ({ amount: formatPrice(tier.amount, annual, price.currency), limit: tier.upTo }))
    const label = `${prices[0].amount} per ${unitLabel}${asterix(annual)}`
    const tooltipTitle = <>{getTieredLabel(prices, unitLabel)}</>
    return <Tag value={label} tooltip={tooltipTitle} />
  }
  const formattedPrice = formatPrice(price.amount, annual, price.currency)
  const label = `${formattedPrice} per ${unitLabel}${asterix(annual)}`
  return <Tag value={label} />
}

const Prices = ({ data, isAnnual, className, textClassName } = {}) => {
  return (
    <>
      <Row type='flex' justify='center' gutter={48} style={{ marginTop: 10 }} className={className}>
        <Col>
          <PriceTag price={get(data, 'inventory')} unitLabel='resource' annual={isAnnual} />
        </Col>
        <Col>
          <PriceTag price={get(data, 'dataIngestion')} unitLabel='GB logs' annual={isAnnual} />
        </Col>
      </Row>
      <FinePrint show={isAnnual} className={textClassName} />
    </>
  )
}

export default Prices
