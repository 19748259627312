import React, { useState } from 'react'
import { compact } from 'lodash'

import Name from './form-field'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'

import styles from './styles.less'

const AddButton = ({ adding, onAdd, toggleAdding }) => {
  if (adding) return <Name onChange={onAdd} />

  return (
    <div className={styles.add} onClick={toggleAdding}>
      <FontAwesomeIcon className={styles.add_icon} icon={faPlus} />
      <span>add name</span>
    </div>
  )
}

const NamesForm = ({ names, onChange }) => {
  const [adding, setAdding] = useState(false)
  const nameChanged = (val, index) => onChange(compact(names.map((name, i) => i === index ? val : name)))

  const toggleAdding = () => setAdding(!adding)
  const addName = value => {
    if (!value) toggleAdding()
    onChange([...names, value])
    toggleAdding()
  }

  return (
    <div className={styles.container}>
      { names.map((name, i) => <Name key={`${i}-${name}`} value={name} editing onChange={val => nameChanged(val, i)} />) }
      <div className={styles.flexWrapper}>
        <AddButton adding={adding} onAdd={addName} toggleAdding={toggleAdding} />
      </div>
    </div>
  )
}

export default NamesForm
