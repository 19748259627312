import React from 'react'

import Items from 'components/inventory/items'

const ELBLoadBalancers = ({ loadBalancers }) => {
  const formatted = loadBalancers?.map(loadBalancer => {
    return {
      id: loadBalancer.loadBalancerArn,
      title: loadBalancer.loadBalancerName,
      url: `/inventory/${loadBalancer.id}`,
      description: loadBalancer.loadBalancerArn
    }
  })
  return (
    <Items items={formatted} />
  )
}

export default ELBLoadBalancers
