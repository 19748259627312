import React from 'react'

import styles from './styles.less'

const Empty = ({ title, details }) => {
  return (
    <div className={styles.empty}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.details}>{details}</div>
    </div>
  )
}

export default Empty
