import { maxBy } from 'lodash'
import { getMetricResourceDimensions } from 'lib/metric-helpers'
import { TIMESCALE_METRICS } from 'lib/metric-constants'
import { findInventoryByType, INVENTORY_SERVICES } from 'lib/resources/constants'

import {
  ALARM_METRICS_TO_CONVENTIONAL_METRICS,
  CONVENTIONAL_METRICS_TO_ALARM_METRICS,
  WIDGET_KIND
} from './dashboards-constants'

export const conventionalMetricToAlarmMetric = (metric) => {
  return CONVENTIONAL_METRICS_TO_ALARM_METRICS[metric] || metric
}

export const alarmMetricToConventionalMetric = (metric) => {
  return ALARM_METRICS_TO_CONVENTIONAL_METRICS[metric] || metric
}

export const getMetricKey = (widget, metric) => {
  const stats = metric?.stats?.sort()?.join('-') || ''

  return `${widget.id}-${metric?.resource}-${metric?.namespace}-${metric?.metric}-${stats}`
}

export const getInvocationsKey = (widget) => {
  return `${widget.id}-${widget?.definition?.service}-${widget?.definition?.flag || ''}-${widget?.definition?.targets || ''}`
}

export const getEventsKey = (widget, type) => {
  return `${widget.id}-${type}-${widget?.definition?.targets || ''}`
}

export const getMetricNamespace = (kind, resourceType, metric) => {
  const isMetricsWidget = kind === WIDGET_KIND.METRICS
  const isLambdaResourceType = resourceType === INVENTORY_SERVICES.Lambda.type
  const isTimescaleMetric = TIMESCALE_METRICS.includes(conventionalMetricToAlarmMetric(metric))

  if (isMetricsWidget && isLambdaResourceType && isTimescaleMetric) {
    return 'dashbird/lambda'
  }

  return 'aws'
}

const formatMetricsPayload = (widget, metric, resources, start, end) => {
  const resource = resources[metric.resource]
  if (!resource) return

  const service = findInventoryByType(resource?.type)
  const region = service.id === INVENTORY_SERVICES.Lambda.id ? { regions: [resource.region] } : {}

  const payload = {
    dimensions: {
      resources: getMetricResourceDimensions(resource, resources, metric.metric),
      ...region
    },
    fill: 'gapfill',
    namespace: metric.namespace,
    metric: metric.metric,
    stats: metric.stats,
    start,
    end
  }

  return payload
}

// TODO: remove duplicate metrics
// widget ids are different, but underlying definition might be the same
export const getChartPayloads = (widgets, resources, start, end) => {
  if (!widgets) return []
  if (!resources) return []

  // Filter out non-metrics widgets
  const metricsWidgets = widgets?.filter(widget => widget.kind === WIDGET_KIND.METRICS)

  // Prepare chart payload to send to metrics API
  const chartPayloads = metricsWidgets.reduce((accumulator, widget) => {
    const payloads = widget.definition?.metrics
      ?.filter(metric => !!metric.resource && !!metric.stats && !!metric.metric)
      ?.map(metric => {
        return {
          id: getMetricKey(widget, metric),
          payload: formatMetricsPayload(widget, metric, resources, start, end)
        }
      }) || []

    return [...accumulator, ...payloads]
  }, [])

  return chartPayloads
}

// Determines the size and position of newly created widgets
export const getWidgetLayout = (widgets, defaultDimenions = { height: 8 }) => {
  const DEFAULT_DIMENSIONS = { width: 8, height: defaultDimenions.height }
  const DEFAULT_POSITION = { x: 0, y: 0 }

  if (!widgets?.length) {
    return { ...DEFAULT_POSITION, ...DEFAULT_DIMENSIONS }
  }

  const widgetMaxY = maxBy(widgets, (widget) => widget.layout.y)
  const widgetPositionY = widgetMaxY.layout.y + widgetMaxY.layout.height - 1

  return { ...DEFAULT_POSITION, ...DEFAULT_DIMENSIONS, y: widgetPositionY }
}
