import React from 'react'
import { Radio, Tooltip } from 'antd'
import { sub } from 'date-fns'
import styles from './styles.less'

export const RELATIVE_VALUES = {
  60: '1h',
  [3 * 60]: '3h',
  [6 * 60]: '6h',
  [12 * 60]: '12h',
  [24 * 60]: '24h',
  [3 * 24 * 60]: '3d',
  [7 * 24 * 60]: '7d',
  [14 * 24 * 60]: '14d',
  [30 * 24 * 60]: '30d'
}

const REFRESH_VALUES = {
  [1 * 60 * 1000]: '1m',
  [2 * 60 * 1000]: '2m',
  [5 * 60 * 1000]: '5m',
  [15 * 60 * 1000]: '15m',
  [false]: 'off'
}

const RelativeDatePicker = ({ relativeSpan, onChange, refreshInterval, refetchHidden, handleIntervalChange, period, relativeOptions = RELATIVE_VALUES }) => {
  const handleRelativeTime = (e) => {
    const valueInMin = e.target.value
    const endNew = new Date()
    const startNew = sub(new Date(), { minutes: valueInMin })
    onChange(startNew, endNew, valueInMin)
  }

  return (
    <>
      <Radio.Group
        className={styles.radio}
        defaultValue={'1440'}
        value={relativeSpan?.toString()}
        size='small'
        onChange={handleRelativeTime}
      >
        {Object.keys(relativeOptions).map(value =>
          <Radio.Button value={value} key={value}>{relativeOptions[value]}</Radio.Button>
        )}
      </Radio.Group>
      {!refetchHidden && (
        <>
          <p className={styles.title}>Refresh interval</p>
          <Tooltip title={!relativeSpan ? 'Timeframe not chosen' : ''}>
            <Radio.Group
              className={styles.radio}
              defaultValue={'false'}
              value={refreshInterval?.toString()}
              size='small'
              onChange={handleIntervalChange}
              disabled={!relativeSpan}
            >
              {Object.keys(REFRESH_VALUES).map(value =>
                <Radio.Button value={value} key={value} disabled={period > 60 * 10 && parseInt(value) < 5 * 60 * 1000}>{REFRESH_VALUES[value]}</Radio.Button>
              )}
            </Radio.Group>
          </Tooltip>
        </>)}
    </>
  )
}

export default RelativeDatePicker
