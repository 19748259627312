import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { EditorState } from 'draft-js'
import { flatten } from 'lodash'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import InvocationsList from 'features/invocations'
import { useRequestsQuery, useSearchExecutionsQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'

import { durationMarkToTime } from 'features/invocations/filter/slider'
import { decorator } from 'containers/search/details/form/query-editor'

import styles from './styles.less'

const buildMemoryPayload = (memoryFilter, lambda) => ({ min: parseInt(lambda?.attributes?.find(item => item?.name === 'memorySize')?.value) * (memoryFilter[0] / 100), max: parseInt(lambda?.attributes?.find(item => item?.name === 'memorySize')?.value) * (memoryFilter[1] / 100) })
const buildDurationPayload = (durationFilter) => ({ min: durationMarkToTime(durationFilter[0]), max: durationMarkToTime(durationFilter[1]) })

const LambdaOccurrences = ({ service, error, url, onDrawerClose }) => {
  const [filter, setFilter] = useState('isError')
  const [durationFilter, setDurationFilter] = useState([0, 3000])
  const [memoryFilter, setMemoryFilter] = useState([0, 100])
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator))
  const [query, setQuery] = useState('')

  const handleSearch = () => {
    setQuery(editorState.getCurrentContnet().getPlainText())
  }

  const { data, isLoading: invocationsLoading, isFetching } = useSearchExecutionsQuery(
    error && error?.resource?.id && {
      filters: {
        errorHash: error?.hash,
        duration: buildDurationPayload(durationFilter),
        memory: buildMemoryPayload(memoryFilter, error?.resource),
        ...(filter && { flag: filter })
      },
      service: INVENTORY_SERVICES.Lambda.service,
      limit: 100
    }
  )
  const invocations = flatten(data?.pages?.map(page => page.data))
  return (
    <InvocationsList
      service={service}
      title='Occurrences'
      url={url}
      onClose={onDrawerClose}
      invocations={invocations}
      loading={invocationsLoading || isFetching || !invocations}
      resource={error?.resource}
      itemClassName={classnames(styles.item, styles.invocation)}
      searchEnabled={false}
      // editorState={editorState}
      // setEditorState={setEditorState}
      // onSearch={handleSearch}
      filter={filter}
      setFilter={value => setFilter(value)}
      durationFilter={durationFilter}
      setDurationFilter={setDurationFilter}
      memoryFilter={memoryFilter}
      setMemoryFilter={setMemoryFilter}
    />
  )
}

const AppsyncOccurrences = ({ service, error, onSelect, onDrawerClose }) => {
  const { data: requests, isLoading: requestsLoading } = useRequestsQuery(error && { errorHash: error?.errorHash })
  return (
    <InvocationsList
      service={service}
      title='Occurrences'
      onSelect={onSelect}
      onClose={onDrawerClose}
      invocations={requests}
      loading={requestsLoading}
      resource={error?.resource}
      itemClassName={classnames(styles.item, styles.invocation)}
    />
  )
}

const ErrorOccurrences = (props) => {
  const { service, error } = props
  const history = useHistory()
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const url = (invocation) => ({ pathname: routes.errors.request.url({ errorId: error.id, requestId: invocation.eventId }), search })

  const onSelect = (invocation) => {
    history.push({ pathname: routes.errors.request.url({ errorId: error.id, requestId: invocation.eventId }), search })
  }

  const onDrawerClose = () => {
    history.push({ pathname: routes.errors.error.url({ errorId: error?.id }), search })
  }

  if (service === INVENTORY_SERVICES.Lambda.id) {
    return (
      <LambdaOccurrences {...props} url={url} onDrawerClose={onDrawerClose} />
    )
  } else if (service === INVENTORY_SERVICES.AppSyncGraphQLApi.id) {
    return <AppsyncOccurrences {...props} onSelect={onSelect} onDrawerClose={onDrawerClose} />
  } else {
    return null
  }
}

export default ErrorOccurrences
