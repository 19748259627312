import React, { useState, useContext } from 'react'
import classnames from 'classnames'
import { Button, Dropdown } from 'antd'
import { ContentState, EditorState } from 'draft-js'

import { ColdstartIcon, RetryIcon, BugIcon, DoubleSidedArrow, ArrowDownIcon, StarIcon, HistoryIcon, SuggestionIcon } from 'components/icons/font-awesome'
import QueryEditor from 'containers/search/details/form/query-editor'
import FilterSlider, { formatDuration } from './slider'

import { useGetQueriesQuery } from 'hooks/api/queries'
import { SearchContext } from 'hooks/context/search-context'

import styles from './styles.less'

const Filter = ({ filter, setFilter, durationSliderValue, setDurationSliderValue, memorySliderValue, setMemorySliderValue, editorState, setEditorState, onSearch, searchEnabled = true, resource }) => {
  const { recentQueries, handleSetLocalStorage, errorMsg, validateQuery } = useContext(SearchContext)
  const { data: savedQueries } = useGetQueriesQuery()
  const suggestedQueries = []

  const [sliderOpen, setSliderOpen] = useState()

  const toggleDurationSlider = () => {
    if (sliderOpen === 'duration') setSliderOpen()
    else setSliderOpen('duration')
  }

  const toggleMemorySlider = () => {
    if (sliderOpen === 'memory') setSliderOpen()
    else setSliderOpen('memory')
  }

  const handleQueryClick = ({ key }) => {
    const query = savedQueries?.find(item => item.id === key)?.query || recentQueries?.find(item => item.id === key)?.query
    setEditorState(EditorState.push(editorState, ContentState.createFromText(query)))
  }

  const handleSearch = () => {
    const validation = validateQuery()
    if (!validation.isValid) return
    onSearch()

    handleSetLocalStorage({
      query: editorState.getCurrentContent().getPlainText(),
      service: 'lambda',
      targets: [resource.id]
    })
  }

  const savedQueriesItems = (savedQueries || [])?.filter(item => item.service === 'lambda')?.map(item => ({
    key: item.id,
    title: item.query,
    label: (
      <div className={styles.dropdown_item_container}>
        <span>{item.name}</span>
        <span className={styles.dropdown_item_type}>{'Saved'}</span>
      </div>),
    icon: <StarIcon />
  }))

  const recentQueriesItems = (recentQueries || [])?.filter(item => item.service === 'lambda')?.map(item => ({
    key: item.id,
    icon: <HistoryIcon />,
    label: (
      <div className={styles.dropdown_item_container}>
        <span>{item.query}</span>
        <span className={styles.dropdown_item_type}>{'History'}</span>
      </div>)
  })
  )

  const suggestedQueriesItems = suggestedQueries?.filter(item => item.service === 'lambda')?.map(item => ({
    key: item.id,
    icon: <SuggestionIcon />,
    label: (
      <div className={styles.dropdown_item_container}>
        <span>{item.query}</span>
        <span className={styles.dropdown_item_type}>{'Suggestion'}</span>
      </div>)
  }))

  return (
    <div className={styles.container}>
      {searchEnabled && <div className={styles.search_container}>
        <div className={styles.search_bar}>
          <QueryEditor editorState={editorState} setEditorState={setEditorState} handleSubmit={handleSearch} allowSelectingSearches />
        </div>
        <Dropdown
          overlayStyle={{ width: '40%' }}
          menu={{
            items: [...savedQueriesItems, ...recentQueriesItems, ...suggestedQueriesItems],
            onClick: handleQueryClick
          }}
          placement='bottomRight'
        >
          <div className={styles.expander_arrow}>
            <ArrowDownIcon className={styles.arrow_down_icon} />
          </div>
        </Dropdown>
        <Button disabled={errorMsg} type='primary' onClick={handleSearch} className={styles.search_btn}>Search</Button>
      </div>}
      <div className={styles.filter_container}>
        <div className={styles.filter_sliders_container}>
          <div className={styles.filter_container}>
            <div className={classnames(styles.filter_title, { [styles.active_filter_title]: sliderOpen === 'duration' })} onClick={toggleDurationSlider}>
              <span className={styles.text_span}>{'Duration'}</span>
              <div>
                <span>{formatDuration(durationSliderValue[0])}</span><DoubleSidedArrow className={styles.arrow_icon} /><span>{formatDuration(durationSliderValue[1])}</span>
              </div>
            </div>
            <div className={classnames(styles.filter_title, { [styles.active_filter_title]: sliderOpen === 'memory' })} onClick={toggleMemorySlider}>
              <span className={styles.text_span}>{'Memory'}</span>
              <div>
                <span>{`${memorySliderValue[0]}%`}</span><DoubleSidedArrow className={styles.arrow_icon} /><span>{`${memorySliderValue[1]}%`}</span>
              </div>
            </div>
          </div>
          {sliderOpen === 'duration' && <FilterSlider type='duration' defaultValue={durationSliderValue} sliderValue={durationSliderValue} setSliderValue={setDurationSliderValue} />}
          {sliderOpen === 'memory' && <FilterSlider type='percentage' defaultValue={memorySliderValue} sliderValue={memorySliderValue} setSliderValue={setMemorySliderValue} />}
        </div>
        <div className={styles.filter_icons_container}>
          <BugIcon onClick={() => filter === 'isError' ? setFilter(null) : setFilter('isError')} className={classnames(styles.filter_icon, { [styles.active_filter_icon]: filter === 'isError' })} />
          <ColdstartIcon onClick={() => filter === 'isColdstart' ? setFilter(null) : setFilter('isColdstart')} className={classnames(styles.filter_icon, { [styles.active_filter_icon]: filter === 'isColdstart' })} />
          <RetryIcon onClick={() => filter === 'isRetry' ? setFilter(null) : setFilter('isRetry')} className={classnames(styles.filter_icon, { [styles.active_filter_icon]: filter === 'isRetry' })} />
        </div>
      </div>
    </div>
  )
}

export default Filter
