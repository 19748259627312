import React, { useState } from 'react'
import { keys, last } from 'lodash'
import classnames from 'classnames'
import { Popover } from 'antd'

import { prepareUsageData } from 'containers/settings/billing/subscriptions/usage'
import { useAccountRoutes } from 'containers/routes'

import AddAccountModal from '../add-account-modal/index'
import TableLayout from 'components/settings/table-layout/index'
import { useOrgResourcesUsageQuery, useOrganizationUsersQuery, useAccountsQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const AccountsTable = () => {
  const routes = useAccountRoutes()
  const { account } = useOrganization()
  const userRole = account?.role
  const { data: resourcesUsageByAccount, isLoading: loadingResourcesUsage } = useOrgResourcesUsageQuery()
  const { data: users, isLoading: loadingUsers } = useOrganizationUsersQuery()

  const { data: accounts, isLoading: loadingAccounts } = useAccountsQuery()

  const loading = loadingAccounts || loadingUsers || loadingResourcesUsage

  const [displayAccounts, setDisplayAccounts] = useState('all')
  const [accountModalVisible, setAccountModalVisible] = useState(false)

  const resourcesCount = Object.entries(resourcesUsageByAccount || {}).map(([accountId, values]) => {
    const lastDayRecords = last(values.data)?.records || []

    const { inventory } = prepareUsageData(lastDayRecords)
    const count = inventory.reduce((acc, item) => acc + item.count, 0)
    return {
      id: accountId, count
    }
  })

  const formattedAccounts = accounts?.map(account => {
    const usersList = users?.filter(user => keys(user.permissions).includes(account.id) || !user.permissions)
    const resourceCount = resourcesCount.find(item => item.id === account.id)?.count || 0
    return {
      key: account.id,
      account: account.name,
      status: 'active',
      access: usersList || ['not available'],
      resources: resourceCount
    }
  }) || []

  const accessColumn = userRole === 'owner' || userRole === 'admin'
    ? [{
      title: 'Access',
      dataIndex: 'access',
      key: 'access',
      render: (item, record) => (
        <Popover
          content={item.map(user => <p key={user.id}>{user.email}</p>)}
          placement='right'
          trigger='hover'>
          <button className={classnames(styles.access_count, styles[record.status])}>{item.length} users</button>
        </Popover>)
    }]
    : []

  const columns = [
    {
      title: 'Account name',
      key: 'account',
      sorter: (a, b) => a.account.localeCompare(b.account),
      sortDirections: ['descend', 'ascend'],
      render: record => <span>{record.account}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    ...accessColumn,
    {
      title: 'Resource count',
      key: 'resources',
      dataIndex: 'resources'

    }
  ]

  const options = [
    { val: 'active', name: 'Active' },
    { val: 'disabled', name: 'Disabled' }
  ]

  const filteredByStatus = displayAccounts !== 'all' ? formattedAccounts?.filter(item => item.status === displayAccounts) : formattedAccounts

  return (
    <>
      <TableLayout
        columns={columns}
        data={filteredByStatus}
        loading={loading}
        filterOptions={options}
        setDataFilter={setDisplayAccounts}
        setModalVisible={setAccountModalVisible}
        tableTitle={'account'}
        rowClickUrl={(record) => routes.settings['manage-accounts'].account.url({ id: record.key })}
        showAddButton
      />
      <AddAccountModal visible={accountModalVisible} setVisible={setAccountModalVisible} />
    </>
  )
}

export default AccountsTable
