import React from 'react'
import { formatBoolean } from 'lib/utils'

import styles from './styles.less'

const Boolean = ({ data }) => {
  const boolean = formatBoolean(data)
  return <span className={styles[boolean]} >{boolean ? 'Enabled' : 'Disabled'}</span>
}

export default Boolean
