const moment = require('moment')
const { get } = require('lodash')

module.exports = subscription => {
  const start = get(subscription, 'periodStart', 0)
  const end = get(subscription, 'periodEnd', 0)
  return {
    start: moment.unix(start).startOf('minute'),
    end: moment.unix(end).startOf('minute')
  }
}
