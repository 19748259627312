import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'

import Section from 'components/layout/content/section'
import InventoryTags from 'components/inventory/tags'
import Events from 'components/inventory/events'
import InventoryResourceContainer from '../../../container'
import TargetGroupMetadata from './metadata'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import ELBLoadBalancers from './load-balancers'
import { HEALTHY } from '../../../metrics-tabs/services/elb-target-group'
import Empty from 'components/layout/content/empty'
import { useAllResourcesQuery } from 'hooks/api'

import Tabs from 'components/antd/tabs'

const Config = ({ item }) => {
  return (<>
    {item.tags.length && <InventoryTags tags={item.tags} inverse />}
    <TargetGroupMetadata attributes={item.attributes} inverse />
  </>)
}

const ELBTargetGroup = ({ item }) => {
  const [loadBalancers, setLoadBalancers] = useState([])
  const { data: resources } = useAllResourcesQuery()

  useEffect(() => {
    const attributes = objectify(item.attributes)
    const parsedLoadBalancers = JSON.parse(attributes.loadBalancers)
    if (!parsedLoadBalancers || !parsedLoadBalancers.length || !resources || !Object.keys(resources).length) return

    // Find a "parent" from target group's load balancers
    item.parent = Object.values(resources || {}).find(resource => resource.externalId === parsedLoadBalancers[0].loadBalancerArn)

    // Set load balancer ids for links
    setLoadBalancers(parsedLoadBalancers.map(loadBalancer => {
      return {
        ...loadBalancer,
        id: Object.values(resources || {}).find(resource => resource.externalId === loadBalancer.loadBalancerArn)?.id
      }
    }))
  }, [item, resources])

  // no parent = no metrics
  if (!item.parent) return <Empty title='Metrics not found' />

  return (
    <>
      <InventoryResourceContainer item={item} metadata={{ loadBalancers }} service={INVENTORY_SERVICES.ELBTargetGroup.id} initialChart={HEALTHY}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey="loadBalancers" items={[
                { key: 'loadBalancers', label: 'Load Balancers', children: <ELBLoadBalancers loadBalancers={loadBalancers} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col span={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default ELBTargetGroup
