import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Form } from 'antd'
import Input from 'components/antd/input'
import { Typography } from 'components/typography'
import styles from './styles.less'

const submit = (values, handlers, channel) => {
  const payload = { name: values.name, uri: values.uri }
  channel ? handlers.update(payload) : handlers.create({ ...payload, type: 'sns' })
}

const SNS = ({ channel, handlers, saving }) => {
  const history = useHistory()

  return (
    <Form onFinish={(values) => submit(values, handlers, channel)} layout='vertical' initialValues={{ name: channel?.name, uri: channel?.uri }}>
      <Form.Item name='name' label='Name' rules={[{ required: true }]}>
        <Input placeholder='name' />
      </Form.Item>
      <Form.Item name='uri' label='Topic ARN' rules={[{ required: true }]}>
        <Input placeholder='topic arn' />
      </Form.Item>
      <Typography.Title level={2}>Amazon SNS Access Policy</Typography.Title>
      <Typography.Paragraph>
        In order for Dashbird to be able to publish messages to the SNS topic, our account ID needs to be allowed to do so.
        <br />
        This can be done by adding a statement to the Amazon SNS topic access policy statements list:
      </Typography.Paragraph>
      <pre className={styles.code}>
        {'{\n' +
          '      "Sid": "DashbirdSNSPublishPolicy",\n' +
          '      "Effect": "Allow",\n' +
          '      "Principal": {\n' +
          '        "AWS": ["458024764010"]\n' +
          '      },\n' +
          '      "Action": "SNS:Publish",\n' +
          '      "Resource": "arn:aws:sns:<your-region>:<your-account-id>:<your-topic-name>"\n' +
          '}'}
      </pre>
      <Typography.Paragraph>
        Read more from here:&nbsp;
        <a href={'https://dashbird.io/docs/quickstart/notification-channels/'} target={'_blank'} rel="noreferrer">
          https://dashbird.io/docs/quickstart/notification-channels/
        </a>
      </Typography.Paragraph>
      <Form.Item>
        <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
        <Button type='link' onClick={history.goBack}>Cancel</Button>
      </Form.Item>
    </Form>
  )
}

export default SNS
