import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Modal, Steps, Button, Tooltip, message } from 'antd'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { API } from 'actions/api'
import { GET_PRICES_V2, PUT_SUBSCRIPTION_V2, PUT_SUBSCRIPTION_V2_ERROR, CREATE_PAYMENT_METHOD_V2 } from 'actions/billing-v2'
import { formatInvocations, formatStripePrice } from 'lib/billing-helpers'
import { TIERS } from 'lib/organization-constants'
import TierOptions from './tier-options'
import ConfigurePlan, { marks } from './configure-plan'
import Checkout from './checkout'
import CheckoutEnterprise from './checkout/checkout-enterprise'
import PaymentMethodModal from '../../payment/add-method-modal'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const { STRIPE_API_KEY } = process.env.CONFIG
const { Step } = Steps

const ChargeNotice = ({ total = 0, currency }) => {
  if (total === 0) return ''
  return (
    <p className={styles.margin_top}>
      You will be {total >= 0 ? 'charged' : 'credited'} {formatStripePrice(total, currency)} immediately
    </p>
  )
}

const ManageSubscriptionModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()
  const subscription = useSelector(state => state.billingV2.subscription)
  const prices = useSelector(state => state.billingV2.prices)
  const preview = useSelector(state => state.billingV2.invoicePreview)
  const promotion = useSelector(state => state.billingV2.promotion)
  const paymentMethods = useSelector(state => state.billingV2.paymentMethods)
  const invoiceUpcoming = useSelector(state => state.billingV2.invoiceUpcoming)
  const updatingSubscription = useSelector(state => state.waiting.list[PUT_SUBSCRIPTION_V2])
  const loadingPrices = useSelector(state => state.waiting.list[GET_PRICES_V2])
  const loadingCreatePaymentMethod = useSelector(state => state.waiting.list[CREATE_PAYMENT_METHOD_V2])

  const [selectedTier, setSelectedTier] = useState('')
  const [priceItem, setPriceItem] = useState({})
  const [tierItem, setTierItem] = useState({})
  const [invocationsCount, setInvocationsCount] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [stripePromise] = useState(() => loadStripe(STRIPE_API_KEY))

  const IN_TRIAL = subscription?.status === 'trialing'
  const SCHEDULED_SUBSCRIPTION = IN_TRIAL && invoiceUpcoming?.lines?.find(item => item.price.metadata.type === 'tier')?.price?.metadata?.tier !== 'trial'

  const getCurrentInvocationsItem = (subscriptionItems) => subscriptionItems?.find(item => item.price.metadata.type === 'invocations')

  const currentInvocationsItem = getCurrentInvocationsItem(SCHEDULED_SUBSCRIPTION ? invoiceUpcoming?.lines : subscription?.items)
  const currentPriceItem = currentInvocationsItem?.price

  const hasPaymentMethod = paymentMethods?.data?.length > 0
  const invocationOptions = !isEmpty(prices) && marks(prices)
  const currentTier = subscription?.items?.find(item => item.price?.metadata?.type === 'tier')?.price?.metadata?.tier

  useEffect(() => {
    if (!organization || !isEmpty(paymentMethods.data) || loadingCreatePaymentMethod) return

    dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.get())
  }, [organization, loadingCreatePaymentMethod])

  useEffect(() => {
    if (!organization.id) return

    dispatch(API.organizations.id(organization.id).billingV2.prices.get())
  }, [organization])

  useEffect(() => {
    if (isEmpty(prices.data) || isEmpty(subscription) || updatingSubscription) return
    const formattedInvocations = formatInvocations(currentPriceItem.metadata.invocations)
    const invocations = Object.keys(invocationOptions).find(key => invocationOptions[key] === formattedInvocations)
    const tier = SCHEDULED_SUBSCRIPTION ? currentPriceItem?.metadata?.tier : IN_TRIAL ? 'pro' : organization?.tier
    handleSelectPrice(invocations, currentPriceItem.interval, tier)
  }, [prices, subscription])

  const handleSelectPrice = (value = 0, interval = 'month', givenTier = selectedTier) => {
    const price = givenTier === TIERS.free
      ? prices?.data?.find(item => item.metadata.type === 'invocations' && item.metadata.tier === 'free')
      : prices?.data?.find(item =>
        item.nickname.startsWith(invocationOptions[value]) && item.interval === interval)
    const tier = prices?.data?.find(item =>
      item.metadata.type === 'tier' &&
      item.metadata.tier === givenTier &&
      item.interval === interval)

    setTierItem(tier)
    setPriceItem(price)
    setInvocationsCount(value)
    setSelectedTier(givenTier)
  }

  const handleSubscriptionChange = async () => {
    const scheduled = IN_TRIAL ? { scheduled: true } : {}
    const discount = isEmpty(promotion?.data) ? {} : { coupon: promotion?.data && promotion.data[0]?.coupon?.id }

    const { type } = await dispatch(API.organizations.id(organization.id).billingV2.subscription.id(subscription.id).put({
      items: [
        { id: subscription.items?.find(item => item.price.metadata.type === 'invocations')?.id, priceId: priceItem.id },
        { id: subscription?.items.find(item => item.price.metadata.type === 'tier')?.id, priceId: tierItem.id }
      ],
      ...scheduled,
      ...discount
    }))
    setVisible(false)
    setCurrentStep(0)
    type === PUT_SUBSCRIPTION_V2_ERROR
      ? message.error('Something went wrong.')
      : message.success('Subscription plan updated successfully')
  }

  const handleContactSales = () => {
    const email = 'sales@dashbird.io'
    const subject = 'Dashbird Enterprise Subscription'
    const body = `My organization ${organization?.name} is interested in the Enterprise plan`
    const newWindow = window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

    setVisible(false)
    setCurrentStep(0)
  }

  const steps = [{
    title: 'choose tier',
    next: {
      title: 'Next',
      action: (selectedTier) => setCurrentStep(selectedTier === 'pro' ? 1 : 2),
      disabled: { condition: loadingPrices }
    }
  }, {
    title: 'configure plan',
    next: {
      title: 'Continue to checkout',
      action: () => setCurrentStep(2),
      disabled: { condition: currentPriceItem?.id === priceItem?.id, title: 'Already subscribed to this item' }
    },
    back: { title: 'Back', action: () => setCurrentStep(0) }
  }, {
    title: 'finish',
    next: {
      title: selectedTier === TIERS.free ? 'Switch to free' : selectedTier === TIERS.pro ? 'Confirm' : 'Contact sales',
      action: selectedTier === TIERS.enterprise ? () => handleContactSales() : () => handleSubscriptionChange(),
      disabled: { condition: selectedTier === TIERS.pro && !hasPaymentMethod || currentTier === TIERS.free && selectedTier === TIERS.free, title: currentTier === TIERS.free && selectedTier === TIERS.free ? 'You are already in the free tier' : 'Add a payment method before subscribing' }
    },
    back: { title: 'Back', action: (selectedTier) => setCurrentStep(selectedTier === 'pro' ? 1 : 0) }
  }]

  return (
    <Modal open={visible} onCancel={() => setVisible(false)} width={850} footer={null}>
      <Steps className={styles.steps_container} labelPlacement={'vertical'} current={currentStep}>
        {steps.map((step, index) => <Step key={index} title={step.title} />)}
      </Steps>
      {currentStep === 0 && <TierOptions selectedTier={selectedTier} setSelectedTier={handleSelectPrice} />}
      {currentStep === 1 && selectedTier === TIERS.pro && <ConfigurePlan selectedId={selectedTier} invocationsCount={invocationsCount} priceItem={priceItem} handleSelectPrice={handleSelectPrice} />}
      {currentStep === 2 && selectedTier !== TIERS.enterprise && <Checkout selectedId={selectedTier} priceItem={priceItem} tierItem={tierItem} />}
      {currentStep === 2 && selectedTier === TIERS.enterprise && <CheckoutEnterprise />}
      <div className={styles.modal_buttons}>
        <div>{steps[currentStep].back && <Button type='primary' onClick={() => steps[currentStep].back.action(selectedTier)}>{steps[currentStep].back.title}</Button>}</div>
        {currentStep === 2 && selectedTier === TIERS.pro && !hasPaymentMethod && (
          <Button
            onClick={() => setShowPaymentModal(true)}
            className={styles.add_payment_btn}
            type='secondary'
          >Add a payment Method</Button>
        )}
        {steps[currentStep].next && (
          <div className={styles.confirm_btns}>
            <Tooltip title={steps[currentStep].next.disabled?.condition ? steps[currentStep].next.disabled?.title : ''}>
              <Button
                type='primary'
                onClick={() => steps[currentStep].next.action(selectedTier)}
                loading={updatingSubscription || loadingPrices}
                disabled={steps[currentStep].next?.disabled?.condition}>
                {steps[currentStep].next.title}
              </Button>
            </Tooltip>
            {currentStep === 2 && !isEmpty(preview) && IN_TRIAL &&
              <ChargeNotice total={IN_TRIAL && selectedTier === TIERS.free ? 0 : preview?.total} currency={preview?.currency} />}
          </div>
        )}
      </div>
      {!hasPaymentMethod && (
        <Elements stripe={stripePromise}>
          <PaymentMethodModal visible={showPaymentModal} setVisible={setShowPaymentModal} />
        </Elements>
      )}
    </Modal>
  )
}

export default ManageSubscriptionModal
