// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BKly449PsFo0wCTrkK89 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.OuMsqKT05k2PqqgW83uA {\n  text-align: center;\n}\n.neXLrh4pAdfxNXmWxLAF {\n  color: #b3b3b3;\n}\n.neXLrh4pAdfxNXmWxLAF h3 {\n  color: #b3b3b3;\n}\n.Hc4ea_ccoIZ02kT0mAj3 {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layout/content/loading/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,cAAA;AAHF;AAEA;EAII,cAAA;AAHJ;AAOA;EACE,mBAAA;AALF","sourcesContent":["@import '../../../../styles/main';\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.wrapper {\n  text-align: center;\n}\n\n.light {\n  color: @text-colors[light];\n\n  h3 {\n    color: @text-colors[light];\n  }\n}\n\n.icon {\n  margin-bottom: 10px;\n}\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BKly449PsFo0wCTrkK89",
	"wrapper": "OuMsqKT05k2PqqgW83uA",
	"light": "neXLrh4pAdfxNXmWxLAF",
	"icon": "Hc4ea_ccoIZ02kT0mAj3"
};
export default ___CSS_LOADER_EXPORT___;
