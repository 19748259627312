import React from 'react'
import { Modal, Button, Steps } from 'antd'

import styles from './styles.less'

const { Step } = Steps

const NextButton = ({ show, disabled, title = 'Next', action, loading, setNextStep } = {}) => {
  if (!show) return null
  const onClick = async () => {
    if (action) {
      await action()
    }
    setNextStep()
  }
  return (
    <Button
      type={'primary'}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >{title}</Button>
  )
}

const BackButton = ({ show, disabled, title = 'Back', action, loading, setPreviousStep } = {}) => {
  if (!show) return null
  const onClick = async () => {
    if (action) {
      await action()
    }
    setPreviousStep()
  }
  return (
    <Button disabled={disabled} loading={loading} onClick={onClick}>{title}</Button>
  )
}

const AddNewModal = ({ visible, currentStep, setStep, closeModal, steps, title, destroyOnClose, isEmailValid, children }) => {
  const step = steps[currentStep]
  const disabled = [!isEmailValid, false, false]

  return (
    <Modal open={visible} title={title} onCancel={() => closeModal()} footer={null} destroyOnClose={destroyOnClose} className={styles.container} width={700}>
      <Steps className={styles.steps_container} labelPlacement={'vertical'} current={currentStep} >
        {steps.map((step, index) => <Step key={index} title={step.title} />)}
      </Steps>

      {children}

      <div className={currentStep === 0 ? styles.next_btnContainer : styles.buttons}>
        <BackButton
          show={currentStep !== 0}
          setPreviousStep={() => setStep(currentStep - 1)}
          {...step.back}
        />
        <NextButton
          show={currentStep !== steps.length - 1}
          disabled={disabled[currentStep]}
          setNextStep={() => setStep(currentStep + 1)}
          {...step.next}
        />
        {currentStep === steps.length - 1 && <Button type={'primary'} onClick={() => closeModal(true)}>Finish</Button>}
      </div>
    </Modal >
  )
}

export default AddNewModal
