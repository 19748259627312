// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QPO9iL4rViciyMKTMNhA {\n  margin-top: 12px;\n}\n.ZFv6WJbN82mrH4uH_oYg {\n  height: 231px;\n  overflow: hidden;\n}\n.Srx5O5klieB7M_hFV831 {\n  height: 75px;\n  overflow: hidden;\n}\n._F0txXMqRR1P2mCGE4aR {\n  height: 75px;\n}\n._F0txXMqRR1P2mCGE4aR .ant-col {\n  align-self: center;\n}\n.n_MA3kmggHtCgYYZ1ErF {\n  min-height: 47px;\n}\n.n_MA3kmggHtCgYYZ1ErF:not(:last-of-type) {\n  margin-bottom: 9px;\n}\n.rzej6lFE15iAPesjq7HI {\n  height: 35px;\n}\n.nZ0WC3EqnSCUZ10j_YLy {\n  color: #666666;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/insights/dashboard/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAIA;EACE,aAAA;EACA,gBAAA;AAFF;AAKA;EACE,YAAA;EACA,gBAAA;AAHF;AAMA;EACE,YAAA;AAJF;AAGA;EAII,kBAAA;AAJJ;AAQA;EACE,gBAAA;AANF;AAQE;EACE,kBAAA;AANJ;AAUA;EACE,YAAA;AARF;AAWA;EACE,cAAA;AATF","sourcesContent":["@import '../../../styles/main';\n\n.wrapper {\n  margin-top: @spacing[normal];\n}\n\n.pillars_height {\n  height: 231px;\n  overflow: hidden;\n}\n\n.severities_height {\n  height: 75px;\n  overflow: hidden;\n}\n\n.severities_wrapper {\n  height: 75px;\n  \n  :global(.ant-col) {\n    align-self: center;\n  }\n}\n\n.item_wrapper {\n  min-height: 47px;\n\n  &:not(:last-of-type) {\n    margin-bottom: @spacing[small];\n  }\n}\n\n.icon {\n  height: 35px;\n}\n\n.icon_dark {\n  color: @text-colors[dark];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "QPO9iL4rViciyMKTMNhA",
	"pillars_height": "ZFv6WJbN82mrH4uH_oYg",
	"severities_height": "Srx5O5klieB7M_hFV831",
	"severities_wrapper": "_F0txXMqRR1P2mCGE4aR",
	"item_wrapper": "n_MA3kmggHtCgYYZ1ErF",
	"icon": "rzej6lFE15iAPesjq7HI",
	"icon_dark": "nZ0WC3EqnSCUZ10j_YLy"
};
export default ___CSS_LOADER_EXPORT___;
