import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { alarmsPaths as paths, ALARMS_RULES_LIST } from './index'
import { useHistory } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'

const createAlarmRule = async (account, payload) => {
  const data = apiFetch(paths.alarms(account), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreateAlarmQuery = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const routes = useAccountRoutes()
  const { account } = useOrganization()
  return useMutation(
    payload => createAlarmRule(account.id, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...ALARMS_RULES_LIST, account.id])
        history.push(routes.alarms.alarm.url({ alarmId: data?.id }))
      }
    }
  )
}
