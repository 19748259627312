import { paths } from '../index'

export const SEARCH = 'search'
export const SEARCH_METRICS = 'search-metrics'
export * from './search-executions'
export * from './search-executions-batch'
export * from './search-logs'
export * from './search-log-matches'
export * from './search-metrics'

export const searchPaths = {
  search: (account) => `${paths.account(account)}/search`,
  logs: (account) => `${searchPaths.search(account)}/logs`,
  logMatches: (account) => `${searchPaths.search(account)}/log-matches`,
  executions: (account) => `${searchPaths.search(account)}/executions`,
  metrics: (account) => `${searchPaths.logs(account)}/metrics`
}

export const serializeExecutionsPayload = (payload) => {
  if (!payload) return null
  return {
    service: payload.service,
    targets: payload.targets,
    ...(payload.filters && {
      filters: {
        ...(payload.filters.query && { query: payload.filters.query }),
        ...(payload.filters.errorHash && { errorHash: payload.filters.errorHash }),
        ...(payload.filters.flag && { flag: payload.filters.flag }),
        ...(payload.filters.memory && { memory: payload.filters.memory }),
        ...(payload.filters.duration && { duration: payload.filters.duration })
      }
    }),
    ...(payload.start && { start: payload.start }),
    ...(payload.end && { end: payload.end }),
    ...(payload.nextToken && { nextToken: payload.nextToken }),
    ...(payload.limit && { limit: payload.limit })
  }
}
