import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { map, isEmpty } from 'lodash'

import { Dropdown, Popconfirm, message } from 'antd'

import { API } from 'actions/api'

import Content from 'components/layout/content'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import EditUser from './edit-user'
import { useAccountRoutes } from 'containers/routes'
import { useOrganizationInvitationsQuery, useOrganizationUsersQuery, useAccountsQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const User = () => {
  const history = useHistory()
  const { userId } = useParams()
  const routes = useAccountRoutes()

  const dispatch = useDispatch()
  const { data: invitations, isLoading: loadingInvitations } = useOrganizationInvitationsQuery()
  const { data: users, isLoading: loadingUsers } = useOrganizationUsersQuery()
  const { data: accounts, isLoading: loadingAccounts } = useAccountsQuery()

  const { organization: currentOrg, account } = useOrganization()
  const userRole = account.role

  const user = users?.find(item => item.id === userId) || invitations?.find(item => item.id === userId)

  const resendInvite = (invitationId, invitation) => {
    dispatch(API.organizations.id(currentOrg.id).invitations.id(invitationId).put(invitation))
    message.success(`Invitation sent for user with email ${user.email}`)
    history.goBack()
  }

  const deleteInvite = (invitationId, invitation) => {
    dispatch(API.organizations.id(currentOrg.id).invitations.id(invitationId).delete(invitation.email))
    message.success(`User deleted with email ${user.email}`)
    history.goBack()
  }

  const deleteUser = (userId) => {
    dispatch(API.organizations.id(currentOrg.id).users.id(userId).delete())
    message.success({ content: `User deleted with email ${user.email}` })
    history.goBack()
  }

  const handleResend = () => {
    const permissions = map(user.permissions, (value, prop) => ({ account: prop, role: value }))
    const { email, role } = user

    resendInvite(user.id, {
      email,
      role,
      permissions
    })
  }

  const handleDelete = () => {
    if (user?.expiresAt) {
      deleteInvite(user.id, user)
    } else {
      deleteUser(user.id, user)
    }
  }

  const editUser = async (user, permissions) => {
    !isEmpty(user) && await dispatch(API.organizations.id(currentOrg.id).users.id(userId).put(user))
    await dispatch(API.organizations.id(currentOrg.id).users.id(userId).permissions.put(permissions))
    history.goBack()
  }

  const handleActionClick = ({ key }) => {
    switch (key) {
      case 'delete': return handleDelete()
      case 'resend': return handleResend()
      default: return () => { }
    }
  }

  const USER_PENDING_EXPIRED = user?.expiresAt

  return (
    <Content
      item
      title='Edit user'
      breadcrumbs={['settings', 'users & permissions', 'edit']}
      loading={loadingUsers || loadingInvitations || loadingAccounts}
      backRoute={routes.settings.users.url()}
      actions={
        <Dropdown
          trigger={['click']}
          overlayClassName={styles.configure_dropdown}
          menu={{
            items: [
              ...(USER_PENDING_EXPIRED ? [{ key: 'resend', label: 'Resend invite', disabled: userRole === 'admin' }] : [{}]),
              {
                key: 'delete',
                label: (
                  <Popconfirm
                    title={`Delete user ${user?.email} from organization?`}
                    onConfirm={deleteUser}
                    placement='bottomRight'
                  >
                    Delete
                  </Popconfirm>
                ),
                disabled: userRole === 'admin'
              }
            ],
            onClick: handleActionClick
          }}
        >
          <ActionsDropdownButton />
        </Dropdown>
      }
    >
      {USER_PENDING_EXPIRED
        ? <div>{`User invite ${new Date(user?.expiresAt) < Date.now() ? 'has expired' : 'is pending'}. You can resend the invite from top right of this page.`}</div>
        : <EditUser user={user} accounts={accounts} loading={loadingAccounts || loadingUsers || loadingInvitations} saveUser={editUser} isAdmin={userRole === 'admin'} />}
    </Content>
  )
}

export default User
