import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Menu } from 'antd'

import AccountPicker from '../../layout/navigation/account-picker'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const MenuItem = ({ label }) => {
  return (
    <span className={styles.item}>{label}</span>
  )
}

const SettingsMenu = () => {
  const history = useHistory()
  const location = useLocation()

  const onSelect = (event) => {
    if (!event.item) return
    history.push(event.key)
  }

  const { account } = useOrganization()
  const userRole = account && account.role

  const IS_ADMIN = userRole === 'admin'
  const IS_OWNER = userRole === 'owner'

  const getAccountKey = (account, key) => {
    if (!account) return key
    return `/${account.id}${key}`
  }

  return (
    <Menu
      onSelect={onSelect}
      mode='vertical'
      selectedKeys={location.pathname}
      className={styles.menu}
      items={[
        { key: getAccountKey(account, '/settings/profile'), label: <MenuItem label={'Profile'} /> },
        { key: getAccountKey(account, '/settings/security'), label: <MenuItem label='Security' /> },
        { key: getAccountKey(account, '/settings/invitations'), label: <MenuItem label='Invitations' /> },
        { type: 'group', label: <AccountPicker className={styles.account_picker} /> },
        // { key: 'account_picker', label: <AccountPicker className={styles.account_picker} /> },
        { key: getAccountKey(account, '/settings/manage-accounts'), label: <MenuItem label='Manage accounts' /> },
        // only for owner, admin, user
        ((IS_ADMIN || IS_OWNER) &&
        {
          type: 'group',
          label: <span className={styles.group_title}>Account</span>,
          children: [
            { key: getAccountKey(account, '/settings/notifications'), label: <MenuItem label='Notifications' /> },
            { key: getAccountKey(account, '/settings/integrations'), label: <MenuItem label='Integrations' /> }]
        }),
        // only for admin and owner
        ((IS_ADMIN || IS_OWNER) && {
          type: 'group',
          label: <span className={styles.group_title}>Organization</span>,
          children: [
            { key: getAccountKey(account, '/settings/users'), label: <MenuItem label='Users & permissions' /> },
            { key: getAccountKey(account, '/settings/subscriptions'), label: <MenuItem label='Billing' /> },
            { key: getAccountKey(account, '/settings/payment'), label: <MenuItem label='Payment methods' /> },
            { key: getAccountKey(account, '/settings/invoices'), label: <MenuItem label='Invoices' /> },
            { key: getAccountKey(account, '/settings/usage'), label: <MenuItem label='Usage reports' /> }
          ]
        })
      ]} />
  )
}

export default SettingsMenu
