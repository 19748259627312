import { apiFetch, getDispatch, omitEmpty } from '../lib/action-helpers'
import queryString from 'query-string'

const apiVersion = '/v1'

const paths = {
  filter: (path, query) => `${path}?${query}`,
  organizations: () => `${apiVersion}/organizations`,
  organization: (organizationId) => `${paths.organizations()}/${organizationId}`,
  users: (organizationId) => `${paths.organization(organizationId)}/users`,
  user: (organizationId, userId) => `${paths.users(organizationId)}/${userId}`,
  userPermissions: (organizationId, userId) => `${paths.user(organizationId, userId)}/permissions`,
  accounts: (organizationId) => `${paths.organization(organizationId)}/accounts`,
  account: (organizationId, accountId) => `${paths.accounts(organizationId)}/${accountId}`,
  invitations: (organizationId) => `${paths.organization(organizationId)}/invitations`,
  invitation: (organizationId, invitationId) => `${paths.invitations(organizationId)}/${invitationId}`
}

export const usersApi = (organization) => {
  return {
    get: () => getUsers(organization),
    id: (user) => {
      return {
        get: () => getUser(organization, user),
        put: (payload) => updateUser(organization, user, payload),
        delete: () => deleteUser(organization, user),
        permissions: {
          put: (payload) => updatePermissions(organization, user, payload)
        }
      }
    }
  }
}

export const invitationsApi = (organization) => {
  return {
    get: () => getInvitations(organization),
    post: (payload) => createInvitation(organization, payload),
    id: (invitationId) => {
      return {
        get: () => getInvitation(organization, invitationId),
        put: (payload) => updateInvitation(organization, invitationId, payload),
        delete: (email) => deleteInvitation(organization, invitationId, email)
      }
    }
  }
}

// USER

export const GET_USERS = 'GET_USERS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'
export const getUsers = (organizationId) => {
  return getDispatch(
    apiFetch(paths.users(organizationId)),
    GET_USERS,
    GET_USERS_ERROR
  )
}

export const GET_USER = 'GET_USER'
export const GET_USER_ERROR = 'GET_USER_ERROR'
export const getUser = (organizationId, userId) => {
  return getDispatch(
    apiFetch(paths.user(organizationId, userId)),
    GET_USER,
    GET_USER_ERROR
  )
}

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const updateUser = (organizationId, userId, user) => {
  return getDispatch(
    apiFetch(paths.user(organizationId, userId), {
      body: JSON.stringify(user),
      method: 'PUT'
    }),
    UPDATE_USER,
    UPDATE_USER_ERROR
  )
}

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const deleteUser = (organizationId, userId) => {
  const action = async () => {
    await apiFetch(paths.user(organizationId, userId), {
      method: 'DELETE'
    })
    return userId
  }

  return getDispatch(
    action(),
    DELETE_USER,
    DELETE_USER_ERROR
  )
}

const roles = ['admin', 'user', 'viewer']
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const UPDATE_PERMISSIONS_ERROR = 'UPDATE_PERMISSIONS_ERROR'
export const updatePermissions = (organizationId, userId, payload) => {
  const permissions = Object.entries(payload.permissions)
    .filter(([_, role]) => roles.includes(role))
    .reduce((result, [key, value]) => {
      result[key] = value
      return result
    }, {})

  return getDispatch(
    apiFetch(paths.userPermissions(organizationId, userId), {
      body: JSON.stringify({ ...payload, permissions }),
      method: 'PUT'
    }),
    UPDATE_PERMISSIONS,
    UPDATE_PERMISSIONS_ERROR
  )
}

// ACCOUNT

// not used
// export const GET_ACCOUNTS = 'GET_ACCOUNTS'
// export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR'
// export const getAccounts = (organizationId) => {
//   return getDispatch(
//     apiFetch(paths.accounts(organizationId)),
//     GET_ACCOUNTS,
//     GET_ACCOUNTS_ERROR
//   )
// }

// export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
// export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR'
// export const createAccount = (organizationId, account) => {
//   return getDispatch(
//     apiFetch(paths.accounts(organizationId), {
//       body: JSON.stringify(account),
//       method: 'POST'
//     }),
//     CREATE_ACCOUNT,
//     CREATE_ACCOUNT_ERROR
//   )
// }

// export const GET_ACCOUNT = 'GET_ACCOUNT'
// export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR'
// export const getAccount = (organizationId, accountId) => {
//   return getDispatch(
//     apiFetch(paths.account(organizationId, accountId)),
//     GET_ACCOUNT,
//     GET_ACCOUNT_ERROR
//   )
// }

// export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
// export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR'
// export const updateAccount = (organizationId, accountId, payload) => {
//   return getDispatch(
//     apiFetch(paths.account(organizationId, accountId), {
//       body: JSON.stringify(payload),
//       method: 'PUT'
//     }),
//     UPDATE_ACCOUNT,
//     UPDATE_ACCOUNT_ERROR,
//     accountId,
//     { organizationId }
//   )
// }

// export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
// export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR'
// export const deleteAccount = (organizationId, accountId) => {
//   return getDispatch(
//     apiFetch(paths.account(organizationId, accountId), {
//       method: 'DELETE'
//     }),
//     DELETE_ACCOUNT,
//     DELETE_ACCOUNT_ERROR,
//     accountId,
//     { organizationId }
//   )
// }

export const GET_INVITATIONS = 'GET_INVITATIONS'
export const GET_INVITATIONS_ERROR = 'GET_INVITATIONS_ERROR'
export const getInvitations = (organizationId) => {
  return getDispatch(
    apiFetch(paths.invitations(organizationId)),
    GET_INVITATIONS,
    GET_INVITATIONS_ERROR
  )
}

export const CREATE_INVITATION = 'CREATE_INVITATION'
export const CREATE_INVITATION_ERROR = 'CREATE_INVITATION_ERROR'
export const createInvitation = (organizationId, payload) => {
  const permissions = payload.permissions ? payload.permissions.filter(permission => roles.includes(permission.role)) : []
  return getDispatch(
    apiFetch(paths.invitations(organizationId), { method: 'POST', body: JSON.stringify({ ...payload, permissions }) }),
    CREATE_INVITATION,
    CREATE_INVITATION_ERROR
  )
}

export const GET_INVITATION = 'GET_INVITATION'
export const GET_INVITATION_ERROR = 'GET_INVITATION_ERROR'

export const getInvitation = (organizationId, invitationId) => {
  return getDispatch(
    apiFetch(paths.invitation(organizationId, invitationId)),
    GET_INVITATION,
    GET_INVITATION_ERROR
  )
}

export const UPDATE_INVITATION = 'UPDATE_INVITATION'
export const UPDATE_INVITATION_ERROR = 'UPDATE_INVITATION_ERROR'
export const updateInvitation = (organizationId, invitationId, payload) => {
  return getDispatch(
    apiFetch(paths.invitation(organizationId, invitationId), { method: 'PUT', body: JSON.stringify(payload) }),
    UPDATE_INVITATION,
    UPDATE_INVITATION_ERROR
  )
}

export const DELETE_INVITATION = 'DELETE_INVITATION'
export const DELETE_INVITATION_ERROR = 'DELETE_INVITATION_ERROR'
export const deleteInvitation = (organizationId, invitationId, email) => {
  const query = queryString.stringify(omitEmpty({
    email
  }))

  const action = async () => {
    await apiFetch(
      paths.filter(paths.invitation(organizationId, invitationId), query),
      { method: 'DELETE' }
    )
    return invitationId
  }

  return getDispatch(
    action(),
    DELETE_INVITATION,
    DELETE_INVITATION_ERROR
  )
}
