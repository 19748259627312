// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z2RUsxSB1HPPKw13F0WC {\n  position: sticky;\n  top: 0;\n  z-index: 2000;\n}\n.X55De6JZbI1dQzc8Q2kL {\n  margin: 0;\n  padding: 3px 6px;\n  border: none;\n  border-radius: 0;\n}\n.LHt9ft6mhhM2OeEI5v1c {\n  background: #fdb0b0;\n}\n.WhOz5OkIW4A5CvlJGtKQ a {\n  margin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/layout/alert-bar/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,MAAA;EACA,aAAA;AADF;AAIA;EACE,SAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AAFF;AAKA;EACE,mBAAA;AAHF;AAMA;EAEI,gBAAA;AALJ","sourcesContent":["@import '../../../styles/main';\n\n.wrapper {\n  position: sticky;\n  top: 0;\n  z-index: 2000;\n}\n\n.alert {\n  margin: 0;\n  padding: @spacing[smallest] @spacing[smaller];\n  border: none;\n  border-radius: 0;\n}\n\n.alert_error {\n  background: @colors[alert-light];\n}\n\n.trial_notice {\n  a {\n    margin-left: 5px;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Z2RUsxSB1HPPKw13F0WC",
	"alert": "X55De6JZbI1dQzc8Q2kL",
	"alert_error": "LHt9ft6mhhM2OeEI5v1c",
	"trial_notice": "WhOz5OkIW4A5CvlJGtKQ"
};
export default ___CSS_LOADER_EXPORT___;
