import React from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { sortBy } from 'lodash'

import { Row, Col, Tooltip, Tag, Table, Badge } from 'antd'
import Section from 'components/layout/content/section'

import filterResources from 'lib/filter-resources'
import { getResourcesByType } from 'lib/resources/filters'

import { filterInventoryByService, findInventoryByType } from 'lib/resources/constants'
import { useResourcesQuery, useResourceGroupsQuery } from 'hooks/api'

import styles from './styles.less'

const ResourceList = ({ integration }) => {
  const { service, configuration = {} } = integration

  const { data: resources, isLoading } = useResourcesQuery()
  const { isLoading: loadingDelegations } = useResourceGroupsQuery()

  const loading = isLoading || loadingDelegations

  const inventoryServices = filterInventoryByService(service)
  const resourcesByType = Object.values(inventoryServices).reduce((acc, service) => acc.concat(getResourcesByType(resources, service.type)), [])

  const { active, ignored } = filterResources(resourcesByType.filter(item => item?.delegation === integration?.delegation?.id), configuration)

  const columns = [{
    title: 'Name',
    key: uuid(),
    render: item => <Tooltip title={item.description}><Link to={`/inventory/${item?.id}`}>{item?.title}</Link></Tooltip>,
    sorter: (a, b) => a.arn.localeCompare(b.arn)
  }, {
    title: 'Type',
    key: uuid(),
    render: item => findInventoryByType(item.type)?.shortTitle,
    sorter: (a, b) => a.arn.localeCompare(b.arn)
  }, {
    title: 'Region',
    dataIndex: 'region',
    key: uuid(),
    render: region => <Tag>{region}</Tag>,
    sorter: (a, b) => a.region.localeCompare(b.region)
  }]

  return (
    <Row gutter={32}>
      <Col span={12}>
        <Section
          title={<span>Active resources <Badge count={active?.length} overflowCount={999} className={styles.active} /></span>}
          loading={loading}
        >
          <Table
            rowKey={(record) => record.id}
            size='small'
            pagination={false}
            locale={{ emptyText: 'All resources are ignored! :(' }}
            dataSource={sortBy(active, 'name')}
            columns={columns}
          />
        </Section>
      </Col>
      <Col span={12}>
        <Section
          title={<span>Ignored resources <Badge count={ignored?.length} overflowCount={999} /></span>}
          loading={loading}
        >
          <Table
            rowKey={(record) => record.id}
            size='small'
            pagination={false}
            locale={{ emptyText: 'Great, all resources are monitored by Dashbird!' }}
            dataSource={sortBy(ignored, 'name')}
            columns={columns}
          />
        </Section>
      </Col>
    </Row>
  )
}

export default ResourceList
