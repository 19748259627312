import React from 'react'

import { ChevronLeftSolidIcon } from 'components/icons'
import styles from './styles.less'

export const PageHeader = ({ icon, title, subtitle, extra, onBack }) => {
  return (
    <div className={styles.header}>
      <div className={styles.heading}>

        <div className={styles.heading_bar}>
          <div className={styles.row_wrapper}>
            {onBack && <a onClick={onBack} type='link' className={styles.back}><ChevronLeftSolidIcon /></a>}
            {icon && <span className={styles.icon}>{icon}</span>}
            <h1 className={styles.title}>{title}</h1>
          </div>
          {extra && <div className={styles.actions}>{extra}</div>}
        </div>

        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  )
}
