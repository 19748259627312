import React from 'react'
import { HexagonBadges } from 'components/badge/hexagon'

import styles from './styles.less'

const STEP_1_DATA = [{
  subtitle: 'Organization'
}, {
  subtitle: 'Account',
  placement: 'bottom_right'
}, {
  subtitle: 'Aws account (Delegation)',
  placement: 'bottom'
}]

const STEP_2_DATA = [{
  subtitle: 'Read only permissions'
}, {
  subtitle: 'Enterprise grade security',
  placement: 'bottom_right'
}, {
  title: '50M',
  subtitle: 'invocations during trial',
  placement: 'bottom'
}]

const OnboardingHexagons = ({ step = 0 }) => {
  return (
    <div className={styles.hexagons_wrapper}>
      <HexagonBadges height={200} items={step === 0 ? STEP_1_DATA : STEP_2_DATA} />
    </div>
  )
}

export default OnboardingHexagons
