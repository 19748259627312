import React from 'react'
import classnames from 'classnames'
import styles from './styles.less'

const hour = 60
const threeHours = 3 * hour
const sixHours = 6 * hour
const twelveHours = 12 * hour
const oneDay = 24 * hour
const sevenDays = 7 * oneDay
const thirtyDays = 30 * oneDay

const defaultOptions = [{
  value: 'startOfToday',
  text: 'Today'
}, {
  value: 'startOfWeek',
  text: 'This week'
}, {
  value: 15,
  text: 'Last 15 min'
}, {
  value: hour,
  text: 'Last 1h'
}, {
  value: threeHours,
  text: 'Last 3h'
}, {
  value: sixHours,
  text: 'Last 6h'
}, {
  value: twelveHours,
  text: 'Last 12h'
}, {
  value: oneDay,
  text: 'Last 24h'
}, {
  value: sevenDays,
  text: 'Last 7 days'
}, {
  value: thirtyDays,
  text: 'Last 30 days'
}]

const QuickOptionsDate = ({ onMinutesChange }) => {
  return <>
    <p className={classnames(styles.title)}>Commonly used</p>
    <div className={classnames(styles.commons)}>
      {
        defaultOptions.map(({ value, text }) => (
          <div className={classnames(styles.common_container)} key={value}>
            <button
              onClick={(event) => onMinutesChange(event.target.value)}
              className={classnames(styles.date_option)} key={value} value={value}>{text}</button>
          </div>
        ))
      }
    </div>
  </>
}

export default QuickOptionsDate
