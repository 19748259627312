import React, { useState, useEffect, createContext } from 'react'
import { Row, Col } from 'antd'
import { flatten } from 'lodash'

import Section from 'components/layout/content/section'
import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../../container'
import InventoryTags from 'components/inventory/tags'
import ECSServiceConfig from './config'
import ECSTasks from './tasks'
import Events from 'components/inventory/events'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { ECS_CPU } from '../../../metrics-tabs/services/ecs-cluster'

import Tabs from 'components/antd/tabs'
import { useInfiniteResourceQuery } from 'hooks/api'
import SearchContextWrapper from 'hooks/context/search-context'
import LogsSearch from 'features/on-demand-search'

export const ECSContext = createContext({ cpuLimit: false, memoryLimit: false })

const Drawer = ({ item, attributes }) => {
  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <ECSServiceConfig attributes={attributes} />
  </>)
}

const EcsService = ({ item, actions, start, end, range, configOpen, closeConfig, loading }) => {
  const [tasks, setTasks] = useState([])
  const idWithParent = `${item.parent?.id}/${item.id}`

  const {
    data,
    fetchNextPage,
    isLoading: loadingTasks
  } = useInfiniteResourceQuery('tasks',
    item && { type: 'AWS::ECS::Task', parent: idWithParent, limit: 10 })

  const attributes = objectify(item.attributes)

  const { cpu: cpuHardLimit, memory: memoryHardLimit } = data ? objectify(data?.pages[0].attributes || {}) : {}

  useEffect(() => {
    if (!data) return
    const newTasks = data.pages.map(page => page.data)
    setTasks(flatten(newTasks))
  }, [data])

  return (
    <ECSContext.Provider value={{ cpuLimit: cpuHardLimit, memoryLimit: memoryHardLimit }}>
      <InventoryResourceContainer item={item} metadata={{ attributes }} start={start} end={end} range={range} datepicker={actions}
        configOpen={configOpen} closeConfig={closeConfig} loading={loading} service={INVENTORY_SERVICES.ECSService.id} initialChart={ECS_CPU}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey="tasks" items={[
                { label: 'Tasks', key: 'tasks', children: attributes.desiredCount && <ECSTasks taskCount={attributes.desiredCount} tasks={tasks} loading={loadingTasks} onLoadMore={fetchNextPage} /> },
                {
                  label: 'Logs',
                  key: 'logs',
                  children: (
                    <SearchContextWrapper>
                      <LogsSearch resource={item} />
                    </SearchContextWrapper>
                  )
                },
                { label: 'Configuration', key: 'configuration', children: <Drawer item={item} attributes={attributes} /> }
              ]} />
            </Section>
          </Col>
          <Col span={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </ECSContext.Provider>
  )
}

export default EcsService
