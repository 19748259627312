import React from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../../container'
import InventoryTags from 'components/inventory/tags'
import KinesisAnalyticsConfig from './config'
import Events from 'components/inventory/events'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { KDA_CPU_UTILIZATION } from '../../../metrics-tabs/services/kinesis-analytics'
import Section from 'components/layout/content/section'
import Tabs from 'components/antd/tabs'
import LogsSearch from 'features/on-demand-search'
import SearchContextWrapper from 'hooks/context/search-context'

const Drawer = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <KinesisAnalyticsConfig attributes={attributes} inverse />
  </>)
}

const KinesisAnalytics = ({ item, actions, start, end, range, configOpen, closeConfig, loading }) => {
  return (
    <>
      <InventoryResourceContainer item={item} start={start} end={end} range={range} datepicker={actions}
        configOpen={configOpen} closeConfig={closeConfig} loading={loading} service={INVENTORY_SERVICES.KinesisAnalytics.id} initialChart={KDA_CPU_UTILIZATION}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey="configuration" items={[
                { label: 'Configuration', key: 'configuration', children: <Drawer item={item} /> },
                {
                  label: 'Logs',
                  key: 'logs',
                  children: (
                    <SearchContextWrapper>
                      <LogsSearch resource={item} />
                    </SearchContextWrapper>
                  )
                }
              ]} />
            </Section>
          </Col>
          <Col span={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default KinesisAnalytics
