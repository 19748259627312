import React from 'react'
import Items from 'components/inventory/items'
import { useAccountRoutes } from 'containers/routes'
import { objectify } from 'lib/utils'

const ClusterInstances = ({ instances, loading, attributes }) => {
  const routes = useAccountRoutes()

  const clusterMembers = JSON.parse(objectify(attributes)?.dbClusterMembers)

  const formatted = instances?.map(item => {
    const member = clusterMembers.find(mem => mem.dbInstanceIdentifier === item.name)

    return ({
      id: item.id,
      title: item.title || item.name,
      url: routes.inventory.resource.url({ resourceId: item.id }),
      description: member?.isClusterWriter ? 'writer' : 'reader'
    })
  })

  return <Items items={formatted} loading={loading} />
}

export default ClusterInstances
