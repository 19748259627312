import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Route, Switch, Redirect, useHistory, useLocation, matchPath } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Alarms from 'containers/alarms'
import Dashboards from 'containers/dashboards'
import DashboardContainer from './containers/dashboard'
import Errors from './containers/errors'
import GlobalSearchContainer from './containers/search'
import ScheduledSearch from 'containers/scheduled-search'
import InsightsContainer from './containers/insights'
import Inventory from './containers/inventory'
import Layout from './containers/layout'
import LoginContainer from 'containers/auth/login'
import MarketplaceContainer from 'containers/auth/obsolete/marketplace'
import ResourceGroups from 'containers/resource-groups'
import SettingsContainer from './containers/settings'
import LoadingDots from 'components/loading-dots/index'
import AuthLayout from 'containers/auth/layout'
import ForgotPasswordContainer from 'containers/auth/forgot-password'
import ResetPasswordContainer from 'containers/auth/reset-password'
import RegisterContainer from 'containers/auth/register'
import Onboarding from 'containers/onboarding/index'
import { ROUTES } from 'containers/routes'
import LoginProviderContainer from 'containers/auth/login/provider'
import InviteContainer from 'containers/auth/invite'
import ConfirmContainer from 'containers/auth/confirm'
import { displayPaymentProblems } from 'components/notification'
import { getLastAuthUserKey, listen } from 'lib/auth/local-storage-listener'
import { useOrganizationsQuery, useUserQuery } from 'hooks/api'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import Analytics from 'lib/analytics'

export const accountRoutes = (account, routes) => {
  const basicRoutes = routes.map(route =>
    <Route key={`route.${route.path}`} path={route.path} component={route.component} />
  )
  return basicRoutes.concat(routes.map(route =>
    <Route key={`route.account.${route.path}`} path={`/:accountId${route.path}`} component={route.component} />
  ))
}

export const accountRedirects = (account, routes, search) => {
  const redirects = account && account.id
    ? routes.map(route => {
      return <Redirect
        key={`redirect.${route.path}`}
        from={route.path}
        to={{
          pathname: `/${account.id}${route.path}`,
          search
        }}
      />
    })
    : []
  return redirects.concat(routes.map(route =>
    <Route key={`route.${route.path}`} path={`/:accountId${route.path}`} component={route.component} />
  ))
}

const Routes = () => {
  const history = useHistory()
  const location = useLocation()

  const [lastAuthUser, setLastAuthUser] = useState('')

  const { authenticated, session, initialUserLoading } = useUser()
  const { organization: currentOrganization, account: currentAccount } = useOrganization()
  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizationsQuery()

  const currentAccountNotMatching = !isEmpty(organizations) && isEmpty(findById(organizations, currentAccount?.id))
  const organizationChecked = !(initialUserLoading || isLoadingOrganizations || currentAccountNotMatching)
  const { data: user, isLoading: isLoadingUser } = useUserQuery(!isEmpty(organizations) && organizationChecked)

  const unauthedRoutes = () => {
    return (
      <AuthLayout>
        <Switch>
          <Route path='/auth/connect-aws/:token' exact component={MarketplaceContainer} />
          <Route path='/auth/password/forgot' component={ForgotPasswordContainer} />
          <Route path='/auth/password/reset' component={ResetPasswordContainer} />
          <Route path='/auth/register' component={RegisterContainer} />
          <Route path='/auth/login/:provider' component={LoginProviderContainer} />
          <Route path='/auth/login' component={LoginContainer} />
          <Route path='/auth/confirm' component={ConfirmContainer} />
          <Route path='/invite' component={InviteContainer} />
          <Route path='/' component={LoginContainer} />
        </Switch>
      </AuthLayout>
    )
  }

  const routes = [
    { path: '/settings', component: SettingsContainer }
  ]
  const redirects = [
    { path: '/alarms/:alarmId?', component: Alarms },
    { path: '/dashboards/:dashboardId?', component: Dashboards },
    { path: '/errors/:errorId?', component: Errors },
    { path: '/inventory/:resourceId?', component: Inventory },
    { path: '/insights/:ruleId?', component: InsightsContainer },
    { path: '/resource-groups', component: ResourceGroups },
    { path: '/search/:queryId?', component: GlobalSearchContainer },
    { path: '/scheduled-search/:queryId?', component: ScheduledSearch }
  ]

  const authedRoutes = () => {
    return (
      <Route
        path='/:accountId?' render={props => (
          <Layout {...props}>
            <Switch>
              <Route path='/auth/confirm' component={ConfirmContainer} />
              <Redirect from='/auth/*' to='/' />
              <Redirect from='/invite' to='/settings/invitations' />
              <Redirect from='/billing' to='/settings/subscriptions' exact />
              <Redirect from='/settings/billing' to='/settings/subscriptions' exact />
              <Redirect from='/:accountId/well-architected-lens' to='/:accountId/insights' />

              <Route path='/onboarding' component={Onboarding} />
              <Route path='/onboarding/:token' component={Onboarding} exact />

              {accountRoutes(currentAccount, routes)}
              {accountRedirects(currentAccount, redirects, location.search)}

              <Route exact path='/' component={DashboardContainer} />
              <Route exact path={`/${currentAccount?.id}`} component={DashboardContainer} />

            </Switch>
          </Layout>
        )}
      />
    )
  }

  useEffect(() => {
    if (authenticated && !isLoadingOrganizations && user?.id) {
      Analytics.identify(user, currentAccount, currentOrganization)
    }
  }, [user?.id, currentOrganization?.id, currentAccount?.id, authenticated, isLoadingOrganizations])

  useEffect(() => {
    if (!initialUserLoading && !isLoadingOrganizations && !isLoadingUser) {
      Analytics.page(location.pathname)
    }
  }, [location.pathname, initialUserLoading, isLoadingOrganizations, isLoadingUser])

  useEffect(() => {
    if (!session || isLoadingOrganizations || !currentOrganization) return
    if (currentOrganization?.onboarded) return

    const matchOne = matchPath(location?.pathname, { path: '/auth/connect-aws/:token' })
    const matchTwo = matchPath(location?.pathname, { path: '/onboarding/:token' })
    if (matchOne?.params?.token || matchTwo?.params?.token) return history.push(`/onboarding/${matchOne?.params?.token || matchTwo?.params?.token}`)
    history.push('/onboarding')
  }, [currentOrganization, session])

  useEffect(() => {
    if (!currentOrganization || !currentOrganization.paymentProblems) return

    displayPaymentProblems({ onClick: () => history.push(ROUTES.settings.subscriptions.path) })
  }, [currentOrganization && currentOrganization.paymentProblems])

  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize('UA-93410800-1')

    // listen and return clean up function
    return listen(getLastAuthUserKey(), setLastAuthUser)
  }, [])

  if (!organizationChecked) return <LoadingDots />
  if (!authenticated) return unauthedRoutes()

  return authedRoutes()
}

export const findById = (permissions = [], identity) => {
  return permissions.map(permission => {
    const account = permission.accounts.find(item => item.id === identity)

    if (account) return [permission.organization, account]
  }).find(item => item) || []
}

export default Routes
