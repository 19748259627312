import React from 'react'
import { sum } from 'lodash'
import { Typography, Row, Col, Table, Tooltip } from 'antd'

import StatusIcon from './status-icon'
import { QuestionIcon } from 'components/icons'

import styles from './styles.less'

const { Text } = Typography

const NameField = ({ name, enabled } = {}) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <StatusIcon enabled={enabled} />
      <Text>{name}</Text>
    </div>
  )
}

const ResourceCountTitle = () => {
  return (
    <>
      <Text strong>Resource count</Text><Tooltip title='Latest recorded count'>
        <QuestionIcon className={styles.help} />
      </Tooltip>
    </>
  )
}

const ResourceTable = ({ data } = {}) => {
  const columns = [
    {
      title: <Text strong>Resources</Text>,
      dataIndex: 'name',
      key: 'type',
      render: (name, data) => <NameField name={name} enabled={data.enabled} />
    },
    {
      title: <ResourceCountTitle />,
      dataIndex: 'count',
      key: 'count'
    }
  ]
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      footer={(pageData) => {
        const counts = pageData.map(item => item.count)
        const total = sum(counts)
        return (
          <Row>
            <Col span={18}><Text strong>Total</Text></Col>
            <Col span={6} style={{ paddingLeft: '4%' }}>{total}</Col>
          </Row>
        )
      }}
    />
  )
}

export default ResourceTable
