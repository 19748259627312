import { combineReducers } from 'redux'
import {
  GET_INTEGRATIONS,
  GET_INTEGRATION,
  PUT_INTEGRATION,
  PUT_INTEGRATION_BLACKLIST,
  POST_INTEGRATIONS
} from 'actions/integrations.actions'

function list (state = {}, action) {
  switch (action.type) {
    case GET_INTEGRATIONS:
      return action.payload
    case POST_INTEGRATIONS:
      return action.payload
    case PUT_INTEGRATION:
      return {
        data: state.data && state.data.map(item => item.id === action.payload.id ? action.payload : item)
      }
    case PUT_INTEGRATION_BLACKLIST:
      return {
        data: state.data && state.data.map(item => item.id === action.payload.id ? action.payload : item)
      }
  }

  return state
}

function active (state = null, action) {
  switch (action.type) {
    case GET_INTEGRATION:
      return action.payload
    case PUT_INTEGRATION:
      return action.payload
  }
  return state
}

export default combineReducers({
  list, active
})
