import React from 'react'

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import IconButton from 'components/buttons/icon'

const DeleteButton = (props) => {
  return <IconButton {...props} icon={faTimes} />
}

export default DeleteButton
