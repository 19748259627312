import React from 'react'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired } from '@fortawesome/pro-light-svg-icons/faNetworkWired'
import { faRouteHighway } from '@fortawesome/pro-light-svg-icons/faRouteHighway'

import Metadata from 'components/inventory/metadata'
import styles from './styles.less'

const Discovery = ({ attributes }) => {
  const serviceRegistries = JSON.parse(attributes.serviceRegistries)
  const loadBalancers = JSON.parse(attributes.loadBalancers)

  if (!loadBalancers.length && !serviceRegistries.length) return '-'

  const renderGroup = (item, icon) => (
    <div key={item.containerName} className={styles.discovery}>
      <FontAwesomeIcon icon={icon} />
      <div>
        <h3 className={styles.name}>{item.containerName}</h3>
        <p className={styles.value}>{item.containerName} {item.containerPort}</p>
      </div>
    </div>
  )

  return (
    <div className={styles.attribute_group}>
      {loadBalancers.map(loadBalancer => renderGroup(loadBalancer, faNetworkWired))}
      {serviceRegistries.map(serviceRegistry => renderGroup(serviceRegistry, faRouteHighway))}
    </div>
  )
}

const Placement = ({ attributes }) => {
  const placementConstraints = JSON.parse(attributes.placementConstraints)
  const placementStrategy = JSON.parse(attributes.placementStrategy)

  if (!placementConstraints.length && !placementStrategy.length) return '-'

  const renderStrategy = (item, index) => (
    <div key={`${item.type}-${index}`}>
      <h4 className={styles.value}>{item.type}</h4>
      <p className={styles.name}>{item.field}</p>
    </div>
  )

  const renderConstraints = (item, index) => (
    <div key={`${item.type}-${index}`}>
      <h4 className={styles.value}>{item.type}</h4>
      <p className={styles.name}>{item.expression}</p>
    </div>
  )

  return (
    <div>
      {placementStrategy.map(renderStrategy)}
      {placementConstraints.map(renderConstraints)}
    </div>
  )
}

const Deployment = ({ attributes }) => {
  const deployments = JSON.parse(attributes.deployments)
  const configuration = JSON.parse(attributes.deploymentConfiguration)

  const renderItem = (item) => (
    <div className={styles.deployment} key={item.id}>
      <h3 className={styles.name}>{item.id}</h3>
      <div className={styles.deployment_details}>
        <div>{item.status}</div>
        <div>{item.desiredCount} tasks</div>
        <div>{moment(item.updatedAt).fromNow()}</div>
      </div>
    </div>
  )

  return (
    <div className={styles.attribute_group}>
      <div className={styles.deployment_configuration}>
        Min
        <span className={styles.configuration_value}>{configuration.minimumHealthyPercent}%</span>
        Max
        <span className={styles.configuration_value}>{configuration.maximumPercent}%</span>
      </div>
      {deployments.map(renderItem)}
    </div>
  )
}

const ECSServiceMetadata = ({ attributes }) => {
  const data = [{
    title: 'Discovery',
    value: <Discovery attributes={attributes} />
  }, {
    title: 'Placement',
    value: <Placement attributes={attributes} />
  }, {
    title: 'Deployment',
    value: <Deployment attributes={attributes} />
  }]

  return <Metadata items={data} inverse />
}

export default ECSServiceMetadata
