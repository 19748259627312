import React, { useState, useEffect } from 'react'
import { Button, Modal, Transfer, Row, Col, Form } from 'antd'
import { keyBy, uniq, compact, isEmpty, includes } from 'lodash'

import Section from 'components/layout/content/section'
import ListItem from 'features/side-list/item'
import Empty from 'components/layout/content/empty'
import { AwsServiceIcon } from 'components/icons'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { getResourceService } from 'lib/resources/transformations'
import { useAllResourcesQuery, useResourceGroupPreviewQuery } from 'hooks/api'
import { getInventoryResources } from 'lib/resources/filters'

import styles from './styles.less'

const INVENTORY_SERVICES_BY_TYPE = keyBy(INVENTORY_SERVICES, 'type')

const Resources = ({ data, perRow = 3 }) => (
  <Row gutter={[8, 8]}>
    {data.map((resource, idx) => {
      const service = INVENTORY_SERVICES_BY_TYPE[resource.type]
      const delegation = resource?.delegationName ? `| ${resource.delegationName}` : ''
      return (
        <Col span={24 / perRow} key={idx}>
          <ListItem
            key={resource.id}
            icon={<AwsServiceIcon service={service?.service} />}
            title={resource?.title}
            left={`${service?.shortTitle} | ${resource.region.toUpperCase()} ${delegation}`}
            className={styles.resource}
          />
        </Col>
      )
    })}
  </Row>
)

const ResourcesPreview = ({ group, payload, handlePreview }) => {
  const [resources, setResources] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedResources, setSelectedResources] = useState(group?.fixedResources || [])

  const { data: resourcesData, isLoading: loadingResources } = useAllResourcesQuery()
  const { data: preview, isLoading: loadingPreview } = useResourceGroupPreviewQuery(payload)

  const allResources = getInventoryResources(resourcesData)

  const allResourcesById = keyBy(allResources, 'id')

  useEffect(() => {
    let resources

    if (isEmpty(preview)) {
      resources = compact(group?.resources.map(id => allResourcesById[id]))
    } else {
      resources = compact(preview?.resources.map(id => allResourcesById[id]))
    }
    setResources(resources)
  }, [group?.resources?.length, preview, allResources?.length])

  const showModal = () => setIsModalVisible(true)
  const handleModalOk = () => setIsModalVisible(false)
  const handleModalCancel = () => setIsModalVisible(false)
  const handleTransfer = (args) => {
    setSelectedResources(args)
    handlePreview()
  }

  const allRegions = uniq(allResources?.map(item => item.region))

  const fixedCount = isEmpty(preview) ? group?.fixedResources?.length : preview.fixedResources?.length

  return (
    <>
      <Section
        title={`Resources Preview (${resources?.length || 0})`}
        titleUpperCase
        loading={loadingPreview || loadingResources}
        actions={<Button type='link' onClick={showModal} className={styles.manageButton}>Manage Specific Resources ({fixedCount || 0})</Button>}
      >
        {isEmpty(resources)
          ? <Empty title='No matching resources found'><p>Change filters from the left or select specific resources in the modal</p></Empty>
          : <Resources data={resources} />}
      </Section>
      <Modal title='Select specific resources' open={isModalVisible} onOk={handleModalOk} onCancel={handleModalCancel} width='min-content'>
        <Form.Item
          name='selectedResources'
          style={{ margin: 0, border: 'none' }}
        >
          <Transfer
            style={{ margin: 12 }}
            dataSource={allResources}
            filterOption={(input, option) => includes(option.title.toLocaleLowerCase(), input.toLocaleLowerCase())}
            showSearch
            listStyle={{
              width: '47%',
              minWidth: 300,
              height: 300
            }}
            rowKey={record => record.id}
            targetKeys={selectedResources}
            titles={['All', 'Included']}
            onChange={handleTransfer}
            render={item => allRegions.length > 1 ? `${getResourceService(item)} | ${item.region} ${item?.title}` : `${getResourceService(item)} | ${item?.title}`}
          />
        </Form.Item>
      </Modal>
    </>
  )
}

export default ResourcesPreview
