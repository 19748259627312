import { orderBy } from 'lodash'
import { getUnixTime } from 'date-fns'
import { getEventsKey } from 'lib/dashboards-helpers'

const eventWithinPeriod = (event, start, end) => {
  const startTimestamp = getUnixTime(start) * 1000
  const endTimestamp = getUnixTime(end) * 1000

  const time = event.type === 'error' ? event.lastOccurredAt : event.openedAt

  return time > startTimestamp && time < endTimestamp
}

export const getEventsData = (widget, events, options) => {
  const { start, end } = options

  const data = widget.definition.type.reduce((accumulator, type) => {
    const key = getEventsKey(widget, type)
    return [...accumulator, ...events?.data[key]?.data || []]
  }, [])

  const filteredEventsByPeriod = data.filter(event => eventWithinPeriod(event, start, end))
  const filteredEventsByTargets = widget.definition.targets?.length
    ? filteredEventsByPeriod.filter(event => widget.definition.targets.includes(event.resource.id))
    : filteredEventsByPeriod

  const sortedEvents = orderBy(filteredEventsByTargets, (event) => event.type === 'error' ? event.lastOccurredAt : event.openedAt, 'desc')

  return sortedEvents
}

