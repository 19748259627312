// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jmXAYaohe2iCsA_WjZdl .ant-card {\n  border: none;\n  border-radius: 7px;\n}\n.jmXAYaohe2iCsA_WjZdl .ant-card .ant-card-head {\n  padding: 9px 18px 0;\n  border-bottom: none;\n  min-height: auto;\n}\n.jmXAYaohe2iCsA_WjZdl .ant-card .ant-card-head .ant-card-head-title {\n  padding: 0;\n}\n.jmXAYaohe2iCsA_WjZdl .ant-card .ant-card-body {\n  padding: 0;\n}\n.jmXAYaohe2iCsA_WjZdl .ant-card .ant-card-loading-content {\n  padding: 0 9px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/scheduled-search/dashboard/styles.less"],"names":[],"mappings":"AAEA;EAEI,YAAA;EACA,kBAAA;AAFJ;AADA;EAMM,mBAAA;EACA,mBAAA;EACA,gBAAA;AAFN;AANA;EAWQ,UAAA;AAFR;AATA;EAgBM,UAAA;AAJN;AAZA;EAoBM,cAAA;AALN","sourcesContent":["@import '../../../styles/main.less';\n\n.wrapper {\n  :global(.ant-card) {\n    border: none;\n    border-radius: 7px;\n\n    :global(.ant-card-head) {\n      padding: @spacing[small] @spacing[large] 0;\n      border-bottom: none;\n      min-height: auto;\n\n      :global(.ant-card-head-title) {\n        padding: 0;\n      }\n    }\n\n    :global(.ant-card-body) {\n      padding: 0\n    }\n\n    :global(.ant-card-loading-content) {\n      padding: 0 @spacing[small];\n    }\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "jmXAYaohe2iCsA_WjZdl"
};
export default ___CSS_LOADER_EXPORT___;
