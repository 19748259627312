import React from 'react'
import numeral from 'numeral'
import classnames from 'classnames'
import { Tooltip } from 'antd'
import { AlarmIcon, ErrorIcon, InsightIcon, SpinnerIcon } from 'components/icons'
import { Time } from 'components/time'
import SeverityBadge from 'components/badge/severity'

import { Item } from 'components/items/item'
import styles from './styles.less'

const getIcon = (item) => {
  if (item.icon) return item.icon
  if (item.type === 'alarm') return <AlarmIcon size='smallest' className={styles.icon} />
  if (item.type === 'insight') return <InsightIcon size='smallest' className={styles.icon} />
  if (item.type === 'error') return <ErrorIcon size='smallest' className={styles.icon} />
}

const EventItem = ({ item, loadingCount }) => {
  const times = item.lastOccurred ? [item.lastOccurred, item.time] : [item.time]

  return (
    <Item url={item.url} icon={getIcon(item)} separator size='md' gap='sm' className={classnames(styles.item, styles.event)}>
      <div className={styles.header}>
        <Tooltip mouseEnterDelay={1} title={item.title}>
          <div className={styles.title}>{item.title}</div>
        </Tooltip>
        {loadingCount && <SpinnerIcon className={styles.spinner_sm} />}
        {item.count && item.severity && <SeverityBadge severity={item.severity} text={numeral(item.count).format('0a')} />}
      </div>
      <div className={styles.content}>
        <div className={styles.description}>{item.description || item.errorType}</div>
        {item.time && <Time value={times} type='short' className={styles.time} />}
      </div>
    </Item>
  )
}

export { EventItem }
