import React, { useMemo } from 'react'

// import { useAccountRoutes } from 'containers/routes'
import Loading from 'components/layout/content/loading'
import Events from './events'
import EditableTitle from 'features/editable-title'
import { getEventsData } from './helpers'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'

import Actions from '../actions'

import styles from './styles.less'

const EventsWidget = ({ widget, events, options, onDelete, onEdit, onDuplicate, onUpdateName }) => {
  // const routes = useAccountRoutes()

  const data = useMemo(() => getEventsData(widget, events, options), [widget, events, options])

  if (!data.length && events.loading) return <Loading height='100%' />

  return (
    <div className={styles.widget_container}>
      <div className={styles.title}>
        <div>
          <EditableTitle
            value={widget?.name || 'Events'}
            onSubmit={value => onUpdateName(widget, value)}
            size='xs'
            solid
            displayContent={widget?.name || 'Events'}
          />
        </div>
        <span className={styles.actions}>
          <IconButton icon={<DragIcon />} className='dbDragHandle' />
          <Actions
            widget={widget}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
          />
        </span>
      </div>
      <div className={styles.separator} />
      <div className={styles.wrapper}>
        <Events events={data} />
      </div>
    </div>
  )
}

export default EventsWidget
