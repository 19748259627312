import React, { useContext } from 'react'
import { Col, Row } from 'antd'
import { getTime } from 'date-fns'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { meanBy, sumBy, values } from 'lodash'

import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../../container'
import InventoryTags from 'components/inventory/tags'
import Events from 'components/inventory/events'
import Section from 'components/layout/content/section'
import Tabs from 'components/antd/tabs'
import RequestItem from 'features/invocations/list/appsync/request'
import { findInventoryByType, INVENTORY_SERVICES } from 'lib/resources/constants'
import { APPSYNC_REQUESTS } from 'containers/inventory/details/resource/metrics-tabs/services/appsync'
import Resolvers from 'containers/inventory/details/resource/services/appsync/graphql-api/resolvers'
import RequestContent from 'features/invocations/details/appsync-request'
import ResolverContent from 'containers/inventory/details/resource/services/appsync/graphql-api/resolvers/content'
import { useAccountRoutes } from 'containers/routes'
import { useRequestsQuery, useResourcesMetricsQuery, useResourcesQuery } from 'hooks/api'
import AppSyncMetadata from './config'

import Drawer from 'components/drawer'
import sha1 from 'sha1'
import { formatDuration } from 'components/charts/utils'
import { ServiceContext } from '../../../index'

import styles from './styles.less'

const aggregates = {
  errors: (data) => sumBy(data, 'sum') || 0,
  requests: (data) => sumBy(data, 'sum') || 0,
  duration: (data) => formatDuration(meanBy(data, 'avg') / 1000000) || 0
}

const AppSyncGraphQLApi = ({ item }) => {
  const routes = useAccountRoutes()
  const { search, pathname } = useLocation()
  const match = useRouteMatch()
  const history = useHistory()

  const { start, end, relativeSpan, range } = useContext(ServiceContext)

  const requestPath = `${match.path}/requests/:requestId`
  const resolverPath = `${match.path}/resolvers/:resolverId`
  const urlParams = ['requests', 'resolvers'].some(path => pathname?.includes(path)) ? pathname.split('/') : []
  const lastUrlParam = urlParams[urlParams.length - 1]

  const filters = { resource: item.id, from: getTime(start), to: getTime(end) }
  const { data: requests } = useRequestsQuery(filters)
  const { data: children, isLoading: loadingChildren } = useResourcesQuery({ parent: item.id })
  const service = findInventoryByType(item?.type).id
  const metricsData = useResourcesMetricsQuery({
    namespaces: ['dashbird/appsync/resolvers'],
    start: getTime(start),
    end: getTime(end)
  })

  const attributes = objectify(item.attributes)

  const childrenArray = values(children)
  const resolvers = childrenArray?.filter(item => item.type === 'AWS::AppSync::Resolver').map(resolver => {
    const resourceId = sha1(resolver.arn)
    return {
      ...resolver,
      metrics: metricsData.filter(metricData => metricData.data).map(metricData => {
        return {
          stats: metricData?.data.stats,
          metric: metricData?.data.metric,
          data: metricData?.data.datapoints[resourceId],
          aggregate: aggregates[metricData?.data.metric](metricData?.data.datapoints[resourceId])
        }
      })
    }
  })
  const dataSources = childrenArray?.filter(item => item.type === 'AWS::AppSync::DataSource')
  const functionConfigurations = childrenArray?.filter(item => item.type === 'AWS::AppSync::FunctionConfiguration')
  const types = childrenArray?.filter(item => item.type === 'AWS::AppSync::Type')

  const objectifiedTypes = types?.map(type => ({
    ...type,
    attributes: objectify(type.attributes)
  }))

  const objectifiedResolvers = resolvers?.map(resolver => ({
    ...resolver,
    attributes: objectify(resolver.attributes)
  }))

  const objectifiedDataSources = dataSources?.map(dataSource => ({
    ...dataSource,
    attributes: objectify(dataSource.attributes)
  }))

  const metadata = {
    resolvers: objectifiedResolvers,
    dataSources: objectifiedDataSources,
    functionConfigurations
  }

  const onDrawerClose = () => {
    history.push({ pathname: routes.inventory.resource.url({ resourceId: item?.id }), search })
  }

  return (
    <InventoryResourceContainer item={item} metadata={metadata} service={INVENTORY_SERVICES.AppSyncGraphQLApi.id} initialChart={APPSYNC_REQUESTS}>
      <Row gutter={12}>
        <Col span={16}>
          <Section>
            <Tabs defaultActiveKey="requests" items={[
              { key: 'requests', label: 'Requests', children: <div className={styles.items_wrapper}>{requests?.length > 0 && requests.map((item, index) => (<RequestItem key={index} item={item} selectedRequestId={lastUrlParam} />))}</div> },
              {
                key: 'schema',
                label: 'Schema',
                children: (
                  objectifiedTypes?.map(type => <Resolvers
                    key={type.id} loading={loadingChildren} resolvers={objectifiedResolvers}
                    type={type} dataSources={objectifiedDataSources} selectedResolverId={lastUrlParam} />)
                )
              },
              {
                key: 'configuration',
                label: 'Configuration',
                children: (
                  <>
                    {item.tags.length !== 0 && <InventoryTags tags={item.tags} />}
                    <AppSyncMetadata attributes={attributes} />
                  </>
                )
              }
            ]} />
          </Section>
        </Col>
        <Col span={8}>
          <Section>
            <Events resourceId={item.id} errors />
          </Section>
        </Col>
      </Row>
      <Route path={requestPath}>
        <Drawer
          open
          onClose={onDrawerClose}
          mask={false}
          closable={false}
          size='large'
        >
          <RequestContent onClose={onDrawerClose} type={service} resource={item} />
        </Drawer>
      </Route>
      <Route path={resolverPath}>
        <Drawer
          open
          onClose={onDrawerClose}
          mask={false}
          closable={false}
          size='large'
          className={styles.resolver_drawer}
        >
          <ResolverContent onClose={onDrawerClose} start={start} end={end} range={range} relativeSpan={relativeSpan} />
        </Drawer>
      </Route>
    </InventoryResourceContainer>
  )
}

export default AppSyncGraphQLApi
