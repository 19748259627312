import React, { useState, useEffect } from 'react'
import numeral from 'numeral'

import { useLocation } from 'react-router-dom'
import { getTime, sub } from 'date-fns'

import { faLambda } from '@fortawesome/pro-light-svg-icons/faLambda'
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer'
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload'

import Section from 'components/layout/content/section'
import Metrics from 'components/metrics'

import { useAccountRoutes } from 'containers/routes'

import AccountsOptions from './group-options'
import DataIngestedHistogramChart from './charts/data-ingested-histogram'
import UsageHistogramChart from './charts/resources-usage-histogram'
import formatInvocations from './formatters/invocations'
import { calculateInvocationSum } from 'lib/usage-helpers'
import { useOrgIngestionQuery, useOrgIngestionSummaryQuery, useOrgResourcesUsageQuery, useOrgResourcesUsageSummaryQuery, useOrganizationsQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

export const findOrganizationAccountIds = (organizations = [], currentOrganization = {}) => {
  const organization = organizations.find(org => (org.organization || {}).id === currentOrganization.id)
  if (!organization || !organization.accounts || !organization.accounts.length) return []
  const ids = organization.accounts.map(account => ({ id: account.id, name: account.name }))
  return ids
}

const UsageReport = () => {
  const location = useLocation()
  const routes = useAccountRoutes()

  const [checkedList, setCheckedList] = useState([])
  const [checkAll, setCheckAll] = useState(true)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedChart, setSelectedChart] = useState(location.pathname.includes(routes.settings.subscriptions.url()) ? 'invocations' : 'ingestion')
  const [group, setGroup] = useState('lambda')

  const { data: organizations } = useOrganizationsQuery()
  const { organization } = useOrganization()

  const from = getTime(sub(new Date(), { days: 30 }))
  const until = Date.now()

  const { data: ingestedDataUsage, isLoading: ingestedUsageLoading } = useOrgIngestionQuery({ start: from, end: until })
  const { data: ingestedDataUsageSummary, isLoading: ingestedSummaryLoading } = useOrgIngestionSummaryQuery({ start: from, end: until })

  const { data: resourcesUsage, isLoading: resourcesUsageLoading } = useOrgResourcesUsageQuery({ start: from, end: until })
  const { data: resourcesUsageSummary, isLoading: resourcesUsageSummaryLoading } = useOrgResourcesUsageSummaryQuery({ start: from, end: until })

  const availableAccounts = findOrganizationAccountIds(organizations, organization)
  const allAccountIds = availableAccounts.map(item => item.id)

  useEffect(() => {
    if (!allAccountIds.length) return
    setCheckedList(availableAccounts)
  }, [allAccountIds.length])

  const changeAccounts = (list) => {
    const accounts = availableAccounts.filter(item => list.includes(item.name))
    setCheckedList(accounts)
    setCheckAll(list.length === allAccountIds.length)
    setIndeterminate(!!checkedList.length && checkedList.length < allAccountIds.length)
  }

  const changeCheckAll = (e) => {
    setCheckedList(e.target.checked ? availableAccounts : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const filterData = (data) => Object.keys(data || {})
    .filter(key => checkedList.map(item => item.id).includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key]
      return obj
    }, {})

  const calculateIngestionSum =
    Object.values(filterData(ingestedDataUsageSummary)).reduce((acc, item) => acc + parseFloat(item.usage.bytes.sum), 0)

  const calculateUsageSum =
    Object.values(filterData(resourcesUsageSummary)).reduce((acc, item) => acc + parseFloat(item.usage), 0)

  const metricsForBilling = [
    {
      title: 'Invocations',
      onClick: () => setSelectedChart('invocations'),
      value: formatInvocations(calculateInvocationSum(filterData(ingestedDataUsage), 'invocations')),
      icon: faLambda,
      selected: selectedChart === 'invocations',
      loading: ingestedSummaryLoading
    }
  ]

  const metrics = [
    {
      title: 'Data ingestion',
      onClick: () => setSelectedChart('ingestion'),
      value: numeral(calculateIngestionSum).format('0.00b'),
      icon: faDownload,
      selected: selectedChart === 'ingestion',
      loading: ingestedSummaryLoading
    },
    {
      title: 'Resources',
      onClick: () => setSelectedChart('usage'),
      value: numeral(calculateUsageSum).format('0'),
      icon: faServer,
      selected: selectedChart === 'usage',
      loading: resourcesUsageSummaryLoading
    },
    ...metricsForBilling
  ]

  const loading = resourcesUsageLoading || ingestedUsageLoading

  return (
    <>
      <p className={styles.description}>See how much data was ingested in all of your accounts within last 30 days</p>
      <AccountsOptions onChange={changeAccounts} checkAll={checkAll} onChangeAll={changeCheckAll} checkedList={checkedList} allAccounts={availableAccounts} indeterminate={indeterminate} changeGrouping={setGroup} selectedChart={selectedChart} />
      <Metrics items={location.pathname.includes(routes.settings.subscriptions.url()) ? metricsForBilling : metrics} />
      <Section solid>
        {selectedChart === 'ingestion' && <DataIngestedHistogramChart data={filterData(ingestedDataUsage)} tab={group} loading={loading} accounts={availableAccounts} />}
        {selectedChart === 'usage' && <UsageHistogramChart data={filterData(resourcesUsage)} tab={group} loading={loading} accounts={availableAccounts} />}
        {selectedChart === 'invocations' && <DataIngestedHistogramChart data={filterData(ingestedDataUsage)} tab={group} type='invocations' loading={loading} accounts={availableAccounts} />}
      </Section>
    </>
  )
}

export default UsageReport
