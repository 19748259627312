import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, message, Button } from 'antd'

import { StarIcon, ClockIcon } from 'components/icons'
import { isEmpty } from 'lodash'
import IconButton from 'components/buttons/icon'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import { useGetQueryQuery } from 'hooks/api/queries'
import { useResourceGroupsQuery } from 'hooks/api'
import { SearchContext } from 'hooks/context/search-context'
import { useAccountRoutes } from 'containers/routes'

import styles from './styles.less'

const SearchActions = ({ form, createQuery, deleteQuery }) => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { data: query, isLoading } = useGetQueryQuery()
  const { data: resourceGroups } = useResourceGroupsQuery()
  const { editorState, validateQuery } = useContext(SearchContext)
  const editorQuery = editorState.getCurrentContent().getPlainText()
  const isActionDisabled = !editorQuery || !editorQuery.length

  const prefixTarget = (target) => {
    if (!(resourceGroups || []).map(group => group.id).includes(target)) return target
    return `group:${target}`
  }

  const onSchedule = async () => {
    const values = form.getFieldsValue()

    if (!validateQuery()?.isValid) return message.error('Query validation failed.')
    if (isEmpty(editorQuery)) return message.error('Cannot schedule a query with no query')

    const payload = {
      ...values,
      targets: values?.targets?.map(identity => prefixTarget(identity)),
      name: editorQuery,
      query: editorQuery,
      relativeTimespan: 15,
      severity: 'info',
      ...(!query?.frequency && { frequency: 30 })
    }

    try {
      const response = await createQuery(payload)
      history.push(routes.scheduledSearch.query.url({ queryId: response.id }))
    } catch { }
  }

  const onSave = () => {
    const values = form.getFieldsValue()
    if (!validateQuery()?.isValid) return message.error('Query validation failed.')
    if (isEmpty(editorQuery)) return message.error('Cannot save a query with no query')

    try {
      createQuery({
        ...values,
        targets: values?.targets?.map(identity => prefixTarget(identity)),
        name: editorQuery,
        query: editorQuery,
        relativeTimespan: 15
      })
    } catch { }
  }

  const onDelete = async () => {
    try {
      await deleteQuery(query.id)
      history.push(routes.search.url())
    } catch { }
  }

  return !isLoading && (query
    ? (
      <>
        <IconButton icon={<ClockIcon />} onClick={onSchedule} title={'Schedule query to run after each 30min'} titlePlacement='left' className={styles.schedule_icon} disabled={isActionDisabled} />
        <Dropdown
          trigger={['click']}
          overlayClassName={styles.configure_dropdown}
          menu={{
            items: [
              { key: 'duplicate', label: <Button type='link' onClick={onSave}>Duplicate</Button>, disabled: isActionDisabled },
              { key: 'delete', label: <Button type='link' onClick={onDelete}>Delete</Button> }
            ]
          }}
        >
          {query && <ActionsDropdownButton />}
        </Dropdown>
      </>)
    : (
      <>
        <IconButton icon={<ClockIcon />} onClick={onSchedule} title={'Schedule query to run after each 30min'} titlePlacement='left' className={styles.schedule_icon} disabled={isActionDisabled} />
        <IconButton icon={<StarIcon />} onClick={onSave} title='Save the query' titlePlacement='left' className={styles.save_icon} disabled={isActionDisabled} />
      </>))
}

export default SearchActions
