import React from 'react'
import { Col, Row } from 'antd'
import { chunk } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas } from '@fortawesome/pro-regular-svg-icons/faGlobeAmericas'
import { faGlobeEurope } from '@fortawesome/pro-regular-svg-icons/faGlobeEurope'
import { faGlobeAsia } from '@fortawesome/pro-regular-svg-icons/faGlobeAsia'

import RegionButton from './region-button'
import styles from './styles.less'

const AREAS = {
  america: { title: 'North America / South America', icon: faGlobeAmericas },
  europe: { title: 'Europe / Middle East / Africa', icon: faGlobeEurope },
  asia: { title: 'Asia Pacific', icon: faGlobeAsia }
}

const AreaColumn = (props) => {
  const { area, regions } = props
  const groupedRegions = chunk(regions, 2)

  return (
    <Col span={8} className={styles.column}>
      <div className={styles.title}>
        <FontAwesomeIcon
          icon={AREAS[area].icon}
          size='lg'
        />
        <h1>{AREAS[area].title}</h1>
      </div>

      {
        groupedRegions.map(([region1, region2], i) => (
          <Row key={i} type='flex' justify='center'>
            <RegionButton region={region1} {...props} />
            <RegionButton region={region2} {...props} />
          </Row>
        ))
      }
    </Col>
  )
}

export default AreaColumn
