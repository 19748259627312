import React from 'react'

import { HexagonBadges } from 'components/badge/hexagon'

import styles from './styles.less'

const AuthHexagons = () => (
  <>
    <div className={styles.hexagons_upper}>
      <HexagonBadges items={[
        {
          title: '10K',
          subtitle: 'AWS accounts',
          key: 1
        },
        {
          title: '1M',
          subtitle: 'functions monitored',
          key: 2,
          placement: 'bottom_right'
        },
        {
          title: '1B',
          subtitle: 'transactions daily',
          key: 3,
          placement: 'bottom'
        }
      ]} />
    </div>
    <div className={styles.hexagons_lower}>
      <HexagonBadges items={[
        {
          title: '2 min',
          subtitle: 'setup',
          key: 1,
          placement: 'top_right'
        },
        {
          title: '',
          subtitle: 'no code changes required',
          key: 2
        }]} />
    </div>
  </>
)

export default AuthHexagons
