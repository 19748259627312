import React from 'react'
import { isEmpty } from 'lodash'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'

import Section from 'components/layout/content/section'
import InventoryTags from 'components/inventory/tags'
import ECSServers from './servers'
import ServiceList from './services'
import Events from 'components/inventory/events'
import InventoryResourceContainer from '../../../container'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { ECS_CPU } from '../../../metrics-tabs/services/ecs-cluster'
import Tabs from 'components/antd/tabs'
import { useResourcesQuery } from 'hooks/api'

const Config = ({ item }) => {
  return (<>
    {item.tags.length !== 0 ? <InventoryTags tags={item.tags} inverse /> : 'No configuration setup'}
  </>)
}

const ECSCluster = ({ item }) => {
  const { data: services } = useResourcesQuery({ type: INVENTORY_SERVICES.ECSService.type, parent: item.id })

  const attributes = objectify(item.attributes)
  const servers = JSON.parse(attributes.containers)

  return (
    <>
      <InventoryResourceContainer item={item} metadata={{ services: services?.data, servers }} service={INVENTORY_SERVICES.ECSCluster.id} initialChart={ECS_CPU}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey="services" items={[
                { key: 'services', label: 'Services', children: !isEmpty(services) && <ServiceList services={services && Object.values(services)} /> },
                { key: 'servers', label: 'Servers', children: <ECSServers servers={servers} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col span={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default ECSCluster
