import { useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'

import { useUser } from '../context/user-context'
import { MSG_RESENT_PASSWORD_RESET_LINK } from 'containers/auth/messages'

export const useForgotPassword = (msg) => {
  const { setAuthMessage } = useUser()
  return useMutation(
    (email) => Auth.forgotPassword(email),
    {
      onSuccess: () => {
        setAuthMessage(MSG_RESENT_PASSWORD_RESET_LINK)
      }
    }
  )
}

export const useForgotPasswordSubmit = () => {
  return useMutation(
    ({ email, code, password }) => Auth.forgotPasswordSubmit(email, code, password)
  )
}

export const useCompleteNewPassword = () => {
  const { user, setUser, setAuthenticated } = useUser()
  return useMutation(
    (password) => Auth.completeNewPassword(user, password),
    {
      onSuccess: (cognitoUser) => {
        setUser(cognitoUser)
        setAuthenticated(!cognitoUser.challengeName)
      }
    }
  )
}
