import { apiFetch } from 'lib/action-helpers'
import { QUERIES_LIST, queryPaths } from 'hooks/api/queries/index'
import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

const deleteQuery = async (account, queryId) => {
  return await apiFetch(queryPaths.query(account, queryId), { method: 'DELETE' })
}

export const useDeleteQueryQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  const queryKey = [...QUERIES_LIST, account.id]

  return useMutation(
    (queryId) => deleteQuery(account.id, queryId),
    {
      onMutate: (queryId) => {
        const previousQueries = queryClient.getQueryData(queryKey)

        const newQueries = previousQueries.filter(({ id }) => id !== queryId)
        queryClient.setQueryData(queryKey, newQueries)

        return { previousQueries }
      },
      onError: (err, newItem, context) => {
        if (!err) return
        queryClient.setQueryData(queryKey, context.previousQueries)
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
      }
    }
  )
}
