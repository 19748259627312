import React from 'react'
import { sub } from 'date-fns'

import GlobalDatePicker from 'components/date-picker/global'
import styles from './styles.less'

const DashboardDatePicker = ({ start, end, selectRange, relativeSpan }) => {
  return <>
    <div className={styles.picker_container}>
      <h4 className={styles.picker_title}>Time period</h4>
      <div className={styles.range_container}>
        <GlobalDatePicker
          start={start}
          end={end}
          onChange={selectRange}
          relativeSpan={relativeSpan}
          refetchHidden
          max={new Date()}
          min={sub(new Date(), { months: 1 })}
        />
      </div>
    </div>
  </>
}

export default DashboardDatePicker
