import React from 'react'
import { Text } from 'recharts'
import colors from 'lib/colors'
import format from 'components/charts/format'

const XAxisTick = ({ payload, x, y, index, span, width, visibleTicksCount, ...rest }) => {
  const formatted = format(payload.value, span)

  const ticksInBetween = width < 450
    ? (visibleTicksCount >= 72
      ? 24 * 2
      : span >= 48 * 60 && visibleTicksCount >= 40
        ? 7 * 2
        : visibleTicksCount < 7 ? 1 : 8 * 2)
    : (visibleTicksCount >= 72
      ? 24
      : span >= 48 * 60 && visibleTicksCount >= 40
        ? 7
        : visibleTicksCount < 7 ? 1 : 8)

  if (index % ticksInBetween !== 0) return <line x1={x} y1={y - 6} x2={x} y2={y - 2} stroke={colors('chart', 'grid')} />
  else {
    return (
      <>
        <line x1={x} y1={y - 6} x2={x} y2={y + 2} stroke={colors('chart', 'grid')} />
        <Text {...rest} x={x} y={y + 8} fill={colors('chart', 'text')} fontSize={12} textAnchor={'middle'}>{formatted}</Text>
      </>
    )
  }
}

export default XAxisTick
