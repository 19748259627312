import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { parse } from 'query-string'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'

import { Dropdown, Tooltip } from 'antd'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import { ActionsDropdownButton } from 'components/buttons'
import Email from './email'
import Slack from './slack'
import SNS from './sns'
import Webhook from './webhook'
import TeamsBot from './teamsbot'
import { Item } from 'components/items'
import { ConditionTypes } from '../policies'
import { AlarmIcon, AlertCircleIcon, SlidersIcon } from 'components/icons/font-awesome'
import { Typography } from 'components/typography'

import { useAccountRoutes } from 'containers/routes'
import mutations from './mutations'
import { useOrganization } from 'hooks/context/organization-context'
import {
  useNotificationChannelQuery,
  useCreateNotificationChannelQuery,
  useDeleteNotificationChannelQuery,
  useUpdateNotificationChannelQuery,
  useCreateSlackConnectionQuery,
  useLastNotificationsQuery,
  useTestChannelQuery,
  useNotificationPoliciesQuery,
  useNotificationChannelsQuery
} from 'hooks/api'

import styles from './styles.less'

const TYPES = {
  email: 'E-mail',
  slack: 'Slack',
  sns: 'AWS SNS',
  webhook: 'Webhook',
  teamsbot: 'Teams Bot'
}

const Title = ({ count }) => {
  return (
    <>Connected policies
      {count === 0 && <Tooltip title='Connect policies to get notifications'><AlertCircleIcon className={styles.alert_icon} /></Tooltip>}
    </>
  )
}

const Channel = () => {
  const history = useHistory()
  const location = useLocation()
  const routes = useAccountRoutes()
  const { channelId, type } = useParams()
  const { code, externalChannelId, externalChannelName } = parse(location.search)

  const { account } = useOrganization()
  const { data: channel, isLoading } = useNotificationChannelQuery(channelId)
  const { mutate: createChannel } = useCreateNotificationChannelQuery()
  const { mutate: deleteChannel } = useDeleteNotificationChannelQuery()
  const { mutate: updateChannel, isLoading: updateLoading } = useUpdateNotificationChannelQuery()
  const { mutate: createSlackConnection } = useCreateSlackConnectionQuery()
  const { mutate: testChannel } = useTestChannelQuery(channelId)
  const { data: lastNotifications, isLoading: loadingNotifications, isFetching: fetchingNotifications } = useLastNotificationsQuery(channelId)
  const { data: policies } = useNotificationPoliciesQuery()
  const { data: channels } = useNotificationChannelsQuery()
  const IS_OWNER = account.role === 'owner'
  const IS_ADMIN = account.role === 'admin'
  const DISABLE_EDIT = !(IS_OWNER || IS_ADMIN)

  const lastNotificationsLoading = loadingNotifications || fetchingNotifications

  const handleAction = (key) => {
    if (key === 'delete') return handlers.remove()
    return testChannel()
  }

  useEffect(async () => {
    if (!account.id || !type || !code) return

    // for connecting with slack channel
    const path = routes.settings.notifications.channels.new.url({ type })
    const uri = `${window.location.protocol}//${window.location.host}${path}`

    await createSlackConnection({ code, uri })

    history.push(routes.settings.notifications.url())
  }, [account.id, type, code])

  useEffect(async () => {
    if (!account.id || !type || !externalChannelId || !externalChannelName) return

    // handle connecting teams bot
    await createChannel({
      payload: {
        name: externalChannelName,
        type,
        uri: externalChannelId
      }
    })

    history.push(routes.settings.notifications.url())
  }, [account.id, type, externalChannelId, externalChannelName])

  const handlers = mutations({
    channel,
    history,
    createChannel,
    deleteChannel,
    updateChannel
  })
  const channelType = type || channel?.type

  const connectedPolicies = policies?.filter(policy => policy.channels.includes(channelId))

  return (
    <Content
      item
      title={!channel ? null : type ? TYPES[type] : `${TYPES[channel?.type]}: ${channel?.name}`}
      backRoute={routes.settings.notifications.url()}
      actions={
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              { key: 'delete', label: 'Delete', disabled: DISABLE_EDIT },
              { key: 'test', label: 'Test', disabled: DISABLE_EDIT }
            ],
            onClick: ({ key }) => handleAction(key)
          }}
        >
          <ActionsDropdownButton />
        </Dropdown>
      }
      breadcrumbs={['Settings', 'Notifications', 'Channel']}
      loading={isLoading}
    >
      {channelType === 'email' && <Email channel={channelId && channel} handlers={handlers} saving={updateLoading} />}
      {channelType === 'slack' && <Slack channel={channelId && channel} handlers={handlers} saving={updateLoading} />}
      {channelType === 'sns' && <SNS channel={channelId && channel} handlers={handlers} saving={updateLoading} />}
      {channelType === 'webhook' && <Webhook channel={channelId && channel} handlers={handlers} saving={updateLoading} />}
      {channelType === 'teamsbot' && <TeamsBot channel={channelId && channel} handlers={handlers} saving={updateLoading} />}

      {channelId && <Section title={<Title count={connectedPolicies?.length} />} titleUpperCase className={styles.last_notifications}>
        {connectedPolicies?.length === 0 && <Typography.Paragraph>This channel has no connected policies</Typography.Paragraph>}
        {connectedPolicies?.map(item => {
          const connectedChannels = channels?.filter(item => item.channels?.includes(policyChannel => policyChannel === item.id))
          return (
            <Item.Filter
              key={item.id}
              title={item.name}
              description={connectedChannels?.map(item => item.name).join(', ')}
              icon={<SlidersIcon className={styles.icon} />}
              url={routes.settings.notifications.policies.policy.url({ policyId: item.id })}
              extra={<ConditionTypes policy={item} />}
            />
          )
        })}
      </Section>}

      {channelId && <Section title='Last notifications' titleUpperCase loading={lastNotificationsLoading} className={styles.last_notifications}>
        {lastNotifications?.length === 0 && <Typography.Paragraph>We did not find any notifications sent to this channel. You can send a test message from top right.</Typography.Paragraph>}
        {lastNotifications?.map((item, index) => {
          const connectedPolicies = policies?.filter(policy => item.policyIds?.includes(policy.id))?.map(item => item.name)
          return (
            /**
             *   {
             *     account: item.account,
             *     channelId: item.channelId,
             *     policyIds: item.policyIds,
             *     sendResult: item.sendResult,
             *     createdAt: item.createdAt
             *   }
             */
            <Item.Filter
              key={index}
              title={item.sendResult === 'success' ? `Successfully sent at ${format(item.createdAt, 'HH:mm:ss.SS MMM do, yyyy')}` : 'Failed'}
              description={`Policies: ${isEmpty(connectedPolicies) ? 'No existing policies connected to this notification' : connectedPolicies?.join(', ')}`}
              icon={<AlarmIcon className={styles.icon} />}
              statusBar={item.sendResult === 'success' ? 'success' : 'error'}
            />
          )
        })}
      </Section>}
    </Content>
  )
}

export default Channel
