// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vmlNgkEa_HZeNss_QxSd {\n  margin-top: 12px;\n}\n.vmlNgkEa_HZeNss_QxSd:first-child {\n  margin-top: 0;\n}\n.bjHV9p_nBt6F2J3s3aPa .ant-drawer-content {\n  background: #f5f5f5;\n}\n.bw_2n20UWZgFegRzHsuQ {\n  display: flex;\n  flex-wrap: nowrap;\n  color: #919191;\n  margin-bottom: 3px;\n  background-color: #fff;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/inventory/details/resource/services/appsync/graphql-api/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAKA;EAEI,mBAAA;AAJJ;AAQA;EACE,aAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,sBAAA;AANF","sourcesContent":["@import '../../../../../../../styles/main';\n\n.tabslist {\n  margin-top: @spacing[normal];\n\n  &:first-child {\n    margin-top: 0;\n  }\n}\n\n.resolver_drawer {\n  :global(.ant-drawer-content) {\n    background: @background-colors[primary];\n  }\n}\n\n.items_wrapper {\n  display: flex;\n  flex-wrap: nowrap;\n  color: @text-colors[normal];\n  margin-bottom: @spacing[smallest];\n  background-color: @background-colors[secondary];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabslist": "vmlNgkEa_HZeNss_QxSd",
	"resolver_drawer": "bjHV9p_nBt6F2J3s3aPa",
	"items_wrapper": "bw_2n20UWZgFegRzHsuQ"
};
export default ___CSS_LOADER_EXPORT___;
