import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Button, Radio, Skeleton, Checkbox, Form } from 'antd'
import Input from 'components/antd/input'

import { UPDATE_PERMISSIONS, UPDATE_USER } from 'actions/organization.actions'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const submit = (values, saveUser, orgOwner) => {
  const formattedPermissions = Object.entries(values.permissions)
    .filter(([key, value]) => key !== 'owner' && value !== 'no-access')
    .reduce((obj, [key, value]) => {
      obj[key] = values.permissions[key]
      return obj
    }, {})

  const permissions = orgOwner
    ? {
      role: 'owner',
      permissions: {}
    }
    : { permissions: { ...formattedPermissions }, role: 'user' }

  const firstName = isEmpty(values.givenName) ? {} : { givenName: values.givenName }
  const lastName = isEmpty(values.familyName) ? {} : { familyName: values.familyName }

  const user = {
    ...firstName,
    ...lastName
  }

  saveUser(user, permissions)
}

const EditUser = ({ user, accounts, loading, saveUser, isAdmin }) => {
  const [orgOwner, setOrgOwner] = useState(isEmpty(user?.permissions) && user?.role === 'owner')

  const history = useHistory()

  const savingPermissions = useSelector(state => state.waiting.list[UPDATE_PERMISSIONS])
  const savingUser = useSelector(state => state.waiting.list[UPDATE_USER])
  const saving = savingPermissions || savingUser
  const { account } = useOrganization()

  const IS_ADMIN = account.role === 'admin'
  const IS_OWNER = account.role === 'owner'

  useEffect(() => {
    setOrgOwner(isEmpty(user?.permissions) && user?.role === 'owner')
  }, [user?.role])

  if (loading || !user || isEmpty(accounts)) return <Skeleton />

  return (
    <Form className={styles.form} layout='vertical'
      onFinish={values => submit(values, saveUser, orgOwner)}
      initialValues={{
        email: user?.email,
        givenName: user?.givenName || '',
        familyName: user?.familyName || '',
        permissions: {
          owner: user?.permissions || isEmpty(user?.permissions)
        }
      }}
    >
      <Form.Item name='email' label='User email' className={styles.form_item}>
        <Input placeholder={`${user?.email}`} disabled />
      </Form.Item>
      <Form.Item name='givenName' label='User first name' className={styles.form_item}>
        <Input placeholder={`${user?.givenName || 'User first name'}`} disabled={isAdmin} />
      </Form.Item>
      <Form.Item name='familyName' label='User last name' className={styles.form_item}>
        <Input placeholder={`${user?.familyName || 'User last name'}`} disabled={isAdmin} />
      </Form.Item>

      {IS_OWNER && <Form.Item name={['permissions', 'owner']}
        label='User permissions'
        className={styles.form_item}
        valuePropName='checked'>
        <>
          {user?.role === 'owner' && <p className={styles.description}>
            Removing this will automatically add role admin to all available accounts.
          </p>}
          <Checkbox onChange={() => setOrgOwner(!orgOwner)} checked={orgOwner}>
            Owner for this organization
          </Checkbox>
        </>
      </Form.Item>}

      {IS_ADMIN && orgOwner && <p className={styles.info}>User role is owner in this organization.</p>}

      {(!orgOwner) && accounts.map(account => {
        const isOwner = !user.permissions || (isEmpty(user?.permissions) && user.role === 'owner')
        const role = isOwner ? 'owner' : user.permissions[account.id]

        return (
          <Form.Item
            name={['permissions', account.id]}
            initialValue={(isOwner && !orgOwner) ? 'admin' : (role || 'no-access')}
            key={account.id} label={`User role for ${account.name}`} className={styles.form_item}>
            <Radio.Group name={['role', account.id]} disabled={orgOwner}>
              <Radio.Button value='owner' disabled>Owner</Radio.Button>
              <Radio.Button value='admin' disabled={IS_ADMIN}>Admin</Radio.Button>
              <Radio.Button value='user' disabled={false}>User</Radio.Button>
              <Radio.Button value='viewer' disabled={false}>Viewer</Radio.Button>
              <Radio.Button value='no-access' disabled={false}>No-access</Radio.Button>
            </Radio.Group>
          </Form.Item>
        )
      })}
      <Form.Item className={styles.form_buttons}>
        <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
        <Button type='link' onClick={history.goBack}>Cancel</Button>
      </Form.Item>
    </Form>
  )
}

export default EditUser
