import { combineReducers } from 'redux'

function active (state = {}, action) {
  switch (action.type) {
    case 'CLIENT_ACTIVE_CHANGE':
      if (!action.payload) {
        return {}
      }
      return action.payload
    case 'USER_LOGOUT':
      return {}
  }
  return state
}

export default combineReducers({
  active
})
