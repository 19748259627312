import React, { useEffect } from 'react'
import { Form } from 'antd'

import Email from 'containers/auth/form-items/email'
import ConfirmationCode from 'containers/auth/form-items/confirmation-code'
import Submit from 'components/antd/form/submit'
import { UnlockOutlined } from '@ant-design/icons'

const RegisterConfirm = ({ loading, onSubmit, email }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  const setEmail = () => {
    form.setFieldsValue({ email })
  }

  useEffect(setEmail, [email])

  return <Form form={form} onFinish={onSubmitWrapper}>
    <Email value={email} />
    <ConfirmationCode />
    <Submit loading={loading} text='Confirm email' icon={<UnlockOutlined />} />
  </Form>
}

export default RegisterConfirm
