import React, { useContext, useMemo } from 'react'
import { format, isToday, startOfDay, parseISO, formatISO } from 'date-fns'
import { Button } from 'antd'
import { SearchContext } from 'hooks/context/search-context'

import Empty from 'components/layout/content/empty'
import LogsParser from 'features/log/search'
import { Typography } from 'components/typography'

import styles from './styles.less'

const OnDemandResults = ({ logs, isFetchingNextPage, fetchNextPage, hasNextPage }) => {
  const { keywords } = useContext(SearchContext)

  const groupLogsByDate = ({ logs }) => {
    return logs.reduce((acc, logItem) => {
      const date = formatISO(startOfDay(logItem.timestamp))
      if (!acc[date]) acc[date] = []
      acc[date].push(logItem)
      return acc
    }, {})
  }

  const groupedByDate = useMemo(() => groupLogsByDate({ logs }), [logs])

  return (
    <div className={styles.results_wrapper}>
      {logs?.length === 0 && <Empty title='Oops..'>We did not find any results for this query.</Empty>}
      {Object.keys(groupedByDate)?.map(date => {
        return (
          <div key={date} className={styles.group_wrapper}>
            <Typography.Paragraph align='center' size='xs' darker>{isToday(parseISO(date)) ? 'Today' : format(parseISO(date), 'eeee, MMMM do')}</Typography.Paragraph>
            {groupedByDate[date]?.map((log, index) => {
              return (<LogsParser key={index} hits={[{ logline: log.logline, timestamp: log.timestamp }]} keywords={keywords} />)
            })}
          </div>
        )
      })}
      <div className={styles.center}>
        <Button
          type='inverted'
          size='small'
          loading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
          hidden={!hasNextPage}>
          Show more results...
        </Button>
      </div>
    </div>
  )
}

export default OnDemandResults
