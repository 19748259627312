import React from 'react'
import { get } from 'lodash'
import { Input } from 'antd'
import classnames from 'classnames'

import styles from './styles.less'

class MinimalInput extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      value: get(props, 'value', '')
    }
  }

  handleChange (event) {
    const { onChange } = this.props
    const value = event.target.value || ''

    this.setState({ value })

    onChange && onChange(event)
  }

  render () {
    const { className, ...rest } = this.props
    return (
      <Input
        {...rest}
        value={this.state.value}
        onChange={e => this.handleChange(e)}
        className={classnames(className, styles.input)} />
    )
  }
}

export default MinimalInput
