import React from 'react'
import { findInventoryByType } from 'lib/resources/constants'

import ListItem from 'features/side-list/item'
import { MutedIcon, AwsServiceIcon } from 'components/icons'
import Badge from 'components/badge/severity'

import styles from './styles.less'

const AlarmListItem = ({ data, index, style }) => {
  const item = data?.items[index]
  const service = findInventoryByType(item.resourceType)

  const count = item.violations.length

  return (
    <div style={style}>
      <ListItem
        key={item.id}
        icon={<AwsServiceIcon service={service?.service} />}
        title={item.name}
        selected={item.id.toString() === data?.selected}
        left={service?.title}
        right={item.disabled ? <MutedIcon className={styles.muted} /> : count > 0 ? <Badge severity={item.severity} text={`${count}`} /> : null}
        onClick={() => data?.onSelect(item)}
      />
    </div>
  )
}

export default AlarmListItem
