import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { Tooltip } from 'antd'
import { ChevronLeftSolidIcon, ChevronRightSolidIcon, FilterIcon } from 'components/icons'
import styles from './styles.less'

const SIZES = {
  small: 'small',
  medium: 'medium'
}

const ToggleListBtn = ({ toggle, listFixed, showFilter }) => {
  return (
    <div className={styles.sidebar_buffer}>
      <Tooltip title={listFixed ? 'Collapse sidebar' : 'Expand sidebar'} placement='right'>
        <button
          onClick={() => toggle(!listFixed)}
          className={classnames(
            {
              db_sidebar_btn_fixed: listFixed,
              db_sidebar_btn: true,
              [styles.filter]: showFilter
            })}>
          {showFilter
            ? <FilterIcon className={styles.filter_icon} />
            : listFixed ? <ChevronLeftSolidIcon /> : <ChevronRightSolidIcon />}
        </button>
      </Tooltip>
    </div>
  )
}

const PAGE_LAYOUT = 'page_layout'

const SplitView = ({ left, right, size }) => {
  const { search } = useLocation()
  const didMount = useRef(false)
  const [listFixed, setListFixed] = useState(true)
  const [listFiltered, setListFiltered] = useState(false)

  const pageLayout = JSON.parse(localStorage.getItem(PAGE_LAYOUT))

  useEffect(() => {
    if (!pageLayout) return

    setListFixed(pageLayout.isSidelistFixed)
  }, [])

  useEffect(() => {
    const data = { isSidelistFixed: listFixed }

    // Resize event forces dashboard to recalculate widget widths
    window.dispatchEvent(new Event('resize'));

    localStorage.setItem(PAGE_LAYOUT, JSON.stringify(data))
  }, [listFixed])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    }

    if (listFixed) return

    setListFiltered(true)
    setTimeout(() => setListFiltered(false), 1000)
  }, [search])

  return (
    <div className={styles.container}>
      <div className={classnames({
        [styles.sidebar]: true,
        [styles.fixed]: listFixed,
        [styles.small]: size === SIZES.small
      })}>
        <div className={classnames({
          db_sidebar_wrapper: true,
          db_sidebar_wrapper_layover: !listFixed,
          db_sidebar_small: size === SIZES.small
        })}>
          <div
            className={classnames({
              [styles.db_sidebar_content]: true,
              db_sidebar_layover_content: !listFixed
            })}>
            {left}
          </div>
          <ToggleListBtn toggle={setListFixed} listFixed={listFixed} search={search} showFilter={listFiltered} />
        </div>
      </div>

      <div className={styles.right}>{right}</div>
    </div >
  )
}

export default SplitView
