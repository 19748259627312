import React from 'react'

import Metadata from 'components/inventory/metadata'

const AppSyncMetadata = ({ attributes }) => {
  if (!attributes) return <></>

  const data = []
  const logConfig = attributes.logConfig && JSON.parse(attributes.logConfig)
  if (logConfig) {
    data.push({
      title: 'Logs',
      children: [
        { key: 'Role ARN', value: logConfig.cloudWatchLogsRoleArn },
        { key: 'Exclude Verbose Content', value: logConfig.excludeVerboseContent ? 'TRUE' : 'FALSE' },
        { key: 'Log Level', value: logConfig.fieldLogLevel }
      ]
    })
  }

  const userPoolConfig = attributes.userPoolConfig && JSON.parse(attributes.userPoolConfig)
  if (userPoolConfig) {
    data.push({
      title: 'User Pool',
      children: [
        { key: 'Region', value: userPoolConfig.awsRegion },
        { key: 'Default Action', value: userPoolConfig.defaultAction },
        { key: 'ID', value: userPoolConfig.userPoolId }
      ]
    })
  }

  data.push({
    title: 'X-Ray Enabled',
    value: attributes.xrayEnabled.toUpperCase()
  })

  return <Metadata items={data} inverse />
}

export default AppSyncMetadata
