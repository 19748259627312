import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { sortBy, flatten, some } from 'lodash'

import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import ListSearch from 'features/side-list/search'
import ListHeader from 'features/side-list/header'
import Item from './item'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { NameAlphabetical, ServiceType, Delegation } from 'features/side-list/sorter'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import { INVENTORY_FILTERS } from './filters'

import { hasMoreDelegations } from 'lib/resources/transformations'
import { getInventoryResources } from 'lib/resources/filters'
import { parseQuery } from 'lib/hooks/use-query-params'
import { useAccountRoutes } from 'containers/routes'
import { useResourceGroupsQuery, useAllResourcesQuery, useResourcesMetricsQuery, useDelegationsQuery } from 'hooks/api'

const ResourceList = () => {
  const { resourceId } = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const routes = useAccountRoutes()

  const groupInUrl = parseQuery(search)?.group

  const { data: resourcesAll, isLoading: loadingResources } = useAllResourcesQuery()
  const { isLoading: loadingDelegations } = useDelegationsQuery()
  const resourcesMetricsData = useResourcesMetricsQuery()
  const { data: resourceGroups } = useResourceGroupsQuery()

  const loadingMetrics = some(resourcesMetricsData, 'isLoading')

  const resources = getInventoryResources(resourcesAll)
  const moreDelegations = hasMoreDelegations(resources)

  const loading = !resourcesAll || loadingResources || loadingDelegations || (groupInUrl && !resourceGroups)

  const sorters = [NameAlphabetical, ServiceType, moreDelegations && Delegation, INVENTORY_SERVICES.Lambda.sortBy.invocations, INVENTORY_SERVICES.Lambda.sortBy.errors, INVENTORY_SERVICES.Lambda.sortBy.duration, INVENTORY_SERVICES.Lambda.sortBy.time]
  const [sorter, setSorter] = useState(sorters[0])
  const [searchItems, setSearchItems] = useState([])

  const selectResource = (resource) => {
    history.push({ pathname: routes.inventory.resource.url({ resourceId: resource.id }), search })
  }

  const sortResources = () => {
    const sortedResults = sortBy(searchItems, sorter.apply)
    setSearchItems(sortedResults)
  }

  const searchUrl = () => {
    if (!resources) return

    const groupsResourceIds = flatten(groupInUrl?.map(id => resourceGroups?.find(item => item.id === id)?.resources))

    const results = filterListFromQuery(resources, INVENTORY_FILTERS, search, groupsResourceIds)
    const sorted = sortBy(results, sorter.apply)
    setSearchItems(sorted)
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  useEffect(sortResources, [sorter])
  useEffect(searchUrl, [search, resourcesAll])

  return (
    <List
      loading={loading}
      renderItem={Item}
      selected={resourceId}
      onSelect={selectResource}
      items={searchItems}
      loadingMetrics={loadingMetrics}
      search={<ListSearch />}
      header={
        <ListHeader
          sorters={sorters}
          onSorterChange={onSorterChange}
          selectedSorter={sorter.key}
          total={resources?.length}
          searchTotal={searchItems?.length}
          overviewURL={routes.inventory.url()}
          loadingFilters={loadingResources || loadingDelegations || !resources}
          view='inventory'
          filters={INVENTORY_FILTERS.reduce((acc, item) => {
            acc[item.key] = item.key
            return acc
          }, {})}
        />
      }
      renderEmpty={<ListEmpty title='No resources found' />}
    />
  )
}

export default ResourceList
