import React from 'react'
import { Row } from 'antd'
import { keyBy } from 'lodash'

import regionGroups from 'lib/aws-regions/regions.json'
import AreaColumn from './area-column'

import styles from './styles.less'

const groupRegions = ({ regions = [], availableRegions: available = [] }) => {
  const configuredRegions = [...regions, ...available]

  return Object.entries(regionGroups).reduce((acc, [area, regions]) => {
    acc[area] = Object.entries(regions)
      .filter(([region]) => configuredRegions.includes(region))
      .map(([region, name]) => ({
        region,
        name,
        disabled: available.includes(region)
      }))

    return acc
  }, {})
}

const groupErrors = errors => {
  if (!errors || !errors.length) return {}
  return keyBy(errors, (error) => error.resource)
}

const Regions = ({ integration, handlers }) => {
  const { configuration = {}, errors = [] } = integration
  const areas = groupRegions(configuration)

  return (
    <Row className={styles.regions}>
      {
        Object.entries(areas).map(([area, regions]) => (
          <AreaColumn
            key={area}
            area={area}
            regions={regions}
            errors={groupErrors(errors)}
            enableRegion={handlers.enable}
            disableRegion={handlers.disable}
            removeFromBlacklist={handlers.blacklist.remove}
          />
        ))
      }
    </Row>
  )
}

export default Regions
