import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode.react'
import { Auth } from '@aws-amplify/auth'
import { ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, message, Steps } from 'antd'

import Section from 'components/layout/content/section'
import MFAVerification from 'containers/auth/mfa-verification/form'
import styles from './styles.less'

const MFASetup = () => {
  const { Step } = Steps

  const [step, setStep] = useState(0)
  const [displayMFASecret, setDisplayMFASecret] = useState(false)
  const [secretCode, setSecretCode] = useState('')
  const [secretCodeLoading, setSecretCodeLoading] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [error, setError] = useState('')

  const otpUri = `otpauth://totp/dashbird.io?secret=${secretCode}`

  const loadSecretCode = async () => {
    setSecretCodeLoading(true)

    try {
      // this will refresh token if it's expired, required for TOTP mfa-setup
      await Auth.currentSession()

      const user = await Auth.currentAuthenticatedUser()
      const preferredMFA = await Auth.getPreferredMFA(user, { bypassCache: true })
      if (preferredMFA === 'SOFTWARE_TOKEN_MFA') {
        setStep(3)
      }

      const code = await Auth.setupTOTP(user)

      setSecretCode(code)
      setSecretCodeLoading(false)
    } catch (e) {
      message.error('Could not setup MFA', 5)
      console.log('MFASetup error: ', e)
    }
  }

  const onSubmit = async (values) => {
    setVerifyLoading(true)

    try {
      const { code } = values

      await Auth.currentSession()

      const user = await Auth.currentAuthenticatedUser()
      await Auth.verifyTotpToken(user, code)
      await Auth.setPreferredMFA(user, 'TOTP')

      setStep(3)
      message.success('Successfully set up MFA!', 5)
    } catch (e) {
      message.error('Could not set up MFA', 5)
      if (e.code === 'EnableSoftwareTokenMFAException' || e.code === 'InvalidParameterException') {
        // Cognito error
        setError('Invalid code')
      }
      console.log('MFASetup error: ', e)
    }

    setVerifyLoading(false)
  }

  useEffect(() => {
    if (!secretCode && !secretCodeLoading) {
      loadSecretCode()
    }
  })

  const nextStep = () => {
    setStep(step < 3 ? step + 1 : step)
  }

  const previousStep = () => {
    setStep(step > 0 ? step - 1 : step)
  }

  const showMFASecret = () => {
    setDisplayMFASecret(true)
  }

  return (
    <Section title='Multi-factor authentication settings' className={styles.container} titleUpperCase>
      <Steps current={step} direction='vertical' labelPlacement='horizontal' onChange={setStep} className={styles.steps}>
        <Step title='Install authenticator' description='Install your preferred authenticator on your mobile device or computer.' disabled={step > 2} />
        <Step title='Scan QR code'
          description="Use your virtual MFA app or your device's camera to scan the QR code"
          icon={secretCodeLoading ? <LoadingOutlined /> : undefined}
          disabled={secretCodeLoading || step > 2}
        />
        <Step title='Enter authenticator code'
          description='Enter the six digit code from your authenticator app'
          icon={verifyLoading ? <LoadingOutlined /> : undefined}
          disabled={secretCodeLoading || step > 2}
        />
        <Step title='Complete' description='MFA successfully set up!' disabled={step < 3} />
      </Steps>

      {step === 1 && secretCode && <div className={styles.qr}>
        <QRCode value={otpUri} />

        {displayMFASecret
          ? <pre className={styles.code}>{otpUri}</pre>
          : <p><a onClick={showMFASecret}>Click here to display the MFA secret</a></p>
        }
      </div>}

      {step === 1 &&
        <Button type='primary' htmlType='button' onClick={previousStep} className={styles.back_button}><ArrowLeftOutlined />Back</Button>
      }

      {(step === 0 || step === 1) &&
        <Button type='primary' htmlType='button' onClick={nextStep} disabled={secretCodeLoading}>Next <ArrowRightOutlined /></Button>
      }

      {step === 3 &&
        <Button type='primary' htmlType='button' onClick={() => setStep(0)}>Restart MFA setup <RedoOutlined /></Button>
      }

      {step === 2 && <div className={styles.mfa}>
        <MFAVerification onCancel={previousStep} onSubmit={onSubmit} loading={verifyLoading} disabled={verifyLoading} error={error} />
      </div>}
    </Section>
  )
}

export default MFASetup
