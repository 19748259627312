import React from 'react'
import { Row, Col } from 'antd'

import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import Section from 'components/layout/content/section'
import OverviewGroupItem from 'components/overview-group-item'
import { AwsServiceIcon } from 'components/icons'

import styles from './styles.less'

const ServiceGroups = ({ items, loading }) => {
  const { getValue: services, setValue: setServices } = useQueryParams('service', [])

  const handleServiceFilter = (item) => {
    setServices(filterHelper(item, services))
  }

  return (
    <Section loading={loading} loadingHeight={3}>
      <Row gutter={[8, 8]}>
        {items.map(group =>
          group.count !== 0
            ? (
              <Col className={styles.group_width} key={group.service}>
                <OverviewGroupItem
                  title={group.title?.slice(4)}
                  id={group.service}
                  icon={<AwsServiceIcon service={group.service} />}
                  count={group.count}
                  onSelect={handleServiceFilter}
                  selected={services}
                  large
                />
              </Col>)
            : null
        )}
      </Row>
    </Section>
  )
}

export default ServiceGroups
