import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, omitBy, isNil } from 'lodash'
import { Skeleton, Row, Col, Tag, Table, Input, Form } from 'antd'

import Totals from '../../../next-payments/totals'
import BuyButton from './buttons/buy'
import CancelButton from './buttons/cancel'

import { GET_INVOICE_PREVIEW } from 'actions/billing.actions'
import { API } from 'actions/api'
import { formatStripePrice } from 'lib/billing-helpers'
import prepareData from './prepare-data'
import buildColumns from './columns'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const prepareStateData = subscription => {
  return {
    inventory: get(subscription, 'items.inventory.quantity', 0),
    dataIngestion: get(subscription, 'items.dataIngestion.quantity', 0)
  }
}

const invoicePreviewBody = (subscriptionId, quantities = {}, promotion) => {
  const couponId = get(promotion, 'coupon.stripeId')
  return omitBy({
    subscriptionId,
    discount: couponId,
    ...quantities
  }, isNil)
}

const doAmountsDiffer = (subscription, quantities) => {
  const inventory = get(subscription, 'items.inventory.quantity', 0)
  const dataIngestion = get(subscription, 'items.dataIngestion.quantity', 0)
  return inventory !== quantities.inventory || dataIngestion !== quantities.dataIngestion
}

const discountValidator = (setValidateStatus, setPromotion, fetch) => async (rule, value) => {
  setValidateStatus('validating')
  if (!value || value === '') {
    setPromotion({})
    setValidateStatus('')
    return Promise.resolve()
  }
  const result = await fetch(value)
  const promotion = get(result, 'payload', {})
  if (!promotion.stripeId) {
    setValidateStatus('error')
    throw new Error(rule.message)
  }
  setPromotion(promotion)
  setValidateStatus('success')
  return Promise.resovee()
}

const PreviewTotals = ({ visible, invoice, loading } = {}) => {
  if (!visible) return null
  return (
    <Skeleton loading={loading}>
      <Totals invoice={invoice} showDate={false} />
    </Skeleton>
  )
}

const ReservedSubscriptionForm = React.forwardRef(({ subscription = {}, usage, price, loading, onSubmit, onCancel, cancelSubscriptionItem } = {}, ref) => {
  const { organization } = useOrganization()

  const data = prepareData(subscription, usage, price)

  const [quantities, setQuantities] = useState(prepareStateData(subscription))

  const dispatch = useDispatch()
  const customer = useSelector(state => get(state, 'billing.customers.active'))
  const invoice = useSelector(state => get(state, 'billing.subscriptions.invoiceTotalPreview'))
  const paymentMethods = useSelector(state => get(state, 'billing.payment.methods'))
  const loadingInvoice = useSelector(state => state.waiting.list[GET_INVOICE_PREVIEW])
  const fetchDiscount = (code) => dispatch(API.organizations.id(organization.id).billing.promotions.id(code).get())
  const [promotion, setPromotion] = useState({})

  useEffect(() => {
    const body = invoicePreviewBody(subscription.stripeId, quantities, promotion)
    dispatch(API.organizations.id(organization.id).billing.invoices.preview.get(body))
  }, [quantities.inventory, quantities.dataIngestion, promotion.stripeId, customer.address])

  const columns = buildColumns({ quantities, setQuantities, invoice, cancelSubscriptionItem })

  const total = formatStripePrice(get(invoice, 'total', 0), get(invoice, 'currency'))
  const showTotals = doAmountsDiffer(subscription, quantities)
  const [validateStatus, setValidateStatus] = useState('')
  const hasNoPaymentMethod = paymentMethods.length < 1

  return (
    <>
      <Form ref={ref}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className={styles.topMargin}
          loading={loading || loadingInvoice}
        />
        <Row className={styles.topMargin}>
          <Col span={14}><Tag color='red'>NB! Monthly overage will be billed on-demand.</Tag></Col>
          <Col span={10}>
            <Form.Item name='promotion' validateTrigger='onBlur' hasFeedback validateStatus={validateStatus}
              rules={[{ validator: discountValidator(setValidateStatus, setPromotion, fetchDiscount), message: 'Invalid code' }]}>
              <Input placeholder='Discount code' />
            </Form.Item>
            <PreviewTotals visible={showTotals} invoice={invoice} loading={loadingInvoice} />
            <BuyButton
              title={subscription.stripeId ? 'Modify subscription' : 'Buy'}
              disabled={hasNoPaymentMethod || !showTotals}
              total={total}
              onConfirm={onSubmit}
              loading={loading}
              tooltip={hasNoPaymentMethod ? 'Please add a payment method before purchasing' : null}
              className={styles.topMargin}
            />
            <CancelButton loading={loading} onConfirm={onCancel} className={styles.topMargin} show={subscription.stripeId} />
          </Col>
        </Row>
      </Form>
    </>
  )
})

export default ReservedSubscriptionForm
