// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vd6hw8TQBIH6PrhWz5OK {\n  color: #4a46c6;\n  margin-right: 6px;\n}\n.k0awOlp56Q3NR3poSmEb div {\n  padding: 6px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/inventory/details/resource/services/rds/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,iBAAA;AADF;AAIA;EAEI,cAAA;AAHJ","sourcesContent":["@import '../../../../../../styles/main';\n\n.icon {\n  color: @colors[primary];\n  margin-right: @spacing[smaller];\n}\n\n.region_wrapper {\n  div {\n    padding: @spacing[smaller] 0;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "vd6hw8TQBIH6PrhWz5OK",
	"region_wrapper": "k0awOlp56Q3NR3poSmEb"
};
export default ___CSS_LOADER_EXPORT___;
