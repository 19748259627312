import React, { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { flatten, isEmpty } from 'lodash'
import { Form, Row, Checkbox } from 'antd'

import { ServiceContext } from 'containers/inventory/details/resource'
import { useSearchLogsQuery, useDelegationsQuery } from 'hooks/api'
import { SearchContext } from 'hooks/context/search-context'
import { findInventoryByType, INVENTORY_SERVICES } from 'lib/resources/constants'
import OnDemandResults from './results'
import Section from 'components/layout/content/section'
import QueryEditor from 'containers/search/details/form/query-editor'
import Submit from 'components/antd/form/submit'
import Empty from 'components/layout/content/empty'
import { useAccountRoutes } from 'containers/routes'
import { objectify } from 'lib/utils'

import styles from './styles.less'

const TYPES = [
  { value: 'INDEX_SLOW_LOGS', label: 'Indexing logs' },
  { value: 'SEARCH_SLOW_LOGS', label: 'Search logs' },
  { value: 'ES_APPLICATION_LOGS', label: 'Error logs' },
  { value: 'AUDIT_LOGS', label: 'Audit logs' }
]

const formatPayload = ({ resource, start, end, filters }) => {
  const opensearch = resource?.type === INVENTORY_SERVICES.OpenSearch.type
  const availableLogTypes = JSON.parse(objectify(resource?.attributes)?.logPublishingOptions || '{}')
  const indices = Object.keys(availableLogTypes)?.filter(type => availableLogTypes[type].Enabled)
  const fetchDisabled = !opensearch ? false : isEmpty(indices)
  if (fetchDisabled) return null
  return {
    start,
    end,
    ...filters,
    limit: 30,
    targets: [resource.id],
    service: findInventoryByType(resource?.type)?.service
  }
}

const LogsSearch = ({ resource }) => {
  const routes = useAccountRoutes()
  const { start, end } = useContext(ServiceContext)
  const { editorState, setEditorState, queryRef, validateQuery } = useContext(SearchContext)
  const [form] = Form.useForm()
  const indicesValue = Form.useWatch('indices', form)

  const opensearch = resource?.type === INVENTORY_SERVICES.OpenSearch.type
  const availableLogTypes = JSON.parse(objectify(resource?.attributes)?.logPublishingOptions || '{}')
  const indices = Object.keys(availableLogTypes)?.filter(type => availableLogTypes[type].Enabled)
  const fetchDisabled = !opensearch ? false : isEmpty(indices)

  const [filters, setFilters] = useState({ query: '', ...(!isEmpty(indices) ? { indices } : {}) })
  const { data: delegations } = useDelegationsQuery()
  const payload = useMemo(() => formatPayload({ resource, start, end, filters }), [start, end, resource, filters])
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useSearchLogsQuery(payload)

  const searchResults = useMemo(() => flatten(data?.pages?.map(page => page.data)), [data])

  const handleSearch = () => {
    const validation = validateQuery(queryRef?.current)
    if (!validation.isValid) return

    setFilters({ query: queryRef?.current, ...(opensearch && { indices: indicesValue }) })
  }

  if (delegations?.find(item => item.id === resource?.delegation)?.version !== '2022-09-19') {
    return (
      <Empty title='You stack is outdated'>To use this feature update your stack under <Link to={routes.settings.integrations.url()}>Integration settings</Link></Empty>
    )
  }

  if (fetchDisabled) return (<Empty title='Opensearch logs are disabled'>See the open <Link to={routes.insights.rule.url({ ruleId: 'aws_opensearch_error-logging-not-enabled' })}>insight</Link></Empty>)

  return (
    <div>
      <Form form={form} className={styles.form} onFinish={handleSearch} layout='vertical' initialValues={{ indices }}>
        {opensearch && !isEmpty(indices) && (
          <Form.Item name='indices' wrapperCol={{ span: 24 }} className={styles.indices}>
            <Checkbox.Group options={TYPES.filter(item => indices.includes(item.value))} className={styles.checkboxes} />
          </Form.Item>)}
        <Row>
          <Form.Item name='query' className={styles.editor_wrapper}>
            <QueryEditor editorState={editorState} setEditorState={setEditorState} handleSubmit={handleSearch} />
          </Form.Item>
          <Submit className={styles.submit} text='Search' loading={isLoading} />
        </Row>
      </Form>
      <Section loading={isLoading} className={styles.results}>
        <OnDemandResults
          logs={searchResults}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </Section>
    </div >
  )
}

export default LogsSearch
