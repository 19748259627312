import React, { useEffect } from 'react'
import moment from 'moment'
import { isEmpty, pickBy } from 'lodash'
import numeral from 'numeral'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tag, Skeleton, Progress, Tooltip } from 'antd'

import { API } from 'actions/api'
import { GET_BILLING, GET_SUBSCRIPTION_V2, PUT_SUBSCRIPTION_V2 } from 'actions/billing-v2'
import { Typography } from 'components/typography'
import { TIERS } from 'lib/organization-constants'
import { formatStripePrice, getSubscriptionItemPrice } from 'lib/billing-helpers'
import { calculateInvocationSum, calculateAvgUsageSince } from 'lib/usage-helpers'
import { findOrganizationAccountIds } from 'containers/settings/usage/content'
import { useOrganizationsQuery, useOrgIngestionQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const tierColor = organization => {
  if (!organization?.tier) return ''
  if (organization.tier === TIERS.trial) return 'blue'
  if ([TIERS.free, TIERS.pro, TIERS.enterprise].includes(organization.tier)) return 'green'
  return 'magenta'
}

const calculateUsagePercentage = (totalInvocations, currentInvocations) => {
  const total = totalInvocations ? parseFloat(totalInvocations) : 0
  const current = currentInvocations ? parseFloat(currentInvocations) : 0
  if (current >= total) return 100
  const percentage = (current * 100) / total
  return parseFloat(numeral(percentage).format('0.00'))
}

const CurrentSubscription = ({ setModalVisible }) => {
  const dispatch = useDispatch()
  const { organization, account } = useOrganization()
  const { data: organizations } = useOrganizationsQuery()
  const billing = useSelector(state => state.billingV2.billing)
  const subscription = useSelector(state => state.billingV2.subscription)
  const upcomingInvoice = useSelector(state => state.billingV2.invoiceUpcoming)
  const { data: ingestionData } = useOrgIngestionQuery()

  const loading = useSelector(state => state.waiting.list[GET_BILLING])
  const loadingSubscription = useSelector(state => state.waiting.list[GET_SUBSCRIPTION_V2])
  const subscriptionUpdating = useSelector(state => state.waiting.list[PUT_SUBSCRIPTION_V2])

  const availableAccounts = findOrganizationAccountIds(organizations, organization)
  const availableIds = availableAccounts.map(item => item.id)
  const usageData = pickBy(ingestionData, (data, id) => availableIds.includes(id))

  const invocationsSum = calculateInvocationSum(usageData, 'invocations')
  const usageSinceCycleStart = calculateAvgUsageSince(usageData, 'invocations', moment.unix(subscription?.currentPeriod?.start))

  useEffect(() => {
    if (!organization.id) return

    dispatch(API.organizations.id(organization.id).billingV2.billing.get())
  }, [organization.id, account.id])

  useEffect(() => {
    if (!organization || isEmpty(billing) || subscriptionUpdating) return

    dispatch(API.organizations.id(organization.id).billingV2.subscription.id(billing?.subscriptions[0]?.id).get())
    dispatch(API.organizations.id(organization.id).billingV2.invoices.upcoming.get(billing?.subscriptions[0]?.id))
  }, [billing, subscriptionUpdating])

  useEffect(() => {
    if (!billing?.customer?.id) return

    dispatch(API.organizations.id(organization.id).billingV2.customer.id(billing?.customer?.id).get())
  }, [billing?.customer?.id])

  const subscriptionItem = subscription?.items?.find(sub => sub?.price?.metadata?.type === 'invocations')
  const planInvocations = subscriptionItem?.price?.metadata?.invocations
  const priceItemAfterTrial = upcomingInvoice?.lines?.find(line => line?.price?.metadata?.type === 'invocations')
  const tierItemAfterTrial = upcomingInvoice?.lines?.find(line => line?.price?.metadata?.type === 'tier')

  const usagePercentage = calculateUsagePercentage(planInvocations, usageSinceCycleStart)
  const usageProgressColor = usagePercentage >= 90 ? '#f91111' : usagePercentage >= 75 ? '#f58d06' : '#3633ab'
  const inTrial = organization.tier === TIERS.trial
  const hasSchedule = !!subscription?.schedule

  return (
    <Skeleton loading={loading || loadingSubscription || isEmpty(subscription)}>
      <Typography.Title level={2}>Current subscription</Typography.Title>
      <section className={styles.current_container}>
        <div className={styles.columns}>
          <div className={styles.width}>
            {inTrial && !hasSchedule && <p className={styles.alert}>Your trial ends in {moment(moment.unix(subscription?.currentPeriod?.end)).diff(moment(), 'days')} days. To continue using Dashbird please upgrade your account</p>}
            {inTrial && hasSchedule &&
              <p className={styles.info}>
                After trial ends your account is upgraded to {tierItemAfterTrial?.price?.metadata.tier} tier with {numeral(priceItemAfterTrial?.price?.metadata?.invocations).format('0a').toUpperCase()} invocations per month.
              </p>}
            <div className={styles.content}>
              <div>
                <p className={styles.label}>Tier</p>
                <p className={styles.text}><Tag color={tierColor(organization)}>{organization.tier.toUpperCase()}</Tag></p>
                <p className={styles.label}>Current plan</p>
                <p className={styles.text}>{numeral(planInvocations).format(planInvocations > 999999 ? '0.0a' : '0 a')}</p>
                <p className={styles.label}>Usage in last billing cycle</p>
                <p className={styles.text}>{numeral(invocationsSum).format(invocationsSum > 999999 ? '0.0a' : '0 a')} invocations </p>
                <p className={styles.label}>Usage in current billing cycle</p>
                <div className={styles.progress_bar}>
                  <Tooltip title={`${numeral(usageSinceCycleStart).format('0a')} invocations since ${moment.unix(subscription?.currentPeriod?.start).format('MMMM Do YYYY')}`}>
                    <Progress
                      percent={usagePercentage}
                      strokeColor={usageProgressColor}
                      strokeWidth={15}
                      strokeLinecap='square'
                      status='normal'
                    />
                  </Tooltip>
                </div>
              </div>
              <div>
                <p className={styles.label}>Billing interval</p>
                <p className={styles.text}>{subscriptionItem?.price?.interval}ly</p>
                <p className={styles.label}>Price</p>
                <p className={styles.text}>{getSubscriptionItemPrice(subscriptionItem)} per {subscriptionItem?.price?.interval} {upcomingInvoice?.discount && `(${upcomingInvoice.discount?.coupon?.name})`}</p>
                <p className={styles.label}>{inTrial ? 'Trial ends' : 'Next invoice'}</p>
                <p className={styles.text}>{!inTrial && `${formatStripePrice(upcomingInvoice?.total, upcomingInvoice?.currency)} on`} {moment.unix(subscription?.currentPeriod?.end).format('MMMM Do YYYY')}</p>
                {inTrial && hasSchedule && (
                  <>
                    <p className={styles.label}>{'Next invoice'}</p>
                    <p className={styles.text}>{`${formatStripePrice(upcomingInvoice?.total, upcomingInvoice?.currency)} on`} {moment.unix(subscription?.currentPeriod?.end).format('MMMM Do YYYY')}</p>
                  </>
                )}
              </div>
            </div>
          </div>

          <Button type='primary' onClick={() => setModalVisible(true)}>Change plan</Button>
        </div>
      </section>
    </Skeleton>
  )
}

export default CurrentSubscription
