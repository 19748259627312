import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

import { Switch, Row, Col, Select } from 'antd'
import { AwsServiceIcon } from 'components/icons'
import { Typography } from 'components/typography'
import { Descriptions } from 'components/descriptions'
import Content from 'components/layout/content'
import Regions from './regions'
import TagsForm from './tags-form'
import NamesForm from './names-form'
import ResourceList from './resource-list'

import { POLLING_INTERVALS } from './intervals'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'

import { API } from 'actions/api'
import mutations from './mutations'
import { GET_INTEGRATION } from 'actions/integrations.actions'
import { useAccountRoutes } from 'containers/routes'
import { useDelegationsQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

const Filters = ({ integration, showNames, handlers }) => {
  const tags = {
    whitelist: get(integration.configuration, 'tags.whitelist', []),
    blacklist: get(integration.configuration, 'tags.blacklist', [])
  }
  if (!showNames) return <TagsForm {...tags} handlers={handlers.tags} />

  const names = {
    whitelist: get(integration.configuration, 'names.whitelist', []),
    blacklist: get(integration.configuration, 'names.blacklist', [])
  }

  return (
    <Row gutter={32}>
      <Col span={12}><TagsForm {...tags} handlers={handlers.tags} /></Col>
      <Col span={12}><NamesForm {...names} handlers={handlers.names} /></Col>
    </Row>
  )
}

const Integration = () => {
  const dispatch = useDispatch()
  const { delegationId, integrationId } = useParams()
  const routes = useAccountRoutes()

  const { account } = useOrganization()
  const integration = useSelector(state => state.integrations.active)
  const { data: delegations, isLoading: loadingDelegations } = useDelegationsQuery()

  const fetching = {
    integration: useSelector(state => state.waiting.list[GET_INTEGRATION])
  }
  const loading = fetching.integration || loadingDelegations || !integration

  useEffect(() => {
    if (!account.id || !delegationId || !integrationId) return

    dispatch(API.accounts.id(account.id).delegations.id(delegationId).integrations.id(integrationId).get())
  }, [account?.id, delegationId, integrationId])

  const handlers = integration && mutations({ dispatch, account, integration })

  const activeDelegation = delegations?.find(item => item.id === integration?.delegation?.id)
  const delegationName = activeDelegation?.name ? activeDelegation?.name : activeDelegation?.provider?.account

  return (
    <Content
      item
      loading={loading}
      title={`${INVENTORY_SERVICE_GROUPS[integration?.service]?.title} | ${delegationName}`}
      breadcrumbs={['settings', 'integrations']}
      backRoute={routes.settings.integrations.url()}
      icon={<AwsServiceIcon service={integration?.service} solid />}
      actions={
        integration && (
          <Switch
            onChange={(value) => handlers.update({ enabled: value })}
            defaultChecked={integration?.id && integration?.enabled}
            checked={integration?.enabled}
          />)
      }
    >
      {integration && (
        <>
          <Descriptions>
            <Descriptions.Item label='Polling interval'>
              <Select
                options={POLLING_INTERVALS.map(item => ({ value: item.value, label: item.title }))}
                onChange={(value) => handlers.update({ interval: value })}
                value={integration.interval || 30}
                disabled={!integration.id}
              />
            </Descriptions.Item>
            <Descriptions.Item label='Insights'>
              <Switch
                onChange={(value) => handlers.update({ insights: value })}
                defaultChecked={integration.insights}
                checked={integration.insights}
                disabled={!integration.enabled}
              />
            </Descriptions.Item>
          </Descriptions>
          <Typography.Title level={2}>Regions</Typography.Title>
          <Regions integration={integration} handlers={handlers.regions} />
          <Typography.Title level={2}>Filter resources</Typography.Title>
          <Typography.Paragraph>When whitelisting then only those will be included and blacklist is ignored totally.</Typography.Paragraph>
          <Typography.Paragraph>When blacklisting then those resources will be excluded and nothing should be in the whitelist.</Typography.Paragraph>
          <Filters integration={integration} showNames={integration.service === 'lambda'} handlers={handlers.filters} />
          <Typography.Title level={2}>Preview</Typography.Title>
          <Typography.Paragraph>Filter changes take effect during the next polling cycle.</Typography.Paragraph>
          <ResourceList integration={integration} />
        </>
      )}
    </Content>
  )
}

export default Integration
