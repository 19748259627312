import React from 'react'
import { Card, Row, Col, Typography } from 'antd'

import { STATUSES } from 'lib/organization-constants'
import { TIER_NAMES } from 'lib/billing-constants'
import Button from './button'
import CustomTag from './tag'

import classnames from 'classnames'
import styles from './styles.less'

const { Text } = Typography

const tierName = (tier) => (TIER_NAMES[tier] || '').toUpperCase()

const tagColor = (status) => {
  switch (status) {
    case STATUSES.normal:
      return 'green'
    case STATUSES.trial:
      return 'blue'
    case STATUSES.suspended:
      return 'red'
    default:
      return ''
  }
}

const UpgradeText = ({ visible } = {}) => {
  if (!visible) return null
  return (
    <Row type='flex' justify='center' gutter={[0, 48]}>
      <Col><Text className={styles.redText}>You have not upgraded your account!</Text></Col>
    </Row>
  )
}

const Status = ({ loading, tier, status, shouldUpgrade, onClick, className } = {}) => {
  const ucStatus = (status || '').toUpperCase()
  return (
    <Card className={classnames(styles.fullHeight, className)} loading={loading}>
      <Row gutter={[0, 48]}>
        <Col offset={5} span={7}><Text strong>Tier</Text></Col>
        <Col span={12}><CustomTag value={tierName(tier)} /></Col>
        <Col offset={5} span={7}><Text strong>Status</Text></Col>
        <Col span={12}>
          <CustomTag value={ucStatus} color={tagColor(status)} />
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button isTrial={status === STATUSES.trial} isTrialEnded={tier === STATUSES.suspended} onClick={onClick} className={styles.button} />
        </Col>
      </Row>
      <UpgradeText visible={shouldUpgrade} />
    </Card>
  )
}

export default Status
