import React from 'react'
import { Button } from 'antd'
import AuthTitle from 'containers/auth/title'
import InvitationsTable from 'containers/settings/user/invitations/table'
import LoadingDots from 'components/loading-dots'

import styles from './styles.less'

const Invitation = ({ goBack, refetchingOrgs }) => {
  if (refetchingOrgs) return <LoadingDots />
  return (
    <>
      <AuthTitle title='Pending invitation' subtitle='You have some pending invitations from other organizations' />
      <InvitationsTable />
      <Button type='link' className={styles.action_btn} onClick={() => goBack()}>I want to onboard a new organization</Button>
    </>
  )
}

export default Invitation
