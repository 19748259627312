import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { insightsPaths as paths, INSIGHTS_LIST, INSIGHT_ACTIVE } from './index'
import { parseInsightRule } from 'containers/insights'

const updateInsight = async (account, identity, payload) => {
  const data = await apiFetch(paths.rule(account, identity), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateInsightRule = () => {
  const { ruleId } = useParams()
  const rule = parseInsightRule(ruleId)
  const queryClient = useQueryClient()

  const { account } = useOrganization()

  return useMutation(
    (status) => updateInsight(account.id, rule, status),
    {
      onMutate: ({ status }) => {
        const openInsight = queryClient.getQueryData([...INSIGHT_ACTIVE, rule])

        queryClient.setQueryData([...INSIGHT_ACTIVE, rule], { ...openInsight, status })
        return { openInsight }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...INSIGHT_ACTIVE, rule], context.openInsight)
        }
      },
      onSuccess: (updatedRule) => {
        queryClient.invalidateQueries([...INSIGHTS_LIST, account.id])
        queryClient.setQueryData([...INSIGHT_ACTIVE, rule], updatedRule)
      }
    }
  )
}
