import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Skeleton, Table } from 'antd'
import { Typography } from 'components/typography'

import { useAccountRoutes } from 'containers/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUp } from '@fortawesome/pro-light-svg-icons/faCircleUp'
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck'

import { isDelegationOutOfDate } from 'lib/delegation-helpers'

import styles from './styles.less'

const columns = [{
  title: 'AWS account',
  key: 'name',
  sorter: (a, b) => a.name.localeCompare(b.name),
  sortDirections: ['descend', 'ascend'],
  render: (record) => <span className={styles.text}>{record.name || 'Connect'}</span>
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status'
}, {
  title: 'ExternalID',
  dataIndex: 'externalId',
  key: 'externalId'
}, {
  title: 'Role',
  dataIndex: 'role',
  key: 'role',
  ellipsis: true
}, {
  key: 'outOfDate',
  render: delegation => {
    if (!delegation.role) return null
    if (!delegation.outOfDate) return <div><FontAwesomeIcon icon={faCircleCheck} /><span> Up to Date</span></div>
    return <div className={styles.outOfDate}><FontAwesomeIcon icon={faCircleUp} /><span> Out of Date</span></div>
  }
}]

const getStatus = (delegation) => {
  if (!delegation.enabled) return 'disabled'
  if (!delegation.roleArn) return 'setup'
  return 'active'
}

const transform = (delegations) => {
  if (!delegations) return []
  return delegations.map(delegation => {
    const name = delegation.name ? `${delegation.name} (${delegation.provider.account})` : delegation.provider.account
    return {
      id: delegation.id,
      name,
      status: getStatus(delegation),
      role: delegation.roleArn,
      externalId: delegation.provider.secret,
      outOfDate: isDelegationOutOfDate(delegation)
    }
  })
}

const Delegations = ({ loading, delegations }) => {
  const history = useHistory()
  const routes = useAccountRoutes()

  if (loading) return <Skeleton />
  const data = transform(delegations)

  const connect = async () => {
    // if (delegation) return history.push(routes.settings.integrations.delegation.delegation.url({ delegationId: delegation.id }))
    history.push(routes.settings.integrations.new.url())
  }

  const handleDelegationClick = (e, record) => {
    e.preventDefault()

    history.push(routes.settings.integrations.delegation.url({ delegationId: record.id }))
  }

  return (
    <>
      <Typography.Title level={1}>Manage AWS connections</Typography.Title>
      <Typography.Paragraph>Add, edit and remove AWS connections</Typography.Paragraph>
      <div className={styles.actions}>
        <Button type='primary' onClick={connect}>Connect AWS account</Button>
      </div>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data}
        onRow={(record) => ({ onClick: event => handleDelegationClick(event, record) })}
        pagination={false}
        className={styles.table}
        rowClassName={record => record.status === 'disabled' ? styles.disabled : styles.enabled}
      />
    </>
  )
}

export default Delegations
