import React from 'react'

import Lottie from 'lottie-react'
import successAnimation from 'containers/onboarding/steps/animations/success-animation.json'
import loadingAnimation from 'containers/onboarding/steps/animations/loading-animation.json'

import styles from './styles.less'

const ProgressIndicator = ({ completed, text } = {}) => {
  let animationData = completed ? successAnimation : loadingAnimation

  return (
    <div className={styles.progressItem}>
      <div className={completed ? styles.success : null}>
        <Lottie
          style={{ flexShrink: 0, margin: '0 9px 0 0', lineHeight: 0.9, width: 30, height: 30 }}
          animationData={animationData}
          loop={!completed}
        />
      </div>
      <div>{text}</div>
    </div>
  )
}

export default ProgressIndicator
