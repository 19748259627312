import React from 'react'

import { Row, Col } from 'antd'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { RDS_CONNECTIONS } from '../../../metrics-tabs/services/rds-instance'
import InventoryTags from 'components/inventory/tags'
import InventoryResourceContainer from '../../../container'
import Events from 'components/inventory/events'
import RDSProxyConfig from './config'
import Tabs from 'components/antd/tabs'
import Section from 'components/layout/content/section'

const Configuration = ({ item }) => {
  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <RDSProxyConfig attributes={item.attributes} inverse />
  </>)
}

const RDSProxy = ({ item }) => {
  return (
    <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.RDSProxy.id} initialChart={RDS_CONNECTIONS}>
      <Row gutter={12}>
        <Col span={16}>
          <Section>
            <Tabs defaultActiveKey="configuration" items={[{ key: 'configuration', label: 'Configuration', children: <Configuration item={item} /> }]} />
          </Section>
        </Col>
        <Col span={8}>
          <Events resourceId={item.id} />
        </Col>
      </Row>
    </InventoryResourceContainer>
  )
}

export default RDSProxy
