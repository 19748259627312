import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ORGANIZATIONS_LIST } from './index'
import { useUser } from 'hooks/context/user-context'

const postOrganization = async (payload) => {
  const data = await apiFetch(paths.organizations(), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useCreateOrganizationsQuery = () => {
  const queryClient = useQueryClient()
  const { refreshCurrentSession } = useUser()

  return useMutation(
    (payload) => postOrganization(payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...ORGANIZATIONS_LIST])
        refreshCurrentSession()
      }
    }
  )
}
