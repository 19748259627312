import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getTime, sub } from 'date-fns'

import { Dropdown, Popconfirm, Row, Col } from 'antd'
import Content from 'components/layout/content'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import GeneralStatistics from './charts'
import EventsList from './list'

import { useAccountRoutes } from 'containers/routes'
import { useAlarmsQuery, useInsightsQuery, useErrorsQuery, useDeleteResourceGroupQuery } from 'hooks/api'

import styles from './styles.less'

const gutter = { xs: 8, sm: 8, md: 10, lg: 12 }

const mutations = ({ history, deleteGroup, group, routes }) => {
  return {
    remove: () => {
      deleteGroup(group.id)
      history.push(routes.resourceGroups.url())
    }
  }
}

const ResourceGroupOverview = ({ group, resources, loadingGroup }) => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const start = getTime(sub(new Date(), { hours: 24 }))
  const end = getTime(new Date())
  const range = 24 * 60

  const { data: alarms, isLoading: loadingAlarms } = useAlarmsQuery()
  const { data: insights, isLoading: loadingInsights } = useInsightsQuery()
  const { data: errors, isLoading: loadingErrors } = useErrorsQuery()
  const { mutate: deleteGroup } = useDeleteResourceGroupQuery()

  const loading = loadingAlarms || loadingInsights || loadingErrors

  useEffect(() => { document.title = 'Dashbird.io - Resource Groups' }, [])

  const groupErrors = errors?.filter(event => group?.resources?.includes(event?.resource?.id || event.resource))
  const groupInsights = insights?.filter(event => group?.resources?.includes(event?.resource?.id || event.resource))
  const groupAlarms = alarms?.filter(event => group?.resources?.includes(event?.resource?.id || event.resource))

  const handlers = mutations({ deleteGroup, history, group, routes })

  return (
    <Content
      title={group?.title}
      item={group}
      breadcrumbs={['Resource groups']}
      className={styles.dashboard}
      loading={loadingGroup || loading}
      actions={
        group && <Dropdown
          trigger={['click']}
          menu={{
            items: [
              { key: 'edit', label: <Link to={routes.resourceGroups.edit.url({ groupId: group?.id })}>Edit</Link> },
              {
                key: 'delete',
                label: (
                  <Popconfirm
                    title={`Are you sure you want to delete ${group.title}?`}
                    onConfirm={handlers.remove}
                  >
                    <span>Delete</span>
                  </Popconfirm>
                )
              }
            ]
          }}
        >
          <ActionsDropdownButton />
        </Dropdown>
      }
    >
      <Row gutter={[gutter, gutter]}>
        <Col xs={24}>
          <GeneralStatistics start={start} end={end} span={range} resources={group?.resources} gutter={gutter} />
        </Col>
        <Col xs={24} md={12}>
          <EventsList items={groupErrors} resources={resources} types={['error']} limit={10} />
        </Col>
        <Col xs={24} md={12}>
          <EventsList items={groupInsights?.concat(groupAlarms)} resources={resources} types={['alarm', 'insight']} limit={10} />
        </Col>
      </Row>

    </Content>
  )
}

export default ResourceGroupOverview
