import React from 'react'
import { Radio as AntdRadio } from 'antd'

import styles from './styles.less'

const RadioSmall = ({ items, ...props }) => {
  return (
    <div className={styles.radio_wrapper}>
      <AntdRadio.Group {...props} className={styles.radio}>
        {items.map(item => (
          <AntdRadio.Button key={item.value} {...item}>
            {item.label}
          </AntdRadio.Button>
        ))}
      </AntdRadio.Group>
    </div>
  )
}

export default RadioSmall
