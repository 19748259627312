import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, message } from 'antd'

import { API } from 'actions/api'
import validateBillingInfo from 'containers/settings/billing/payment/billing-info/modal/validate'
import Content from 'components/layout/content'
import BillingForm from './billing-form'
import { useAccountRoutes } from 'containers/routes'
import { GET_CUSTOMER_V2, PUT_CUSTOMER_V2 } from 'actions/billing-v2'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const EditBilling = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { customerId } = useParams()
  const routes = useAccountRoutes()

  const { organization } = useOrganization()
  const customer = useSelector(state => state.billingV2.customer)
  const saving = useSelector(state => state.waiting.list[PUT_CUSTOMER_V2])
  const loading = useSelector(state => state.waiting.list[GET_CUSTOMER_V2])
  const [error, setError] = useState(null)

  const formRef = useRef()

  const handleUpdateBilling = async () => {
    const billingInfo = await validateBillingInfo(formRef?.current)

    if (!billingInfo) {
      return null
    }

    setError(null)

    try {
      await dispatch(API.organizations.id(organization.id).billingV2.customer.id(customerId).put(billingInfo))
      message.success('Billing info updated successfully.')
      return history.push(routes.settings.payment.url())
    } catch (error) {
      setError(error.message)
    }
  }

  useEffect(() => {
    if (customer?.id === customerId) return

    dispatch(API.organizations.id(organization.id).billingV2.customer.id(customerId).get())
  }, [])

  const validateVAT = API.organizations.id(organization.id).billingV2.validation.VAT

  return (
    <Content item title='Edit billing' loading={loading} backRoute={routes.settings.payment.url()} breadcrumbs={['settings', 'payment-methods', 'edit']}>
      <BillingForm customer={customer} ref={formRef} validateVat={validateVAT} />
      <Button className={styles.save_btn} type='primary' loading={saving} onClick={() => handleUpdateBilling()}>Save</Button>
      <Button type='link' onClick={history.goBack}>Cancel</Button>
    </Content>
  )
}

export default EditBilling
