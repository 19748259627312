// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HH2e6E4x6RMs5sNLywzz {\n  padding: 9px 18px;\n  display: flex;\n  flex-direction: column;\n}\n.HH2e6E4x6RMs5sNLywzz a {\n  margin-bottom: 6px;\n  display: flex;\n  align-items: center;\n}\n.HH2e6E4x6RMs5sNLywzz a path {\n  stroke: #8b91e3;\n}\n.HH2e6E4x6RMs5sNLywzz a.IbdqyLG5msWbEU91ftb6 path {\n  stroke: #7ed321;\n}\n.HH2e6E4x6RMs5sNLywzz a.esXoApiWQT9l4TYKovvm path {\n  stroke: #4a46c6;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/dashboard/onboarding-checklist/styles.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAFA;EAMI,kBAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAPA;EAWM,eAAA;AADN;AAII;EACE,eAAA;AAFN;AAKI;EACE,eAAA;AAHN","sourcesContent":["@import '../../../styles/main';\n\n.container {\n  padding: @spacing[small] @spacing[large];\n  display: flex;\n  flex-direction: column;\n\n  a {\n    margin-bottom: @spacing[smaller];\n    display: flex;\n    align-items: center;\n\n    path {\n      stroke: @colors[primary-light]\n    }\n\n    &.success path {\n      stroke: @colors[success];\n    }\n\n    &.error path {\n      stroke: @colors[primary]\n    }\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HH2e6E4x6RMs5sNLywzz",
	"success": "IbdqyLG5msWbEU91ftb6",
	"error": "esXoApiWQT9l4TYKovvm"
};
export default ___CSS_LOADER_EXPORT___;
