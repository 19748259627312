import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'

import List from './list'
import Details from './details'
import Form from 'features/resource-groups-list/edit'
import { accountRedirects } from '../../routes'
import { useOrganization } from 'hooks/context/organization-context'

const ResourceGroups = () => {
  const { account: currentAccount } = useOrganization()

  useEffect(() => { document.title = 'Dashbird.io - Resource Groups' }, [])

  const routes = [
    { path: '/resource-groups/new', component: Form },
    { path: '/resource-groups/:groupId/edit', component: Form },
    { path: '/resource-groups/:groupId', component: Details },
    { path: '/resource-groups', component: List }
  ]

  return (
    <Switch>
      {accountRedirects(currentAccount, routes)}
    </Switch>
  )
}

export default ResourceGroups
