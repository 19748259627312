import React from 'react'

import Content from 'components/layout/content'
import InvitationsTable from './table'

import styles from './styles.less'

const Invitations = () => {
  return <Content item={{}} title='Invitations' breadcrumbs={['Settings', 'Invitations']}>
    <p className={styles.subtitle}>View and accept your invitations to organizations</p>
    <InvitationsTable />
  </Content>
}

export default Invitations
