import React, { useRef, useState } from 'react'
import { times } from 'lodash'
import classnames from 'classnames'

import { Skeleton } from 'antd'

import { Typography } from 'components/typography'

import styles from './styles.less'

const renderTitle = (title, className, titleUpperCase) => {
  if (!title) return null
  return <Typography.Title level={2} className={classnames(className)} upperCase={titleUpperCase}>{title}</Typography.Title>
}

const renderActions = (actions, actionsClassName) => {
  if (!actions) return null
  return <div className={classnames(styles.actions, actionsClassName)}>{actions}</div>
}

const Loading = ({ height = 4 }) => {
  return (
    times(height).map(index => (
      <Skeleton key={index} active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />
    )))
}

const ContentSection = ({
  loading,
  title,
  actions,
  children,
  solid,
  className,
  titleClassName,
  cancelMargin,
  disableUserSelection,
  contentWrapperClassName,
  titleUpperCase,
  loadingHeight,
  actionsClassName,
  sticky
}) => {
  const headerRef = useRef()
  const [showSticky, setShowSticky] = useState(false)

  // const options = {
  //   rootMargin: '-105px 0px 0px 0px',
  //   threshold: [0.98, 0.99, 1]
  // }

  // useEffect(() => {
  //   if (!sticky || !headerRef?.current) return
  //   console.log(headerRef?.current?.offsetTop)

  //   const handleSticky = (entries) => {
  //     const [entry] = entries
  //     console.log('show sticky', !entry?.isIntersecting)
  //     setShowSticky(!entry?.isIntersecting)
  //   }

  //   const observer = new IntersectionObserver(handleSticky, options)
  //   observer.observe(headerRef?.current)

  //   return () => headerRef?.current && observer.unobserve(headerRef?.current, options)
  // }, [headerRef?.current?.offsetTop])

  return (
    <section className={classnames(
      styles.section,
      className,
      {
        [styles.cancel_margin]: cancelMargin,
        [styles.disable_userSelection]: disableUserSelection
      })}>
      <header className={classnames(styles.header, { [styles.sticky]: showSticky })} ref={headerRef}>
        {renderTitle(title, titleClassName, titleUpperCase)}
        {renderActions(actions, actionsClassName)}
      </header>
      <div className={classnames(contentWrapperClassName || '', solid ? styles.solid : '')}>
        {loading ? <Loading height={loadingHeight} /> : children}
      </div>
    </section >
  )
}

export default ContentSection
