import React, { useContext } from 'react'
import classnames from 'classnames'
import Item from './item'
import { ScrollContext } from 'components/layout/content'
import styles from './styles.less'

const Metrics = ({ items, tabNarrow, drawer = false, fixed }) => {
  const { width } = useContext(ScrollContext)
  if (!items) return null

  return (
    <div className={classnames(
      styles.metrics_wrapper,
      {
        [styles.fixed]: fixed || drawer,
        [styles.drawer]: drawer
      })}
      style={fixed || drawer ? { width } : {}}>
      <div className={classnames(styles.metrics, { [styles.drawer]: drawer })}>
        {items.map((item, index) => item ? <Item key={index} {...item} fixed={fixed || drawer} drawer={drawer} tabNarrow={tabNarrow} /> : null)}
      </div>
    </div>
  )
}

export default Metrics
