import React from 'react'

import { AlarmIcon, AwsServiceIcon } from 'components/icons'
import Select from 'components/antd/select'
import Actions from './actions'
import { filterInventoryByNamespace } from 'lib/resources/constants'

import styles from './styles.less'

const AlarmForm = ({ mutations, condition, alarms, disableEditing }) => {
  const handlers = mutations({ condition })

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <AlarmIcon /><span>Alarms</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>alarms</span>
          <span className={styles.value}>{condition.targets.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} disableEditing={disableEditing} />
        </div>
      </div>
      <section>
        <span className={styles.label}>Filter by alarms</span>
        <Select
          mode='multiple'
          optionFilterProp='title'
          defaultValue={condition.targets}
          placeholder='Select alarms'
          solid
          onSelect={(value) => handlers.targets(value).add()}
          onDeselect={(value) => handlers.targets(value).remove()}
          options={alarms?.map(item => ({ id: `alarm:${item.id}`, value: `alarm:${item.id}`, title: item.name, disabled: disableEditing, service: filterInventoryByNamespace(item?.namespace)[0]?.service }))}
          optionRender={({ item }) => (
            <span className={styles.option}>
              <AwsServiceIcon service={item.service} size='small' className={styles.icon} />
              <span className={styles.title}>{item.title}</span>
            </span>
          )}
        />
      </section>
    </div>
  )
}

export default AlarmForm
