export const ALARM_METRICS_TO_CONVENTIONAL_METRICS = {
  count_coldstart: 'coldstarts',
  count_retry: 'retries',
  cost: 'gb_second'
}

export const CONVENTIONAL_METRICS_TO_ALARM_METRICS = {
  coldstarts: 'count_coldstart',
  retries: 'count_retry',
  gb_second: 'cost'
}

export const PRETTY_STATS = {
  avg: 'Average',
  min: 'Minimum',
  max: 'Maximum',
  sum: 'Sum'
}

export const WIDGET_KIND = {
  // Placeholder widget is added right after new widget is created,
  // and removed when API returns successful response.
  PLACEHOLDER: 'placeholder',

  // Metrics widget displays resource charts
  METRICS: 'metrics',

  // Invocations widget displays Lambda invocations
  INVOCATIONS: 'invocations',

  // Events widget displays Errors, Insights or Alarms
  EVENTS: 'events',

  // Text widget displays titles or other text messages
  TEXT: 'text'
}

export const WIDGET_SIZE_LIMITS = {
  [WIDGET_KIND.METRICS]: {
    upper: { w: 16, h: 8 },
    lower: { w: 2, h: 1 }
  },

  [WIDGET_KIND.INVOCATIONS]: {
    upper: { w: 16, h: 8 },
    lower: { w: 4, h: 2 }
  },

  [WIDGET_KIND.EVENTS]: {
    upper: { w: 16, h: 8 },
    lower: { w: 4, h: 2 }
  },

  [WIDGET_KIND.TEXT]: {
    upper: { w: 16, h: 8 },
    lower: { w: 1, h: 1 }
  }
}
