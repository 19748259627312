import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ChevronRightIcon, AccountIcon } from 'components/icons'
import classnames from 'classnames'
import { Popover, Typography } from 'antd'

import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery } from 'hooks/api'

import styles from './styles.less'

const { Text } = Typography

const List = () => {
  const location = useLocation()
  const history = useHistory()

  const { data: organizations } = useOrganizationsQuery()
  const { account: currentAccount, setOrgAndAccount } = useOrganization()

  const setAccount = (organization, account) => {
    if (currentAccount?.id === account.id) return

    const [url, accountId, page] = location.pathname?.split('/') || []
    const replaceWith = `${url}/${account.id}/${page || ''}`
    history.replace(replaceWith)

    setOrgAndAccount(organization, account)
  }

  return (
    <div className={styles.list}>
      <ul>
        {organizations && organizations.map(item => (
          <li key={item.organization.id}>
            <span className={styles.organization}>{item.organization.name}</span>
            <ul>
              {item.accounts && item.accounts.map(account => (
                <li key={account.id}>
                  <div onClick={() => setAccount(item.organization, account)} className={styles.link}>
                    <AccountIcon className={styles.account_icon} /> {account.name}
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

const AccountPicker = ({ className }) => {
  const { account: currentAccount, organization } = useOrganization()

  return (
    <Popover
      content={<List />}
      placement='right'
      overlayInnerStyle={{ maxHeight: window.innerHeight - 40, overflow: 'auto', minWidth: 150 }}
      getPopupContainer={triggerNode => triggerNode?.parentNode}>
      <div className={classnames(styles.current, className)}>
        <dl>
          <dt>Organization</dt>
          <Text style={{ width: 80, fontSize: 14, fontWeight: 400 }} ellipsis={true}>{organization && organization.name}</Text>
        </dl>
        <span className={styles.arrow}>→</span>
        <dl>
          <dt>Account</dt>
          <Text style={{ width: 80, fontSize: 14, fontWeight: 400 }} ellipsis={true}>{currentAccount && currentAccount.name}</Text>
        </dl>
        <ChevronRightIcon className={styles.arrow} />
      </div>
    </Popover>
  )
}

export default AccountPicker
