import React from 'react'
import { Button } from 'antd'

import { Typography } from 'components/typography'
import LoadingDots from 'components/loading-dots/index'

import styles from './styles.less'

const Polling = ({ cancel, polling }) => {
  return (
    <div className={styles.polling_wrapper}>
      <LoadingDots />
      <Typography.Paragraph size='sm' align='center' className={styles.polling_text}>Creating stack. Please do not close this page</Typography.Paragraph>
      {polling && <Button type='link' onClick={() => cancel()} className={styles.polling_btn}>Cancel stack creation</Button>}
    </div>
  )
}

export default Polling
