import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [
  //   {
  //   id: 'APPSYNC_TOTAL_REQUESTS',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'requests',
  //     stats: [STATISTICS.SUM],
  //     period: 0
  //   }
  // }, {
  //   id: 'APPSYNC_TOTAL_ERRORS_5XX',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'errors.5xx',
  //     stats: [STATISTICS.SUM],
  //     period: 0
  //   }
  // }, {
  //   id: 'APPSYNC_TOTAL_ERRORS_4XX',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'errors.4xx',
  //     stats: [STATISTICS.SUM],
  //     period: 0
  //   }
  // }, {
  //   id: 'APPSYNC_TOTAL_LATENCY',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'latency',
  //     stats: [STATISTICS.AVERAGE],
  //     period: 0
  //   }
  // }
]

export default metrics
