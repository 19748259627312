import React from 'react'

import IconButton from 'components/buttons/icon'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

const DownloadButton = ({ link, title }) => {
  return (
    <a href={link} target='_blank' rel='noopener noreferrer'><IconButton icon={faDownload} title={title || 'Download'} /></a>
  )
}

export default DownloadButton
