// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a3srGJu21eAP4ghvkoGq {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.lFkoSLidAIGpjOvfxJ9H {\n  text-align: center;\n}\n.VvuXUBLB9SM5SpDjgojt {\n  margin-bottom: 10px;\n}\n.cnEviZmFD8RCXhwoHrin {\n  position: fixed;\n  top: calc(55px + 50px);\n  z-index: 200;\n  background-color: #fff;\n  margin: 0 -18px;\n  padding: 0;\n  border-bottom: 1px solid #4a46c6;\n}\n", "",{"version":3,"sources":["webpack://./src/components/charts/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,mBAAA;AAHF;AAMA;EACE,eAAA;EACA,sBAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,UAAA;EACA,gCAAA;AAJF","sourcesContent":["@import '../../styles/main.less';\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.wrapper {\n  text-align: center;\n}\n\n.icon {\n  margin-bottom: 10px;\n}\n\n.fixed_chart {\n  position: fixed;\n  top: calc(@fixed-height[title] + @fixed-height[metrics]);\n  z-index: @fixed-layer[metricsBar];\n  background-color: @background-colors[secondary];\n  margin: 0 -@spacing[large];\n  padding: 0;\n  border-bottom: 1px solid @colors[primary];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "a3srGJu21eAP4ghvkoGq",
	"wrapper": "lFkoSLidAIGpjOvfxJ9H",
	"icon": "VvuXUBLB9SM5SpDjgojt",
	"fixed_chart": "cnEviZmFD8RCXhwoHrin"
};
export default ___CSS_LOADER_EXPORT___;
