import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dropdown } from 'antd'
import { format } from 'date-fns'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { API } from 'actions/api'
import { CREATE_PAYMENT_METHOD_V2, GET_PAYMENT_METHODS_V2, DELETE_PAYMENT_METHOD_V2, SET_PRIMARY_PAYMENT_METHOD_V2 } from 'actions/billing-v2'
import mutations from './mutations'

import { Typography } from 'components/typography'
import Section from 'components/layout/content/section'
import CardItem from 'components/billing/stripe/card-item'
import AddMethodModal from './add-method-modal'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const { STRIPE_API_KEY } = process.env.CONFIG

const expiration = method => {
  return `${method.expiryMonth}/${format(new Date(method.expiryYear, 1, 1), 'yy')}`
}

const PaymentMethods = () => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()
  const paymentMethods = useSelector(state => state.billingV2.paymentMethods)
  const loading = useSelector(state => state.waiting.list[GET_PAYMENT_METHODS_V2])
  const loadingCreate = useSelector(state => state.waiting.list[CREATE_PAYMENT_METHOD_V2])
  const loadingDelete = useSelector(state => state.waiting.list[DELETE_PAYMENT_METHOD_V2])
  const loadingSetPrimary = useSelector(state => state.waiting.list[SET_PRIMARY_PAYMENT_METHOD_V2])

  const [visible, setVisible] = useState(false)
  const [stripePromise] = useState(() => loadStripe(STRIPE_API_KEY))

  useEffect(() => {
    if (!organization.id || loadingCreate) return

    dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.get())
  }, [organization, loadingCreate])

  const handlers = mutations({ dispatch, organization })

  const sortedByPrimary = paymentMethods?.data?.sort((a, b) => b.isPrimary - a.isPrimary)

  const handleCardActions = (key, item) => {
    switch (key) {
      case 'primary': return handlers.setPrimary(item.id)
      case 'delete': return handlers.remove(item.id)
      default: return () => { }
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div>
          <Typography.Title level={2}>Payment methods</Typography.Title>
          <Typography.Paragraph>Manage your payments methods</Typography.Paragraph>
        </div>
        <Button type='primary' onClick={() => setVisible(true)}>Add new payment method</Button>
      </div>
      <Section loading={loading || loadingDelete || loadingSetPrimary || loadingCreate}>
        {sortedByPrimary?.map(item => {
          return (
            <div key={item.id} className={styles.method_container}>
              <div className={styles.header}>
                <div>
                  <CardItem card={item.card} />
                  <p className={styles.expires_at}>Expires at <span>{expiration(item.card)}</span> </p>
                </div>
                <div className={styles.actions}>
                  {item.isPrimary && <p className={styles.primary}>Primary</p>}
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: [
                        { key: 'primary', label: 'Make primary', disabled: item.isPrimary },
                        { key: 'delete', label: 'Delete', disabled: sortedByPrimary.length === 1 }
                      ],
                      onClick: ({ key }) => handleCardActions(key, item)
                    }}
                  >
                    <ActionsDropdownButton />
                  </Dropdown>
                </div>
              </div>
            </div>
          )
        })}
      </Section>

      <Elements stripe={stripePromise}>
        <AddMethodModal visible={visible} setVisible={setVisible} />
      </Elements>

    </>
  )
}

export default PaymentMethods
