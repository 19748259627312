import React from 'react'

import classnames from 'classnames'
import styles from './styles.less'

export const IconWithText = ({ icon, text, className }) => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      {icon}
      <span>{text}</span>
    </div>
  )
}
