import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Dropdown, message } from 'antd'
import { Typography } from 'components/typography'
import { Descriptions } from 'components/descriptions'
import Input from 'components/antd/input'
import Content from 'components/layout/content'
import { ActionsDropdownButton } from 'components/buttons'

import { useAccountRoutes } from 'containers/routes'
import IntegrationChangeForm from './delegation-change-form'
import { useDelegationQuery, useUpdateDelegationQuery, useDeleteDelegationQuery } from 'hooks/api'
import { isDelegationOutOfDate } from 'lib/delegation-helpers'
import UpgradeStack from './upgrade-stack'

import styles from './styles.less'

const Delegation = () => {
  const history = useHistory()
  const { delegationId } = useParams()
  const routes = useAccountRoutes()

  const { data: delegation, isLoading, refetch, isRefetching } = useDelegationQuery()
  const { mutate: putDelegation, isLoading: updating, isSuccess } = useUpdateDelegationQuery()
  const { mutate: removeDelegation } = useDeleteDelegationQuery()
  const [showReloadBtn, setShowReloadButton] = useState(false)
  const canUpgrade = isDelegationOutOfDate(delegation)

  useEffect(() => {
    if (!isSuccess) return

    history.push(routes.settings.integrations.url())
    message.success('Delegation updated successfully. If you changed arn your resources will show up with the next polling cycle.')
  }, [isSuccess])

  useEffect(() => {
    if (!isRefetching && delegation && showReloadBtn) {
      if (canUpgrade) {
        message.error('Stack is still out of date')
      } else {
        message.success('Stack upgraded successfully!')
      }
    }
  }, [isRefetching])
  const title = delegation?.provider?.account ? `AWS ${delegation.provider.account}` : 'Connect with AWS account'

  const updateDelegation = (values) => {
    const roleArn = values?.arn?.trim()
    const name = values?.delegationName?.trim() === '' || !values?.delegationName ? ' ' : values?.delegationName?.trim()
    putDelegation({ payload: { name, roleArn } })
  }

  return (
    <Content
      item
      breadcrumbs={['Settings', 'Integrations', 'AWS connection']}
      loading={isLoading}
      title={title}
      backRoute={routes.settings.integrations.url()}
      actions={!delegation?.roleArn &&
        <Dropdown
          trigger={['click']}
          menu={{ items: [{ key: 'delete', label: 'Delete' }], onClick: () => removeDelegation(delegationId) }}
        >
          <ActionsDropdownButton />
        </Dropdown>}
    >
      {canUpgrade &&
        <UpgradeStack
          delegation={delegation}
          onUpgrade={() => setShowReloadButton(true)}
          onRefresh={refetch}
          showReload={showReloadBtn}
          isReLoading={isRefetching}
        />
      }
      {delegation?.roleArn && (
        <Descriptions style={{ margin: 0, padding: 0 }}>
          <Descriptions.Item layout='vertical' label='External ID' className={styles.label}>
            <Typography.Paragraph>
              <Input defaultValue={delegation.provider.secret} disabled />
            </Typography.Paragraph>
          </Descriptions.Item>
        </Descriptions>
      )}

      {delegation?.id && <IntegrationChangeForm
        className={styles.arn_form}
        onSubmit={updateDelegation}
        history={history}
        saving={updating}
      />}
    </Content>
  )
}

export default Delegation
