import React from 'react'
import { isEmpty, get, first, orderBy } from 'lodash'
import { Line } from '@visx/shape'
import { Group } from '@visx/group'
import { AnimatedAxis } from '@visx/react-spring'
import { scaleLinear } from '@visx/scale'
import { useTooltip } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { format } from 'date-fns'

import { formatDuration } from 'components/charts/utils'
import colors from 'lib/colors'

import SegmentBar from './segment-bar'
import SegmentTooltip from './tooltip'

const Traces = ({ trace }) => {
  const width = 640
  const barHeight = 20

  if (isEmpty(trace)) {
    return null
  }

  let segmentRows = 0
  const mappedIds = []

  const sortedSegments = orderBy(trace.segments, 'Document.start_time', 'asc')
  const min = get(first(sortedSegments), 'Document.start_time')

  const formatSegment = (seg) => {
    const origin = get(seg, 'Document.origin')
    const runtime = get(seg, 'Document.service.runtime_version')
    const http = get(seg, 'Document.http')
    const metadata = get(seg, 'Document.metadata')
    const isError = get(seg, 'Document.error')
    const op = get(seg, 'Document.aws.operation')
    const end = get(seg, 'Document.end_time', Date.now())

    const content = origin
      ? `${seg.Document.name}${op ? `- ${op}` : ''} [${origin}]`
      : runtime
        ? `${seg.Document.name} [runtime: ${runtime}]`
        : seg.Document.name

    return {
      index: segmentRows - 1,
      content,
      start: (seg.Document.start_time - min) * 1000,
      end: (end - min) * 1000,
      id: seg.Document.id,
      parentId: get(seg, 'Document.parent_id'),
      error: isError,
      data: {
        id: seg.Document.id,
        start: format(seg.Document.start_time * 1000, 'dd/MM/yyyy HH:mm:ss.SSS'),
        end: format(end * 1000, 'dd/MM/yyyy HH:mm:ss.SSS'),
        ...(http && { http }),
        ...(metadata && { metadata })
      },
      fill: origin
        ? colors('trace', origin)
        : runtime
          ? colors('chart', 'primaryLight')
          : colors('chart', 'yellow'),
      color: isError ? colors('chart', 'red') : '#000000',
      children: findSegment(seg.Document.id)
    }
  }

  const findSegment = (id) => {
    const sub = sortedSegments.find(seg => seg.Document.parent_id === id)
    if (!sub) return []

    mappedIds.push(sub.Document.id)
    segmentRows = segmentRows + 1

    return [formatSegment(sub)]
  }

  const formattedSegments = sortedSegments.map(doc => {
    if (mappedIds.includes(doc.Document.id)) return
    segmentRows = segmentRows + 1

    return formatSegment(doc)
  })

  const xScale = scaleLinear({
    domain: [0, trace.duration * 1000],
    range: [0, width],
    nice: false
  })

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0
  } = useTooltip()

  const handleMouseOver = (event, datum) => {
    const coords = localPoint(event.target.ownerSVGElement, event)
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum
    })
  }

  return (
    <div style={{ position: 'relative' }} onMouseLeave={hideTooltip}>
      <svg height={segmentRows * barHeight + 50} width={width + 15} >
        <Group left={10}>
          <Line from={{ x: xScale(formattedSegments[0].start), y: 0 }}
            to={{ x: xScale(formattedSegments[0].start), y: segmentRows * barHeight + 10 }}
            fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')}
          />
          {formattedSegments.filter(item => item).map((item, index) => {
            return <SegmentBar key={index}
              item={item}
              index={item.index}
              width={width}
              xScale={xScale}
              handleMouseOver={handleMouseOver}
              hideTooltip={hideTooltip}
              barHeight={barHeight}
            />
          })}
          <AnimatedAxis
            scale={xScale}
            top={segmentRows * barHeight + 10}
            stroke={colors('chart', 'grid')}
            tickStroke={colors('chart', 'text')}
            tickFormat={num => formatDuration(num)}
            animationTrajectory={'min'}
          />
        </Group>
      </svg>

      {tooltipOpen && <SegmentTooltip data={tooltipData} top={tooltipTop} left={tooltipLeft} handleClose={hideTooltip} />}
    </div>)
}

export default Traces
