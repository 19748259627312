import React from 'react'
import { useParams } from 'react-router-dom'

import { WIDGET_KIND } from 'lib/dashboards-constants'
import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import IconButton from 'components/buttons/icon'
import { IconWrapper, CloseIcon } from 'components/icons'
import { faChartColumn } from '@fortawesome/pro-light-svg-icons/faChartColumn'

import MetricsWidgetDetails from './metrics'
import InvocationsWidgetDetails from './invocations'
import EventsWidgetDetails from './events'
import TextWidgetDetails from './text'
import EditableTitle from 'features/editable-title'

import styles from './styles.less'

const WidgetDetailsComponent = (props) => {
  const { kind, onClose } = props

  switch (kind) {
    case WIDGET_KIND.METRICS:
      return <MetricsWidgetDetails {...props} />

    case WIDGET_KIND.INVOCATIONS:
      return <InvocationsWidgetDetails {...props} />

    case WIDGET_KIND.EVENTS:
      return <EventsWidgetDetails {...props} />

    case WIDGET_KIND.TEXT:
      return <TextWidgetDetails {...props} />

    default:
      onClose()
      return <div>Not implemented</div>
  }
}

const Title = ({ editing, kind, widget, onSave }) => {
  const name = widget?.name || `${editing ? 'Edit' : 'Create new'} ${kind} widget`
  const save = (value) => {
    onSave(widget, value)
  }
  if (!editing || kind === WIDGET_KIND.TEXT) return name
  return (
    <EditableTitle value={name} onSubmit={save} displayContent={name} size='sm' solid />
  )
}

const EmptyDashboard = ({ onClose }) => {
  return (
    <div className={styles.empty_wrapper}>
      <IconButton icon={<CloseIcon />} title='Close' onClick={onClose} />
      <Empty title='Dashboard not found.' />
    </div>
  )
}

const WidgetDetails = (props) => {
  const { dashboard, widgets, loading, onClose, handlers } = props
  const { widgetId } = useParams()

  const editing = Object.values(WIDGET_KIND).findIndex(kind => kind === widgetId) === -1
  const widget = editing && widgets.find(widget => widget.id === widgetId)
  const kind = editing ? widget.kind : widgetId

  return (
    <Content
      breadcrumbs={['Dashboards', dashboard.name]}
      loading={loading}
      item={dashboard}
      renderEmpty={<EmptyDashboard onClose={onClose} />}
      title={<Title editing={editing} widget={widget} kind={kind} onSave={handlers?.widgets?.updateName} />}
      actions={<IconButton icon={<CloseIcon />} title='Close' onClick={onClose} />}
      icon={<IconWrapper icon={faChartColumn} color='primary' solid />}
      className={styles.drawer}
      drawer bordered
      onDrawerClose={onClose}
    >
      <WidgetDetailsComponent editing={editing} widget={widget} kind={kind} {...props} />
    </Content>
  )
}

export default WidgetDetails
