// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ss1vyHpTtgzxiRiy2J4H {\n  background: #f5f5f5;\n  padding-top: 0;\n}\n.PY4hOE1orwIgPAomE4AQ {\n  margin: 0 -18px 12px -18px;\n}\n.yVGb5DCcXW2cxWvNaV6f {\n  display: flex;\n  flex-wrap: wrap;\n}\n.yVGb5DCcXW2cxWvNaV6f > * {\n  width: 30%;\n  margin: 0 9px 9px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/inventory/details/resource/services/appsync/graphql-api/resolvers/content/styles.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,cAAA;AADF;AAIA;EACE,0BAAA;AAFF;AAKA;EACE,aAAA;EACA,eAAA;AAHF;AAKE;EACE,UAAA;EACA,mBAAA;AAHJ","sourcesContent":["@import '../../../../../../../../../styles/main';\n\n.resolverContent {\n  background: @background-colors[primary];\n  padding-top: 0;\n}\n\n.chart {\n  margin: 0 -@spacing[large] @spacing[normal] -@spacing[large];\n}\n\n.occurrences {\n  display: flex;\n  flex-wrap: wrap;\n\n  & > * {\n    width: 30%;\n    margin: 0 @spacing[small] @spacing[small] 0;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resolverContent": "ss1vyHpTtgzxiRiy2J4H",
	"chart": "PY4hOE1orwIgPAomE4AQ",
	"occurrences": "yVGb5DCcXW2cxWvNaV6f"
};
export default ___CSS_LOADER_EXPORT___;
