import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { flatten, sortBy } from 'lodash'
import { Checkbox } from 'antd'

import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import { NameAlphabetical, OccurrenceCountLastDay, OccurrenceLast, Muted } from 'features/side-list/sorter'
import ListSearch from 'features/side-list/search'
import ListHeader from 'features/side-list/header'
import IconButton from 'components/buttons/icon'
import { ResolvedIcon, MutedIcon } from 'components/icons/font-awesome'
import Item from './item'
import { ERRORS_FILTERS } from './filters'
import { useAccountRoutes } from 'containers/routes'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import useQueryParams from 'lib/hooks/use-query-params'
import { useErrorsQuery, useErrorsBatchUpdate, useAllResourcesQuery, useDelegationsQuery, useResourceGroupsQuery } from 'hooks/api'
import { ErrorMetricsContext } from '../index'

import styles from './styles.less'

const ErrorsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { errorId: selected } = useParams()
  const { search } = useLocation()
  const { getValue: groupInUrl } = useQueryParams('group', [])

  const { dashboardStart, dashboardEnd } = useContext(ErrorMetricsContext)

  const sorters = [OccurrenceLast, OccurrenceCountLastDay, NameAlphabetical, Muted]

  const [sorter, setSorter] = useState(sorters[0])
  const [bulkSelected, setBulkSelected] = useState([])
  const [searchItems, setSearchItems] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [loading, setLoading] = useState(false)

  const { isLoading: loadingErrors, isFetching, data: errorsData } = useErrorsQuery({ start: dashboardStart, end: dashboardEnd })
  const { isLoading: loadingResources, isSuccess: resourcesSuccess } = useAllResourcesQuery()
  const { isLoading: loadingDelegations, isSuccess: delegationsSuccess } = useDelegationsQuery()

  const { mutate: bulkUpdate, isSuccess, isLoading } = useErrorsBatchUpdate()

  const { data: resourceGroups } = useResourceGroupsQuery()

  const onSelect = (error) => {
    history.push({ pathname: routes.errors.error.url({ errorId: error.id }), search })
  }

  const resetBulkSelected = () => {
    if (!isSuccess) return
    setBulkSelected([])
  }

  const onBulkSelect = (id, value) => {
    if (value) {
      setBulkSelected(bulkSelected.concat(id))
    } else {
      setBulkSelected(bulkSelected.filter(item => item !== id))
      setSelectAll(false)
    }
  }

  const onSelectAll = (value) => {
    if (value) {
      setBulkSelected(searchItems?.map(event => event.id))
      setSelectAll(true)
    } else {
      setBulkSelected([])
      setSelectAll(false)
    }
  }

  const onBulkStatusChange = (status) => {
    if (bulkSelected?.length === 0) {
      return
    }

    setLoading(status)
    bulkUpdate(bulkSelected.map(id => ({ id, status })))
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  const sortErrors = () => {
    setSearchItems(sorter?.sort ? sorter.sort(searchItems) : sortBy(searchItems, sorter.apply))
  }

  const searchErrors = () => {
    if (!errorsData || isFetching) return
    const groupsResourceIds = flatten(groupInUrl?.map(id => resourceGroups?.find(item => item.id === id)?.resources))

    const results = filterListFromQuery(errorsData, ERRORS_FILTERS, search, groupsResourceIds)

    if (bulkSelected?.length !== 0) {
      const bulkIds = bulkSelected?.filter(bulkId => results?.map(error => error.id)?.includes(bulkId))
      setBulkSelected(bulkIds)
      if (bulkIds?.length === 0) setSelectAll(false)
    }

    setSearchItems(sorter?.sort ? sorter.sort(results) : sortBy(results, sorter.apply))
  }

  useEffect(sortErrors, [sorter])
  useEffect(resetBulkSelected, [isSuccess])
  useEffect(searchErrors, [search, delegationsSuccess, resourcesSuccess, isFetching, errorsData])

  return (
    <List
      search={<ListSearch />}
      header={
        <ListHeader
          sorters={sorters}
          selectedSorter={sorter.key}
          onSorterChange={onSorterChange}
          total={errorsData?.length}
          searchTotal={searchItems?.length}
          overviewURL={routes.errors.url()}
          left={
            <div className={styles.left}>
              <Checkbox checked={selectAll} onClick={() => onSelectAll(!selectAll)} />
              <IconButton icon={<MutedIcon />} title='Mute' onClick={() => onBulkStatusChange('muted')} loading={loading === 'muted' && isLoading} />
              <IconButton icon={<ResolvedIcon />} title='Resolve' onClick={() => onBulkStatusChange('resolved')} loading={loading === 'resolved' && isLoading} />
            </div>
          }
          filters={ERRORS_FILTERS.reduce((acc, item) => {
            acc[item.name] = item.key
            return acc
          }, {})}
          view='errors'
        />
      }
      loading={loadingErrors || !errorsData}
      loadingMetrics={loadingResources || loadingDelegations}
      items={searchItems}
      selected={selected}
      renderItem={Item}
      bulkSelected={bulkSelected}
      onSelect={onSelect}
      onBulkSelect={onBulkSelect}
      renderEmpty={<ListEmpty title='No errors found' />}
      itemHeight={65.5}
    />
  )
}

export default ErrorsList
