// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PzjmKjlHty1QafbyBW6K {\n  display: flex;\n  align-items: center;\n}\n.lggluKiJ5IAKx5ckWnGX {\n  color: #4a46c6;\n  font-size: 30px;\n  margin-right: 10px;\n}\n.SlkJWzEuwtQyBUCtPF35 {\n  flex: 1;\n  color: #1a1a1a;\n}\n", "",{"version":3,"sources":["webpack://./src/components/billing/stripe/card-item/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;AAIA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAFF;AAKA;EACE,OAAA;EACA,cAAA;AAHF","sourcesContent":["@import '../../../../styles/main';\n\n.container {\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  color: @colors[primary];\n  font-size: 30px;\n  margin-right: 10px;\n}\n\n.cardNumber {\n  flex: 1;\n  color: @text-colors[darker]\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PzjmKjlHty1QafbyBW6K",
	"icon": "lggluKiJ5IAKx5ckWnGX",
	"cardNumber": "SlkJWzEuwtQyBUCtPF35"
};
export default ___CSS_LOADER_EXPORT___;
