import React from 'react'
import { Col, Row } from 'antd'
import Tabs from 'components/antd/tabs'

import InventoryTags from 'components/inventory/tags'
import Events from 'components/inventory/events'
import Section from 'components/layout/content/section'
import GlobalIndex from './global-index'
import SecondaryIndex from './secondary-index'
import InventoryResourceContainer from '../../container'
import { objectify } from 'lib/utils'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { DYNAMODB_READS } from '../../metrics-tabs/services/dynamodb'
import styles from './styles.less'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  const indexTitle = 'Table' || attributes.indexName
  const keySchema = JSON.parse(attributes.keySchema)

  const localSecondaryIndexes = JSON.parse(attributes.localSecondaryIndexes)
  const globalSecondaryIndexes = JSON.parse(attributes.globalSecondaryIndexes)

  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <GlobalIndex
      className={styles.index_container}
      title={indexTitle}
      attributes={attributes}
      keySchema={keySchema}
      inverse
    />
    {attributes.localSecondaryIndexes && localSecondaryIndexes.map(index =>
      <SecondaryIndex
        key={index.indexName}
        className={styles.index_container}
        indexName={index.indexName}
        keySchema={index.keySchema}
        inverse
      />)}

    {attributes.globalSecondaryIndexes && globalSecondaryIndexes.map(index =>
      <GlobalIndex
        key={index.indexName}
        className={styles.index_container}
        title={`${index.indexName} (GSI)`}
        attributes={index}
        keySchema={index.keySchema}
        inverse
      />)}
  </>)
}

const DynamoDB = ({ item }) => {
  return (
    <>
      <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.DynamoDB.id} initialChart={DYNAMODB_READS}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey='configuration' items={[
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col span={8}>
            <Section>
              <Events resourceId={item.id} />
            </Section>
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default DynamoDB
