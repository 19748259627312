import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { delegationPaths as paths, DELEGATIONS_LIST } from './index'
import { useAccountRoutes } from 'containers/routes'

const deleteDelegation = async (account, identity) => {
  const data = await apiFetch(paths.delegation(account, identity), { method: 'DELETE' })
  return data
}

export const useDeleteDelegationQuery = () => {
  const queryClient = useQueryClient()

  const history = useHistory()
  const routes = useAccountRoutes()
  const { account } = useOrganization()

  return useMutation(
    delegationId => deleteDelegation(account.id, delegationId),
    {
      onMutate: (alarmId) => {
        const previousDelegations = queryClient.getQueryData([...DELEGATIONS_LIST, account.id])

        const newList = previousDelegations?.filter(item => item.id !== alarmId)
        queryClient.setQueryData([...DELEGATIONS_LIST, account.id], newList)

        return { previousDelegations }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...DELEGATIONS_LIST, account.id], context.previousAlarmRules)
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...DELEGATIONS_LIST, account.id])
        history.push(routes.settings.integrations.url())
      }
    }
  )
}
