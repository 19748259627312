import React, { useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import { Select } from 'antd'
import styles from './styles.less'

const { Option } = Select

const quickOptions = ['minutes', 'hours', 'days']

const quickNumbers = {
  minutes: [...Array(61).keys()].slice(10, 61),
  hours: [...Array(25).keys()].slice(1, 25),
  days: [...Array(31).keys()].slice(1, 31)
}

const QuickSelectDate = ({ setOpenSelectRange, handleCalChange, dashboard }) => {
  const [quickStart, setQuickStart] = useState(15)
  const [quickUnit, setQuickUnit] = useState('minutes')
  const [numberData, setNumberData] = useState(quickNumbers.minutes)

  const handleQuickUnit = (value) => {
    setNumberData(quickNumbers[value])
    setQuickUnit(value)
  }

  const handleQuickApply = (e) => {
    e.preventDefault()
    let formatUnit
    if (quickUnit === 'minutes') formatUnit = 'minute'
    else if (quickUnit === 'hours') formatUnit = 'hour'
    else formatUnit = 'day'
    const minuteRange = moment().diff(moment(moment().subtract(quickStart, formatUnit).startOf('minutes')), 'minutes')
    handleCalChange(moment().subtract(quickStart, formatUnit).startOf('minutes').valueOf(), moment().valueOf(), minuteRange)
  }

  return (
    <>
      <p className={classnames(styles.title)}>
        Quick select
        <button className={classnames(styles.close_btn, dashboard && styles.close_btn_dashboard)} onClick={e => setOpenSelectRange(false)}>X</button>
      </p>
      <div className={styles.quick}>
        <p className={styles.quick_title}>Last</p>
        <Select
          className={styles.quick_select}
          defaultValue={numberData[0]}
          style={{ width: 60 }}
          size={'small'}
          value={quickStart}
          onChange={value => setQuickStart(value)}
        >
          {numberData.map(val => (
            <Option key={val}>{val}</Option>
          ))}
        </Select>
        <Select
          className={styles.quick_select}
          style={{ width: 100 }}
          defaultValue={quickOptions[0]}
          size={'small'}
          value={quickUnit}
          onChange={handleQuickUnit}
        >
          {quickOptions.map(unit => (
            <Option key={unit}>{unit}</Option>
          ))}
        </Select>
        <button type='submit' className={styles.quick_applyBtn} onClick={handleQuickApply}>Apply</button>
      </div>
    </>
  )
}

export default QuickSelectDate
