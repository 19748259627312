import React, { useMemo, useCallback } from 'react'
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { sub } from 'date-fns'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'

import Drawer from 'components/drawer'
import WidgetDetails from 'features/widgets/details'
import { IconWrapper } from 'components/icons'
import Content from 'components/layout/content'
import GlobalDatePicker from 'components/date-picker/global'
import EditableTitle from 'features/editable-title'
import InvocationDetails from 'features/invocations/details'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import { useAccountRoutes } from 'containers/routes'
import DashboardContainer from 'hooks/context/dashboard-context'
import { useAllResourcesQuery, useDashboardQuery, useWidgetsQuery } from 'hooks/api'
import { useMutations, useMetricsFetcher, useInvocationsFetcher, useEventsFetcher } from '../hooks'
import { useGlobalDatePicker } from 'hooks'

import { WIDGET_SIZE_LIMITS } from 'lib/dashboards-constants'
import { Actions, TitleActions } from './actions'
import DashboardGrid from './grid'

import styles from './styles.less'

const Title = ({ onSubmit, item }) => (
  item
    ? <EditableTitle
      value={item?.name}
      onSubmit={onSubmit}
      displayContent={item?.name}
    />
    : null
)

const InvocationDetailsDrawer = ({ resources, onClose }) => {
  const { resourceId, invocationHash } = useParams()

  return (
    <Drawer
      open
      onClose={onClose}
      mask={false}
      closable={false}
      size='large'
    >
      <InvocationDetails
        type={INVENTORY_SERVICES.Lambda.id}
        resource={resources[resourceId]}
        onClose={onClose}
        hash={invocationHash}
      />
    </Drawer>
  )
}

const Dashboard = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const match = useRouteMatch()

  const { data: dashboard, isLoading: loadingDashboard } = useDashboardQuery()
  const { data: widgets, isLoading: loadingWidgets } = useWidgetsQuery()
  const { data: resources, isLoading: loadingResources } = useAllResourcesQuery()

  const handlers = useMutations({ dashboard, widgets })

  const {
    start,
    end,
    range,
    relativeSpan,
    refreshInterval,
    handleSelectRange,
    handleIntervalChange
  } = useGlobalDatePicker()

  const metrics = useMetricsFetcher({ widgets, resources, start, end, relativeSpan, refreshInterval })
  const invocations = useInvocationsFetcher({ widgets, start, end, refreshInterval })
  const events = useEventsFetcher({ widgets, start, end, refreshInterval })

  const period = useMemo(() => metrics ? Object.values(metrics)[0]?.period : 3600, [metrics])
  const loading = useMemo(() => loadingDashboard || loadingWidgets || loadingResources, [loadingDashboard, loadingWidgets, loadingResources])

  const handleWidgetDragStop = useCallback((layout, oldItem, newItem) => {
    const hasPositionChanged = oldItem.x !== newItem.x || oldItem.y !== newItem.y

    if (hasPositionChanged) {
      handlers.update.layout(layout)
    }
  }, [handlers.update.layout])

  const handleWidgetResize = useCallback((layout, oldItem, newItem, placeholder) => {
    const hasSizeChanged = oldItem.w !== newItem.w || oldItem.h !== newItem.h

    if (hasSizeChanged) {
      const widget = widgets.find(widget => widget.id === newItem.i)
      if (!widget) return

      const { w, h } = newItem
      const { upper, lower } = WIDGET_SIZE_LIMITS[widget.kind]
      if (!upper || !lower) return

      // Check WIDTH upper bound
      if (w >= upper.w) {
        newItem.w = upper.w
        placeholder.w = upper.w
      }

      // Check WIDTH lower bound
      if (w <= lower.w) {
        newItem.w = lower.w
        placeholder.w = lower.w
      }

      // Check HEIGHT upper bound
      if (h >= upper.h) {
        newItem.h = upper.h
        placeholder.h = upper.h
      }

      // Check HEIGHT lower bound
      if (h <= lower.h) {
        newItem.h = lower.h
        placeholder.h = lower.h
      }
    }
  }, [widgets])

  const handleWidgetResizeStop = useCallback((layout, oldItem, newItem) => {
    const hasSizeChanged = oldItem.w !== newItem.w || oldItem.h !== newItem.h

    if (hasSizeChanged) {
      handlers.update.layout(layout)
    }
  }, [handlers.update.layout])

  const handleWidgetDetailsClose = () => {
    history.push({ pathname: routes.dashboards.dashboard.url({ dashboardId: dashboard.id }) })
  }

  const handleInvocationsDetailsClose = () => {
    history.push({ pathname: routes.dashboards.dashboard.url({ dashboardId: dashboard.id }) })
  }

  const handleWidgetCreate = (dashboard, kind) => {
    history.push({ pathname: routes.dashboards.widget.url({ dashboardId: dashboard.id, widgetId: kind }) })
  }

  const handleManualRefetch = () => {
    metrics.refetch()
    invocations.refetch()
    events.refetch()
  }

  return (
    <Content
      loading={loading}
      item={dashboard}
      breadcrumbs={['Dashboards']}
      backRoute={routes.dashboards.url()}
      title={<Title onSubmit={handlers.update.title} item={dashboard} />}
      icon={<IconWrapper icon={faLayerGroup} color='primary' solid />}
      fixed
      bordered
      fixedTitle={dashboard?.name}
      className={styles.wrapper}
      actions={(
        dashboard && <>
          <GlobalDatePicker
            max={new Date()}
            min={sub(new Date(), { months: 1 })}
            start={start}
            end={end}
            refreshInterval={refreshInterval}
            relativeSpan={relativeSpan}
            period={period}
            fetching={metrics.fetching || invocations.fetching}
            onChange={handleSelectRange}
            handleIntervalChange={handleIntervalChange}
            handleManualRefetch={handleManualRefetch}
          />
          <Actions
            item={dashboard}
            onDelete={handlers.remove}
          />
        </>
      )}
      titleRowActions={
        <TitleActions
          item={dashboard}
          loading={loading}
          onCreateWidget={handleWidgetCreate}
        />
      }
    >
      <Route path={`${match.path}/widgets/:widgetId`}>
        <Drawer
          open
          size='large'
          mask={false}
          closable={false}
        >
          <WidgetDetails
            loading={loading}
            dashboard={dashboard}
            widgets={widgets}
            resources={resources}
            handlers={handlers}
            onClose={handleWidgetDetailsClose}
          />
        </Drawer>
      </Route>
      {
        !loadingResources && (
          <Route path={`${match.path}/inventory/:resourceId/requests/:invocationHash`} >
            <InvocationDetailsDrawer
              resources={resources}
              onClose={handleInvocationsDetailsClose}
            />
          </Route>
        )
      }
      <DashboardContainer
        handlers={handlers}
        metrics={metrics}
        invocations={invocations}
        events={events}
        options={{ start, end, range }}
      >
        <DashboardGrid
          widgets={widgets}
          onDragStop={handleWidgetDragStop}
          onResize={handleWidgetResize}
          onResizeStop={handleWidgetResizeStop}
        />
      </DashboardContainer>
    </Content>
  )
}

export default Dashboard
