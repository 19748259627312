import React from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../../container'
import InventoryTags from 'components/inventory/tags'
import KinesisDataStreamConfig from './config'
import Events from 'components/inventory/events'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { KINESIS_DS_INCOMING } from '../../../metrics-tabs/services/kinesis-datastream'
import Section from 'components/layout/content/section'
import Tabs from 'components/antd/tabs'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <KinesisDataStreamConfig attributes={attributes} inverse />
  </>)
}

const KinesisDataStream = ({ item }) => {
  return (
    <>
      <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.KinesisDataStream.id} initialChart={KINESIS_DS_INCOMING}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey="configuration" items={[{ key: 'configuration', label: 'Configuration', children: <Config item={item} /> }]} />
            </Section>
          </Col>
          <Col span={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default KinesisDataStream
