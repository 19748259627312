import React, { createContext } from 'react'

export const DashboardContext = createContext({
  handlers: null,
  metrics: { data: {}, loading: false, fetching: false },
  invocations: { data: {}, loading: false, fetching: false },
  events: { data: {}, loading: false, fetching: false },
})

const DashboardContainer = ({ children, handlers, metrics, invocations, events, options }) => {
  return (
    <DashboardContext.Provider value={{ handlers, metrics, invocations, events, options }}>
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardContainer
