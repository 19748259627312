// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upXzPcn_E5Nmxzxk2B5q {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\nh3.Qgr4_R06oN7Am1_aXeO2 {\n  text-align: center;\n  margin-bottom: 9px;\n  color: #666666;\n}\n.YEoHJ7YL25rOVwdbP9Z0 {\n  width: 70%;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/auth/title/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;AAIA;EACE,kBAAA;EACA,kBAAA;EACA,cAAA;AAFF;AAKA;EACE,UAAA;AAHF","sourcesContent":["@import '../../../styles/main';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\nh3.auth_title {\n  text-align: center;\n  margin-bottom: @spacing[small];\n  color: @text-colors[dark];\n}\n\n.subtitle {\n  width: 70%;\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "upXzPcn_E5Nmxzxk2B5q",
	"auth_title": "Qgr4_R06oN7Am1_aXeO2",
	"subtitle": "YEoHJ7YL25rOVwdbP9Z0"
};
export default ___CSS_LOADER_EXPORT___;
