import React from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'

import { formatBoolean, objectify } from 'lib/utils'
import SecurityGroups from '../security-groups'
import Enabled from 'components/inventory/enabled'
import Metadata from 'components/inventory/metadata'
import Region from '../region'
import Endpoint from '../endpoint'
import PrettyPrinter from 'components/json-pretty'

const formatDate = (date) => moment(date).format('MMMM Do YYYY, h:mm:ss a')

const RDSClusterConfig = ({ attributes, inverse }) => {
  const data = objectify(attributes)

  const items = [{
    title: 'General',
    children: [
      { key: 'Engine', value: `${data?.engine} - ${data?.engineMode}` },
      { key: 'Engine version', value: data.engineVersion },
      { key: 'Cluster resource ID', value: data?.dbClusterResourceId },
      { key: 'Cluster created', value: formatDate(data.clusterCreateTime) },
      { key: 'Deletion protection', value: <Enabled data={data.deletionProtection} /> }
    ]
  }, {
    title: 'Scaling configuration',
    value: <PrettyPrinter jsonString={JSON.parse(data?.scalingConfigurationInfo)} collapsed={false} />
  }, {
    title: 'Availability',
    children: [
      { key: 'IAM DB authentication', value: <Enabled data={data.iamDatabaseAuthenticationEnabled} /> },
      { key: 'Multi AZ', value: formatBoolean(data?.multiAZ) ? 'YES' : 'NO' }
    ]
  }, {
    title: 'Endpoint',
    value: <Endpoint address={data?.endpoint} port={data?.port} hostedZoneId={data?.hostedZoneId} />
  }, {
    title: 'Storage',
    children: [
      { key: 'Encryption', value: <Enabled data={data?.storageEncrypted} /> },
      { key: 'KMS Key', value: data?.kmsKeyId },
      { key: 'Allocated storage', value: `${data?.allocatedStorage}GiB` },
      { key: 'Capacity', value: `${data?.capacity} capacity unit` }
    ]
  }, {
    title: 'Networking',
    children: [
      { key: 'Subnet group', value: data?.dbSubnetGroup },
      { key: 'Available regions', value: JSON.parse(data?.availabilityZones).map(reg => <Region key={reg} reg={reg} />) },
      { key: 'HTTP Endpoint', value: <Enabled data={data?.httpEndpointEnabled} /> }
    ]
  }, {
    title: 'Security',
    children: [
      { key: 'VPC Security Groups', value: <SecurityGroups groups={JSON.parse(data.vpcSecurityGroups)} /> }
    ]
  }, {
    title: 'Backups',
    children: [
      { key: 'Automated backups', value: `${data?.backupRetentionPeriod} days` },
      { key: 'Copy tags to snapshots', value: <Enabled data={data.copyTagsToSnapshot} /> },
      { key: 'Earliest restore time', value: formatDate(data?.earliestRestorableTime) },
      { key: 'Latest restore time', value: formatDate(data?.latestRestorableTime) },
      { key: 'Backup window', value: `${data?.preferredBackupWindow} UTC (GMT)` }
    ]
  }, {
    title: 'Maintenance',
    value: `${data?.preferredMaintenanceWindow} UTC (GMT)`
  }]

  const statusItem = { title: 'Status', value: data?.status }
  const activityStreamItem = { title: 'Activity Stream status', value: data?.activityStreamStatus }

  return (
    <>
      <Row gutter={12}>
        <Col span={12}><Metadata items={[statusItem]} inverse={inverse} /></Col>
        <Col span={12}><Metadata items={[activityStreamItem]} inverse={inverse} /></Col>
      </Row>
      <Metadata items={items} inverse={inverse} />
    </>
  )
}

export default RDSClusterConfig
