import React from 'react'
import { get } from 'lodash'

import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard'
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover'
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex'
import { faCcDinersClub } from '@fortawesome/free-brands-svg-icons/faCcDinersClub'
import { faCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './styles.less'

const cardNumber = lastFour => `•••• •••• •••• ${lastFour}`

const brandIcons = {
  visa: {
    icon: faCcVisa
  },
  mastercard: {
    icon: faCcMastercard
  },
  amex: {
    icon: faCcAmex
  },
  diners: {
    icon: faCcDinersClub
  },
  discover: {
    icon: faCcDiscover
  },
  jcb: {
    icon: faCcJcb
  },
  default: {
    icon: faCreditCard
  }
}

const getCcIcon = brand => {
  return get(brandIcons[brand], 'icon', brandIcons.default.icon)
}

const CardItem = ({ card }) => {
  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={getCcIcon(card.brand)} className={styles.icon} />
      <p className={styles.cardNumber}>{cardNumber(card.lastDigits)}</p>
    </div>
  )
}

export default CardItem
