import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'

import styles from './styles.less'

const Empty = ({ onClick, text }) => {
  return (
    <div className={styles.empty} onClick={onClick}>
      <FontAwesomeIcon className={styles.icon} icon={faPlus} size='3x' />
      <div className={styles.body}>{text}</div>
    </div>
  )
}

export default Empty
