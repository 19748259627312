import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import { Divider, Skeleton, Button, Tooltip } from 'antd'

import { API } from 'actions/api'
import { POST_INVOICES_PREVIEW, CREATE_PAYMENT_METHOD_V2 } from 'actions/billing-v2'
import { useOrganization } from 'hooks/context/organization-context'
import { tiersInfo, TIERS } from 'lib/organization-constants'
import { formatStripePrice, formatInvocations } from 'lib/billing-helpers'
import PromoCodeForm from './promo-code-form'
import InvoiceLine from './invoice-line'
import Section from 'components/layout/content/section'
import CheckoutSummary from './summary'

import styles from '../styles.less'

const PaymentInfo = ({ priceItem, preview, IN_TRIAL }) => {
  const nextBillingDate = preview?.lines?.find(item => item.price.id === priceItem?.id)?.period?.end
  const dateFormatted = moment.unix(nextBillingDate).format('MMMM Do YYYY')
  const price = formatStripePrice(priceItem?.amount, priceItem?.currency)
  const discount = preview?.discount ? `(${preview?.discount?.coupon?.name})` : ''
  return <p className={styles.info}>
    {IN_TRIAL
      ? `NB! You will be charged ${price} ${discount} when trial ends on ${dateFormatted}`
      : `NB! Next billing will be ${price} ${discount} on ${dateFormatted}`}
  </p>
}

const Checkout = ({ selectedId, priceItem, tierItem }) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()

  const subscription = useSelector(state => state.billingV2.subscription)
  const selectedTier = tiersInfo.find(item => item.id === selectedId)
  const preview = useSelector(state => state.billingV2.invoicePreview)
  const [promotion, setPromotion] = useState(subscription?.discount ? subscription.discount : {})
  const loadingInvoicePreview = useSelector(state => state.waiting.list[POST_INVOICES_PREVIEW])
  const loadingCreatePaymentMethod = useSelector(state => state.waiting.list[CREATE_PAYMENT_METHOD_V2])
  const loading = loadingInvoicePreview || loadingCreatePaymentMethod
  const validatePromotion = API.organizations.id(organization.id).billingV2.discount.promotion

  const IN_TRIAL = subscription?.status === 'trialing'
  const IN_TRIAL_SWITCH = IN_TRIAL && selectedId === TIERS.free

  const getInvoicePreview = (discount = {}) => {
    const subscriptionItem = subscription?.items.find(item => item.price?.metadata.type === 'invocations')
    const currentTierItem = subscription?.items.find(item => item.price.metadata.type === 'tier')

    dispatch(API.organizations.id(organization.id).billingV2.invoices.preview.post({
      subscriptionId: subscription.id,
      items: [
        { id: subscriptionItem.id, priceId: priceItem.id },
        { id: currentTierItem?.id, priceId: tierItem?.id }
      ],
      ...discount
    }))
  }

  useEffect(() => {
    if (!organization || isEmpty(priceItem)) return

    getInvoicePreview(!isEmpty(promotion) ? { coupon: promotion?.coupon?.id } : {})
  }, [promotion])

  return (
    <Section className={styles.step_container}>
      <h2 className={styles.title}>Checkout overview</h2>

      <CheckoutSummary
        title={selectedTier?.title}
        selectedId={selectedId}
        invocations={priceItem?.metadata.invocations}
        interval={priceItem?.interval}
      />

      <div className={styles.total_container}>
        <Skeleton loading={loading} paragraph={{ rows: 7, width: [400, 400, 400, 400, 400, 400, 400] }} active title={false}>
          <div className={styles.checkout_prices}>
            {preview?.lines?.map(line => {
              if (line.price.metadata.type === 'tier') return null
              const description = IN_TRIAL ? 'Selected invocations amount' : line.description
              const invocationsAmount = IN_TRIAL_SWITCH ? 1000000 : line.price.metadata.invocations
              const price = IN_TRIAL_SWITCH ? 0 : line?.amount
              return (
                <InvoiceLine
                  key={line.id}
                  description={`${description} (${formatInvocations(invocationsAmount)})`}
                  amount={price}
                  currency={preview?.currency}
                  interval={line?.price?.interval}
                />
              )
            })}
            {selectedId === TIERS.pro && !isEmpty(preview) && <PaymentInfo priceItem={priceItem} preview={preview} IN_TRIAL={IN_TRIAL} />}
          </div>

          {selectedId === TIERS.pro && (
            <>
              <PromoCodeForm promotion={promotion} setPromotion={setPromotion} fetch={validatePromotion} />
              {!isEmpty(promotion) && <div className={styles.row}>
                <p className={styles.confirm_text}>Discount applied: {preview?.discount?.coupon?.name}</p>
                {!(subscription?.discount?.coupon?.id === preview?.discount?.coupon?.id) &&
                  <Tooltip title='Remove coupon code'><Button shape='circle' icon={<DeleteOutlined />} onClick={() => setPromotion({})} className={styles.remove_btn} /></Tooltip>}
              </div>}
            </>
          )}

          <Divider className={styles.divider} />

          <InvoiceLine description='Subtotal' amount={IN_TRIAL_SWITCH ? 0 : preview?.subtotal || 0} currency={preview?.currency} />
          <InvoiceLine description='Tax' amount={IN_TRIAL_SWITCH ? 0 : preview?.tax || 0} currency={preview?.currency} />
          <InvoiceLine description='Total' amount={IN_TRIAL_SWITCH ? 0 : preview?.total} currency={preview?.currency} total />
        </Skeleton>
      </div>
    </Section >
  )
}

export default Checkout
