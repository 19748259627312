import React from 'react'
import { Row, Col, Typography } from 'antd'

import { TIERS } from 'lib/organization-constants'
import { ResolvedSolidIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const { Text } = Typography

const FEATURES = {
  free: [
    ['Up to 15 resources', '1GB / month', 'Failure detection', 'Project views']
  ],
  pro: [
    ['Unlimited resources', 'Automatic insights', 'Custom event tracking', 'Log analytics', 'Full text search', 'Project views']
  ],
  enterprise: [
    ['Unlimited resources', 'Automatic insights', 'Custom event tracking', 'Log analytics', 'Full text search', 'Project views'],
    ['Advanced insights', 'Enterprise support', 'SLA']
  ]
}

const FeatureRow = ({ feature } = {}) => {
  return (
    <Row>
      <Col>
        <ResolvedSolidIcon className={styles.checkMark} />
        <Text>{feature}</Text>
      </Col>
    </Row>
  )
}

const Plan = ({ tier } = {}) => {
  if (!TIERS[tier]) return null

  const columns = FEATURES[tier].map((columnData, columnNum) => {
    const column = columnData.map((feature, idx) => <FeatureRow key={idx} feature={feature} />)
    return (
      <Col key={columnNum}>{column}</Col>
    )
  })

  return (
    <Row type='flex' justify='center' gutter={48} className={styles.features}>{columns}</Row>
  )
}

export default Plan
