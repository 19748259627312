import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import ListHeader from 'features/side-list/header'
import ListSearch from 'features/side-list/search'
import IconButton from 'components/buttons/icon/index'
import DashboardListItem from './item'

import { useAccountRoutes } from 'containers/routes'
import { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'

import { AddIcon } from 'components/icons/font-awesome'
import { useDashboardsQuery, useCreateDashboardQuery } from 'hooks/api'

import styles from './styles.less'

const mutations = ({ createDashboard }) => {
  return {
    create: () => {
      const payload = {
        name: 'My dashboard'
      }

      createDashboard(payload)
    }
  }
}

const DashboardsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()
  const { dashboardId: selected } = useParams()

  const { data: dashboards, isLoading } = useDashboardsQuery()
  const { mutate: createDashboard } = useCreateDashboardQuery()

  const loading = isLoading || !dashboards
  const handlers = mutations({ createDashboard })

  const onSelect = (identity) => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })

    history.push({ pathname: routes.dashboards.dashboard.url({ dashboardId: identity }), search: newValue })
  }

  return (
    <List
      loading={loading}
      items={dashboards}
      selected={selected}
      renderItem={DashboardListItem}
      itemHeight={41}
      onSelect={(item) => onSelect(item.id)}
      search={<ListSearch />}
      header={
        <ListHeader
          total={dashboards?.length}
          searchTotal={dashboards?.length}
          overviewURL={routes.dashboards.url()}
          extra={
            <IconButton
              className={styles.action}
              icon={<AddIcon />}
              title='New dashboard'
              onClick={() => handlers.create()}
            />
          }
          title='New dashboard'
        />
      }
      renderEmpty={() => <ListEmpty title='No dashboards found' />}
    />
  )
}

export default DashboardsList
