import React from 'react'
import classnames from 'classnames'
import { Typography } from 'components/typography'

import styles from './styles.less'

const HexagonBadge = ({ title, subtitle }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.hexagon} />
      <div className={styles.content}>
        <Typography.Title level={4} className={styles.title}>{title}</Typography.Title>
        <Typography.Paragraph align='center' className={styles.subtitle}>{subtitle}</Typography.Paragraph>
      </div>
    </div>
  )
}

export const HexagonBadges = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div key={index} className={classnames(styles.placement, { [styles[item.placement]]: item.placement })}>
          <HexagonBadge title={item.title} subtitle={item.subtitle} />
        </div>
      ))}
    </div>
  )
}

export default HexagonBadge
