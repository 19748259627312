import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import querystring from 'query-string'
import { times } from 'lodash'
import { Skeleton, Button } from 'antd'

import ListSearch from 'features/side-list/search'
import ListHeader from 'features/side-list/header'
import Item from './item'
import IconButton from 'components/buttons/icon/index'
import { Typography } from 'components/typography'
import { AddIcon } from 'components/icons/font-awesome'

import { SearchContext } from 'hooks/context/search-context'
import { useGetQueriesQuery, useGetQueryQuery } from 'hooks/api/queries'
import useQueryParams from 'lib/hooks/use-query-params'
import { useAccountRoutes } from 'containers/routes'

import styles from './styles.less'

const OPTIONS = { arrayFormat: 'comma' }

const SearchList = () => {
  const routes = useAccountRoutes()
  const history = useHistory()
  const { getValue: searchQuery } = useQueryParams('search', '')

  const { recentQueries, handleClearHistory, selected, setSelected } = useContext(SearchContext)
  const { data: savedQueries, isLoading: isQueriesLoading } = useGetQueriesQuery()
  const { data: query } = useGetQueryQuery()

  const [savedListQueries, setSavedListQueries] = useState([])
  const [recentListQueries, setRecentListQueries] = useState([])

  const handleSelectRecent = (item) => {
    setSelected(item.id)

    // adds search query to url for easier sharing and prefills form
    const search = querystring.stringify({ query: JSON.stringify(item) }, OPTIONS)
    history.push({ pathname: routes.search.url(), search })
  }

  const handleSelectSaved = (item) => {
    setSelected(item.id)
    history.push({ pathname: routes.search.query.url({ queryId: item.id }) })
  }

  useEffect(() => {
    if (!savedQueries) return
    const filteredSaved = savedQueries?.filter(item => !item?.frequency && (item?.query?.includes(searchQuery) || item?.name?.includes(searchQuery)))
    const filteredRecent = recentQueries?.filter(item => item?.query?.includes(searchQuery))

    setSavedListQueries(filteredSaved)
    setRecentListQueries(filteredRecent)
  }, [recentQueries, savedQueries, searchQuery])

  useEffect(() => {
    if (!query) return
    setSelected(query.id)
  }, [query])

  return (
    <>
      <ListSearch />
      <ListHeader extra={<IconButton icon={<AddIcon />} title='New search' onClick={() => history.push(routes.search.url())} />} />
      <div className={styles.list}>
        <div className={styles.items_container}>
          {isQueriesLoading
            ? (
              <div className={styles.skeleton_wrapper}>{times(3).map(
                index => (<Skeleton key={index} active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />)
              )}</div>)
            : savedListQueries?.map(
              (item, index) => <Item key={index} item={item} onSelect={handleSelectSaved} selected={selected} />
            )
          }
        </div>
        <div className={styles.items_container}>
          <div className={styles.history_header}>
            <Typography.Title level={4} className={styles.history_title} upperCase>History</Typography.Title>
            <Button type='link' size='small' onClick={() => handleClearHistory()} className={styles.clear}>Clear</Button>
          </div>
          <div className={styles.history_list}>
            {recentListQueries?.map(recent => <Item key={recent.updatedAt} item={recent} onSelect={handleSelectRecent} selected={selected} />)}
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchList
