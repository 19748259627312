// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q7878F926bFkOpqSfv16 .ant-modal-body {\n  min-height: 140px;\n  display: flex;\n  align-items: center;\n  padding: 40px;\n}\n.q7878F926bFkOpqSfv16 .ant-modal-body > * {\n  flex: 1;\n}\n.NJBveFSu2b_ufzf3gKS7 {\n  color: #f91111;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/settings/billing/payment/billing-info/modal/styles.less"],"names":[],"mappings":"AAEA;EAEI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;AAFJ;AAHA;EAQM,OAAA;AAFN;AAOA;EACE,cAAA;EACA,kBAAA;AALF","sourcesContent":["@import '../../../../../../styles/main';\n\n.modal {\n  :global(.ant-modal-body) {\n    min-height: 140px;\n    display: flex;\n    align-items: center;\n    padding: 40px;\n\n    > * {\n      flex: 1;\n    }\n  }\n}\n\n.errorText {\n  color: @colors[alert];\n  text-align: center;\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "q7878F926bFkOpqSfv16",
	"errorText": "NJBveFSu2b_ufzf3gKS7"
};
export default ___CSS_LOADER_EXPORT___;
