import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import billing from './billing.reducer'
import billingV2 from './billing-v2'
import client from './client.reducer'
import events from './events.reducer'
import integrations from './integrations.reducer'
import logSources from './log-sources.reducer'
import metric from './metric.reducer'
import organizations from './organizations.reducer'
import waiting from './waiting.reducer'

export default (history) => combineReducers({
  router: connectRouter(history),
  billing,
  billingV2,
  client,
  events,
  integrations,
  logSources,
  metric,
  organizations,
  waiting
})
