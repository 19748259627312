// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B92SVDVX7sefUH6w0L4f {\n  margin-top: 18px;\n}\n.bg10vkSckS96YgVDHO1I {\n  color: #4a46c6;\n  line-height: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/dashboards/overview/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAIA;EACE,cAAA;EACA,iBAAA;AAFF","sourcesContent":["@import '../../../styles/main';\n\n.resource_groups {\n  margin-top: @spacing[large];\n}\n\n.icon {\n  color: @colors[primary];\n  line-height: 18px;\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resource_groups": "B92SVDVX7sefUH6w0L4f",
	"icon": "bg10vkSckS96YgVDHO1I"
};
export default ___CSS_LOADER_EXPORT___;
