import { useQuery } from 'react-query'
import queryString from 'query-string'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { invitationPaths, INVITATION } from './index'
import { paths } from '../index'
import { useQuery as useQueryParams } from 'lib/hooks/utils'

const getInvitations = async (email, token) => {
  const query = queryString.stringify(omitEmpty({
    email,
    token
  }))
  const data = await apiFetch(paths.filter(invitationPaths.invitations(), query))
  return data
}

export const useInvitationQuery = () => {
  const params = useQueryParams()
  const token = params.get('token')
  const email = params.get('email')

  return useQuery(
    [INVITATION, token, email],
    () => getInvitations(email, token),
    {
      staleTime: Infinity,
      enabled: !!email && !!token,
      retry: false
    }
  )
}
