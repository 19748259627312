import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink'

import { message, Button, Alert, Divider } from 'antd'
import { Typography } from 'components/typography'
import { RefetchIcon, CopyIcon } from 'components/icons/font-awesome'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from 'components/buttons/icon'

import { extractCFStackRegion } from 'lib/delegation-helpers'

import styles from './styles.less'

const { CLOUDFORMATION_URL } = process.env.CONFIG

const UpgradeButton = ({ stackId, onClick, region }) => {
  const url = `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/update/template?stackId=${encodeURIComponent(stackId)}&templateURL=${encodeURIComponent(CLOUDFORMATION_URL)}`
  return (
    <Button
      onClick={onClick}
      type='primary'
      className={styles.upgradeButton}
      href={url}
      target='_blank'
      rel='noopener noreferrer'
    >
      Upgrade AWS CloudFormation stack
    </Button>
  )
}

const CopyButton = ({ msg }) => {
  return <IconButton title='Copy' icon={<CopyIcon />} className={styles.copyBtn} onClick={() => message.success(msg)} />
}

const UpgradeInstructions = ({ region, onCFLinkClick, accountId }) => {
  const url = `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks`
  return (
    <>
      <Typography.Paragraph>
        1. <a onClick={onCFLinkClick} href={url} target='_blank' rel='noopener noreferrer'>Go to CloudFormation <FontAwesomeIcon icon={faExternalLink} /></a> and find the dashbird stack (named <i>dashbird-delegation</i> by default) and click <b>Update</b>
      </Typography.Paragraph>
      <Typography.Paragraph>2. Choose <b>Replace current template</b></Typography.Paragraph>
      <CopyToClipboard text={CLOUDFORMATION_URL}>
        <Typography.Paragraph>
          3. Put <i>{CLOUDFORMATION_URL}</i> as the S3 template URL. <CopyButton msg='Template URL copied' />
        </Typography.Paragraph>
      </CopyToClipboard>
      <CopyToClipboard text={accountId}>
        <Typography.Paragraph>
          4. Insert your account ID as the <b>DashbirdAccountId</b> parameter if empty: <i>{accountId}</i>  <CopyButton msg='Account ID copied' />
        </Typography.Paragraph>
      </CopyToClipboard>
      <Typography.Paragraph>5. Deploy the stack</Typography.Paragraph>
    </>
  )
}

const UpgradeStack = ({ delegation, onRefresh, isReLoading, showReload, onUpgrade }) => {
  const hasStackId = delegation?.stackId

  const region = extractCFStackRegion(delegation) || 'us-east-1'
  return (
    <>
      <Alert
        type='warning'
        message='The Dashbird stack you have deployed is out of date'
        showIcon
        description={
          <>
            <Typography.Paragraph>To continue using Dashbird to the fullest extent, please upgrade your CloudFormation stack</Typography.Paragraph>
            {hasStackId
              ? <UpgradeButton stackId={delegation.stackId} onClick={onUpgrade} region={region} />
              : <UpgradeInstructions onCFLinkClick={onUpgrade} region={region} accountId={delegation.account} />
            }
          </>
        }
      />
      {showReload &&
        <Button
          onClick={onRefresh}
          className={styles.reloadBtn}
          icon={<RefetchIcon spin={isReLoading} className={styles.refetchIcon} />}
        >
          Check CloudFormation stack upgrade status
        </Button>
      }
      <Divider />
    </>
  )
}

export default UpgradeStack
