import React from 'react'

import PrettyPrinter from 'components/json-pretty'
import Metadata from 'components/inventory/metadata'

const SNSMetadata = ({ attributes, inverse }) => {
  const arn = attributes.TopicArn
  const subscriptions = attributes.SubscriptionsConfirmed
  const displayName = attributes.DisplayName

  const items = [{
    title: 'ARN', value: arn
  }, {
    title: 'Subscriptions', value: subscriptions
  }, {
    title: 'Display name', value: displayName || '-'
  }, {
    title: 'Policy', value: <PrettyPrinter jsonString={JSON.parse(attributes.Policy)} collapsed={false} />
  }]

  return <Metadata items={items} inverse={inverse} />
}

export default SNSMetadata
