import { API } from 'actions/api'

const mutations = ({ dispatch, account, integration }) => {
  const updateAction = API.accounts.id(account.id).delegations.id(integration.delegation.id).integrations.id(integration.id).put
  return {
    update: (attributes) => {
      const payload = { ...attributes }
      if (payload.enabled === false) {
        payload.insights = false
      }
      dispatch(updateAction(payload))
    },
    regions: {
      enable: (region) => {
        const { names, tags, regions } = integration.configuration || {}
        const updated = regions.concat(region)
        const payload = { configuration: { names, tags, regions: updated } }
        dispatch(updateAction(payload))
      },
      disable: (region) => {
        const { names, tags, regions } = integration.configuration || {}
        const updated = regions.filter(item => item !== region)
        const payload = { configuration: { names, tags, regions: updated } }
        dispatch(updateAction(payload))
      },
      blacklist: {
        remove: (region) => {
          const payload = { isBlacklisted: false, region }
          dispatch(API.accounts.id(account.id).delegations.id(integration.delegation.id).integrations.id(integration.id).blacklist.put(payload))
        }
      }
    },
    filters: {
      names: {
        update: (lists) => {
          const { names, tags, regions } = integration.configuration || {}
          dispatch(updateAction({
            configuration: {
              names: {
                whitelist: names?.whitelist,
                blacklist: names?.blacklist,
                ...lists
              },
              tags,
              regions
            }
          }))
        }
      },
      tags: {
        update: (lists) => {
          const { names, tags, regions } = integration.configuration || {}
          dispatch(updateAction({
            configuration: {
              tags: {
                whitelist: tags?.whitelist,
                blacklist: tags?.blacklist,
                ...lists
              },
              names,
              regions
            }
          }))
        }
      }
    }
  }
}

export default mutations
