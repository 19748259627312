// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SnCHXRyGHq1J5QBdlTlb {\n  background: none;\n}\n.IWAwIwLAPCa7kvO2UAMe {\n  color: #7ed321;\n}\n.cQBPy84vz1iJfHmgZEgv {\n  color: #e9e9e9;\n}\n", "",{"version":3,"sources":["webpack://./src/components/billing/stripe/invoice/indicator/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAIA;EACE,cAAA;AAFF;AAKA;EACE,cAAA;AAHF","sourcesContent":["@import '../../../../../styles/main';\n\n.avatar {\n  background: none;\n}\n\n.complete {\n  color: @named-colors[apple-green];\n}\n\n.uncomplete {\n  color: @named-colors[very-light-gray];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "SnCHXRyGHq1J5QBdlTlb",
	"complete": "IWAwIwLAPCa7kvO2UAMe",
	"uncomplete": "cQBPy84vz1iJfHmgZEgv"
};
export default ___CSS_LOADER_EXPORT___;
