import { useHistory } from 'react-router-dom'

import { useAccountRoutes } from 'containers/routes'
import { WIDGET_KIND } from 'lib/dashboards-constants'
import { getMetricNamespace, getWidgetLayout } from 'lib/dashboards-helpers'
import {
  useDeleteDashboard,
  useUpdateDashboardQuery,
  useCreateWidgetQuery,
  useDeleteWidgetQuery,
  useUpdateWidgetQuery
} from 'hooks/api'

const formatStatistic = (stat) => {
  if (Array.isArray(stat)) return stat
  if (stat === 'avg') return ['avg', 'min', 'max']
  else return [stat]
}
// TODO: extract somewhere
export const formatWidgetPayload = ({ widgets, creating, kind, data }) => {
  let definition = {}

  switch (kind) {
    case WIDGET_KIND.METRICS:
      definition = {
        metrics: data.payloads
          .filter(payload => !!payload)
          .map(payload => ({
            resourceType: payload?.resourceType,
            namespace: getMetricNamespace(kind, payload?.resourceType, payload?.metric),
            ...(payload.targets && { resource: payload.targets[0] }),
            ...(payload.stats && { stats: formatStatistic(payload.stats) }),
            ...(payload.metric && { metric: payload.metric })
          }))
      }
      break

    case WIDGET_KIND.INVOCATIONS:
      definition = {
        service: 'lambda',
        targets: data.targets,
        ...(data.flag && { flag: data.flag })
      }
      break

    case WIDGET_KIND.EVENTS:
      definition = {
        type: data.type?.length ? data.type : ['error', 'insight', 'alarm'],
        ...(data?.targets?.length && { targets: data.targets })
      }
      break

    case WIDGET_KIND.TEXT:
      definition = {
        text: data?.text,
        size: data?.size
      }
      break
  }

  return {
    ...(creating && { kind }),
    ...(creating && { layout: getWidgetLayout(widgets, kind === WIDGET_KIND.TEXT && { height: 1 }) }),
    ...(data?.name && { name: `${data.name} copy` }),
    definition
  }
}

const useMutations = ({ dashboard, widgets }) => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { mutate: deleteDashboard } = useDeleteDashboard()
  const { mutate: updateDashboard } = useUpdateDashboardQuery()
  const { mutate: createWidget, mutateAsync: createWidgetAsync } = useCreateWidgetQuery()
  const { mutate: deleteWidget } = useDeleteWidgetQuery()
  const { mutate: updateWidget } = useUpdateWidgetQuery()

  const handlers = {
    remove: (dashboardId) => {
      deleteDashboard(dashboardId)
      history.push(routes.dashboards.url())
    },

    update: {
      title: (value) => {
        updateDashboard({ name: value })
      },

      layout: (layout) => {
        const changedWidgets = layout.filter((widget) => {
          const oldWidget = widgets.find(x => x.id === widget.i)

          if (!oldWidget) return false
          if (oldWidget.kind === WIDGET_KIND.PLACEHOLDER) return false
          if (widget.x !== oldWidget.layout.x || widget.y !== oldWidget.layout.y) return true
          if (widget.w !== oldWidget.layout.width || widget.h !== oldWidget.layout.height) return true

          return false
        })

        changedWidgets.forEach(widget => {
          const payload = {
            layout: {
              x: widget.x,
              y: widget.y,
              width: widget.w,
              height: widget.h
            }
          }

          updateWidget({ widgetId: widget.i, payload })
        })
      }
    },

    widgets: {
      add: (kind, data) => {
        const payload = formatWidgetPayload({ widgets, creating: true, kind, data })
        console.log('🚀 ~ file: use-mutations.js:119 ~ useMutations ~ payload', payload)
        createWidget(payload)
      },

      delete: (widget) => {
        deleteWidget(widget.id)
      },

      // This handler opens an edit drawer
      // This handler is called when user clicks Edit button in widget dropdown menu
      edit: (widget) => {
        history.push({ pathname: routes.dashboards.widget.url({ dashboardId: dashboard.id, widgetId: widget.id }) })
      },

      update: (widget, data) => {
        const payload = formatWidgetPayload({ creating: false, kind: widget.kind, data })
        updateWidget({ widgetId: widget.id, payload })
      },

      updateName: (widget, name) => updateWidget({ widgetId: widget.id, payload: { name } }),

      duplicate: async (widget) => {
        const formatDuplicatePayload = (widget) => {
          switch (widget.kind) {
            case WIDGET_KIND.METRICS:
              return {
                payloads: widget?.definition?.metrics?.map(metric => ({
                  resourceType: metric.resourceType,
                  metric: metric.metric,
                  targets: [metric.resource],
                  stats: metric.stats
                }))
              }

            case WIDGET_KIND.INVOCATIONS:
              return widget.definition
            case WIDGET_KIND.EVENTS:
              return widget.definition
            default: return {}
          }
        }

        const data = {
          ...formatDuplicatePayload(widget),
          ...(widget?.name && { name: widget.name })
        }

        const payload = formatWidgetPayload({ widgets, creating: true, kind: widget.kind, data })

        try {
          const data = await createWidgetAsync(payload)
          handlers.widgets.edit(data)
        } catch { }
      }
    }
  }

  return handlers
}

export default useMutations
