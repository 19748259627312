import { useHistory, useLocation } from 'react-router-dom'
import querystring from 'query-string'
import { useMemo } from 'react'

const OPTIONS = { arrayFormat: 'comma' }

export const parseQueryString = (search) => querystring.parse(search, OPTIONS)
export const stringifyQuery = (newQuery) => querystring.stringify(newQuery, OPTIONS)

export const getQueryStringValue = (key, query, initialValue = '') => {
  const values = parseQueryString(query)

  if (!values[key]) return initialValue

  if (Array.isArray(initialValue) && typeof values[key] === 'string') return [values[key]]

  return values[key]
}

export const parseQuery = (query) => {
  const queryParams = parseQueryString(query)

  return Object.keys(queryParams).reduce((acc, key) => {
    if (typeof queryParams[key] === 'string' && key !== 'search') {
      acc[key] = [queryParams[key]]
    } else acc[key] = queryParams[key]
    return acc
  }, {})
}

const useQueryParams = (parameterName, initialValue = '') => {
  const { pathname, search } = useLocation()
  const history = useHistory()

  const queryParams = parseQueryString(search)

  const setValue = (val) => {
    const updatedQuery = { ...queryParams, [parameterName]: val }

    const newUrl = querystring.stringifyUrl({
      url: pathname,
      query: updatedQuery
    }, OPTIONS)

    history.replace(newUrl)
  }

  const values = useMemo(() => ({
    getValue: getQueryStringValue(parameterName, search, initialValue),
    setValue
  }), [search, pathname])

  return values
}

export default useQueryParams
