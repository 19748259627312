import React from 'react'

import classnames from 'classnames'
import styles from './styles.less'

export const TypographyParagraph = ({ className, dark, darker, children, size, align, uppercase, bold, ...props }) => {
  return (
    <p className={classnames(styles.paragraph, className, {
      [styles[size]]: size,
      [styles.bold]: bold,
      [styles.text_dark]: dark,
      [styles.text_darker]: darker,
      [styles.center]: align === 'center',
      [styles.uppercase]: uppercase
    })} {...props}>
      {children}
    </p>)
}
