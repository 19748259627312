import React from 'react'
import { useParams } from 'react-router-dom'

import Lambda from './lambda'
import RequestContent from './appsync-request'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

const InvocationDetails = props => {
  const { type } = props
  const { invocationHash } = useParams()

  switch (type) {
    case INVENTORY_SERVICES.Lambda.id:
      return <Lambda {...props} hash={props.hash ? props.hash : invocationHash} />
    case INVENTORY_SERVICES.AppSyncGraphQLApi.id:
      return <RequestContent {...props} hash={props.hash ? props.hash : invocationHash} />
    default:
      return <div>Not implemented</div>
  }
}

export default InvocationDetails
