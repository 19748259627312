import React from 'react'

import classnames from 'classnames'
import styles from './styles.less'

export const TypographyTitle = ({ level, className, upperCase, children, ...props }) => {
  if (level === 1) return <h1 {...props} className={classnames(styles.title, className)}>{children}</h1>
  if (level === 2) return <h2 {...props} className={classnames(styles.title, className, { [styles.uppercase]: upperCase })}>{children}</h2>
  if (level === 3) return <h3 {...props} className={classnames(styles.title, className)}>{children}</h3>
  if (level === 4) return <h4 {...props} className={classnames(styles.title, className, { [styles.uppercase]: upperCase })}>{children}</h4>
  return <h1 {...props}>{children}</h1>
}
