import React from 'react'
import classnames from 'classnames'
import { Row, Col } from 'antd'
import { Typography } from 'components/typography'

import styles from './styles.less'

const MarketingColumnLayout = ({ children, title, subtitle, view, hexagons = null, cta }) => {
  return (
    <Row type='flex' className={styles.wrapper}>
      <Col sm={24} lg={8} className={styles.container_left}>
        <div className={styles.birds_left} />
        <div className={styles.content_wrapper}>
          <div className={styles.logo}>
            <img src='/static/dashbird-symbol@2x.png' alt='dashbird.io' />
            <img src='/static/dashbird-wordmark@2x.png' alt='dashbird.io' className={styles.logo_word} />
          </div>
          <div className={styles.content}>
            {children}
          </div>
        </div>
      </Col>
      <Col sm={0} lg={16} className={styles.container_right}>
        <div className={styles.pattern} />
        <div className={styles.birds} />
        <div className={classnames(styles.img, { [styles[view]]: !!view })} />
        <div className={classnames(styles.text_wrapper, {
          [styles.top]: view !== 'auth'
        })}>
          <Typography.Title level={1} className={styles.title}>{title}</Typography.Title>
          <Row gutter={8} align='middle'>
            <Col span={hexagons ? 8 : 0}>
              {hexagons}
            </Col>
            <Col span={hexagons ? 16 : 24}>
              <Typography.Paragraph className={styles.subtitle}>{subtitle}</Typography.Paragraph>
              {cta && <a href={cta?.url} className={styles.cta} target='_blank' rel='noopener noreferrer'>{cta.text}</a>}
            </Col>
          </Row>
        </div>
        <div className={styles.logos_wrapper}>
          <img src="/static/soc-logo.png" className={styles.marketing} />
          <img src="/static/gartner.png" className={styles.marketing} />
          <img src="/static/aws.png" className={styles.marketing} />
        </div>
      </Col>
    </Row>
  )
}

export default MarketingColumnLayout
