import React, { useEffect } from 'react'
import { Switch, useLocation } from 'react-router-dom'

import SplitPane from 'components/split-pane'
import Menu from './menu'
import Profile from './user/profile'
import Security from './user/security'
import Integrations from './account/integrations'
import User from './organization/users/user'
import UserPermissions from './organization/users'
import Delegation from './account/integrations/delegation'
import Integration from './account/integrations/integration'
import Notifications from './account/notifications'
import NotificationChannel from './account/notifications/channel'
import NotificationPolicy from './account/notifications/policy'
import ManageAccounts from './manage-accounts'
import Account from './manage-accounts/account'
import Payment from './billing/payment'
import PaymentV2 from './organization/payment'
import EditPayment from './organization/payment/edit-billing'
import Invoices from './billing/invoices'
import InvoicesV2 from './organization/invoices'
import UsageReport from './usage'
import SubscriptionsManagement from './billing/subscriptions'
import SubscriptionsManagementV2 from './organization/billing-v2'
import CreateDelegation from './account/integrations/delegation/create-delegation'
import { accountRedirects } from '../../routes'

import { ROUTES } from 'containers/routes'
import { useOrganization } from 'hooks/context/organization-context'
import Invitations from 'containers/settings/user/invitations'

const route = ROUTES.settings

const Settings = () => {
  const { search } = useLocation()
  const { account: currentAccount, organization } = useOrganization()

  const billingV2 = organization?.metadata?.billing === 'v2'

  const IS_ADMIN = currentAccount && currentAccount.role === 'admin'
  const IS_OWNER = currentAccount && currentAccount.role === 'owner'

  const routes = [
    { path: route.profile.path, component: Profile },
    { path: route.invitations.path, component: Invitations },
    { path: route.security.path, component: Security },
    { path: route['manage-accounts'].path, component: ManageAccounts },
    { path: route.notifications.channels.new.path, component: NotificationChannel },
    { path: route.notifications.channels.channel.path, component: NotificationChannel },
    { path: route.notifications.policies.policy.path, component: NotificationPolicy },
    { path: route.notifications.path, component: Notifications },
    { path: route.path, component: Profile }
  ]

  const adminRoutes = [
    { path: route.integrations.integration.path, component: Integration },
    { path: route.integrations.new.path, component: CreateDelegation },
    { path: route.integrations.delegation.path, component: Delegation },
    { path: route.integrations.path, component: Integrations },
    { path: route.users.user.path, component: User },
    { path: route.users.path, component: UserPermissions },
    ...routes
  ]

  const ownerRoutes = [
    { path: route['manage-accounts'].account.path, component: Account },
    // TODO: Add check for billing version later
    { path: route.subscriptions.path, component: billingV2 ? SubscriptionsManagementV2 : SubscriptionsManagement },
    { path: route.payment.billing.path, component: billingV2 ? EditPayment : null },
    { path: route.payment.path, component: billingV2 ? PaymentV2 : Payment },
    { path: route.invoices.path, component: billingV2 ? InvoicesV2 : Invoices },
    { path: route.usage.path, component: UsageReport },
    ...adminRoutes
  ]

  const availableRoutes = IS_OWNER
    ? ownerRoutes
    : IS_ADMIN
      ? adminRoutes
      : routes

  useEffect(() => { document.title = 'Dashbird.io - Settings' }, [])

  return <SplitPane
    size='small'
    left={<Menu />}
    right={
      <Switch>
        {accountRedirects(currentAccount, availableRoutes, search)}
      </Switch>
    }
  />
}

export default Settings
