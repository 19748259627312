import React from 'react'
import { Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { isEmpty, flatten, uniqBy } from 'lodash'
import { formatDistanceToNowStrict } from 'date-fns'
import { useErrorQuery } from 'hooks/api'
import classnames from 'classnames'
import { BugIcon } from 'components/icons'
import SeverityBadge from 'components/badge/severity'
import styles from './styles.less'

const Loading = <Skeleton active title={false} paragraph={{ rows: 2 }} />

const ResolverError = ({ item }) => {
  if (!item) return Loading

  const { data: event, isLoading } = useErrorQuery(item.hash)
  if (!event || isEmpty(event) || isLoading) return Loading

  return <Link to={`/errors/${item.hash}`} className={classnames(styles.item)}>
    <div className={classnames(styles.icon, event.severity === 'warning' && styles.warning)}>
      <BugIcon/>
    </div>
    <div className={styles.requestid}>{event.errorMessage}</div>
    <SeverityBadge severity={event.severity} text={event.count} />
    <div className={styles.timestamp}>{formatDistanceToNowStrict(event.lastOccurredAt)} ago</div>
  </Link>
}

const ResolverErrors = ({ loading, resolvers }) => {
  if (loading || !resolvers) return null
  const errors = uniqBy(flatten(resolvers.map(resolver => resolver.errors)), e => e.hash)

  return (
    errors && errors.map((error, index) =>
      <ResolverError key={index} item={error} />
    )
  )
}

export default ResolverErrors
