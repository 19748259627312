import React from 'react'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import styles from './styles.less'

const StatusIcon = ({ enabled } = {}) => {
  const icon = enabled ? faCheck : faTimes
  const className = enabled ? styles.enabled : styles.disabled
  const text = enabled ? 'Enabled' : 'Disabled'
  return (
    <Tooltip title={text}>
      <div className={className}>
        <FontAwesomeIcon icon={icon} color='#e9e8e8' />
      </div>
    </Tooltip>
  )
}

export default StatusIcon
