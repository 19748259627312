import React from 'react'
import { Link } from 'react-router-dom'

export const MSG_INVITATION_NOT_FOUND = {
  title: 'Invitation Not Found',
  message: 'Unfortunately, we did not find an invitation with this email and token, please log in or contact your administrator!'
}

export const MSG_INVITATION_EXPIRED = {
  title: 'Invitation Expired',
  message: 'This invitation has expired, please contact your administrator for a new invitation!'
}

export const MSG_INVITATION_ERROR = {
  title: 'Error',
  message: 'There has been an error with retrieving your invitation, please contact your administrator!'
}

export const MSG_VERIFICATION_LINK = {
  title: 'Verification Link',
  message: 'We have sent you a verification link, please check your email and verify your identity! Once you have completed verification, you can log in.'
}

export const MSG_RESENT_VERIFICATION_LINK = {
  title: 'Resent Verification Link',
  message: 'We have resent you a verification link, please check your email and verify your identity! Once you have completed verification, you can log in.'
}

export const MSG_VERIFICATION_SUCCEEDED = {
  title: 'Verification Succeeded',
  message: (countdown) => `We have successfully verified your email! You will be redirected to login in ${countdown / 1000}s.`
}

export const MSG_USER_ALREADY_EXISTS = {
  title: 'User Already Exists',
  message: 'Please log in with your credentials to accept the invite!'
}

export const getSignupUserAlreadyExistsMessage = (email) => {
  return {
    title: 'User Already Exists',
    message: <>Please <Link to={`/auth/login?email=${email}`}>sign in</Link> with your credentials!</>,
    type: 'error'
  }
}

export const MSG_PASSWORD_RESET_REQUIRED = {
  title: 'Password Reset Required',
  message: 'We have sent you a password reset link, please check your email and reset your password!'
}

export const MSG_RESENT_PASSWORD_RESET_LINK = {
  title: 'Resent Password Reset Link',
  message: 'We have resent you a password reset link, please check your email and reset your password!'
}

export const MSG_PASSWORD_CHANGE_REQUIRED = {
  title: 'Password Change Required',
  message: 'Please choose and confirm your new password!'
}
