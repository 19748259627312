import React, { useRef } from 'react'
import { get, isEmpty, reduce, omitBy, isNil } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'

import { GET_SUBSCRIPTIONS, PUT_SUBSCRIPTION, DELETE_SUBSCRIPTION } from 'actions/billing.actions'
import { API } from 'actions/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const createSubscriptionBody = (values = {}, prices) => {
  return reduce(values, (carry, value, key) => {
    if (!value || value === '') return carry
    const body = key === 'promotion' ? value : { quantity: value, price: get(prices, [key, 'id']) }
    carry[key] = body
    return carry
  }, {})
}

const updateSubscriptionBody = (values = {}, subscription, prices) => {
  return reduce(values, (carry, value, key) => {
    if (!value || value === '') return carry
    if (key === 'promotion') {
      carry[key] = value
    } else {
      const existingItem = get(subscription, ['items', key], {})
      if (value === existingItem.quantity) return carry
      carry[key] = omitBy({
        id: existingItem.stripeId,
        quantity: value,
        price: get(prices, [key, 'id'])
      }, isNil)
    }
    return carry
  }, {})
}

const ReservedSubscription = ({ price = { id: 'test' }, usage, onOk } = {}) => {
  if (isEmpty(price)) return null

  const { organization } = useOrganization()

  const reservedFormRef = useRef()

  const dispatch = useDispatch()

  const loadingSubscriptions = useSelector(state => state.waiting.list[GET_SUBSCRIPTIONS])
  const subscriptions = useSelector(state => get(state, 'billing.subscriptions.list'))
  const subscription = get(subscriptions, 'reserved', {})

  const buyReserved = async () => {
    const values = await reservedFormRef.current.validateFields().then(values =>
      Promise.resolve(values)
    ).catch(errorInfo => {
      console.log(errorInfo)
      return Promise.resolve(null)
    })
    if (!values) return

    if (!subscription.stripeId) {
      try {
        const body = createSubscriptionBody(values, price)
        dispatch(API.organizations.id(organization.id).billing.subscriptions.post(body))
        return onOk && onOk()
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const body = updateSubscriptionBody(values, subscription, price)
        dispatch(API.organizations.id(organization.id).billing.subscriptions.id(subscription.stripeId).put({ ...body, type: 'reserved' }))
        return onOk && onOk()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const cancelItem = async (subscriptionId, subscriptionItemId, itemType) => {
    if (!subscriptionId || !subscriptionItemId) return
    try {
      dispatch(API.organizations.id(organization.id).billing.subscriptions.id(subscriptionId).items.id(subscriptionItemId).delete(itemType))
      return onOk && onOk()
    } catch (error) {
      console.log(error)
    }
  }

  const cancelReserved = async () => {
    const subscriptionId = subscription.stripeId
    if (!subscriptionId) return
    try {
      dispatch(API.organizations.id(organization.id).billing.subscriptions.id(subscriptionId).delete())
      return onOk && onOk()
    } catch (error) {
      console.log(error)
    }
  }

  const updatingSubscription = useSelector(state => state.waiting.list[PUT_SUBSCRIPTION])
  const deletingSubscription = useSelector(state => state.waiting.list[DELETE_SUBSCRIPTION])

  const loading = loadingSubscriptions || updatingSubscription || deletingSubscription
  return (
    <>
      <Form
        loading={loading}
        className={styles.topMargin}
        subscription={subscription}
        usage={usage}
        price={price}
        ref={reservedFormRef}
        onSubmit={buyReserved}
        onCancel={cancelReserved}
        cancelSubscriptionItem={cancelItem}
      />
    </>
  )
}

export default ReservedSubscription
