// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MheH30zmQ7nwR_oL0X09 {\n  margin-top: 30px;\n}\n.ykuA1TBDOiGXxac2nHaX,\n.ryxO46tGISe6RLBakc3H {\n  margin-bottom: 18px;\n}\n.fTNNpbxh4moKNPAfoLRY {\n  white-space: pre-wrap;\n  background: rgba(0, 0, 0, 0.06);\n  padding: 5px;\n  border: 1px solid rgba(0, 0, 0, 0.06);\n  border-radius: 3px;\n}\n.L5D8OjTXc59HU2rghx6p {\n  margin-right: 12px;\n}\n.HIfZUO_TjnmOTPTd9NEM input {\n  max-width: 100px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/settings/user/security/mfa-setup/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAIA;;EAEE,mBAAA;AAFF;AAKA;EACE,qBAAA;EACA,+BAAA;EACA,YAAA;EACA,qCAAA;EACA,kBAAA;AAHF;AAMA;EACE,kBAAA;AAJF;AAOA;EAEI,gBAAA;AANJ","sourcesContent":["@import '../../../../../styles/main';\n\n.container{\n  margin-top: @spacing[largest];\n}\n\n.steps,\n.qr {\n  margin-bottom: @spacing[large];\n}\n\n.code {\n  white-space: pre-wrap;\n  background: rgba(0, 0, 0, 0.06);\n  padding: 5px;\n  border: 1px solid rgba(0, 0, 0, 0.06);\n  border-radius: 3px;\n}\n\n.back_button {\n  margin-right: @spacing[normal];\n}\n\n.mfa {\n  input {\n    max-width: 100px;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MheH30zmQ7nwR_oL0X09",
	"steps": "ykuA1TBDOiGXxac2nHaX",
	"qr": "ryxO46tGISe6RLBakc3H",
	"code": "fTNNpbxh4moKNPAfoLRY",
	"back_button": "L5D8OjTXc59HU2rghx6p",
	"mfa": "HIfZUO_TjnmOTPTd9NEM"
};
export default ___CSS_LOADER_EXPORT___;
