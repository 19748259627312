import React from 'react'
import { ResponsiveContainer } from 'recharts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons'

import styles from './styles.less'

const Disclaimer = ({ height, icon = faEmptySet, text = '' }) => {
  return (
    <ResponsiveContainer className={styles.container} height={height} width='100%' debounce={300}>
      <div className={styles.wrapper}>
        <FontAwesomeIcon className={styles.icon} size='3x' icon={icon} />
        <h3>{text}</h3>
      </div>
    </ResponsiveContainer>
  )
}

export default Disclaimer
