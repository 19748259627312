import React from 'react'
import { Button, Card, Row, Col, Typography } from 'antd'
import styles from './styles.less'

const { Title } = Typography

const CONTACT_PAGE = 'https://sls.dashbird.io/meetings/mart6'

const openContactPage = () => {
  const newWindow = window.open(CONTACT_PAGE, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const ContactSales = ({ className } = {}) => {
  return (
    <Card className={className}>
      <Row type='flex' justify='center' gutter={[0, 64]}>
        <Col><Title level={3}>Have questions?</Title></Col>
      </Row>
      <Row type='flex' justify='center' gutter={[0, 64]} className={styles.buttonRow}>
        <Col span={16}>
          <Button type='primary' block onClick={() => openContactPage()}>Talk to sales</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ContactSales
