import React from 'react'
import classnames from 'classnames'
import { Tooltip } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import MinimalInput from 'components/minimal-input'

import styles from './styles.less'

const Name = ({ value, onClick, className, disabled }) => {
  return (
    <Tooltip mouseEnterDelay={1} title={value}>
      <div className={classnames(styles.nameContainer, className, { [styles.disabled]: disabled, [styles.clickable]: !!onClick })} onClick={onClick}>
        <div className={styles.value}>{value}</div>
      </div>
    </Tooltip>
  )
}

class NameFormField extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      value: props.value,
      editing: !props.value,
      closeTimeout: null
    }
  }

  toggleEdit () {
    const { editing } = this.state
    this.setState({ editing: !editing })
  }

  static getDerivedStateFromProps (props, state) {
    if (!state.editing) {
      return props.value
    }
    return null
  }

  onBlur () {
    const { onChange, isNew } = this.props
    const { value } = this.state
    if (!value) return

    const newState = {
      editing: isNew,
      value: !isNew ? value : ''
    }

    this.setState({
      closeTimeout: setTimeout(() => this.setState(newState, () => onChange(value)), 50)
    })
  }

  onFocus () {
    clearTimeout(this.state.closeTimeout)
  }

  render () {
    const { onChange, isNew } = this.props
    const { value, editing } = this.state

    const valChanged = e => this.setState({ value: e.target.value })
    const deleteName = () => onChange(null)

    if (!editing) {
      return <Name value={value} className={styles.clickable} onClick={this.toggleEdit.bind(this)} />
    }

    return (
      <div className={styles.flexWrapper}>
        <div className={classnames(styles.nameContainer, styles.editName)} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)}>
          <MinimalInput
            placeholder='name'
            className={styles.value}
            value={value}
            onChange={valChanged} />
          {editing && !isNew && <FontAwesomeIcon onClick={deleteName} className={styles.delete} icon={faTimes} />}
        </div>
      </div>
    )
  }
}

export default NameFormField
