import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'

import Content from 'components/layout/content'
import EditAccount from './edit-account'
import { useAccountRoutes } from 'containers/routes'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery, useUpdateAccountQuery } from 'hooks/api'

const findSelectedAccount = (organization = {}, accountId) => {
  const accounts = organization.accounts || []
  return accounts.find(account => account.id === accountId) || {}
}

const Account = () => {
  const history = useHistory()
  const { id: accountId } = useParams()
  const routes = useAccountRoutes()

  const { organization: currentOrg } = useOrganization()
  const { data: organizations } = useOrganizationsQuery()
  const { mutateAsync: editAccount, isLoading: saving } = useUpdateAccountQuery()

  const activeOrg = organizations.find(item => item.organization.id === currentOrg.id)
  const selectedAccount = findSelectedAccount(activeOrg, accountId)

  const editAccountName = async (payload) => {
    try {
      editAccount(payload)
      history.goBack()
    } catch { }
  }

  const removeAccount = async () => {
    message.success('Account deleted')
    history.goBack()
  }

  return (

    <Content title='Edit account' backRoute={routes.settings['manage-accounts'].url()} item breadcrumbs={['Settings', 'Manage accounts', 'edit']}>
      <EditAccount account={selectedAccount} saveAccount={editAccountName} saving={saving} />
    </Content>
  )
}

export default Account
