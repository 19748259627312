import React from 'react'
import { Row, Col } from 'antd'

import useQueryParams from 'lib/hooks/use-query-params'

import Content from 'components/layout/content'
import Pillars from './pillars'
import SeverityGroups from './severity-groups'
import ServicesList from './services'
import RegionsList from './regions'
import DelegationsList from './delegations'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { useInsightsQuery, useInsightsRulesQuery } from 'hooks/api'

const InsightsDashboard = () => {
  const { data: rules, isLoading: rulesLoading } = useInsightsRulesQuery()
  const { data: insights, isLoading } = useInsightsQuery()

  const loading = isLoading || rulesLoading

  const { getValue: selectedPillars, setValue: setSelectedPillars } = useQueryParams('pillar', [])
  const { getValue: selectedSeverities, setValue: setSelectedSeverities } = useQueryParams('severity', [])
  const { getValue: selectedServices, setValue: setSelectedServices } = useQueryParams('service', [])
  const { getValue: selectedRegions, setValue: setSelectedRegions } = useQueryParams('region', [])
  const { getValue: selectedDelegations, setValue: setSelectedDelegations } = useQueryParams('delegation', [])

  const onPillarSelect = (item) => {
    setSelectedPillars(filterHelper(item, selectedPillars))
  }

  const onSeveritySelect = (item) => {
    setSelectedSeverities(filterHelper(item, selectedSeverities))
  }

  const onServicesSelect = (item) => {
    setSelectedServices(filterHelper(item, selectedServices))
  }

  const onRegionsSelect = (item) => {
    setSelectedRegions(filterHelper(item, selectedRegions))
  }

  const onDelegationsSelect = (item) => {
    setSelectedDelegations(filterHelper(item, selectedDelegations))
  }

  return (
    <Content title='Well-Architected Lens' item={{}} breadcrumbs={['well-architected lens']}>
      <Pillars rules={rules || []} loading={loading} selected={selectedPillars} onSelect={onPillarSelect} />
      <SeverityGroups rules={rules || []} loading={loading} selected={selectedSeverities} onSelect={onSeveritySelect} />
      <Row gutter={6}>
        <Col span={8}>
          <ServicesList rules={rules || []} loading={loading} selected={selectedServices} onSelect={onServicesSelect} />
        </Col>
        <Col span={8}>
          <RegionsList rules={rules || []} insights={insights} loading={loading} selected={selectedRegions} onSelect={onRegionsSelect} />
        </Col>
        <Col span={8}>
          <DelegationsList rules={rules || []} insights={insights} loading={loading} selected={selectedDelegations} onSelect={onDelegationsSelect} />
        </Col>
      </Row>
    </Content>
  )
}

export default InsightsDashboard
