import React from 'react'
import classnames from 'classnames'
import { ReactSVG } from 'react-svg'
import { groupBy, isEmpty } from 'lodash'

import { objectify } from 'lib/utils'
import Items from 'components/inventory/items'
import { RedirectIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const Endpoints = ({ loading, endpoints }) => {
  if (loading) return null

  const formatted = endpoints?.map((endpoint) => {
    const attributes = objectify(endpoint.attributes)

    const method = attributes.method || attributes?.routeKey?.split('/')[0].trim()
    const isLambda = attributes?.integrationUri?.startsWith('arn:aws:lambda') || attributes.functionName

    return {
      id: endpoint.id,
      title: attributes.path || endpoint.name.replace(method, ''),
      icon: isLambda
        ? <ReactSVG src={'/static/vendor/icons/lambda.svg'} className={styles.icon} />
        : endpoint.type === 'AWS::ApiGatewayV2::Endpoint'
          ? <RedirectIcon />
          : null,
      iconTitle: isLambda ? `Lambda name: ${attributes?.functionName}` : `Redirection to: ${attributes?.integrationUri}`,
      authorizer: attributes?.authorizationType !== 'NONE' ? attributes.authorizationType : null,
      apiKey: attributes?.apiKeyRequired !== 'false' ? attributes.apiKeyRequired : null,
      method: <span className={classnames(styles.method, styles[method?.toLowerCase()])}>{method}</span>,
      httpMethod: method
    }
  })

  const grouped = groupBy(formatted, 'httpMethod')
  const type = (method) => !isEmpty(grouped[method]) ? grouped[method] : []

  const reordered = !isEmpty(grouped) ? [...type('GET'), ...type('POST'), ...type('PUT'), ...type('DELETE'), ...type('OPTIONS'), ...type('ANY')] : []

  return reordered ? <Items type='endpoint' items={reordered} /> : null
}

export default Endpoints
