import React from 'react'

import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'

import classnames from 'classnames'
import styles from './styles.less'

const renderLoading = ({ size = 'sm', className }) => {
  return (
    <button className={classnames(styles.button, styles.loading, className)}>
      <FontAwesomeIcon icon={faSpinnerThird} size={size} spin />
    </button>
  )
}

const IconButton = ({ loading, onClick, disabled, icon, className, title, titlePlacement = 'top', hidden, size, iconColor, htmlType }) => {
  if (hidden) return null
  if (loading) return renderLoading({ size, className })

  return (
    <Tooltip title={title || ''} placement={titlePlacement} trigger='hover' mouseEnterDelay={0}>
      <button
        type={htmlType || 'button'}
        disabled={disabled}
        className={classnames(styles.button, className)}
        onClick={(e) => onClick && onClick(e)}
      >
        {!React.isValidElement(icon)
          ? <FontAwesomeIcon icon={icon} size={size} {...(iconColor && { color: iconColor })} />
          : icon}
      </button>
    </Tooltip>
  )
}

export default IconButton
