import React from 'react'
import { compact } from 'lodash'

import Tag from 'components/inventory/tags/tag/form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'

import styles from './styles.less'

class TagsForm extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      adding: false
    }
  }

  renderTag (tag, index, onChange) {
    return (
      <Tag key={`${index}-${tag}`} value={tag} editing onChange={val => onChange(val, index)} />
    )
  }

  renderAddButton () {
    const { onChange, tags } = this.props
    const { adding } = this.state

    const toggleAdding = () => this.setState({ adding: !adding })
    const addTag = value => {
      if (!value) toggleAdding()
      onChange([...tags, value])
      toggleAdding()
    }

    if (adding) return <Tag onChange={addTag} />

    return (
      <div className={styles.add} onClick={toggleAdding}>
        <FontAwesomeIcon className={styles.add_icon} icon={faPlus} />
        <span>add tag</span>
      </div>
    )
  }

  render () {
    const { tags, onChange } = this.props
    const tagChanged = (val, index) => {
      onChange(compact(tags.map((tag, i) => i === index ? val : tag)))
    }

    return (
      <div className={styles.container}>
        { tags.map((tag, i) => this.renderTag(tag, i, tagChanged)) }
        { this.renderAddButton() }
      </div>
    )
  }
}

export default TagsForm
