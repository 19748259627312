export const INSIGHTS_DESCRIPTIONS = {
  'aws.apigateway.rest.latency-nearing-timeout': { content: 'API Gateway has a timeout limit of 30 seconds for all requests. This cannot be changed in the configuration. In almost all cases, APIs should be far quicker and if you see your endpoints taking such a long time to respond consistently, it is likely worth investigating the situation further. With this insight, an alarm is raised if the duration of a request is equal or higher than 25 seconds.', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-is-nearing-timeout/' },
  'aws.apigateway.rest.high-latency': { content: 'Monitors for latency increases in API Gateway endpoints. This rule looks at the past 2 days of API latency and checks if there has been a significant increase (more than 20%) during the last 24 hours.', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-high-latency/' },
  'aws.apigateway.rest.high-error-percentage': { content: 'Check for detecting high error rates in your API Gateway endpoints. Some errors are inevitable and should be handled from the application side. However, if error rates increase significantly, it is cause for concern and requires further investigation.', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-high-error-percentage' },
  'aws.apigateway.rest.abandoned': { content: 'API Gateway stage has not seen activity during the last 5 days', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-stage-is-abandoned/' },
  'aws.apigateway.rest.content-encoding-not-enabled': { content: 'Checks if the content encoding feature is enabled for API Gateway stages to compress API payloads. When encoding is enabled, API Gateway allows compression of response bodies based Accept-Encoding header. Payload compression is triggered when the response body size is greater or equal to the threshold configured as the minimum compression size.', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-encoding-not-enabled/' },
  'aws.apigateway.rest.detailed-monitoring-not-enabled': { content: 'Checks if detailed CloudWatch metrics are enabled for your API Gateway stages. The detailed CloudWatch metrics help you to get a more granular understanding of your API latency, errors and caching. This enables you to take action quickly and ensure a better service quality', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-detailed-monitoring-not-enabled/' },
  'aws.apigateway.rest.logging-not-enabled': { content: 'Checks if an API Gateway stage resource has either ERROR or INFO level logging enabled. Logs are useful for debugging activity after the fact. If your API endpoint suffers a failure or a slow response, logs can be used to pin down issues and understand the specifics or individual requests. We recommend enabling ERROR logs as the bare minimum for staging and production environments.', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-logging-is-not-enabled/' },
  'aws.apigateway.rest.tracing-not-enabled': { content: 'You can use X-Ray to trace and analyze user requests as they travel through your Amazon API Gateway APIs to the underlying services. API Gateway supports X-Ray tracing for all API Gateway endpoint types: Regional, edge-optimized, and private. You can use X-Ray with Amazon API Gateway in all AWS Regions where X-Ray is available.', url: 'https://dashbird.io/event-library/api-gateway/api-gateway-tracing-not-enabled/' },
  'aws.apigateway.rest.not-tagged': { content: '', url: '' },
  'aws.apigateway.http.high-5xx-error-percentage': { content: '', url: '' },
  'aws.apigateway.http.high-4xx-error-percentage': { content: '', url: '' },
  'aws.apigateway.http.high-latency': { content: '', url: '' },
  'aws.apigateway.http.latency-nearing-timeout': { content: '', url: '' },
  'aws.apigateway.http.abandoned': { content: '', url: '' },
  'aws.apigateway.http.not-tagged': { content: '', url: '' },
  'aws.dynamodb.read-throttles': { content: 'Checks for throttling is occuring in your DynamoDB Table. Some amount of throttling can be expected and handled by your application. However, if this occurrs frequently or you’re not sure of the underlying reasons, this calls for additional investigation.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-read-throttling-2/' },
  'aws.dynamodb.high-latency': 'SCAN, QUERY, GET, PUT, or UPDATE calls to a DynamoDB table took more than 0.8 seconds to complete',
  'aws.dynamodb.reads-near-capacity-gsi': 'Some risk for throttling was detected in your Global Secondary Index',
  'aws.dynamodb.reads-near-capacity': 'Some risk for throttling was detected in your DynamoDB Table',
  'aws.dynamodb.read-throttles-gsi': { content: 'Checks for throttling is occuring in your DynamoDB Table. Some amount of throttling can be expected and handled by your application. However, if this occurrs frequently or you’re not sure of the underlying reasons, this calls for additional investigation.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-read-throttling-2/' },
  'aws.dynamodb.writes-near-capacity-gsi': { content: 'Checks for risk of throttling in your DynamoDB Table or Global Secondary Index. Some amount of throttling can be expected and handled by your application. However, if this occurrs frequently or you’re not sure of the underlying reasons, this calls for additional investigation.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-or-gsi-near-capacity/' },
  'aws.dynamodb.writes-near-capacity': { content: 'Checks for risk of throttling in your DynamoDB Table or Global Secondary Index. Some amount of throttling can be expected and handled by your application. However, if this occurrs frequently or you’re not sure of the underlying reasons, this calls for additional investigation.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-or-gsi-near-capacity/' },
  'aws.dynamodb.write-throttles-gsi': { content: 'Checks for throttling is occuring in your DynamoDB Table. Some amount of throttling can be expected and handled by your application. However, if this occurrs frequently or you’re not sure of the underlying reasons, this calls for additional investigation.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-read-throttling-2/' },
  'aws.dynamodb.write-throttles': { content: 'Checks for throttling is occuring in your DynamoDB Table. Some amount of throttling can be expected and handled by your application. However, if this occurrs frequently or you’re not sure of the underlying reasons, this calls for additional investigation.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-read-throttling-2/' },
  'aws.dynamodb.continuous-backups': { content: 'DynamoDB can back up your data with per-second granularity and restore to any single second from the time PITR was enabled up to the prior 35 days. Checks if DynamoDB tabless have continuous backups and point in time recovery enabled. This is a good way to defend against accidental data loss on top of on-demand backups. With PITR enabled, you can restore your DynamoDB table and GSI/LSIs to any second in time in the past 35 days. This setting does not interfere with on-demand backups but rather acts as an additional layer of defence.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-continuous-backups-and-point-in-time-recovery-disabled/' },
  'aws.dynamodb.not-encrypted': { content: 'Checks for use of customer-managed CMKs to help protect sensitive applications, adhere to your organization’s policies, meet compliance and regulatory requirements, and maintain an additional secure copy of your encryption keys outside of AWS.', url: 'https://dashbird.io/event-library/aws-dynamodb/dynamodb-table-is-not-encrypted-with-a-customer-managed-cmk/' },
  'aws.dynamodb.not-tagged': { content: '', url: '' },
  'aws.ecs.cluster.cpu-nearing-limit': { content: 'ECS Cluster CPU is nearing the limit of 90% during the last hour', url: 'h' },
  'aws.ecs.cluster.reserved-memory-nearing-limit': '',
  'aws.ecs.cluster.abandoned': { content: 'ECS Cluster CPU count is zero during the last 5 days', url: '' },
  'aws.ecs.cluster.not-tagged': { content: '', url: '' },
  'aws.ecs.service.cpu-usage-high': { content: 'ECS Service CPU is nearing the threshold of 90% during the last hour', url: '' },
  'aws.ecs.service.memory-usage-low': { content: '80% of ECS Service are utilizing below 50% of available memory during the last 24h', url: '' },
  'aws.ecs.service.reserved-memory-nearing-limit': { content: 'ECS Service hard memory limit is nearing 90% during the last 2h', url: '' },
  'aws.ecs.service.not-tagged': { content: '', url: '' },
  'aws.kinesis.data-streams.abandoned': { content: 'Checks if a Kinesis Stream has seen any activity for the past 3 days. If not, it is considered to be abandoned. If a resource is no longer used and is forgotten, it is good practice to remove it entirely. If left unaddressed, abandoned resources can be a security threat and leave your system open for exploitation.', url: 'https://dashbird.io/event-library/aws-kinesis/aws-kinesis-data-stream-is-abandoned/' },
  'aws.kinesis.data-streams.high-delay': { content: 'Checks if a Kinesis Data Stream is experiencing a higher than usual outgoing message age. If the mean record age of the last 30 minutes has increased by more than 50% as compared to previous times, the situation should be checked for unexpected results.', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-has-high-delay/' },
  'aws.kinesis.data-streams.not-encrypted': { content: 'A monitor to identify unencrypted Kinesis Data Streams.', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-is-not-encrypted/' },
  'aws.kinesis.data-streams.read-throttles': { content: 'Checks if a Kinesis Data Stream has throttled.', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-reads-have-throttles/' },
  'aws.kinesis.data-streams.write-throttles': { content: 'A monitor for detecting throttling in your Kinesis Data Stream. This check runs every 30 minutes to check if any stream in your stack has been throttled during the last hour.', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-writes-have-throttles/' },
  'aws.kinesis.data-streams.shard-metrics-disabled': { content: 'Checks if shard levels CloudWatch metrics are enabled for your Kinesis Data streams. The detailed CloudWatch metrics help you to get a more granular understanding of your streams and enables you to take action quickly and ensure a better service quality.', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-shard-metrics-is-disabled/' },
  'aws.kinesis.data-streams.stream-failing': { content: 'Check for identifying records that failed to be processed in your Kinesis Data stream. This alarm monitors the incoming and outgoing record success rates and raises an alarm when a there is a time period if no records were succesfully processed.', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-is-failing/' },
  'aws.kinesis.data-streams.stream-not-worked-on': { content: 'A monitor for identifying Kinesis Streams that records are being pushed to but not processed and worked on. This insight activates when there are records coming into the stream (IncomingRecords) but none of them are being processed (OutgoingRecords).', url: 'https://dashbird.io/event-library/aws-kinesis/kinesis-data-stream-is-not-being-worked-on/' },
  'aws.kinesis.data-streams.not-tagged': { content: '', url: '' },
  'aws.kinesis.firehose.data-freshness-exceeds-buffer-limit': { content: '', url: '' },
  'aws.kinesis.firehose.not-encrypted': { content: '', url: '' },
  'aws.kinesis.firehose.throttles': { content: '', url: '' },
  'aws.kinesis.firehose.not-tagged': { content: '', url: '' },
  'aws.sqs.growing-queue': { content: 'Monitors SQS queues for increasing messages in flight. If over the past 45 minutes, there are a significant amount of items in the queue and they are continuously growing, then this insight is triggered. This check does not trigger in case there are less than 1000 items in the queue.', url: 'https://dashbird.io/event-library/aws-sqs/sqs-queue-is-growing/' },
  'aws.sqs.high-delay': { content: 'Monitors SQS queue for sudden increases to message age. Checks if an SQS queue delay has increased in the last 30 minutes by more than 50%, compared to the last 2 hours.', url: 'https://dashbird.io/event-library/aws-sqs/sqs-queue-has-high-delay/' },
  'aws.sqs.payload-size-nearing-limit': { content: 'SQS Queue items have a payload limit of 262144bytes (256 KB). If the payload pushed to the queue is of a higher size, the queue will not process that item. This insight is hourly checking for near or to the limit (90%+) payload sizes in your SQS queues.', url: 'https://dashbird.io/event-library/aws-sqs/sqs-payload-size-is-nearing-the-limit/' },
  'aws.sqs.queue-abandoned': { content: 'Checks if an SQS has seen any activity for the past 3 days. If not, the queue is considered to be abandoned. If a resource is no longer used and is forgotten, it is good practice to remove it entirely. If left unaddressed, abandoned resources can be a security threat and leave your system open for exploitation.', url: 'https://dashbird.io/event-library/aws-sqs/sqs-queue-is-abandoned/' },
  'aws.sqs.queue-is-not-worked-on': { content: 'An hourly check for cases when there are messages being pushed into the queue but not being consumed from it. If this insight is triggered, it’s possible that you have misconfigured a consumer function to your SQS queu or the consumer is not getting access to the queue.', url: 'https://dashbird.io/event-library/aws-sqs/sqs-queue-is-not-worked-on/' },
  'aws.sqs.deadletter-not-configured': 'This queue has no deadletter queue configured, which other queues (source queues) could target for messages that can’t be processed successfully',
  'aws.sqs.not-tagged': { content: '', url: '' },
  'aws.sns.topic.abandoned': { content: '', url: '' },
  'aws.sns.topic.exposed': { content: '', url: '' },
  'aws.sns.topic.not-encrypted': { content: '', url: '' },
  'aws.sns.topic.not-tagged': { content: '', url: '' },
  'aws.stepfunctions.abandoned': { content: 'Checks if a state machine has been used during the past 7 days. If a resource is no longer used and is forgotten, it is good practice to remove it entirely. If left unaddressed, abandoned resources can be a security threat and leave your system open for exploitation.', url: 'https://dashbird.io/event-library/aws-step-functions/step-functions-state-machine-abandoned/' },
  'aws.stepfunctions.failing': { content: 'Check for detecting high error rates in your Step Functions. Some errors are inevitable and should be handled from the application side. However, if error rates increase significantly, it is cause for concern and requires further investigation.', url: 'https://dashbird.io/event-library/aws-step-functions/step-function-has-a-high-error-percentage/' },
  'aws.stepfunctions.throttles': { content: 'AWS Step Functions places quotas on the sizes of certain state machine parameters, such as the number of API actions that you can make during a certain time period or the number of state machines that you can define. Although these quotas are designed to prevent a misconfigured state machine from consuming all of the resources of the system, many aren’t hard quotas. This monitor is in place to notify if your Step Functions are experiencing throttling.', url: 'https://dashbird.io/event-library/aws-step-functions/step-function-state-machine-reads-have-throttles/' },
  'aws.stepfunctions.timeouts': { content: 'A Task state either ran longer than the TimeoutSeconds value, or failed to send a heartbeat for a period longer than the HeartbeatSeconds value.', url: 'https://dashbird.io/event-library/aws-step-functions/step-function-state-machine-has-timed-out/' },
  'aws.stepfunctions.timeout-not-configured': { content: 'By default, the Amazon States Language doesn’t set timeouts in state machine definitions. Without an explicit timeout, Step Functions often relies solely on a response from an activity worker to know that a task is complete. If something goes wrong and TimeoutSeconds isn’t specified, an execution is stuck waiting for a response that will never come. To avoid this, specify a reasonable timeout when you create a task in your state machine.', url: 'https://dashbird.io/event-library/aws-step-functions/step-function-definition-task-timeout-is-not-configured/' },
  'aws.stepfunctions.not-tagged': { content: '', url: '' },
  'aws.lambda.abandoned': { content: 'Checks if a lambda has been used during the past 3 days. If a resource is no longer used and is forgotten, it is good practice to remove it entirely. If left unaddressed, abandoned resources can be a security threat and leave your system open for exploitation.', url: '' },
  'aws.lambda.function-duration-nearing-timeout': { content: 'Lambda function duration has been nearing the limit of 90% during the last 30 minutes', url: '' },
  'aws.lambda.high-error-percentage': { content: 'Check for detecting high error rates in your lambda functions. Some errors are inevitable and should be handled from the application side. However, if error rates increase significantly, it is cause for concern and requires further investigation.', url: '' },
  'aws.lambda.function-memory-nearing-limit': { content: 'Lambda function memory usage has been nearing the limit of 90% during the last two hours', url: '' },
  'aws.lambda.upgradable-runtime': { content: '', url: '' },
  'aws.lambda.not-tagged': { content: '', url: '' }
}
