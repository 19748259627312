import { differenceBy, get, isEmpty, some } from 'lodash'
import { isMatch } from 'micromatch'

const extractFilters = configuration => {
  return {
    regions: get(configuration, 'regions', []),
    tags: { whitelist: get(configuration, 'tags.whitelist', []), blacklist: get(configuration, 'tags.blacklist', []) },
    names: { whitelist: get(configuration, 'names.whitelist', []), blacklist: get(configuration, 'names.blacklist', []) }
  }
}

const applyRegionFilters = (resources, allowedRegions) => {
  return resources.filter(r => allowedRegions.includes(r.region))
}

const applyFilter = (resources, filter, getComparator) => {
  const whitelist = get(filter, 'whitelist', [])
  const blacklist = get(filter, 'blacklist', [])
  if (!whitelist.length && !blacklist.length) return resources

  return resources.filter(r => {
    const comparator = getComparator(r)

    if (!isEmpty(whitelist)) return some(whitelist, comparator)

    return !some(blacklist, comparator)
  })
}

const filterResources = (resources = [], integrationConfiguration) => {
  const { regions, tags, names } = extractFilters(integrationConfiguration)

  const inAllowedRegions = applyRegionFilters(resources, regions)
  const filteredByTags = applyFilter(
    inAllowedRegions,
    tags,
    resource => {
      const tags = get(resource, 'tags', []).map(({ name, value }) => `${name}:${value}`)
      return tag => tags.includes(tag)
    }
  )

  const filteredByNames = applyFilter(filteredByTags, names, resource => n => isMatch(resource.name, n))

  const ignored = differenceBy(resources, filteredByNames, 'id')
  return { active: filteredByNames, ignored }
}

export default filterResources
