// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y6bHpJeTeGt4BASXk5SI {\n  margin-right: 12px;\n}\n.WwgTn4VbuQHh_ooGkkKL {\n  justify-content: start;\n}\n.bFWVgXcEQHottRVCZc3x {\n  margin-bottom: 30px;\n  margin-top: 9px;\n}\n.ROHZYFFjJCwUdn_CndHd {\n  margin-top: 30px;\n}\n.uMFxVpWq8pQ3e68wtXov {\n  color: #fff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/billing/stripe/manage-subscription-modal/styles.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAIA;EACE,sBAAA;AAFF;AAKA;EACE,mBAAA;EACA,eAAA;AAHF;AAMA;EACE,gBAAA;AAJF;AAOA;EACE,WAAA;AALF","sourcesContent":["@import '../../../../styles/main';\n\n.checkMark {\n  margin-right: @spacing[normal];\n}\n\n.listItem {\n  justify-content: start;\n}\n\n.features {\n  margin-bottom: @spacing[largest];\n  margin-top: @spacing[small];\n}\n\n.topMargin {\n  margin-top: @spacing[largest];\n}\n\n.tooltipText {\n  color: @named-colors[white];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkMark": "y6bHpJeTeGt4BASXk5SI",
	"listItem": "WwgTn4VbuQHh_ooGkkKL",
	"features": "bFWVgXcEQHottRVCZc3x",
	"topMargin": "ROHZYFFjJCwUdn_CndHd",
	"tooltipText": "uMFxVpWq8pQ3e68wtXov"
};
export default ___CSS_LOADER_EXPORT___;
