import React from 'react'
import classnames from 'classnames'
import { Skeleton } from 'antd'

import styles from './styles.less'

const SkeletonLine = ({ className, size, flex }) => {
  return (
    <div className={classnames(styles.loading, { [styles[size]]: size, [styles.flex]: flex }, className)}>
      <Skeleton paragraph={false} active />
    </div>
  )
}

export default SkeletonLine
