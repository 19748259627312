import React, { useState, useCallback } from 'react'
import { CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis, Legend, Tooltip, Customized } from 'recharts'
import Numeral from 'numeral'

import { tooltipProps } from 'components/charts/utils'
import format from 'components/charts/format'
import ChartLegend from 'components/charts/legend'
import RefLine from './ref-line'
import { XAxisTick } from 'components/charts'

const ChartContainer = ({
  data,
  span,
  disableSync = false,
  showLegend = true,
  percentageChart,
  formatYTicks,
  formatTooltip,
  children,
  yDomain
}) => {
  const [primaryRefLine, setPrimaryRefLine] = useState(false)

  const handleMouseMove = useCallback((e) => {
    if (!e) return
    setPrimaryRefLine(true)
  })

  const handleMouseLeave = useCallback(() => {
    setPrimaryRefLine(false)
  })

  const yDomainMax = (dataMax) => {
    if (percentageChart) return dataMax > 100 ? dataMax : 100
    return dataMax
  }

  const yAxisPropsNew = {
    tick: {
      fill: '#989898',
      fontSize: 12
    },
    stroke: '#dbdcdb',
    axisLine: false,
    tickSize: 4,
    width: 60,
    orientation: 'right'
  }

  const xAxisPropsNew = {
    tickLine: false,
    stroke: '#dbdcdb',
    tickSize: 4,
    interval: 0,
    dataKey: 'date'
  }

  // Height is set to 99% to fix chart scaling in a dynamic grid
  // https://github.com/recharts/recharts/issues/1423
  return (
    <ResponsiveContainer width='100%' height='99%' debounce={0}>
      <ComposedChart
        style={{ cursor: 'pointer' }}
        syncId={!disableSync && 'dashboard-metrics-charts-sync-id'}
        data={data}
        margin={{ top: 24, left: 0, bottom: -1, right: 0 }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <CartesianGrid
          vertical={false}
          strokeDasharray='3 3'
        />
        <XAxis
          {...xAxisPropsNew}
          tick={<XAxisTick span={span} />}
        />
        <YAxis
          {...yAxisPropsNew}
          mirror
          domain={yDomain || [0, yDomainMax]}
          tickFormatter={formatYTicks || (value => Numeral(value).format())}
        />
        <Tooltip
          {...tooltipProps}
          formatter={formatTooltip || ((value) => [value, undefined])}
          labelFormatter={date => format(date, span)}
          wrapperStyle={{ display: showLegend ? 'block' : 'none' }}
        />
        {showLegend && (
          <Legend
            align='left'
            verticalAlign='top'
            content={<ChartLegend />}
            wrapperStyle={{ left: '0', top: '0', padding: '8px', height: '35px' }}
          />
        )}
        <Customized
          key=''
          component={<RefLine primaryRefLine={primaryRefLine} showLegend={showLegend} />}
        />
        {children}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default ChartContainer
