import { useQuery } from 'react-query'
import { sum, isEqual } from 'lodash'
import querystring from 'query-string'
import memoizeOne from 'memoize-one'

import useQueryParams from 'lib/hooks/use-query-params'
import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { ERRORS_LIST } from './index'
import { eventPaths as paths } from '../index'
import { useErrorMetricsQuery, useAllResourcesQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

const mapResourcesToErrors = (errors, resources, metrics) => {
  if (!errors) return undefined
  if (!resources) return errors

  return errors?.map(error => {
    const countLastDay = sum(metrics?.datapoints[error.id]?.map(item => item.sum))

    return {
      ...error,
      resource: resources[error.resource],
      countLastDay
    }
  })
}

const memoizedResponse = memoizeOne(mapResourcesToErrors, isEqual)

const getErrors = async (account, status = 'open', filters) => {
  const query = querystring.stringify(omitEmpty({ status, type: 'error', ...filters }))
  const data = await apiFetch(paths.filter(paths.events(account), query))
  return data?.data
}

export const useErrorsQuery = (metricsFilters = {}) => {
  const { account } = useOrganization()

  const { getValue: status } = useQueryParams('status', 'open')

  const { data: resources } = useAllResourcesQuery()
  const { data: metrics } = useErrorMetricsQuery({ key: 'errors' }, metricsFilters.start, metricsFilters.end)

  const queryOpts = useQuery(
    [...ERRORS_LIST, status, account?.id],
    () => getErrors(account?.id, status),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 5 * 60 * 1000
    }
  )

  const data = memoizedResponse(queryOpts?.data, resources, metrics)

  return {
    ...queryOpts,
    data
  }
}
