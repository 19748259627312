import React from 'react'
import { countBy, uniqBy, flatten, orderBy } from 'lodash'

import Section from 'components/layout/content/section'
import { Region } from 'components/items/progress-item'
import { REGIONS } from 'lib/aws-regions'
import styles from './styles.less'

const RegionsList = ({ rules, insights, loading, selected = [], onSelect }) => {
  const mappedRules = rules?.filter(item => item.count > 0).map(rule => ({
    ...rule,
    regions: uniqBy(insights?.filter(item => item?.rule === rule?.id), 'resource.region').map(insight => insight.resource.region)
  }))

  const allRegions = flatten(mappedRules?.map(item => item.regions))
  const countByRegion = countBy(allRegions)

  const items = Object.keys(countByRegion).map(region => {
    const regionInfo = REGIONS.find(item => item.region === region)
    return {
      title: region,
      count: countByRegion[region],
      total: allRegions?.length,
      description: regionInfo?.name,
      area: regionInfo?.area,
      id: region
    }
  })

  return (
    <Section title='Regions' loading={loading} className={styles.wrapper} titleUpperCase>
      {items.length !== 0
        ? orderBy(items, 'count', 'desc').map(item =>
          <Region
            key={item.id}
            {...item}
            selected={selected}
            onSelect={onSelect}
          />)
        : <p>No violations found</p>}
    </Section>
  )
}

export default RegionsList
