import React from 'react'
import { Form, Button } from 'antd'
import Input from 'components/antd/input'
import Submit from 'components/antd/form/submit'
import AuthTitle from 'containers/auth/title'
import { Typography } from 'components/typography/index'
import { useCreateOrganizationsQuery, useUserInvitationsQuery } from 'hooks/api'
import { useUser } from 'hooks/context/user-context'

import styles from './styles.less'

const OrgAccountForm = ({ viewInvitations }) => {
  const { mutate: createOrganization, isLoading } = useCreateOrganizationsQuery()
  const { data: invitations } = useUserInvitationsQuery()
  const { user } = useUser()

  const onSubmit = (values) => {
    const { account, organization } = values
    createOrganization({ name: organization, accounts: [{ name: account }], email: user?.username })
  }

  return (
    <>
      <AuthTitle title='Setting up your account structure' />
      <Form layout='vertical' onFinish={onSubmit}>
        <Form.Item name='organization' label='Organization name'>
          <Input solid />
        </Form.Item>
        <Typography.Paragraph size='xs'>Organization is the root of all accounts. It manages billing and users. It can have one to multiple accounts</Typography.Paragraph>
        <Form.Item name='account' label='Account name'>
          <Input solid />
        </Form.Item>
        <Typography.Paragraph size='xs'>Account is organizational unit for environments, project or team. It can connect to multiple AWS accounts.</Typography.Paragraph>
        <Submit text='Next' loading={isLoading} titleUpperCase />
      </Form>
      {invitations?.length !== 0 && <Button type='link' className={styles.action_btn} onClick={() => viewInvitations()}>View pending invitations</Button>}
    </>
  )
}

export default OrgAccountForm
