import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Typography, Row, Col } from 'antd'
import { get } from 'lodash'

import SuspensionButton from './suspension-button'

import { GET_PAYMENT_METHODS } from 'actions/billing.actions'
import { API } from 'actions/api'

import { STATUSES } from 'lib/organization-constants'
import { useOrganization } from 'hooks/context/organization-context'

const { Text, Title } = Typography

const SuspensionText = ({ suspended }) => {
  const title = `${suspended ? 'Unsuspend' : 'Suspend'} your organization`
  const text = suspended
    ? 'Once you unsuspend your organization, we will start ingesting data and discovering resources again. A subscription will be created for you and any reserved subscriptions that have not expired will still be valid.'
    : 'Once you suspend your organization, we will stop ingesting data and stop discovering resources. Your subscription will be canceled and you will be charged based on the outstanding data usage. If you have bought a reserved subscription, it will be active until the end your the billing period.'

  return (
    <Row>
      <Col>
        <Title level={4}>{title}</Title>
      </Col>
      <Col>
        <Text>{text}</Text>
      </Col>
    </Row>
  )
}

const DangerZone = ({ className }) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()
  const paymentMethods = useSelector(state => get(state, 'billing.payment.methods'))
  const paymentMethodsV2 = useSelector(state => get(state, 'billingV2.paymentMethods'))
  const loading = useSelector(state => get(state, ['waiting', 'list', GET_PAYMENT_METHODS]))

  const BILLING_V2 = organization?.metadata?.billing === 'v2'

  useEffect(() => {
    if (!organization.id) return
    dispatch(BILLING_V2 ? API.organizations.id(organization.id).billingV2.paymentMethods.get() : API.organizations.id(organization.id).billing.payment.methods.get())
  }, [organization.id])

  const hasPaymentMethod = BILLING_V2 ? paymentMethodsV2?.length : paymentMethods?.length
  const isSuspended = (organization || {}).status === STATUSES.suspended

  return (
    <Card
      title='Danger Zone'
      className={className}
      loading={loading}
      headStyle={{ backgroundColor: '#ff4d4f', color: '#fff' }}
    >
      <Row>
        <Col span={14}>
          <SuspensionText suspended={isSuspended} />
        </Col>
        <Col offset={2} span={8}>
          <SuspensionButton suspended={isSuspended} paymentMethodExists={hasPaymentMethod} loading={loading} />
        </Col>
      </Row>
    </Card>
  )
}

export default DangerZone
