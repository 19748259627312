import React from 'react'

import { Drawer } from 'antd'

import classnames from 'classnames'
import styles from './styles.less'

const StyledDrawer = (props) => {
  const { className, size } = props
  return <Drawer {...props}
    width={size === 'large' ? 720 : 500}
    className={classnames(styles.drawer, className)}
    title={className === 'config_drawer' && 'Configuration'}>
    {props.children}
    {!props.children && className === 'config_drawer' &&
      <p>No configuration set up</p>
    }
  </Drawer>
}

export default StyledDrawer
