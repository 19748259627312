import React from 'react'
import { Checkbox } from 'antd'

import { InsightIcon } from 'components/icons'
import Actions from './actions'
import { InsightRules } from 'lib/policy-constants'
import TargetSelector from 'features/target-selector'
import Select from 'components/antd/select'

import styles from './styles.less'

const blacklist = ['AWS::ApiGateway::RestApi', 'AWS::ApiGateway::Resource']

const filter = (resources) => {
  return resources.filter(resource => !blacklist.includes(resource.type))
}

const InsightForm = ({ mutations, condition, lambdas, resources, groups, disableEditing }) => {
  const selectable = filter(resources)
  const handlers = mutations({ condition })
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <InsightIcon /><span>Insights</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>services</span>
          <span className={styles.value}>{condition.rules && condition.rules.length ? condition.rules.length : 'ALL'}</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>resources</span>
          <span className={styles.value}>{condition.targets && condition.targets.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} disableEditing={disableEditing} />
        </div>
      </div>
      <section>
        <span className={styles.label}>Severities</span>
        <Checkbox.Group
          options={['critical', 'warning', 'info']}
          defaultValue={condition.severities}
          onChange={(values) => handlers.update({ severities: values })}
          disabled={disableEditing}
        />
      </section>
      <section>
        <span className={styles.label}>Filter by services</span>
        <Select
          mode='multiple'
          defaultValue={condition.rules}
          placeholder='Select services'
          solid
          onChange={(values) => handlers.update({ rules: values })}
          options={InsightRules.map(item => ({ id: item.value, title: item.title, disabled: disableEditing, value: item.value }))}
        />
      </section>
      <section>
        <span className={styles.label}>Filter by resources</span>
        <TargetSelector
          lambdas={lambdas}
          resources={selectable}
          resourceGroups={groups}
          value={condition.targets}
          placeholder='Select resources'
          prefixValues
          onSelect={(value) => handlers.targets(value).add()}
          onDeselect={(value) => handlers.targets(value).remove()}
        />
      </section>
    </div>
  )
}

export default InsightForm
