import React from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'
import Metadata from 'components/inventory/metadata'
import PrettyPrinter from 'components/json-pretty'
import Enabled from 'components/inventory/enabled'

const formatDate = (date) => moment(date).format('MMMM Do YYYY, h:mm:ss a')

const RDSProxyConfig = ({ attributes, inverse }) => {
  const data = objectify(attributes)

  const items = [{
    title: 'IAM role',
    value: data?.roleArn
  }, {
    title: 'Idle client connection timeout',
    value: `${parseInt(data?.idleClientTimeout) / 60} minutes`
  }, {
    title: 'VPC',
    children: [
      { key: 'Security group id', value: JSON.parse(data.vpcSecurityGroupIds)?.map((item, index) => <p key={index}>{item}</p>) },
      { key: 'Subnet group ids', value: JSON.parse(data.vpcSubnetIds)?.map((item, index) => <p key={index}>{item}</p>) }
    ]
  }, {
    title: 'Authentication',
    value: <PrettyPrinter jsonString={JSON.parse(data?.auth)} collapsed={false} />
  }]

  const statusItem = { title: 'Status', value: data?.status }
  const engineItem = { title: 'Engine compability', value: data?.engineFamily }

  const createdItem = { title: 'Created', value: formatDate(data?.createdDate) }
  const updatedItem = { title: 'Updated', value: formatDate(data?.updatedDate) }

  const tlsItem = { title: 'Transport layer security', value: <Enabled data={data?.requireTLS} /> }
  const enhancedItem = { title: 'Enhanced logging', value: <Enabled data={data?.debugLogging} /> }

  return (
    <>
      <Row gutter={12}>
        <Col span={12}><Metadata items={[statusItem]} inverse={inverse} /></Col>
        <Col span={12}><Metadata items={[engineItem]} inverse={inverse} /></Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}><Metadata items={[createdItem]} inverse={inverse} /></Col>
        <Col span={12}><Metadata items={[updatedItem]} inverse={inverse} /></Col>
      </Row>

      <Metadata items={items} inverse={inverse} />

      <Row gutter={12}>
        <Col span={12}><Metadata items={[tlsItem]} inverse={inverse} /></Col>
        <Col span={12}><Metadata items={[enhancedItem]} inverse={inverse} /></Col>
      </Row>
    </>
  )
}

export default RDSProxyConfig
