import React, { useState } from 'react'

import Content from 'components/layout/content'
import CurrentSubscription from './current'
import ManageSubscriptionModal from './manage-subscription-modal'
import UsageReport from './usage'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const ManageSubscriptions = () => {
  const { organization } = useOrganization()

  const [modalVisible, setModalVisible] = useState(false)

  const Title = () => <>Subscription for <span className={styles.org_name}>{organization.name}</span> organization</>

  return (
    <Content item={{}} title={<Title />} breadcrumbs={['settings', 'manage subscription']}>
      <CurrentSubscription setModalVisible={setModalVisible} />
      {modalVisible && <ManageSubscriptionModal visible={modalVisible} setVisible={setModalVisible} />}
      <UsageReport />
    </Content>
  )
}

export default ManageSubscriptions
