import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { dashboardsPaths as paths, DASHBOARD_ACTIVE } from './index'

const getDashboard = async (account, identity) => {
  const data = await apiFetch(paths.dashboard(account, identity))
  return data
}

export const useDashboardQuery = () => {
  const { dashboardId } = useParams()
  const { account } = useOrganization()

  return useQuery(
    [...DASHBOARD_ACTIVE, dashboardId],
    () => getDashboard(account.id, dashboardId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!dashboardId,
      refetchOnWindowFocus: false
    }
  )
}
