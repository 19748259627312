import React from 'react'
import { countBy } from 'lodash'

import Section from 'components/layout/content/section'
import { useAlarmsQuery, useAllResourcesQuery } from 'hooks/api'
import { findInventoryByType } from 'lib/resources/constants'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { Resource } from 'components/items/progress-item'

const ViolatedResources = () => {
  const { getValue: selectedResources, setValue: setSelectedResources } = useQueryParams('resource', [])

  const { data: alarms, isLoading } = useAlarmsQuery()
  const { isLoading: resourcesLoading } = useAllResourcesQuery()

  const countByResource = !resourcesLoading && countBy(alarms, 'resource.id')

  const items = countByResource
    ? Object.keys(countByResource)?.map(resourceId => {
      const alarm = alarms?.find(item => item.resource.id === resourceId)

      const delegation = alarm?.resource?.delegationName ? `${alarm?.resource?.delegationName} |` : ''
      const service = findInventoryByType(alarm?.resource?.type)

      return ({
        service: service?.service,
        id: alarm?.resource?.id,
        key: alarm?.resource?.id,
        description: alarm?.resource?.title || '<resource deleted or missing>',
        title: `${service?.title} | ${delegation} ${alarm?.resource?.region?.toUpperCase()}`,
        count: countByResource[resourceId]
      })
    })
    : []

  return (
    <Section title='violated resources' titleUpperCase loading={isLoading || resourcesLoading}>
      {items?.length !== 0
        ? items?.sort((a, b) => b.count - a.count)?.map(item => (
          <Resource
            {...item}
            key={item.id}
            selected={selectedResources}
            onSelect={(id) => setSelectedResources(filterHelper(id, selectedResources))} />))
        : <p>No violated resourcees found</p>}
    </Section>
  )
}

export default ViolatedResources
