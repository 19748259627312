import React from 'react'
import { isEmpty } from 'lodash'

import ListItem from 'features/side-list/item'
import { AwsServiceIcon } from 'components/icons'
import { formatDistanceToNowStrict } from 'date-fns'
import Badge from 'components/badge/info'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import SeverityBadge from 'components/badge/severity'

import styles from './styles.less'

const LeftDetails = ({ item, serviceGroup }) => {
  const resourceText = (count) => count === 1 ? `${count} resource` : `${count} resources`
  return (
    <>
      <Badge text={isEmpty(item?.targets) ? 'all' : resourceText(item?.targets?.length)} />
      <span className={styles.margin_left}>{serviceGroup?.title}</span>
    </>
  )
}

const RightDetails = ({ item }) => {
  if (!item) return null

  const { count, severity, hasPartialResults } = item
  const hasOccurrences = count > 0
  if (!hasOccurrences && !hasPartialResults) return null
  return (
    <div className={styles.right_details}>
      {hasPartialResults && <SeverityBadge text='i' />}
      {hasOccurrences && <SeverityBadge text={count} severity={severity} />}
    </div>
  )
}

const DetailsBelow = ({ item }) => (
  <div className={styles.details}>
    {item?.lastOccurredAt ? <span>{`${formatDistanceToNowStrict(item?.lastOccurredAt)} ago`}</span> : <span className={styles.date_filler}> </span>}
  </div>
)

const SearchListItem = ({ data, index, style }) => {
  const item = data?.items[index]

  const serviceGroup = INVENTORY_SERVICE_GROUPS[item?.service]
  return (
    <div style={style}>
      <ListItem
        key={item?.id}
        icon={<AwsServiceIcon service={item?.service} />}
        title={item?.name || item?.query || <span className={styles.italic}>latest logs</span>}
        selected={item?.id?.toString() === data?.selected}
        left={<LeftDetails item={item} serviceGroup={serviceGroup} />}
        right={<RightDetails item={item} />}
        detailsBelowTitle
        details={<DetailsBelow item={item} />}
        onClick={() => data?.onSelect(item)}
      />
    </div>
  )
}

export default SearchListItem
