import React from 'react'

import IconButton from 'components/buttons/icon'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'

import styles from './styles.less'

const LoadMoreButton = ({ onClick, isLoading, hidden }) => {
  if (hidden) return null

  return (
    <div className={styles.wrapper}>
      <IconButton
        onClick={onClick}
        loading={isLoading}
        className={styles.button}
        title='Load more'
        icon={faEllipsisH}>
      </IconButton>
    </div>
  )
}

export default LoadMoreButton
