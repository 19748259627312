import React, { useCallback } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { WIDGET_KIND } from 'lib/dashboards-constants';

import Widget from './widget';
import styles from './styles.less'

const ResponsiveGridLayout = WidthProvider(Responsive);

// TODO: extract
const widgetsToGridLayout = (widgets = []) => {
  const COMMON_WIDGET_PROPS = {
    isDraggable: true,
  }

  return widgets.map((widget) => {
    return {
      ...COMMON_WIDGET_PROPS,
      isResizable: widget.kind !== WIDGET_KIND.PLACEHOLDER,

      i: widget.id,
      x: widget.layout.x,
      y: widget.layout.y,
      w: widget.layout.width,
      h: widget.layout.height,
      raw: widget
    }
  })
}

const DashboardGrid = ({ widgets = [], onDragStop, onResize, onResizeStop }) => {
  const layout = widgetsToGridLayout(widgets)

  const layouts = {
    lg: layout,
    md: layout,
    sm: layout,
    xs: layout,
    xxs: layout
  }

  const handleDragStop = useCallback((...data) => {
    if (onDragStop) {
      onDragStop(...data)
    }
  }, [onDragStop])

  const handleResize = useCallback((...data) => {
    if (onResize) {
      onResize(...data)
    }
  }, [onResize])

  const handleResizeStop = useCallback((...data) => {
    if (onResizeStop) {
      onResizeStop(...data)
    }
  }, [onResizeStop])

  return (
    <ResponsiveGridLayout
      className={styles.grid}
      layouts={layouts}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 16, md: 16, sm: 12, xs: 2, xxs: 2 }}
      rowHeight={70}
      resizeHandles={['se']}
      autoSize={false}
      onDragStop={handleDragStop}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      draggableHandle='.dbDragHandle'
    >
      {layout.map(widget => (
        <div key={widget.i}>
          <Widget widget={widget.raw} />
        </div>
      ))}
    </ResponsiveGridLayout>
  )
}

export default DashboardGrid
