const defaults = {
  error: {
    occurrence: 'new',
    severities: ['critical']
  },
  alarm: {},
  insight: {
    severities: ['critical'],
    rules: []
  },
  'log-match': {
    occurrence: 'new',
    severities: ['critical']
  }
}

const mutations = ({ policy, history, routes, deletePolicy, updatePolicy, addPolicyChannel, deletePolicyChannel, addCondition, updateCondition, deleteCondition, addTarget, deleteTarget, setChannelSearchValue }) => {
  return {
    update: (payload) => {
      updatePolicy({ policy: policy.id, payload })
    },
    remove: async () => {
      try {
        await deletePolicy(policy.id)
        history.push(routes.settings.notifications.url())
      } catch { }
    },
    condition: ({ condition }) => {
      return {
        create: (attributes) => {
          const payload = {
            ...attributes,
            ...defaults[attributes.type]
          }
          addCondition({ policyId: policy.id, payload })
        },
        update: (payload) => {
          updateCondition({ policyId: policy.id, conditionId: condition.id, payload })
        },
        remove: () => {
          deleteCondition({ policyId: policy.id, conditionId: condition.id })
        },
        targets: (target) => {
          return {
            add: () => {
              addTarget({ policyId: policy.id, conditionId: condition.id, target })
            },
            remove: () => {
              deleteTarget({ policyId: policy.id, conditionId: condition.id, target })
            }
          }
        }
      }
    },
    channels: (channel) => {
      return {
        add: () => {
          addPolicyChannel({ policyId: policy.id, channelId: channel })
          setChannelSearchValue('')
        },
        remove: () => {
          deletePolicyChannel({ policyId: policy.id, channelId: channel })
          setChannelSearchValue('')
        }
      }
    }
  }
}

export default mutations
