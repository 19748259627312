import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Content from 'components/layout/content'
import Delegations from './delegations'
import Integrations from './integrations'

import { API } from 'actions/api'
import { GET_INTEGRATIONS } from 'actions/integrations.actions'
import { useDelegationsQuery } from 'hooks/api/delegations'
import { useOrganization } from 'hooks/context/organization-context'

const ManageIntegrations = () => {
  const dispatch = useDispatch()
  const { account } = useOrganization()
  const { data: delegations, isLoading: loadingDelegations } = useDelegationsQuery()
  const { data: integrations } = useSelector(state => state.integrations.list)

  const fetching = {
    integrations: useSelector(state => state.waiting.list[GET_INTEGRATIONS])
  }
  const loading = fetching.integrations

  useEffect(() => {
    if (!account.id) return
    dispatch(API.accounts.id(account.id).integrations.get())
  }, [account.id])

  return (
    <Content item={{}} title='' breadcrumbs={['settings', 'integrations']} loading={loading}>
      <Delegations loading={loadingDelegations} delegations={delegations} />
      <Integrations loading={loading} integrations={integrations} delegations={delegations} />
    </Content>
  )
}

export default ManageIntegrations
