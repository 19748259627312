import { GET_LOG_SOURCES, GET_LOG_SOURCES_ERROR } from 'actions/log-sources.actions'
import { combineReducers } from 'redux'

function active (state = {}, action) {
  switch (action.type) {
    case GET_LOG_SOURCES:
      return action.payload
    case GET_LOG_SOURCES_ERROR:
      return { data: null, error: action.payload }
  }
  return state
}

export default combineReducers({
  active
})
