import classnames from 'classnames'
import React, { useEffect } from 'react'
import { Tooltip, Button, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'

import { API } from 'actions/api'
import { GET_LOG_SOURCES } from 'actions/log-sources.actions'
import { useDelegationsQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const ERROR_DESCRIPTIONS = {
  'log-source.log-source-not-found': 'Log group not found'
}

const FAILURE_DESCRIPTIONS = {
  LimitExceededException: 'We could not create a subcription since the log group already has 2 or more subscriptions. Please, remove one or all subscriptions manually.',
  ResourceNotFoundException: 'Log group for this lambda does not exist. This could happen if lambda has not been executed yet.',
  CredentialsError: 'Dashbird coundn\'t access your AWS account to create a subscription.',
  ThrottlingException: 'Throttling exception occurred while trying to create a subscription.'
}

const icon = (x) => <FontAwesomeIcon icon={x} color='#e9e8e8' />

const getIcon = (loading, logSource, logSourceError) => {
  if (loading) return null
  if (logSourceError) return icon(faExclamation)

  if (logSource.failure) {
    return icon(faTimes)
  }

  return icon(faCheck)
}

const getText = (loading, logSource, logSourceError) => {
  if (loading) return null

  if (logSource.failure || logSourceError) {
    return 'Error!'
  }

  if (logSource.disabled || logSource.suspended) {
    return 'Disabled!'
  }

  return 'All good!'
}

const getTooltipText = (loading, logSource, logSourceError) => {
  if (loading) return null
  if (logSourceError) {
    return ERROR_DESCRIPTIONS[logSourceError.code] || 'Unknown error'
  }

  if (logSource.failure) {
    return FAILURE_DESCRIPTIONS[logSource.failure.type] || `${logSource.failure.type}: ${logSource.failure.reason}`
  }

  if (logSource.suspended) {
    return 'Subscription is suspended!'
  }

  if (logSource.disabled) {
    return 'Subscription is disabled!'
  }

  return 'Everything looks good!'
}

const logGroupName = (name) => `/aws/lambda/${name}`

const LogGroupStatus = ({ item }) => {
  const dispatch = useDispatch()
  const { account } = useOrganization()
  const loading = useSelector(state => state.waiting.list[GET_LOG_SOURCES])
  const logSource = useSelector(state => get(state, 'logSources.active', {}))
  const logSourceError = useSelector(state => get(state, 'logSources.active.error', null))

  const { data: delegations } = useDelegationsQuery()
  const delegation = delegations?.find(delegation => delegation.account === account?.id)

  useEffect(() => {
    if (isEmpty(delegations)) return

    dispatch(API.accounts.id(account.id).logSources.id(delegation?.id).get(item.region, logGroupName(item.name)))
  }, [delegations])

  const indicatorIcon = getIcon(loading, logSource, logSourceError)
  const indicatorText = getText(loading, logSource, logSourceError)
  const indicatorTooltipText = getTooltipText(loading, logSource, logSourceError)
  const indicatorClassNames = classnames(styles.indicator, {
    [styles.indicator_active]: !loading && !isEmpty(logSource) && !logSource.failure,
    [styles.indicator_error]: !loading && !isEmpty(logSource) && logSource.failure,
    [styles.indicator_disabled]: !loading && ((!isEmpty(logSource) && !logSource.failure && logSource.disabled) || logSourceError)
  })

  // <Tooltip title={<span>{indicatorTooltipText}<br /><b>Click to recreate a subscription.</b></span>}>
  return (
    <div className={styles.container}>
      <h4 className={styles.attribute_header}>Log group subscription</h4>

      <Row className={styles.indicatorContainer}>
        Status:

        <Tooltip title={indicatorTooltipText}>
          <Button
            loading={loading}
            shape='circle'
            size='small'
            className={indicatorClassNames}
            onClick={() => dispatch(API.accounts.id(account.id).logSources.id(delegation?.id).get(item.region, logGroupName(item.name), true))}
          >
            {indicatorIcon}
          </Button>
        </Tooltip>

        {indicatorText}
      </Row>
    </div>
  )
}

export default LogGroupStatus
