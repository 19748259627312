import React, { useCallback } from 'react'
import { Form } from 'antd'
import Input from 'components/antd/input'
import Submit from 'components/antd/form/submit'
import RadioSmall from 'components/antd/radio/small'

const getInitialValues = (widget, editing) => {
  if (editing) {
    return {
      size: widget?.definition?.size || 'md',
      text: widget?.definition?.text
    }
  }
  return { size: 'md', text: '' }
}

const TextWidgetDetails = ({ editing, widget, kind, handlers, onClose }) => {
  const handleFormSubmit = useCallback((payload) => {
    if (editing) {
      handlers.widgets.update(widget, payload)
    } else {
      handlers.widgets.add(kind, payload)
    }

    onClose()
  }, [handlers, widget, editing, kind])

  return (
    <Form layout='vertical' onFinish={handleFormSubmit} initialValues={getInitialValues(widget, editing)}>
      <Form.Item label='Text size' name='size'>
        <RadioSmall items={[{ label: 'Small', value: 'sm' }, { label: 'Medium', value: 'md' }, { label: 'Large', value: 'lg' }]} />
      </Form.Item>
      <Form.Item label='Text' name='text' rules={[{ required: true, message: 'Please input a text message' }]}>
        <Input solid />
      </Form.Item>
      <Submit text={editing ? 'Update widget' : 'Add widget'} />
    </Form>
  )
}

export default TextWidgetDetails
