import React from 'react'
import { Avatar, Tooltip } from 'antd'

import { ResolvedSolidIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const PaymentIndicator = ({ isPaid }) => {
  const className = isPaid ? 'complete' : 'uncomplete'
  return (
    <Avatar
      className={styles.avatar}
      icon={
        <Tooltip title={isPaid ? 'Paid' : 'Unpaid'}>
          <ResolvedSolidIcon className={styles[className]} />
        </Tooltip>
      }
    />
  )
}

export default PaymentIndicator
