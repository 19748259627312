import React from 'react'
import classnames from 'classnames'
import { isNil } from 'lodash'
import { Button, Form } from 'antd'
import { Typography } from 'components/typography'
import Input from 'components/antd/input'
import { useDelegationQuery } from 'hooks/api'

import styles from './styles.less'

const DelegationNameForm = ({ onSubmit, className, history, saving }) => {
  const [form] = Form.useForm()
  const { data: delegation } = useDelegationQuery()

  if (!delegation) return null

  return (
    <Form
      form={form}
      className={classnames(styles.form_wrapper, className)}
      layout='vertical'
      onFinish={onSubmit}
      initialValues={{ arn: delegation.roleArn, delegationName: delegation?.name }}>
      <Form.Item name='arn' label='Role arn' validateFirst
        rules={[
          { required: true, message: 'Arn must be defined' },
          { pattern: /^arn:aws:iam::\d+:role\/[a-zA-Z]+$/, message: 'Invalid role arn' }
        ]}>
        <Input autoFocus
          placeholder='arn:aws:iam::XXXXXXXXXXXX:role/dashbird-integration-stack-DashbirdIntegrationRole-XXXXXXXXXXXXX'
          disabled={!isNil(delegation?.roleArn)} />
      </Form.Item>
      <Form.Item
        name='delegationName'
        label='AWS account name (optional)'
        validateFirst
      >
        <Input autoFocus placeholder='AWS account name' style={{ opacity: 0.7 }} />
      </Form.Item>
      <Form.Item shouldUpdate>{() =>
        <Typography.Paragraph className={styles.buttons_wrapper}>
          <Button type='primary' ghost onClick={history.goBack}>Cancel</Button>
          <Button type='primary' htmlType='submit' loading={saving} disabled={isNil(delegation?.roleArn) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
              .length > 0}>Save Changes</Button>
        </Typography.Paragraph>}
      </Form.Item>
    </Form>
  )
}

export default DelegationNameForm
