import React, { createContext, useEffect, useRef, useState } from 'react'
import { times } from 'lodash'
import { Skeleton } from 'antd'

import classnames from 'classnames'

import Breadcrumbs from 'components/breadcrumbs'
import { PageHeader } from 'components/page-header'
import FixedHeader from 'components/layout/fixed-header'

import styles from './styles.less'

export const ScrollContext = createContext(false)

const renderContent = (content, item) => {
  if (typeof content === 'function') {
    return content(item)
  }
  return content
}

const renderEmptyContent = (render) => {
  if (!React.isValidElement(render)) return render()
  if (React.isValidElement(render)) return render
  return null
}

const Actions = ({ actions, item }) => {
  const content = renderContent(actions, item)
  if (!content) return null
  return <div className={styles.actions}>{content}</div>
}

const Loading = () => (
  <>
    <div style={{ height: '12px' }} />
    {
      times(4).map(index => (
        <Skeleton key={index} active title={false} paragraph={{ rows: 4 }} className={styles.skeleton} />
      ))
    }
  </>
)

const Content = ({
  loading,
  title,
  subtitle,
  icon,
  item,
  breadcrumbs = [],
  breadcrumbsLoading,
  backRoute,
  actions,
  error,
  renderEmpty,
  className,
  fixed,
  fixedTitle,
  drawer,
  bordered,
  HEADER_HEIGHT = 70,
  onDrawerClose,
  titleRowActions,
  hideTitleActions,
  children
}) => {
  const scrollRef = useRef()
  const [showFixed, setshowFixed] = useState(false)
  const [componentWidth, setComponentWidth] = useState('')

  useEffect(() => {
    if (!scrollRef?.current) return
    const observer = new ResizeObserver(entries => {
      setComponentWidth(entries[0].contentRect.width)
    })

    observer.observe(scrollRef.current)
    return () => scrollRef.current ? observer.unobserve(scrollRef.current) : observer.disconnect()
  }, [scrollRef?.current])

  const handleScroll = () => {
    if (!fixed) return
    setshowFixed(scrollRef?.current?.scrollTop > HEADER_HEIGHT)
  }

  const handleScrollTop = () => {
    if (!scrollRef?.current) return
    scrollRef.current.scrollTop = 0
  }

  if (error) return error
  if (!loading && !item) return renderEmptyContent(renderEmpty)
  const shouldRenderHeader = item && (!!title || !!actions) && !drawer

  return (
    <ScrollContext.Provider value={{ fixed: showFixed, width: componentWidth, handleScrollTop }}>
      <div onScroll={handleScroll} ref={scrollRef} className={styles.scroll}>
        {(drawer || (showFixed && fixed)) && (
          <FixedHeader
            title={fixedTitle || title}
            breadcrumbs={breadcrumbs}
            icon={icon}
            onClose={onDrawerClose}
            bordered={bordered}
            topBarActions={!drawer && actions}
            titleBarActions={!hideTitleActions && titleRowActions} />
        )}
        <div className={classnames(styles.content, { [styles.drawer]: drawer }, className)}>
          {!drawer && (
            <div className={styles.top_wrapper}>
              <Breadcrumbs breadcrumbs={breadcrumbs} backRoute={backRoute} loading={breadcrumbsLoading} />
              <Actions actions={actions} item={item} />
            </div>
          )}
          {shouldRenderHeader &&
            <PageHeader
              icon={icon}
              title={title}
              subtitle={subtitle}
              extra={titleRowActions}
            />}
          {loading
            ? <Loading />
            : children}
        </div>
      </div>
    </ScrollContext.Provider>

  )
}

export default Content
