import React, { useMemo } from 'react'
import { flatten, isEmpty, mergeWith, toNumber, orderBy } from 'lodash'
import { BarChart, Legend, XAxis, YAxis, ResponsiveContainer } from 'recharts'

import moment from 'moment'

import colors from 'lib/colors'
import Empty from 'components/layout/content/empty'
import Loading from 'components/layout/content/loading'

import formatIngestedData from '../formatters/ingestedData'
import formatInvocations from '../formatters/invocations'
import stringToColor from '../lib/stringToColor'
import useBars from '../hooks/useBars'
import useTooltip from '../hooks/useTooltip'

import styles from './styles.less'
import { yAxisProps } from 'components/charts/utils'
import { formatStacks } from 'lib/usage-helpers'

const LAMBDA_COLUMN_ITEM_LIMIT = 10
const OTHER_DISPLAY_NAME = 'other'

const dataType = (type) => type === 'data'
const lambdaTab = (tab) => tab === 'lambda'
const regionTab = (tab) => tab === 'region'

const fill = ({ tab, key }) => {
  if (lambdaTab(tab)) {
    return stringToColor(key)
  }

  return colors(tab, key)
}

const formatter = (type, value, precision) => {
  if (dataType(type)) {
    return formatIngestedData(value, precision)
  }

  return formatInvocations(value)
}

const limitDisplayedData = (histogram) => {
  return histogram.map(stack => {
    const orderedByHighest = Object.entries(stack).sort((a, b) => b[1] - a[1])
    return orderedByHighest.reduce((carry, stackData, idx) => {
      if (stackData[0] === 'key') return carry
      if (idx < LAMBDA_COLUMN_ITEM_LIMIT) {
        carry[stackData[0]] = stackData[1]
      } else {
        carry[OTHER_DISPLAY_NAME] = (carry[OTHER_DISPLAY_NAME] || 0) + stackData[1]
      }
      return carry
    }, { key: stack.key })
  })
}

const mergeValuesByDate = histogram => {
  const mergedByKey = histogram.reduce((carry, dayData) => {
    const current = carry[dayData.key] || {}
    carry[dayData.key] = mergeWith({ ...dayData }, current, (v1, v2, k) => {
      if (k === 'key') return v1
      if (v1 && v2) return toNumber(v1) + toNumber(v2)
      return toNumber(v1) || toNumber(v2)
    })
    return carry
  }, {})
  return Object.values(mergedByKey).sort((a, b) => b - a)
}

const prepareHistogram = (data = [], type, tab, accounts) => {
  const keys = {}

  if (tab === 'account') {
    const test = Object.entries(data).map(([accountId, accountData]) => {
      const histogram = accountData.data.map((item) => {
        const groupedStacks = formatStacks(item, tab)

        const all = Object.entries(groupedStacks).reduce((acc, [key, stacks]) => {
          const value = stacks.reduce((acc, stack) => acc + (dataType(type) ? stack.bytes : stack.invocations), 0)

          return acc + value
        }, 0)

        const accountName = accounts.find(item => item.id === accountId).name
        keys[accountName] = all

        return { key: moment(item.key).startOf('day').valueOf(), [accountName]: all }
      })

      return histogram
    })

    return {
      keys: Object.entries(keys).sort((a, b) => b[1] - a[1]).map(([key]) => key),
      histogram: mergeValuesByDate(flatten(test))
    }
  } else {
    const histogram = flatten(Object.values(data).map(value => value.data)).map((item) => {
      const groupedStacks = formatStacks(item, tab)

      return Object.entries(groupedStacks).reduce((acc, [key, stacks]) => {
        const value = stacks.reduce((acc, stack) => acc + (dataType(type) ? stack.bytes : stack.invocations), 0)

        keys[key] = value

        return {
          ...acc,
          [key]: value
        }
      }, { key: moment(item.key).startOf('day').valueOf() })
    })
    const mergedByDate = mergeValuesByDate(histogram)
    return {
      keys: [...Object.entries(keys).sort((a, b) => b[1] - a[1]).map(([key]) => key), 'other'],
      histogram: limitDisplayedData(mergedByDate)
    }
  }
}

const NoData = () => {
  return (
    <Empty title='No data available yet.'>
      <div>There is no accounts selected or no ingested data available for the last 30 days.</div>
    </Empty>
  )
}

const DataIngestedHistogramChart = ({ data, loading, tab, type, accounts }) => {
  if (type === undefined) type = 'data'

  const { keys, histogram } = useMemo(() => prepareHistogram(data, type, tab, accounts), [data, type, tab])

  const stacked = true
  const [bars, activeBar] = useBars({ keys, tab, fill, stacked })
  const tooltip = useTooltip({
    activeBar,
    className: styles.tooltip,
    heading: dataType(type) ? 'Data ingested: ' : 'Invocations: ',
    value: (value) => formatter(type, value, '0.00')
  })

  if (loading) return <Loading height={500} />
  if (isEmpty(data)) return <NoData />

  return (
    <ResponsiveContainer width='100%' height={500} debounce={300}>
      <BarChart
        data={orderBy(histogram, 'key')} margin={{
          top: 42, left: -8, bottom: 8, right: 8
        }}
      >
        <XAxis
          dataKey='key'
          stroke={colors('chart', 'grid')}
          tick={{ fill: colors('chart', 'text'), fontSize: 12 }}
          tickFormatter={tick => moment(tick).format('DD.MM.YYYY')}
        />
        <YAxis tickFormatter={value => formatter(type, value)} {...yAxisProps} />
        {regionTab(tab) && <Legend wrapperStyle={{ padding: '8px' }} />}
        {tooltip}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default DataIngestedHistogramChart
