import React, { useEffect, useState } from 'react'

import Register from './form'
import GenericAction from 'containers/auth/generic-action'
import { MSG_VERIFICATION_LINK } from 'containers/auth/messages'
import { getEmailConfirmedKey, getOnEmailConfirmedAction, listen } from 'lib/auth/local-storage-listener'
import { useSignup, useResendSignUp, useLogin } from 'hooks/auth'
import { useUser } from 'hooks/context/user-context'

const RegisterContainer = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmSignUpSuccess, setConfirmSignUpSuccess] = useState(false)

  const { mutate: signUp, isLoading: signUpLoading, isSuccess: signUpSuccess } = useSignup()
  const { mutate: resendSignUp, isLoading: resendLoading } = useResendSignUp()
  const { mutate: login, isLoading: loginLoading } = useLogin()

  const { user, setAuthMessage } = useUser()

  const userHasCodeDelivery = user && user.codeDeliveryDetails

  const onSignUp = (values) => {
    const { email, password, givenName, familyName } = values

    setEmail(email)
    setPassword(password)
    signUp({ email, password, givenName, familyName })
  }

  const onSignIn = () => {
    if (!password || !email) return
    login({ email, password })
  }

  const handleSignUpSuccess = () => {
    if (!confirmSignUpSuccess) return
    onSignIn()
  }

  const onResendVerification = () => {
    if (!email) return

    resendSignUp(email)
  }

  useEffect(handleSignUpSuccess, [confirmSignUpSuccess])

  useEffect(() => {
    if (!userHasCodeDelivery) return
    setAuthMessage(MSG_VERIFICATION_LINK)
  }, [user])

  // check if use has verified his email (confirmed sign up) in this browser
  useEffect(() => {
    if (!signUpSuccess) return

    const emailConfirmedKey = getEmailConfirmedKey(email)
    const onEmailConfirmedAction = getOnEmailConfirmedAction(emailConfirmedKey, setConfirmSignUpSuccess)
    return listen(emailConfirmedKey, onEmailConfirmedAction)
  }, [signUpSuccess])

  const getForm = () => {
    if (userHasCodeDelivery) {
      return <GenericAction onSubmit={onSignIn} loading={loginLoading} text='Log in' altLoading={resendLoading} onAltClick={onResendVerification} altText='Resend verification' />
    }

    return <Register onSubmit={onSignUp} loading={signUpLoading} email={email} />
  }

  return getForm()
}

export default RegisterContainer
