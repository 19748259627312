import React from 'react'
import { Button, Col, Tooltip } from 'antd'
import classnames from 'classnames'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import styles from './styles.less'

const DEFAULT_SPAN = 11
const icon = (x) => <FontAwesomeIcon icon={x} color='#e9e8e8' />

const getIcon = (error, disabled, errorDismissed) => {
  if (error && !errorDismissed) {
    return icon(faTimes)
  }

  if (!disabled) {
    return icon(faCheck)
  }

  return ' '
}

const withTooltip = (element, text, visible) => {
  const tooltipText = <span>{text}<br />Click to dismiss this error.</span>

  return (
    <Tooltip title={tooltipText} open={visible}>
      {element}
    </Tooltip>
  )
}

class RegionButton extends React.PureComponent {
  constructor() {
    super()

    this.state = {
      buttonClicked: false,
      errorDismissed: false,
      displayLoading: false,
      hover: false
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.updating && !this.props.updating) {
      this.setState({
        displayLoading: false
      })

      if (this.state.buttonClicked) {
        this.setState({
          buttonClicked: false
        })
      }

      if (this.state.buttonClicked && this.state.errorDismissed) {
        this.setState({
          errorDismissed: false
        })
      }
    }
  }

  handleClick () {
    const { updating, errors, region, enableRegion, disableRegion, removeFromBlacklist } = this.props
    const { errorDismissed } = this.state
    const error = errors[region.region]

    if (updating) {
      return
    }

    if (error && !errorDismissed) {
      this.setState({
        errorDismissed: true,
        displayLoading: true
      })

      disableRegion(region.region)
      removeFromBlacklist(region.region)
      return
    }

    this.setState({
      buttonClicked: true,
      displayLoading: true
    })

    if (region.disabled) {
      return enableRegion(region.region)
    }

    return disableRegion(region.region)
  }

  render () {
    const { updating, region, errors } = this.props
    const { buttonClicked, errorDismissed, displayLoading } = this.state

    if (!region) {
      return <Col span={DEFAULT_SPAN} />
    }

    const error = errors[region.region]
    const buttonIcon = getIcon(error, region.disabled, errorDismissed)
    const buttonClassNames = classnames(styles.button, {
      [styles.button_active]: !error && !region.disabled,
      [styles.button_error]: error && !errorDismissed
    })
    const eventHandlingProps = {
      onClick: this.handleClick.bind(this),
      onMouseEnter: () => this.setState({ hover: true }),
      onMouseLeave: () => this.setState({ hover: false })
    }

    const button = (
      <Button
        loading={displayLoading && updating}
        shape='circle'
        size='small'
        className={buttonClassNames}
        {...eventHandlingProps}
      >
        {!(buttonClicked && updating) && buttonIcon}
      </Button>
    )

    return (
      <Col span={DEFAULT_SPAN} className={styles.container}>
        {
          error && !errorDismissed
            ? withTooltip(button, error.reason, this.state.hover)
            : button
        }
        <div
          className={styles.body}
          {...eventHandlingProps}
        >
          <h1>{region.name}</h1>
          <span>{region.region}</span>
        </div>
      </Col>
    )
  }
}

function mapStateToProps (state) {
  return {
    updating: get(state.waiting.list, 'PUT_INTEGRATION') || get(state.waiting.list, 'INTEGRATION_BLACKLIST_UPDATE')
  }
}

export default connect(mapStateToProps)(RegionButton)
