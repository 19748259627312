// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CfQkFIlHgulPU8ZWvqXi {\n  color: #666666;\n}\n.CfQkFIlHgulPU8ZWvqXi path {\n  fill: #666666;\n}\n.F_t3sNnY4LdtxDitaJjC {\n  font-size: 16px;\n  color: #666666;\n  padding: 0 3px;\n}\n.wU6uQstNglNGIYMIjmmJ {\n  width: 20px;\n}\n.AY_2PqBre8nj7xMjjRf7 {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  margin-right: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/settings/account/notifications/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAAA;EAII,aAAA;AADJ;AAKA;EACE,eAAA;EACA,cAAA;EACA,cAAA;AAHF;AAMA;EACE,WAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AALF","sourcesContent":["@import '../../../../styles/main';\n\n.icon {\n  color: @text-colors[dark];\n\n  path {\n    fill: @text-colors[dark];\n  }\n}\n\n.condition_type {\n  font-size: 16px;\n  color: @text-colors[dark];\n  padding: 0 @spacing[smallest];\n}\n\n.alarm_type {\n  width: 20px;\n}\n\n.condition_types {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  margin-right: @spacing[smaller];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "CfQkFIlHgulPU8ZWvqXi",
	"condition_type": "F_t3sNnY4LdtxDitaJjC",
	"alarm_type": "wU6uQstNglNGIYMIjmmJ",
	"condition_types": "AY_2PqBre8nj7xMjjRf7"
};
export default ___CSS_LOADER_EXPORT___;
