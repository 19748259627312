import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'RDS_CPU_UTILIZATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'cpu.utilization',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, [STATISTICS.MAX]]
  }
}, {
  id: 'RDS_DATABASE_CONNECTIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'database.connections',
    stats: [STATISTICS.MAX]
  }
}, {
  id: 'RDS_QUEUE_DEPTH',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'queue.depth',
    stats: [STATISTICS.COUNT]
  }
}, {
  id: 'RDS_FREEABLE_MEMORY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'freeable.memory',
    stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
  }
}, {
  id: 'RDS_FREE_STORAGE_SPACE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'free.storage.space',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_READ_OPERATIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'read.operations',
    stats: [STATISTICS.COUNT]
  }
}, {
  id: 'RDS_READ_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'read.latency',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_READ_THROUGHPUT',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'read.throughput',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_WRITE_OPERATIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'write.operations',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_WRITE_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'write.latency',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_WRITE_THROUGHPUT',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'write.throughput',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_REPLICA_LAG',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'replica.lag',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_NETWORK_INCOMING',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'network.receive.throughput',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_NETWORK_OUTGOING',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'network.transmit.throughput',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_BURST_BALANCE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'burst.balance',
    stats: [STATISTICS.MIN]
  }
}]

export default metrics
