import React from 'react'

import Items from 'components/inventory/items'

const ELBTargetGroups = ({ targetGroups }) => {
  const formatted = targetGroups?.map(targetGroup => {
    return {
      id: targetGroup.targetGroupArn,
      title: targetGroup.targetGroupName,
      url: `/inventory/${targetGroup.id}`,
      description: targetGroup.targetGroupArn
    }
  })
  return (
    <Items items={formatted} />
  )
}

export default ELBTargetGroups
