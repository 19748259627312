import React from 'react'
import { compact, uniqueId } from 'lodash'
import { mapStr, extractTime, removeLineStart } from './log-helper'

import style from './styles.less'

function mapString (content, isError, simplify) {
  let time = null

  return compact(content.split('\n').map((row, key) => {
    let line = row
    if (simplify) {
      time = extractTime(row)
      line = removeLineStart(row)
    }

    if (!line.trim()) { return }
    const classNames = [
      style.line,
      isError ? style.error : '',
      simplify ? style.simplified : ''
    ].join(' ')

    const infoString = time ? `  ${line}` : line
    return (
      <span className={classNames} key={uniqueId('log-')}>
        {[
          time || null,
          line.indexOf('START') !== -1 || line.indexOf('REPORT') !== -1 ? <span className={style.start_stop_line} key={uniqueId('log-')}>{infoString}</span> : infoString
        ]}
      </span>
    )
  }))
}

function renderString (str, { isError = false } = {}, timestamp, simplify) {
  const mappedStr = mapStr(str)
  return mappedStr.map(({ content, type }, index) => {
    if (type === 'raw') {
      return mapString(content, isError, timestamp, simplify)
    }
    return (
      <span className={style.search_term} key={index}>{content}</span>
    )
  })
}

function renderStructuredLogs (logs, errorId, simplify) {
  if (!logs) return null

  return logs.map(({ logline, id, eventId, timestamp }) => {
    return renderString(logline, {
      isError: errorId && (id === errorId || eventId === errorId)
    }, timestamp, simplify)
  })
}

const RawLog = ({ dataToDisplay = '', isText = false, isStacktrace = false, logStyle = '', errorId = null, simplify = true }) => {
  const logToDisplay = isStacktrace ? dataToDisplay : isText ? renderString(dataToDisplay) : renderStructuredLogs(dataToDisplay, errorId, simplify)

  return (
    <pre className={logStyle}>
      {logToDisplay}
    </pre>
  )
}

export default RawLog
