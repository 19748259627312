import React from 'react'
import classnames from 'classnames'
import { Radio as AntdRadio } from 'antd'

import styles from './styles.less'

const Radio = ({ items, bordered, ...props }) => {
  return (
    <div className={styles.radio_wrapper}>
      <AntdRadio.Group {...props}>
        {items.map(item => (
          <AntdRadio.Button key={item.value} value={item.value} className={classnames(styles.radio_button, { [styles.bordered]: !!bordered })}>
            {item.content}
          </AntdRadio.Button>
        ))}
      </AntdRadio.Group>
    </div>
  )
}

export default Radio
