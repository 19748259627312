import React from 'react'
import { Skeleton } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classnames from 'classnames'
import styles from './styles.less'

const Item = ({ icon, title, value, subvalue, critical, selected, onClick, tabNarrow, loading, fixed, drawer }) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={classnames(styles.item, {
        [styles.critical]: critical,
        [styles.clickable]: onClick,
        [styles.selected]: selected,
        [styles.fixed]: fixed,
        [styles.itemNarrow]: tabNarrow,
        [styles.drawer]: drawer
      })}
    >
      <div className={styles.topBar}>
        <div className={classnames(styles.title, { [styles.fixed]: fixed })}>{title}</div>
        {!React.isValidElement(icon)
          ? <FontAwesomeIcon icon={icon} />
          : icon}
      </div>
      <div className={classnames(styles.value, { [styles.fixed]: fixed })}>{loading ? <Skeleton className={classnames(styles.loading, selected && styles.selected)} paragraph={false} active /> : value}</div>
      {!selected && !fixed && <div className={styles.subvalue}>{subvalue}</div>}
    </div>
  )
}

export default Item
