import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Form, InputNumber, Radio, Select } from 'antd'

import { AlarmTermDurations, TermOperators, AggregationTypes } from 'lib/alerting-constants'
import { getResourcesByNotType, getResourcesByType } from 'lib/resources/filters'
import { SEVERITY_TYPES } from 'lib/event-constants'
import { filterInventoryByNamespace, INVENTORY_SERVICES } from 'lib/resources/constants'
import Targets from 'features/target-selector'
import { Typography } from 'components/typography'

import styles from './styles.less'

const findAlarmMetrics = (alarm) => filterInventoryByNamespace(alarm.namespace)[0]?.metrics

const AlarmCondition = ({ alarm, resources, onAddTarget, onRemoveTarget, groups, onChange }) => {
  const [inputWidth, setInputWidth] = useState(50)
  const [form] = Form.useForm()

  const lambdas = getResourcesByType(resources, INVENTORY_SERVICES.Lambda.type)
  const otherResources = getResourcesByNotType(resources, INVENTORY_SERVICES.Lambda.type)

  const defaultValues = {
    severity: alarm.severity,
    metric: alarm.metric,
    operator: alarm.operator,
    threshold: alarm.threshold,
    stat: alarm.stat,
    duration: alarm.duration,
    targets: alarm.targets.map(targetId => targetId.startsWith('group:') ? ({ id: targetId.split(':').pop(), type: 'group' }) : ({ id: targetId, type: 'resource' })) || []
  }

  const handleInputWidth = (value) => {
    if (!value) return
    const length = value?.toString()?.length

    if (length === 0) setInputWidth(50)
    if (length * 5 > 25) setInputWidth(40 + length * 5)
  }

  useEffect(() => handleInputWidth(alarm.threshold), [])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  const metrics = findAlarmMetrics(alarm)?.map(metric => {
    const { value, title } = metric
    return {
      value,
      title: title.toLowerCase()
    }
  }) || []

  const filterAggregationTypes = () => {
    const WITHOUT_SUM_AGGREGATION = ['memory_size', 'max_memory_used', 'percentage_memory_used']
    if (!WITHOUT_SUM_AGGREGATION.includes(alarm.metric)) return AggregationTypes

    return AggregationTypes.filter(type => type.value !== 'sum')
  }
  console.log(metrics?.map(item => ({ key: item.value, label: item.title })) || [])
  return (
    <div className={styles.condition}>
      <Form
        initialValues={defaultValues}
        layout='vertical'
        onValuesChange={(changed) => onChange && onChange(changed)}
        form={form}
      >
        <Form.Item name='severity' label='severity' className={styles.formItem}>
          <Radio.Group size='small'>
            <Radio.Button value={SEVERITY_TYPES.CRITICAL} className={styles.critical}>{SEVERITY_TYPES.CRITICAL}</Radio.Button>
            <Radio.Button value={SEVERITY_TYPES.WARNING} className={styles.warning}>{SEVERITY_TYPES.WARNING}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Typography.Title level={4} title='condition' className={styles.label} upperCase>Condition</Typography.Title>
        <div className={styles.row}>
          <Form.Item name='metric' className={classnames(styles.formItem, styles.marginRight)}>
            <Select
              className={styles.dropdown}
              dropdownMatchSelectWidth={false}
              options={metrics?.map(item => ({ value: item.value, label: item.title }))}
            />
          </Form.Item>
          <p>is</p>
          <Form.Item name='operator' className={classnames(styles.formItem, styles.marginBoth)}>
            <Select
              className={styles.dropdown}
              options={TermOperators.map(item => ({ value: item.value, label: item.title }))}
            />
          </Form.Item>
          <Form.Item name='threshold' trigger='onBlur' className={classnames(styles.formItem, styles.marginRight)}>
            <InputNumber controls={false} bordered={false} min={0} className={classnames(styles.threshold, styles.input_number)} onChange={handleInputWidth} style={{ width: inputWidth }} />
          </Form.Item>
          <p>on</p>
          <Form.Item name='stat' className={classnames(styles.formItem, styles.marginBoth)}>
            <Select
              className={styles.dropdown}
              options={filterAggregationTypes().map(item => ({ value: item.value, label: item.title }))}
            />
          </Form.Item>
          <p>over the last</p>
          <Form.Item name='duration' className={classnames(styles.formItem, styles.marginBoth)}>
            <Select
              className={styles.dropdown}
              options={AlarmTermDurations.map(item => ({ value: item.value, label: item.title }))}
            />
          </Form.Item>
        </div>
        <div className={classnames(styles.row, styles.target_row, styles.formItem)}>
          <Form.Item name='targets' label='Targets' className={styles.targets}>
            <Targets onSelect={onAddTarget} onDeselect={onRemoveTarget} lambdas={lambdas} resources={otherResources} resourceGroups={groups} filterOption={true} placeholder='Select targets' />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default AlarmCondition
