import React from 'react'

import TagsForm from './form'
import Section from 'components/layout/content/section'

const TagsFormContainer = ({ whitelist, blacklist, handlers }) => {
  return (
    <div>
      <Section title='Whitelist resources by tags'>
        <TagsForm onChange={whitelist => handlers.update({ whitelist })} tags={whitelist} />
      </Section>
      <Section title='Blacklist resources by tags'>
        <TagsForm onChange={blacklist => handlers.update({ blacklist })} tags={blacklist} />
      </Section>
    </div>
  )
}

export default TagsFormContainer
