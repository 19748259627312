import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'
import { LongArrowRightIcon, ArrowBackIcon } from 'components/icons/font-awesome'
import SkeletonLine from 'components/skeleton-line'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery } from 'hooks/api'

import styles from './styles.less'

const Breadcrumbs = ({ breadcrumbs = [], className, backRoute, loading }) => {
  const { search } = useLocation()

  const { organization, account } = useOrganization()
  const { isLoading } = useOrganizationsQuery()

  const filtered = breadcrumbs?.filter(item => item)

  const getBackUrl = () => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })
    return { pathname: backRoute || '', search: newValue }
  }

  return (
    <div className={classnames(styles.container, className)}>
      {backRoute && <Link to={() => getBackUrl()} className={styles.back}><ArrowBackIcon /></Link>}

      <span className={styles.breadcrumb}>
        {isLoading
          ? <SkeletonLine className={styles.breadcrumb_loading} />
          : organization.name}
      </span>

      {[account.name, ...filtered].map((breadcrumb, index) => (
        <span key={index} className={styles.breadcrumb}>
          <LongArrowRightIcon className={styles.arrow} />
          {(isLoading || !account?.name) && index === 0 && <SkeletonLine className={styles.breadcrumb_loading} />}
          {loading && index !== 0 ? <SkeletonLine className={styles.breadcrumb_loading} /> : breadcrumb}
        </span>
      ))}
    </div>
  )
}

export default Breadcrumbs
