import React from 'react'
import { Radio, Checkbox } from 'antd'

import { ErrorTypes } from 'lib/policy-constants'
import { ErrorIcon } from 'components/icons'
import Actions from './actions'
import TargetSelector from 'features/target-selector'
import Select from 'components/antd/select'

import { getResourcesByType } from 'lib/resources/filters'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { SEVERITY_TYPES } from 'lib/event-constants'
import styles from './styles.less'

const ErrorForm = ({ mutations, condition, resources, lambdas, groups, disableEditing }) => {
  const selectableAppsync = getResourcesByType(resources, INVENTORY_SERVICES.AppSyncGraphQLApi.type)

  const handlers = mutations({ condition })
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <ErrorIcon /><span>Errors</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>error types</span>
          <span className={styles.value}>{condition?.errors?.length ? condition.errors.length : 'ALL'}</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>resources</span>
          <span className={styles.value}>{condition?.targets.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} disableEditing={disableEditing} />
        </div>
      </div>
      <section>
        <Radio.Group
          defaultValue={condition.occurrence}
          onChange={(e) => handlers.update({ occurrence: e.target.value })}
        >
          <Radio.Button value='new' disabled={disableEditing}>Only new and reopened events</Radio.Button>
          <Radio.Button value='all' disabled={disableEditing}>All events</Radio.Button>
        </Radio.Group>
      </section>
      <section>
        <span className={styles.label}>Severities</span>
        <Checkbox.Group
          options={[SEVERITY_TYPES.CRITICAL, SEVERITY_TYPES.WARNING, SEVERITY_TYPES.INFO]}
          defaultValue={condition.severities}
          onChange={(values) => handlers.update({ severities: values })}
          disabled={disableEditing}
        />
      </section>
      <section>
        <span className={styles.label}>Filter by error types</span>
        <Select
          solid
          mode='multiple'
          defaultValue={condition.errors}
          placeholder='Select error types'
          onChange={(values) => handlers.update({ errors: values })}
          options={ErrorTypes.map(item => ({ disabled: disableEditing, title: item.title, id: item.value, value: item.value }))}
        />
      </section>
      <section>
        <span className={styles.label}>Filter by resources</span>
        <TargetSelector
          resourceGroups={groups}
          lambdas={lambdas}
          resources={selectableAppsync}
          onSelect={(value) => handlers.targets(value).add()}
          onDeselect={(value) => handlers.targets(value).remove()}
          value={condition.targets}
          prefixValues
          placeholder='Select resources'
        />
      </section>
    </div>
  )
}

export default ErrorForm
