import React from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'antd'

import { Typography } from 'components/typography'
import Content from 'components/layout/content'
import Channels from './channels'
import Policies from './policies'

import { useAccountRoutes } from 'containers/routes'
import { useNotificationChannelsQuery, useNotificationPoliciesQuery, useCreateNotificationPolicyQuery } from 'hooks/api'

const mutations = ({ history, routes, createPolicy }) => {
  return {
    policies: {
      create: async () => {
        try {
          const policy = await createPolicy({ payload: { name: 'Untitled policy' } })
          history.push(routes.settings.notifications.policies.policy.url({ policyId: policy.id }))
        } catch { }
      }
    }
  }
}

const Notifications = () => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { data: policies, isLoading: policiesLoading } = useNotificationPoliciesQuery()
  const { data: channels, isLoading: channelsLoading } = useNotificationChannelsQuery()
  const { mutateAsync: createPolicy, isLoading: isCreating } = useCreateNotificationPolicyQuery()

  const handlers = mutations({ history, routes, createPolicy })

  return (
    <Content item={{}} title='Notifications' breadcrumbs={['settings', 'notifications']}>
      <Typography.Paragraph>To receive notifications of errors, insights and alarms, add new channel and attach it to a policy </Typography.Paragraph>
      <Row gutter={[8, 8]}>
        <Col span={16}>
          <Policies loading={policiesLoading} policies={policies} channels={channels} handlers={handlers.policies} creating={isCreating} />
        </Col>
        <Col span={8}>
          <Channels loading={channelsLoading} channels={channels} />
        </Col>
      </Row>
    </Content>
  )
}

export default Notifications
