import { combineReducers } from 'redux'
import { GET_USER, GET_USERS, UPDATE_USER } from 'actions/organization.actions'

function active (state = {}, action) {
  switch (action.type) {
    case GET_USER:
      return action.payload
    case UPDATE_USER:
      return action.payload
  }
  return state
}

function list (state = [], action) {
  switch (action.type) {
    case GET_USERS:
      return action.payload
  }
  return state
}

export default combineReducers({
  active, list
})
