import React, { useCallback, useState } from 'react'
import { Button, Form, Radio } from 'antd'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import Targets from 'features/target-selector'

import { getResourcesByType } from 'lib/resources/filters'

import styles from './styles.less'

const DEFAULT_VALUES = {
  targets: [],
  flag: null
}

const InvocationsWidgetDetails = ({ editing, widget, kind, resources, handlers, onClose }) => {
  const [values, setValues] = useState(!editing
    ? DEFAULT_VALUES
    : {
      targets: widget.definition.targets,
      flag: widget.definition.flag || null
    }
  )

  const [form] = Form.useForm()

  const lambdas = getResourcesByType(resources, INVENTORY_SERVICES.Lambda.type)

  const handleFormSubmit = useCallback((payload) => {
    if (editing) {
      handlers.widgets.update(widget, payload)
    } else {
      handlers.widgets.add(kind, payload)
    }

    onClose()
  }, [handlers, widget, editing, kind])

  const handleFormChange = (change) => setValues({ ...values, ...change })

  return (
    <>
      <Form
        initialValues={values}
        layout='vertical'
        form={form}
        onFinish={handleFormSubmit}
        onValuesChange={handleFormChange}
      >
        <Form.Item name='targets' label='Targets'>
          <Targets
            resources={lambdas}
            filterOption={true}
            placeholder='Select lambda'
          />
        </Form.Item>

        <Form.Item name='flag' label='Execution flag'>
          <Radio.Group size='small'>
            <Radio.Button value={null}>Successful</Radio.Button>
            <Radio.Button value='isError'>Error</Radio.Button>
            <Radio.Button value='isColdstart'>Coldstart</Radio.Button>
            <Radio.Button value='isRetry'>Retry</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
          >
            {editing ? 'Update widget' : 'Add widget'}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default InvocationsWidgetDetails
