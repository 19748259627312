// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fl4Y4tjSGXi5hMhI03Lw {\n  border: none;\n  padding: 0;\n  /* border-bottom: 1px dashed rgba(0, 0, 0, 0.43); */\n  border-radius: 0;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/minimal-input/styles.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,mDAAmD;EACnD,gBAAA;EACA,eAAA;AACF","sourcesContent":[".input {\n  border: none;\n  padding: 0;\n  /* border-bottom: 1px dashed rgba(0, 0, 0, 0.43); */\n  border-radius: 0;\n  cursor: pointer;\n}\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "Fl4Y4tjSGXi5hMhI03Lw"
};
export default ___CSS_LOADER_EXPORT___;
