import React, { useEffect, useState } from 'react'
import { isEmpty, keys, sortBy } from 'lodash'

import TableLayout from 'components/settings/table-layout/index'
import styles from './styles.less'
import moment from 'moment'

import { useAccountRoutes } from 'containers/routes'

const UsersTable = ({ users = [], org, setModalVisible, loading }) => {
  const [displayUsers, setDisplayUsers] = useState('all')
  const [formattedUsers, setFormattedUsers] = useState([])

  const routes = useAccountRoutes()

  useEffect(() => {
    if (isEmpty(users)) return

    setFormattedUsers(users?.filter(user => user)?.map(user => {
      const permissions = keys(user?.permissions).reduce((permissions, accId) => {
        const role = user?.permissions[accId] || 'owner'
        if (role === 'no-access') return permissions

        const account = org.accounts.find(acc => acc.id === accId)

        if (account) permissions.push(`${account.name} (${role})`)

        return permissions
      }, [])

      let status = 'active'
      if (user?.expiresAt) {
        status = new Date(user?.expiresAt) < Date.now() ? 'expired' : 'pending'
      }

      return {
        key: user.id,
        email: user.email,
        status,
        timestamp: user?.expiresAt || user.createdAt,
        role: user.role,
        access: user.role === 'owner' ? ['All accounts'] : permissions,
        isInvite: !!user?.expiresAt
      }
    }))
  }, [users])

  const columns = [{
    title: 'Email',
    key: 'email',
    sorter: (a, b) => a.email.localeCompare(b.email),
    sortDirections: ['descend', 'ascend'],
    render: record => record.email
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: val => <span className={styles[val]}>{val}</span>
  }, {
    title: 'Role',
    dataIndex: 'role',
    key: 'role'
  }, {
    title: 'Access',
    dataIndex: 'access',
    key: 'access',
    render: val => {
      return val.join(', ')
    }
  }, {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (val, record) => val
      ? <span>{record.isInvite ? 'Expiration ' : 'Created '}{moment(val).fromNow()}</span>
      : null
  }]

  const options = [
    { val: 'active', name: 'Active' },
    { val: 'pending', name: 'Pending' },
    { val: 'expired', name: 'Expired' }
    // { val: 'disabled', name: 'Disabled' }
  ]

  const filteredByStatus = displayUsers !== 'all' ? formattedUsers.filter(item => item.status === displayUsers) : formattedUsers
  const sortedByRole = sortBy(filteredByStatus, user => user.role)

  return (
    <TableLayout
      loading={loading}
      columns={columns}
      data={sortedByRole}
      filterOptions={options}
      setDataFilter={setDisplayUsers}
      setModalVisible={setModalVisible}
      rowClickUrl={(record) => routes.settings.users.user.url({ userId: record.key })}
      tableTitle={'user'}
      showAddButton
    />
  )
}

export default UsersTable
