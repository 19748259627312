import React from 'react'
import { first } from 'lodash'

import XRayTrace from './traces'
import ExternalLinkButton from 'components/buttons/external-link'

const renderDisabled = () => {
  return <div>Tracing not enabled <ExternalLinkButton link='https://dashbird.io/docs/quickstart/enable-x-ray/' title='Click here to see how to set it up' /></div>
}

const renderEmpty = () => {
  return <div>Trace not found</div>
}

const Trace = ({ loading, enabled, traces }) => {
  if (!enabled) return renderDisabled()
  if (loading) return null
  if (!traces || !traces.length) return renderEmpty()

  return <XRayTrace trace={first(traces) || {}} />
}

export default Trace
