import React from 'react'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import { Select as AntdSelect } from 'antd'

import styles from './styles.less'

const { Option } = AntdSelect

// solid prop changes select box background to grey
const Select = React.forwardRef(({ bordered = false, solid, label, className, optionRender, options, children, ...props }, ref) => {
  const empty = isEmpty(props.value) && isEmpty(props.defaultValue)

  return (
    <div className={classnames(label && styles.select_wrapper)}>
      <AntdSelect
        {...props}
        ref={ref}
        bordered={bordered}
        style={{ width: '100%' }}
        className={classnames(styles.select, { [styles.solid]: !!solid, [styles.bordered]: !!bordered }, className)}
      >
        {options?.map(item =>
          <Option key={item?.id || item?.value || item} value={item?.value || item} name={item?.title} className={styles.option} disabled={item?.disabled}>
            {optionRender ? optionRender({ item }) : (item?.title || item)}
          </Option>
        )}
        {children}
      </AntdSelect>
      {label && <label className={classnames(styles.label, !empty && styles.not_empty)}>{label}</label>}
    </div>
  )
})

export default Select
