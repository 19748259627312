import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { differenceInMilliseconds, formatDistanceToNowStrict, sub } from 'date-fns'
import classnames from 'classnames'
import { some, isEmpty } from 'lodash'

import ListItem from 'features/side-list/item'
import Section from 'components/layout/content/section'
import Chart from './chart'
import RangePicker from 'components/date-picker/chart'
import { AwsServiceIcon } from 'components/icons'
import { findInventoryByType } from 'lib/resources/constants'
import { ChevronDownIcon, LinkIcon } from 'components/icons/font-awesome'

import { getLocalPeriod, getCalculatedRange, getMetricResourceDimensions } from 'lib/metric-helpers'
import { useAccountRoutes } from 'containers/routes'
import { VIOLATION_STATUSES } from 'lib/event-constants'

import { useChartMetricsQuery, useAllResourcesQuery } from 'hooks/api'

import styles from './styles.less'

const Details = ({ violation }) => (
  <span className={styles.details}>
    {violation?.status === VIOLATION_STATUSES.RESOLVED && <span className={styles.resolved}>RESOLVED <span>|</span></span>}
    <span>{violation?.resource?.region?.toUpperCase() || 'REGION'}  |</span>
    {violation?.resource?.delegationName && <span>{violation?.resource?.delegationName} |</span>}
    <span>{formatDistanceToNowStrict(violation?.openedAt)} ago</span>
  </span>
)

const Title = ({ title, id, routes }) => {
  if (!id) return <p className={styles.violation_title}>{'<resource deleted or missing>'}</p>
  return (
    <>
      <Link to={routes.inventory.resource.url({ resourceId: id })} className={styles.violation_title} onClick={e => e.stopPropagation()}>{title}</Link>
      <LinkIcon className={styles.link_icon} />
    </>
  )
}

const ViolationItem = ({ violation, onClick, selected }) => {
  const routes = useAccountRoutes()
  const { data: resources } = useAllResourcesQuery()
  const NO_METRICS = isEmpty(violation.metrics)

  const [range, setRange] = useState(getCalculatedRange(violation).range)
  const [start, setStart] = useState(getCalculatedRange(violation).start)
  const [end, setEnd] = useState(getCalculatedRange(violation).end)

  const payloads = useMemo(() => violation?.resource
    ? violation?.metrics.map(metric => ({
      payload: {
        dimensions: { resources: getMetricResourceDimensions(violation?.resource, resources, metric.metric) },
        namespace: metric?.namespace,
        metric: metric.metric,
        stats: [metric.stat],
        start: new Date(start),
        end: new Date(end),
        period: getLocalPeriod(start, end)
      }
    }))
    : [], [violation?.resource, resources, start, end])

  const data = useChartMetricsQuery(payloads)

  const mapData = (data) => data?.map(({ data: metricData }) => {
    return {
      start: metricData?.start,
      end: metricData?.end,
      period: metricData?.period,
      metric: metricData?.metric,
      namespace: metricData?.namespace,
      stat: metricData?.stats?.[0],
      resources: [{ id: violation?.resource?.id }],
      values: metricData?.datapoints?.default?.map(datapoint => datapoint?.[metricData?.stats?.[0]]),
      timestamps: metricData?.datapoints?.default?.map(datapoint => datapoint?.date)
    }
  })

  const onChange = (start, end) => {
    setStart(start)
    setEnd(end)
    setRange(differenceInMilliseconds(end, start))
  }

  return (
    <>
      <ListItem
        title={<Title title={violation?.resource?.title} id={violation?.resource?.id} routes={routes} />}
        icon={<AwsServiceIcon service={findInventoryByType(violation?.resource?.type)?.service} />}
        left={<Details violation={violation} />}
        right={!NO_METRICS && <ChevronDownIcon className={styles.arrow_icon} {...selected ? { rotation: 180 } : {}} />}
        className={classnames(styles.violation_item, selected && styles.opened, selected && NO_METRICS && styles.no_metrics, NO_METRICS && styles.cancel_click)}
        onClick={!NO_METRICS ? () => onClick(violation?.id) : () => false}
      />
      {selected && !NO_METRICS && (
        <Section solid disableUserSelection cancelMargin>
          <Chart data={mapData(data)} loading={some(data, 'isLoading')} span={range} threshold={violation?.threshold} />
          <RangePicker
            onChange={onChange}
            min={sub(new Date(), { months: 1 })}
            max={Date.now()}
            start={start}
            end={end}
          />
        </Section>
      )}
    </>
  )
}

export default ViolationItem
