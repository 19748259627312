import React from 'react'
import { Popconfirm } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { DELETE_PAYMENT_METHOD } from 'actions/billing.actions'
import { API } from 'actions/api'
import { useOrganization } from 'hooks/context/organization-context'

import DeleteButton from 'components/buttons/delete'
import styles from './styles.less'

const RemoveButton = ({ entityId, disabled }) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()
  const deleting = useSelector(state => state.waiting.list[DELETE_PAYMENT_METHOD])

  const deleteMethod = (cardId) => dispatch(API.organizations.id(organization.id).billing.payment.methods.id(cardId).delete())

  const tooltipText = disabled ? 'To remove your card, please contact your account manager or write to support@dashbird.io' : 'Remove card'

  return (
    <Popconfirm
      title='Are you sure you want to remove this card?'
      onConfirm={() => deleteMethod(entityId)}
      okText='Yes'
      cancelText='No'
      disabled={disabled}
    >
      <DeleteButton
        title={tooltipText}
        loading={deleting}
        className={disabled && styles.disabled}
      />
    </Popconfirm>
  )
}

export default RemoveButton
