// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".futvAaKIhF4joV_srFTS {\n  display: inline-block;\n  min-width: 60px;\n  height: 100%;\n}\n.futvAaKIhF4joV_srFTS .ant-skeleton-title {\n  margin: 0;\n}\n.LgKhkyz9q7j6W399MYJH {\n  display: flex;\n}\n.DTvLEi0FFlRp5Jm95D7_ .ant-skeleton-title {\n  height: 10px;\n}\n.o2HYwfl2tl7WTO7Rz11h {\n  width: 100%;\n}\n.o2HYwfl2tl7WTO7Rz11h .ant-skeleton-title {\n  height: 24px;\n  width: 25%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/skeleton-line/styles.less"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,eAAA;EACA,YAAA;AADF;AAFA;EAMI,SAAA;AADJ;AAKA;EACE,aAAA;AAHF;AAMA;EAEI,YAAA;AALJ;AASA;EACE,WAAA;AAPF;AAMA;EAII,YAAA;EACA,UAAA;AAPJ","sourcesContent":["@import '../../styles/main';\n\n.loading {\n  display: inline-block;\n  min-width: 60px;\n  height: 100%;\n\n  :global(.ant-skeleton-title) {\n    margin: 0\n  }\n}\n\n.flex {\n  display: flex;\n}\n\n.xs {\n  :global(.ant-skeleton-title) {\n    height: 10px;\n  }\n}\n\n.lg-25 {\n  width: 100%;\n\n  :global(.ant-skeleton-title) {\n    height: @text-sizes[extra-large];\n    width: 25%;\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "futvAaKIhF4joV_srFTS",
	"flex": "LgKhkyz9q7j6W399MYJH",
	"xs": "DTvLEi0FFlRp5Jm95D7_",
	"lg-25": "o2HYwfl2tl7WTO7Rz11h"
};
export default ___CSS_LOADER_EXPORT___;
