// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WLQg1aPDi8BdrsJx8i2l {\n  color: #7ed321;\n}\n.AV6tcgZKeufHG9manxGT {\n  color: #f91111;\n}\n.fgHRxwKtd7mjrNd7uqkO {\n  color: #faad14;\n}\n.tiQNAUPIbXftDnn6jdJc {\n  display: inline-block;\n  margin-right: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/invocations/details/appsync-request/metrics/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAIA;EACE,cAAA;AAFF;AAKA;EACE,cAAA;AAHF;AAMA;EACE,qBAAA;EACA,iBAAA;AAJF","sourcesContent":["@import '../../../../../styles/main';\n\n.success {\n  color: @status-colors[success];\n}\n\n.error {\n  color: @status-colors[critical];\n}\n\n.warning {\n  color: @status-colors[warning];\n}\n\n.flag {\n  display: inline-block;\n  margin-right: @spacing[smaller];\n\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "WLQg1aPDi8BdrsJx8i2l",
	"error": "AV6tcgZKeufHG9manxGT",
	"warning": "fgHRxwKtd7mjrNd7uqkO",
	"flag": "tiQNAUPIbXftDnn6jdJc"
};
export default ___CSS_LOADER_EXPORT___;
