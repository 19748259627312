import React from 'react'

import { Button, Dropdown, Popconfirm } from 'antd'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import IconButton from 'components/buttons/icon'
import { AddIcon } from 'components/icons'
import { WIDGET_KIND } from 'lib/dashboards-constants'

import styles from './styles.less'

export const Actions = ({ item, onDelete }) => {
  if (!item) return null

  const confirm = `Are you sure you want to delete ${item.name}?`
  const items = [
    {
      key: 'delete',
      label: <Popconfirm title={confirm} onConfirm={() => onDelete(item.id)}>
        <span>Delete dashboard</span>
      </Popconfirm>
    }
  ]

  return (
    <Dropdown menu={items} trigger={['click']}>
      <IconButton icon={faEllipsisV} className={styles.extra} />
    </Dropdown>
  )
}

export const TitleActions = ({ item, loading, onCreateWidget }) => {
  if (!item || loading) return null

  const items = [
    {
      key: 'metrics',
      label: 'Metrics widget'
    }, {
      key: 'invocations',
      label: 'Invocations widget'
    }, {
      key: 'events',
      label: 'Events widget'
    }, {
      key: 'text',
      label: 'Text widget'
    }
  ]

  const onClick = ({ key }) => {
    switch (key) {
      case 'metrics':
        onCreateWidget(item, WIDGET_KIND.METRICS)
        break
      case 'invocations':
        onCreateWidget(item, WIDGET_KIND.INVOCATIONS)
        break
      case 'events':
        onCreateWidget(item, WIDGET_KIND.EVENTS)
        break
      case 'text':
        onCreateWidget(item, WIDGET_KIND.TEXT)
        break
      default:
        break
    }
  }

  return (
    <Dropdown menu={{ items, onClick }} trigger={['click']}>
      <div className={styles.actions}>
        <Button
          type='link'
          size='small'
          className={styles.button}
        >
          Add widget
          <AddIcon />
        </Button>
      </div>
    </Dropdown>
  )
}
