import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { findInventoryByType } from 'lib/resources/constants'
import { useAllResourcesQuery, useGetQueryQuery } from 'hooks/api'

import Section from 'components/layout/content/section'
import { Resource } from 'components/items/progress-item'
import Empty from 'components/layout/content/empty'
import { SpinnerIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const TargetResources = ({ data, loadingExecutions }) => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('selected', [])
  const [targetResources, setTargetResources] = useState([])
  const { data: resources, isLoading: loadingResources, isSuccess: resourcesFetched } = useAllResourcesQuery()
  const { data: currentQuery, isLoading: loadingQuery } = useGetQueryQuery()

  const getItems = (targets, executions, loading) => {
    return Object.keys(resources).filter(item => targets?.includes(item)).map(resourceId => ({
      count: loadingExecutions || currentQuery?.service !== 'lambda' ? null : executions?.filter(item => item.resource === resourceId)?.length,
      extra: loading ? <SpinnerIcon className={styles.spinner} /> : null,
      title: resources[resourceId].region,
      description: resources[resourceId].title,
      id: resourceId,
      service: findInventoryByType(resources[resourceId]?.type)?.service
    }))
  }

  const getTargetResources = () => {
    if (!resourcesFetched || !currentQuery) return

    // maps from all executions
    if (isEmpty(currentQuery?.targets)) {
      const executionTargets = data?.map(item => item.resource)
      return setTargetResources(getItems(executionTargets, data))
    }
    return setTargetResources(getItems(currentQuery?.targets.filter(item => !item.includes('group')), data, loadingExecutions))
  }

  useEffect(getTargetResources, [data, resourcesFetched, currentQuery?.id])

  return (
    <Section title='Filter by resources' loading={loadingQuery || loadingResources || (isEmpty(currentQuery?.targets) && loadingExecutions)} titleUpperCase>
      {isEmpty(targetResources) && <Empty>No single resources found as query targets</Empty>}
      {targetResources?.map(item => {
        return (
          <Resource
            {...item}
            key={item.id}
            onSelect={(item) => setSelected(filterHelper(item, selected))}
            selected={selected}
          />
        )
      })}
    </Section>
  )
}

export default TargetResources
