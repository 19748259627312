import React from 'react'
import { Button, Form } from 'antd'
import classnames from 'classnames'

import styles from './styles.less'

const GenericFormButton = ({ onClick, loading, text, icon, titleUpperCase }) => {
  return <Form.Item className={styles.item}>
    <Button
      type='default'
      htmlType='button'
      loading={loading}
      onClick={onClick}
      className={classnames(styles.button, { [styles.uppercase]: titleUpperCase })}>
      {text} {icon}
    </Button>
  </Form.Item>
}

export default GenericFormButton
