import React from 'react'
import { withBoundingRects } from '@visx/bounds'
import { Tooltip } from '@visx/tooltip'
import { formatDuration } from 'components/charts/utils'
import JsonPrettifier from 'components/json-pretty/prettifier'
import IconButton from 'components/buttons/icon/index'
import { CloseIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const SegmentTooltip = ({ data, handleClose, left, top, rect, parentRect }) => {
  return (
    <Tooltip
      key={Math.random()}
      top={rect?.height > parentRect?.top + parentRect?.height ? parentRect.top : top}
      left={left > parentRect?.width - rect?.width ? left - rect?.width - 10 : left}
      className={styles.tooltip}
      unstyled
    >
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h4 className={styles.title}>{`${data.content} (${formatDuration(data.end - data.start)})`}</h4>
          <IconButton onClick={() => handleClose()} icon={<CloseIcon />} title='Close' size='1x' />
        </div>
        {data.data && <JsonPrettifier jsonObj={data.data || {}} collapsed={false} />}
      </div>
    </Tooltip>
  )
}

export default withBoundingRects(SegmentTooltip)
