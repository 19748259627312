import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form } from 'antd'
import Input from 'components/antd/input'
import { GET_PROMOTION_V2, GET_PROMOTION_V2_ERROR } from 'actions/billing-v2'

import styles from '../styles.less'

const discountValidator = async (rule, value, setValidateStatus, setPromotion, fetch, dispatch) => {
  if (!value || value === '') {
    setValidateStatus('')
    return Promise.resolve()
  }

  const { type, payload } = await dispatch(fetch({ promotionCode: value }))

  if (type === GET_PROMOTION_V2_ERROR) {
    setValidateStatus('error')
    throw new Error(rule.message)
  }

  setPromotion(payload?.data?.find(item => item.code.toLowerCase() === value.toLowerCase()))
  setValidateStatus('success')
  return Promise.resolve()
}

const PromoCodeForm = ({ fetch, promotion, setPromotion }) => {
  const dispatch = useDispatch()
  const fetching = useSelector(state => state.waiting.list[GET_PROMOTION_V2])
  const [validateStatus, setValidateStatus] = useState(promotion?.code ? 'success' : '')

  return (
    <Form className={styles.coupon_form} layout='inline' initialValues={{ promotion: promotion?.code }} validateTrigger='onSubmit'>
      <Form.Item
        name='promotion'
        label='Coupon code'
        hasFeedback
        validateStatus={validateStatus}
        rules={[{ validator: (rule, value) => discountValidator(rule, value, setValidateStatus, setPromotion, fetch, dispatch), message: 'Invalid code' }]}>
        <Input placeholder='Code' solid />
      </Form.Item>
      <Form.Item className={styles.coupon_input} noStyle>
        <Button loading={fetching} type='secondary' htmlType='submit'>Apply</Button>
      </Form.Item>
    </Form>
  )
}

export default PromoCodeForm
