import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Tabs from 'components/antd/tabs'
import Login from '../login/form'
import Register from '../register/form'
import RegisterConfirm from '../register/confirm-form'

import { useSignup, useLogin, useConfirmSignup } from 'hooks/auth'
import { useUser } from 'hooks/context/user-context'

const MarketplaceContainer = () => {
  const history = useHistory()
  const { token } = useParams()

  const { mutate: signUp } = useSignup()
  const { mutateAsync: confirmSignup } = useConfirmSignup()
  const { mutateAsync: login, isLoading: loadingLogin } = useLogin()
  const { user, logout } = useUser()

  const [email, setEmail] = useState('')

  useEffect(() => {
    logout()
  }, [])

  const onSignUp = async (values) => {
    const { email, password } = values
    setEmail(email)
    signUp({ email, password })
  }

  const onConfirmSignUp = async (values) => {
    const { email, code } = values
    try {
      await confirmSignup({ email, code })
      history.push(`/onboarding/${token}`)
    } catch { }
  }

  const onSubmitLogin = async (values) => {
    const { email, password } = values
    try {
      login({ email, password })
      history.push(`/onboarding/${token}`)
      // await dispatch(API.organizations.id(payload.organization?.id).billing.marketplace.post({ token, email: values.email, name: payload?.organization?.name }))
    } catch { }
  }

  const getForm = () => {
    if (user && user.codeDeliveryDetails) return <RegisterConfirm onSubmit={onConfirmSignUp} email={email} />
    return <Register onSubmit={onSignUp} />
  }

  return (<Tabs defaultActiveKey='login' style={{ width: '100%' }} items={[
    {
      key: 'login',
      label: 'Login',
      children: (
        <Login
          onSubmit={onSubmitLogin}
          loading={loadingLogin}
        />
      )
    },
    { key: 'signup', label: 'Sign up', children: getForm() }
  ]} />
  )
}

export default MarketplaceContainer
