import React from 'react'
import { tail, compact } from 'lodash'
import { Skeleton } from 'antd'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'

import Items from 'components/inventory/items'
import IconButton from 'components/buttons/icon'
import { objectify } from 'lib/utils'
import styles from './styles.less'

const LoadMoreBtn = ({ onLoadMore, loading }) => {
  return (
    <IconButton
      title='Load more'
      onClick={onLoadMore}
      className={styles.icon_btn}
      icon={faEllipsisH}
      size='2x'
      loading={loading}>
      Load more
    </IconButton>
  )
}

const ECSTasks = ({ taskCount, tasks, nextTasks, loading, onLoadMore }) => {
  const formatted = compact(tasks?.map(task => {
    if (!task) return
    const attributes = objectify(task.attributes)
    const healthy = attributes.lastStatus === attributes.desiredStatus
    const timestamp = attributes.lastStatus === 'RUNNING' ? attributes.startedAt : attributes.stoppedAt

    return {
      id: task.arn,
      title: task.name,
      description: tail(attributes.taskDefinitionArn.split('/')),
      time: timestamp,
      background: healthy ? 'white' : 'pink'
    }
  }))

  return (
    <>
      <Items items={formatted} />
      <div className={styles.load_container}>
        {loading && <Skeleton active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />}
        {!loading && tasks && taskCount > tasks.length && <LoadMoreBtn onLoadMore={onLoadMore} loading={loading} />}
      </div>
    </>
  )
}

export default ECSTasks
