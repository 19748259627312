import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Skeleton, message } from 'antd'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { API } from 'actions/api'
import { isMethodBeingAdded, CREATE_PAYMENT_METHOD_SETUP_V2, PAYMENT_METHOD_ADDED } from 'actions/billing-v2'
import BillingForm from './billing-form'
import validateBillingInfo from 'containers/settings/billing/payment/billing-info/modal/validate'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const submitMethod = async (stripe, elements, setup, organization) => {
  if (!stripe || !elements || !setup) throw new Error('Stripe is not loaded')

  const result = await stripe.confirmCardSetup(setup.secret, {
    payment_method: {
      card: elements.getElement(CardElement),
      metadata: { organization: organization?.id }
    }
  })

  if (result.error) {
    throw new Error(result.error.message)
  }

  return { stripeId: result.setupIntent.payment_method, type: result.setupIntent.payment_method_types[0] }
}

const AddMethodModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const formRef = useRef()

  const { organization } = useOrganization()

  const customer = useSelector(state => state.billingV2.customer)
  const loadingSetup = useSelector(state => state.waiting[CREATE_PAYMENT_METHOD_SETUP_V2])
  const setup = useSelector(state => state.billingV2.setup)
  const settingUpPayment = useSelector(state => state.waiting.list[PAYMENT_METHOD_ADDED])

  const [error, setError] = useState(null)

  const shouldUpdateAddress = customer && Object.values(customer?.address).every(x => (!x || x === ''))

  useEffect(() => {
    if (visible) {
      dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.setup.post())
    }
  }, [visible])

  const handleClose = () => {
    dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.setup.delete(setup?.id))
    setVisible(false)
  }

  const handleSubmitMethod = async () => {
    let billingInfo
    if (shouldUpdateAddress) {
      billingInfo = await validateBillingInfo(formRef?.current)
      if (!billingInfo) {
        return null
      }
    }
    setError(null)
    dispatch(isMethodBeingAdded(true))

    try {
      const paymentMethod = await submitMethod(stripe, elements, setup, organization)
      dispatch(API.organizations.id(organization.id).billingV2.paymentMethods.post(paymentMethod))

      if (shouldUpdateAddress) {
        await dispatch(API.organizations.id(organization.id).billingV2.customer.id(customer.id).put(billingInfo))
      }

      message.success('Payment method created')
      setVisible(false)
    } catch (error) {
      setError(error.message)
    } finally {
      dispatch(isMethodBeingAdded(false))
    }
  }

  return (
    <Modal
      open={visible}
      onOk={() => handleSubmitMethod()}
      onCancel={() => handleClose()}
      confirmLoading={settingUpPayment}
      className={styles.modal}
      title='Add new payment method'
    >
      <Skeleton loading={loadingSetup}>
        {shouldUpdateAddress && <BillingForm customer={customer} ref={formRef} />}
        <CardElement className={styles.card_input} />
        {error && <p className={styles.error}>{error}</p>}
      </Skeleton>
    </Modal>
  )
}

export default AddMethodModal
