import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ComposedChart, ResponsiveContainer, XAxis, YAxis, Legend, Tooltip, Customized } from 'recharts'
import numeral from 'numeral'

import format from './format'
import { updateActiveMetric } from 'actions/metric.actions'

import ChartLegend from 'components/charts/legend'
import Loading from 'components/layout/content/loading'
import Empty from './empty'

import { RefLine, XAxisTick } from 'components/charts'
import { chartMargin, xAxisProps, yAxisProps, tooltipProps, chartHeight } from 'components/charts/utils'
import { SQS_DIFFERENCE } from 'containers/inventory/details/resource/metrics-tabs/services/sqs'

const ChartContainer = ({
  data,
  height = chartHeight,
  span,
  yTicksFormatting,
  formatYTicks,
  formatTooltip,
  formatTab,
  tabKey,
  percentageChart,
  children,
  yDomain,
  loading
}) => {
  const [refLine, setRefLine] = useState(false)

  const dispatch = useDispatch()

  const formatTabValue = (e) => {
    if (tabKey === SQS_DIFFERENCE) {
      const inM = e.activePayload?.find(item => item.dataKey === 'messagesIn').value
      const outM = e.activePayload?.find(item => item.dataKey === 'messagesOut').value
      const msgDiff = inM && outM && inM - outM
      return { [tabKey]: formatTab(msgDiff) }
    }

    let tabValue = e.activePayload?.find(item => item.dataKey === tabKey)
    if (!tabValue) {
      tabValue = e.activePayload?.find(item => item.payload[tabKey])
    }

    const formatted = tabValue && formatTab(tabValue.value)
    return { [tabKey]: formatted }
  }

  const handleMouseMove = useCallback((e) => {
    if (!e) return

    if (!e.isTooltipActive) {
      dispatch(updateActiveMetric({ [tabKey]: null }))
      setRefLine(false)
      return
    }

    const formatted = formatTabValue(e)
    dispatch(updateActiveMetric(formatted))
    setRefLine(true)
  })

  const yDomainMax = (dataMax) => {
    if (percentageChart) return dataMax > 100 ? dataMax : 100
    return dataMax
  }

  if (loading) return <Loading height={chartHeight} />
  if (data?.length === 0) return <Empty height={chartHeight} />

  return (
    <ResponsiveContainer width='100%' height={height} debounce={300}>
      <ComposedChart data={data} margin={chartMargin} onMouseMove={handleMouseMove}>
        <XAxis {...xAxisProps} tick={<XAxisTick span={span} />} />
        <YAxis domain={yDomain || [0, yDomainMax]} {...yAxisProps} tickFormatter={formatYTicks || (value => numeral(value).format(yTicksFormatting))} />
        <Legend verticalAlign='top' align='left' wrapperStyle={{ left: '0', top: '0', padding: '8px' }} content={<ChartLegend />} />
        <Tooltip formatter={formatTooltip || ((value) => [value, undefined])} {...tooltipProps} labelFormatter={date => format(date, span)} />
        {children}
        {refLine && <Customized component={<RefLine height={height} />} key='' />}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default ChartContainer
