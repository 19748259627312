import React, { useEffect, useRef } from 'react'
import { onLocationChanged } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import classnames from 'classnames'
import { Card } from 'antd'

import AlertBar from './alert-bar'
import Navigation from './navigation'

import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const Error = ({ accountError, reset }) => {
  const errorCode = accountError ? 404 : 500
  const { pathname } = useLocation()

  const didMount = useRef(false)
  useEffect(() => {
    if (didMount?.current) {
      reset && reset()
    } didMount.current = true
  }, [pathname])

  return (
    <Card className={styles.error_body} bordered>
      <h3 className={styles.error_title}>Error has occured</h3>
      <h1 className={styles.error_code}>{errorCode}</h1>
      <p className={styles.error_description}>
        {errorCode === 404
          ? 'You have no permissions for this account, change account from menu or ask for permissions from your admin.'
          : 'The app encountered an internal error and was unable to complete your request. Dashbird team will be notified.'}
      </p>
    </Card>
  )
}

const Layout = ({ children }) => {
  const { user } = useUser()
  const { organization, account: currentAccount, accountError } = useOrganization()

  useEffect(() => {
    onLocationChanged(() => {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    })
  }, [])

  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setUser({ email: user?.username })
      scope.setTag('organization_name', organization?.name)
    })
  }, [])

  return (
    <div className={styles.body_container}>
      {organization?.onboarded && (
        <div className={styles.left_nav}>
          <Navigation />
        </div>)}
      <div className={classnames(styles.content, { [styles.onboarding]: !organization?.onboarded })}>
        {currentAccount && <AlertBar />}
        {!accountError
          ? (
            <Sentry.ErrorBoundary fallback={({ resetError }) => <Error reset={resetError} />}>
              {children}
            </Sentry.ErrorBoundary>)
          : <Error accountError />}
      </div>
    </div>
  )
}

export default Layout
