import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Form } from 'antd'
import Input from 'components/antd/input'

const submit = (values, handlers, channel) => {
  const payload = { name: values.name, uri: values.name }
  channel ? handlers.update(payload) : handlers.create({ ...payload, type: 'email' })
}

const Email = ({ channel, handlers, saving }) => {
  const history = useHistory()

  return (
    <Form layout='vertical'
      initialValues={{ name: channel && channel.name }}
      onFinish={values => submit(values, handlers, channel)}>
      <Form.Item name='name' label='E-mail' rules={[{ required: true }]}>
        <Input placeholder='email' />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
        <Button type='link' onClick={history.goBack}>Cancel</Button>
      </Form.Item>
    </Form>
  )
}

export default Email
