import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { isNil } from 'lodash'
import { useQueryClient } from 'react-query'
import classnames from 'classnames'

import { Tooltip, Menu, Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactSVG } from 'react-svg'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faHeartRate } from '@fortawesome/pro-light-svg-icons/faHeartRate'
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb'
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock'
import Profile from './profile'
import AccountPicker from './account-picker'

import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'
import { useAccountRoutes } from 'containers/routes'
import Analytics from 'lib/analytics'
import styles from './styles.less'

const MenuItem = ({ title, url, icon }) => (
  <Tooltip placement='right' title={title} overlayClassName={styles.tooltip}>
    <Link to={url}><FontAwesomeIcon icon={icon} className={styles.icon} /></Link>
  </Tooltip>
)

const Navigation = () => {
  const location = useLocation()
  const history = useHistory()
  const routes = useAccountRoutes()

  const queryClient = useQueryClient()
  const [selectedKeys, setSelectedKeys] = useState([])

  const { organization } = useOrganization()
  const { logout } = useUser()

  const organizationCreated = !isNil(organization)
  const onBoardingCompleted = organization?.onboarded

  const keys = {
    dashboards: '/dashboards',
    inventory: '/inventory',
    errors: '/errors',
    alarms: '/alarms',
    insights: '/insights',
    resourceGroups: '/resource-groups',
    search: '/search',
    scheduledSearch: '/scheduled-search',
    settings: '/settings'
  }

  useEffect(() => {
    let selectedKey = '/'
    for (const k in keys) {
      const key = keys[k]
      const pattern = `^/[A-Za-z0-9-]*${key}`
      if (location.pathname.startsWith(key) || location.pathname.match(pattern)) {
        selectedKey = key
      }
    }
    setSelectedKeys([selectedKey])
  }, [location.pathname])

  useEffect(() => {
    const link = document.getElementById('leftnav-dashbird-documentation')
    Analytics.trackLink(link, 'User accessed help')
  }, [])

  return (
    <div className={styles.menu_wrapper}>
      <ReactSVG src='/static/dashbird-logo.svg' className={styles.logo} onClick={() => history.push(`/${!onBoardingCompleted ? 'onboarding' : ''}`)} />
      <div className={styles.separator} />
      {onBoardingCompleted &&
        <Menu
          theme='light'
          className={styles.navigation}
          mode='vertical'
          selectedKeys={selectedKeys}
          items={[
            { key: '/', label: <MenuItem title='Dashboard' icon={faHeartRate} url={'/'} /> },
            // { key: keys.dashboards, label: <MenuItem title='Dashboard' icon={faBrowsers} url={routes.dashboards.url()} /> },
            { key: keys.inventory, label: <MenuItem title='Inventory' icon={faServer} url={routes.inventory.url()} /> },
            { key: keys.errors, label: <MenuItem title='Errors' icon={faBug} url={routes.errors.url()} /> },
            { key: keys.alarms, label: <MenuItem title='Alarms' icon={faExclamationTriangle} url={routes.alarms.url()} /> },
            { key: keys.insights, label: <MenuItem title='Insights' icon={faLightbulb} url={routes.insights.url()} /> },
            { key: keys.resourceGroups, label: <MenuItem title='Resource groups' icon={faLayerGroup} url={routes.resourceGroups.url()} /> },
            { key: keys.scheduledSearch, label: <MenuItem title='Scheduled search' icon={faCalendarClock} url={routes.scheduledSearch.url()} /> },
            { key: keys.search, label: <MenuItem title='Search' icon={faSearch} url={routes.search.url()} /> }
          ]}
        />}

      <Menu
        theme='light'
        className={styles.user}
        mode='vertical'
        selectedKeys={selectedKeys}
        items={[
          {
            key: keys.settings,
            label: (
              <Popover
                placement='rightBottom' trigger='hover' content={
                  <>
                    <Profile className={styles.section} />
                    {organizationCreated &&
                      <>
                        <AccountPicker className={styles.section} />
                        <ul className={classnames(styles.section, styles.links)}>
                          <li><Link to={routes.settings.url()}>Settings</Link></li>
                          <li><Link to={routes.settings.notifications.url()}>Notifications</Link></li>
                          <li><a target='_blank' href='https://dashbird.io/docs' rel='noreferrer' id='leftnav-dashbird-documentation'>Documentation</a></li>
                        </ul>
                      </>}
                    <div className={styles.section}>
                      <a onClick={() => {
                        logout()
                        queryClient.clear()
                        history.push('/auth/login')
                      }}>
                        Sign out
                      </a>
                    </div>
                  </>
                }
              >
                <a><FontAwesomeIcon icon={faUser} className={styles.icon} /></a>
              </Popover>
            )
          }
        ]}
      />
    </div >
  )
}

export default Navigation
