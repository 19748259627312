import React, { useState, useEffect, useRef } from 'react'

import { Bar, Line } from '@visx/shape'

import colors from 'lib/colors'
import { formatDuration } from 'components/charts/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/pro-solid-svg-icons/faBug'

import styles from './styles.less'

const getTextWidth = (text, font = '400 12px sans-serif') => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.font = font
  return context.measureText(text).width
}

const cutString = (content, maxWidth) => {
  if (getTextWidth(content) <= maxWidth) return content

  const characters = content.split('')
  let currentLine = ''

  characters.forEach((character, index) => {
    const lineWidth = getTextWidth(currentLine)
    const nextLine = `${currentLine}${character}`
    if (lineWidth <= maxWidth - 20) {
      currentLine = nextLine
    }
  })

  return !currentLine ? '' : `${currentLine}...`
}

const SegmentBar = ({ item, width, xScale, handleMouseOver, index, hideTooltip, barHeight }) => {
  if (!item) return null

  const [label, setLabel] = useState('')
  const labelRef = useRef(null)

  const barPos = index * barHeight
  const dashedLinePos = barPos + 13
  const barWidth = xScale(item.end - item.start)

  useEffect(() => {
    if (label) return

    const content = labelRef.current.attributes.value.value
    const allowedLength = width - labelRef.current.attributes.x.value

    setLabel(cutString(content, allowedLength))
  })

  return (
    <>
      <g onMouseOver={(e) => handleMouseOver(e, item)} >
        <Line from={{ x: 0, y: dashedLinePos }}
          to={{ x: xScale(item.start), y: dashedLinePos }}
          fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')} strokeDasharray='5 5'
        />
        <Bar
          x={xScale(item.start)}
          y={barPos}
          width={barWidth}
          height={barHeight}
          fill={item.fill}
        />
        {item.error && <FontAwesomeIcon className={styles.icon_bug} width='12' height='12' stroke={colors('chart', 'red')} fill={colors('chart', 'red')} icon={faBug} x={xScale(item.start) + 5} y={barPos + 3} />}
        <text
          x={item.error ? xScale(item.start) + 20 : xScale(item.start) + 5}
          y={dashedLinePos}
          fill={item.color}
          fontSize='12px'
          cursor='default'
          value={`${item.content} (${formatDuration(item.end - item.start)})`}
          ref={labelRef}
        >
          {label}
        </text>
      </g>
      {item.children && item.children.map((chi) => {
        return <SegmentBar
          key={chi.id}
          item={chi}
          index={chi.index}
          width={width}
          xScale={xScale}
          handleMouseOver={handleMouseOver}
          hideTooltip={hideTooltip}
          barHeight={barHeight}
        />
      })}
    </>)
}

export default SegmentBar
