import React from 'react'
import { Radio, Checkbox } from 'antd'

import Actions from './actions'
import TargetSelector from 'features/target-selector'
import { SearchIcon } from 'components/icons/font-awesome'

import { SEVERITY_TYPES } from 'lib/event-constants'
import styles from './styles.less'

const LogMatchForm = ({ mutations, condition, resources, lambdas, groups, disableEditing }) => {
  const handlers = mutations({ condition })
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span className={styles.search_icon}>
            <SearchIcon />
          </span>
          <span>Log match event</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>error types</span>
          <span className={styles.value}>{condition?.errors?.length ? condition.errors.length : 'ALL'}</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>resources</span>
          <span className={styles.value}>{condition?.targets?.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} disableEditing={disableEditing} />
        </div>
      </div>
      <section>
        <Radio.Group
          defaultValue={condition.occurrence}
          onChange={(e) => handlers.update({ occurrence: e.target.value })}
        >
          <Radio.Button value='new' disabled={disableEditing}>Only new and reopened occurrences</Radio.Button>
          <Radio.Button value='all' disabled={disableEditing}>All occurrences</Radio.Button>
        </Radio.Group>
      </section>
      <section>
        <span className={styles.label}>Severities</span>
        <Checkbox.Group
          options={[SEVERITY_TYPES.CRITICAL, SEVERITY_TYPES.WARNING, SEVERITY_TYPES.INFO]}
          defaultValue={condition.severities}
          onChange={(values) => handlers.update({ severities: values })}
          disabled={disableEditing}
        />
      </section>

      <section>
        <span className={styles.label}>Filter by resources</span>
        <TargetSelector
          resourceGroups={groups}
          lambdas={lambdas}
          onSelect={(value) => handlers.targets(value).add()}
          onDeselect={(value) => handlers.targets(value).remove()}
          value={condition.targets}
          prefixValues
          placeholder='Select resources'
        />
      </section>
    </div>
  )
}

export default LogMatchForm
