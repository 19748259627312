import React from 'react'
import { get } from 'lodash'
import { Typography, Table, Switch, InputNumber } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'

import styles from './styles.less'

const { Text } = Typography

const NameField = ({ name, enabled, type, toggle } = {}) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <Switch style={{ marginRight: 10 }} defaultChecked={enabled} onChange={(checked) => toggle(type, checked)} />
      <Text>{name}</Text>
    </div>
  )
}

const ResourceTable = ({ resources, price = {}, changeCount, toggleEnable } = {}) => {
  const columns = [
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'type',
      render: (name, data) => <NameField {...data} toggle={toggleEnable} />
    },
    {
      title: 'Resource count',
      dataIndex: 'count',
      key: 'count',
      render: (count, data) => {
        return (
          <InputNumber
            min={0}
            defaultValue={count}
            disabled={!get(resources, [data.type, 'enabled'])}
            onChange={(val) => changeCount(data.type, val)}
          />
        )
      }
    },
    {
      title: 'Price per resource',
      key: 'price',
      render: () => <Text>{formatStripePrice(price.amount, price.currency)}</Text>
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={Object.values(resources)}
      pagination={false}
      className={styles.marginBottom}
    />
  )
}

export default ResourceTable
