import React from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { Table, Select, Button } from 'antd'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const { Option } = Select

const TableLayout = ({ columns, data, filterOptions, setDataFilter, setModalVisible, tableTitle, loading, locale, showAddButton, rowClickUrl }) => {
  const history = useHistory()
  const { account } = useOrganization()
  const userRole = account.role

  const IS_OWNER = userRole === 'owner'

  const handleRowClick = (e, record) => {
    e.preventDefault()

    if (!IS_OWNER) return null

    history.push(rowClickUrl(record))
  }

  return (
    <section className={styles.container}>
      <div className={styles.top_bar}>
        {filterOptions && <div className={styles.status_select}>
          <p className={styles.select_label}>Status:</p>
          <Select defaultValue='all'
            onChange={value => setDataFilter(value)}
            className={styles.select}
            dropdownClassName={styles.dropdown}
            dropdownMatchSelectWidth={false}
          >
            <Option value='all'>All</Option>
            {filterOptions.map(option => <Option key={option.val} value={option.val}>{option.name}</Option>)}
          </Select>
        </div>}
        {IS_OWNER && showAddButton && <Button type='primary' onClick={() => setModalVisible(true)}>Create {tableTitle}</Button>}
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        onRow={(record) => ({ onClick: event => handleRowClick(event, record) })}
        rowClassName={(record) => record.status === 'disabled' ? styles.disabled : classnames(IS_OWNER && styles.clickable, styles.enabled)}
        pagination={false}
        locale={locale}
      />
    </section>
  )
}

export default TableLayout
