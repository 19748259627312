import React, { useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { CompositeDecorator, Editor, EditorState, Modifier, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
import { max, min, takeWhile } from 'lodash'
import { suggest } from '@dashbird/dashql'
import { Popover, Tooltip } from 'antd'
import { AlertCircleIcon, InfoIcon } from 'components/icons/font-awesome'
import { SearchContext } from 'hooks/context/search-context'
import { Typography } from 'components/typography'

import 'draft-js/dist/Draft.css'

import styles from './styles.less'

const { hasCommandModifier } = KeyBindingUtil

const debounce = (func, timeout = 500) => {
  let timer

  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(args)
    }, timeout)
  }
}

const EXAMPLES = [
  'AccessDenied OR "Internal server error"',
  '32jd-3242-32423-2354dss AND NOT error',
  'warning AND NOT (customer1 OR customer2)',
  'error AND NOT "Rate exceeded"',
  'this will be searched as keywords'
]

const Info = () => {
  return (
    <div className={styles.popup}>
      <Typography.Paragraph className={styles.title}>You can use keywords like <span>AND</span>, <span>OR</span>, <span>NOT</span> to create complex queries, e.g:</Typography.Paragraph>
      <ul className={styles.examples_list}>
        {EXAMPLES.map((item, index) => <li key={index}><code>{item}</code></li>)}
      </ul>
    </div>
  )
}

const InfoPopup = () => {
  return (
    <Popover content={<Info />} placement='bottomRight'>
      <InfoIcon className={styles.info_icon} />
    </Popover>
  )
}

const Error = () => {
  return <Tooltip title='Query not valid'><AlertCircleIcon className={styles.error_icon} /></Tooltip>
}

const QUERY_PLACEHOLDERS = [
  'SEARCH KEYWORD OR QUERY'
]

const keywords = [/\band\b/gi, /\bnot\b/gi, /\bor\b/g, /[()]/g]

const QuerySuggestions = ({ autoComplete, onSelect }) => {
  if (!autoComplete || !autoComplete?.suggestions?.length || autoComplete?.position?.x === 0) return null
  return (
    <div style={{ top: autoComplete.position.y + 25, left: autoComplete.position.x }} className={styles.suggestions_wrapper}>
      <ul className={styles.list}>
        {autoComplete?.suggestions
          .map((item, index) => <li
            key={item.value}
            onMouseDown={() => onSelect(index)}
            className={classnames(styles.item, { [styles.selected]: index === autoComplete.selectedIndex })}>
            <span className={styles.item_value}>{item.value}</span><span className={styles.item_desc}>{item.description}</span></li>)}
      </ul>
    </div>
  )
}

const Suggestion = ({ children }) => {
  return <span className={styles.suggestion}>{children}</span>
}

const findKeywords = (contentBlock, callback) => {
  const text = contentBlock.getText()
  keywords.forEach(word => {
    const matches = [...text.matchAll(word)]
    matches.forEach(match => callback(match.index, match.index + match[0].length))
  })
}

export const decorator = new CompositeDecorator([{ strategy: findKeywords, component: Suggestion }])

const QueryEditor = ({ editorState, setEditorState, handleSubmit }) => {
  const { errorMsg, validateQuery } = useContext(SearchContext)
  const editorRef = useRef()
  const [autoComplete, setAutoComplete] = useState(null)
  const [placeholder] = useState(QUERY_PLACEHOLDERS[Math.floor(Math.random() * QUERY_PLACEHOLDERS?.length)])
  const CaretCoordinates = {
    x: 0,
    y: 0
  }

  const getSelectionRange = () => {
    const selection = window.getSelection()
    if (selection.rangeCount === 0) return null
    return selection.getRangeAt(0)
  }

  const getCaretCoordinates = () => {
    const range = getSelectionRange()
    if (range) {
      const { left: x, top: y } = range.getBoundingClientRect()
      Object.assign(CaretCoordinates, { x, y })
    }
    return CaretCoordinates
  }

  const getPreviousText = () => {
    const content = editorState.getCurrentContent()
    const selection = editorState.getSelection()

    const anchorKey = selection.getAnchorKey()
    const block = content.getBlockForKey(anchorKey)
    const end = selection.getStartOffset()
    const text = block.getText().slice(0, end)

    const prevText = takeWhile(content.getBlocksAsArray(), value => value.getKey() !== anchorKey).map(value => value ? value.getText() : '')

    return [...prevText, text].join(' ')
  }

  const addSuggestion = (e, editorState, index) => {
    if (!autoComplete) return false

    const chosenItem = autoComplete?.suggestions[index || autoComplete?.selectedIndex]
    if (!chosenItem) return false
    const words = autoComplete.text.split(' ')
    const lastTypedWord = words[words.length - 1]

    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const entitySelection = selection.set('anchorOffset', selection.getAnchorOffset() - lastTypedWord.trim().length)
    const contentStateWithEntity = Modifier.replaceText(contentState, entitySelection, chosenItem.value + ' ')

    const nextEditorState = EditorState.push(editorState, contentStateWithEntity)

    setEditorState(nextEditorState)
    return true
  }

  const updateSuggestions = (editorState) => {
    const selection = getSelection(editorState)
    const text = selection?.text
    if (!selection || !text) return null

    if (text?.trim() !== '') {
      return {
        ...autoComplete,
        position: getCaretCoordinates(),
        text,
        suggestions: autoComplete?.suggestions?.filter(item => item.value.startsWith(text.trim().toUpperCase())) || []
      }
    }

    const valueBefore = getPreviousText(editorState)
    const suggestions = suggest(valueBefore)
    if (!suggestions || suggestions.length === 0) return null

    return {
      text,
      suggestions,
      position: getCaretCoordinates(),
      selectedIndex: 0
    }
  }

  const getSelection = (editorState) => {
    const selection = editorState.getSelection()

    if (!selection.hasFocus) return null

    let text = editorState.getCurrentContent().getPlainText()

    text = text.substring(0, selection.anchorOffset)

    const index = text.lastIndexOf(' ')
    if (index === -1) return { text }

    text = text.substring(index)

    return {
      text,
      start: index,
      end: selection.focusOffset
    }
  }

  const handleKeyBinds = (command) => {
    if (command === 'enter') {
      handleSubmit()
      return 'handled'
    }

    if (command === 'arrow-up' || command === 'arrow-down') {
      const increment = command === 'arrow-down' ? 1 : -1
      const nextIndex = autoComplete.selectedIndex + increment
      const totalEntries = autoComplete?.suggestions?.length - 1
      const selectedIndex = max([0, min([nextIndex, totalEntries])])
      setAutoComplete({ ...autoComplete, selectedIndex })
      return 'handled'
    }

    if (command === 'tab') {
      handleChooseSuggestion(autoComplete.selectedIndex)
      return 'handled'
    }

    if (command === 'escape') {
      setAutoComplete(null)
      return 'handled'
    }

    return 'not-handled'
  }

  const handleChooseSuggestion = (index) => {
    addSuggestion('', editorState, index)
  }

  const handleChange = (value) => {
    setEditorState(value)
  }

  useEffect(() => {
    const updatedState = updateSuggestions(editorState)
    setAutoComplete(updatedState)
  }, [editorState])

  useEffect(() => {
    if (!editorRef?.current) return

    const handleValidation = debounce(() => validateQuery())
    const element = editorRef.current

    element.addEventListener('keyup', handleValidation)

    return () => element.removeEventListener('keyup', handleValidation)
  }, [editorRef?.current])

  const keyBindings = (e) => {
    if (e.keyCode === 40) return 'arrow-down'
    if (e.keyCode === 38) return 'arrow-up'
    // if there are suggestions, use custom handler (https://github.com/facebook/draft-js/issues/1296)
    if (e.keyCode === 9 && autoComplete?.suggestions?.length) return 'tab'
    if (e.keyCode === 27) return 'escape'
    if (e.keyCode === 13 && !e.shiftKey && !hasCommandModifier(e)) return 'enter'
    return getDefaultKeyBinding(e)
  }

  return (
    <div className={styles.editor_wrap} ref={editorRef}>
      <div className={styles.editor}>
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          onChange={handleChange}
          handleKeyCommand={handleKeyBinds}
          keyBindingFn={keyBindings}
          handleReturn={addSuggestion} />
        <QuerySuggestions autoComplete={autoComplete} onSelect={handleChooseSuggestion} />
      </div>
      {errorMsg ? <Error /> : <InfoPopup />}
    </div>
  )
}

export default QueryEditor
