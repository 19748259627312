import React, { useEffect, useState } from 'react'

import Metadata from 'components/inventory/metadata'

const LoadBalancerMetadata = ({ attributes = [], inverse }) => {
  const [items, setItems] = useState([])

  const targetGroupsFinder = item => item.name === 'targetGroups'
  const listenersFinder = item => item.name === 'listeners'
  const securityGroupsFinder = item => item.name === 'securityGroups'
  const availabilityZonesFinder = item => item.name === 'availabilityZones'
  const routingFinder = item => item.name.startsWith('routing')

  useEffect(() => {
    const tempItems = []
    const filtered = attributes.filter(
      item => !targetGroupsFinder(item) &&
        !listenersFinder(item) &&
        !securityGroupsFinder(item) &&
        !availabilityZonesFinder(item) &&
        !routingFinder(item)
    )
    tempItems.push({
      title: 'General',
      children: filtered.map(attribute => ({
        key: attribute.name,
        value: attribute.value
      }))
    })

    tempItems.push({
      title: 'Routing',
      children: attributes.filter(routingFinder).map(attribute => {
        const parts = attribute.name.split('.')
        parts.shift()
        const key = parts.join('.')

        return {
          key,
          value: attribute.value
        }
      })
    })

    const securityGroupsAttribute = attributes.find(securityGroupsFinder)
    if (securityGroupsAttribute) {
      const securityGroups = JSON.parse(securityGroupsAttribute.value)
      tempItems.push({
        title: 'Security Groups',
        children: securityGroups.map(group => ({ key: group }))
      })
    }

    const listenersAttribute = attributes.find(listenersFinder)
    if (listenersAttribute) {
      const listeners = JSON.parse(listenersAttribute.value)
      tempItems.push(...listeners.map(listener => ({
        title: 'Listener',
        children: [{
          key: 'port',
          value: listener.port
        }, {
          key: 'protocol',
          value: listener.protocol
        }]
      })))
    }

    setItems(tempItems)
  }, [attributes])

  return <Metadata items={items} inverse={inverse} />
}

export default LoadBalancerMetadata
