import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Typography } from 'components/typography'

import styles from './styles.less'

const RuleDescription = ({ text }) => {
  return (
    <ReactMarkdown
      children={text}
      components={{
        h1: (props) => <Typography.Title {...props} level={1} />,
        h2: (props) => <Typography.Title {...props} level={2} />,
        h3: (props) => <Typography.Title {...props} level={3} />,
        h4: (props) => <Typography.Title {...props} level={4} upperCase />,
        p: (props) => <Typography.Paragraph {...props} textDark className={styles.paragraph} />,
        ul: (props) => <ul className={styles.ul} {...props} />,
        ol: (props) => <ol className={styles.ol} {...props} />,
        code: (props) => <span className={styles.code_wrapper} ><code {...props} /></span>
      }}
    />
  )
}

export default RuleDescription
