import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { isEmpty } from 'lodash'

import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import InventoryServiceGroups from './services'
import InventoryRegions from './regions'
import InventoryDelegations from './delegations'
import ResourceGroups from 'features/resource-groups-list'
import { useAllResourcesQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.less'

const SettingsButton = ({ role }) => {
  const DISABLE_EDIT = !(role === 'owner' || role === 'admin')

  if (DISABLE_EDIT) return null
  return <Link to='settings/integrations' className={styles.settings_btn}>
    Integrations Settings <img src='/static/vendor/icons/config.svg' className={styles.settings_icon} alt='Configuration' />
  </Link>
}

const InventoryDashboard = () => {
  const { account } = useOrganization()
  const { data: resources } = useAllResourcesQuery()

  return (
    <Content
      title='Inventory overview'
      breadcrumbs={['inventory']}
      actions={<SettingsButton role={account?.role} />}
      item
    >

      {resources && isEmpty(resources)
        ? <Empty title='No resources found' />
        : (
          <>
            <InventoryServiceGroups />
            <Row gutter={8}>
              <Col span={8}>
                <ResourceGroups view='inventory' />
              </Col>
              <Col span={8}>
                <InventoryRegions />
              </Col>
              <Col span={8}>
                <InventoryDelegations />
              </Col>
            </Row>
          </>)}
    </Content>
  )
}

export default InventoryDashboard
