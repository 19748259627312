import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Form, Button } from 'antd'
// import { Auth } from '@aws-amplify/auth'

import Email from 'containers/auth/form-items/email'
import Password from 'containers/auth/form-items/password'
import Submit from 'components/antd/form/submit'
import AuthTitle from 'containers/auth/title/index'

import styles from './styles.less'

const Login = ({ loading, onSubmit, email, password }) => {
  const { query } = useLocation()

  const [form] = Form.useForm()
  const [showPwdInput, setShowPwdInput] = useState(false)

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  const setEmail = () => {
    if (!email || email?.length === 0) return
    form.setFieldsValue({ email })
    setShowPwdInput(true)
  }

  const setPassword = () => {
    if (!password) return
    form.setFieldsValue({ password })
  }

  useEffect(setEmail, [email])
  useEffect(setPassword, [password])

  /*
  const onGoogleSignIn = async () => {
    await Auth.federatedSignIn({ provider: 'Google' })
  }
  */

  const validateEmail = () => {
    form.validateFields(['email']).then(values => {
      setShowPwdInput(true)
    }).catch(errorInfo => { })
  }

  return (
    <>
      <AuthTitle title='Sign in' />
      <Form form={form} onFinish={onSubmitWrapper} layout='vertical' requiredMark={'optional'} className={styles.login}>
        {showPwdInput && <Button type='link' onClick={() => setShowPwdInput(false)} className={styles.not_you_btn}>Not you?</Button>}
        <Email disabled={showPwdInput} initialValue={query} onPressEnter={() => validateEmail()} />
        {!showPwdInput && <Button type='primary' onClick={() => validateEmail()} className={styles.next_btn}>Next</Button>}
        <Password hidden={!showPwdInput} focus />
        {showPwdInput && (
          <div className={styles.row}>
            <Link to={{ pathname: '/auth/password/forgot', query: form.getFieldValue('email') }} className={styles.forgot_btn}>Forgot password?</Link>
          </div>
        )}
        <Submit loading={loading} text='Sign in' disabled={!showPwdInput} hidden={!showPwdInput} titleUpperCase />
        { /* <GenericButton onClick={onGoogleSignIn} loading={loading} text='Sign in with Google' /> */}
      </Form>
    </>)
}

export default Login
