import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import Section from 'components/layout/content/section'
import Drawer from 'components/drawer'
import InvocationsList from './list'
import InvocationDetails from './details'
import Filter from './filter'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

const InvocationsContainer = ({
  durationFilter,
  setDurationFilter,
  memoryFilter,
  setMemoryFilter,
  invocations,
  service,
  resource,
  setFilter,
  filter,
  title,
  actions,
  onSelect,
  onClose,
  itemClassName,
  loading,
  editorState,
  setEditorState,
  onSearch,
  searchEnabled,
  url,
  hideFilters = false,
  hash,
  logMatch
}) => {
  const match = useRouteMatch()
  const invocationPath = `${match.path}/requests/:invocationHash`
  const requestPath = `${match.path}/requests/:requestId`
  const logMatchPath = `${match.path}/log-matches/:invocationHash`

  return (
    <Section
      title={title || ''}
      actions={actions}
      titleUpperCase
    >
      {!hideFilters && (
        <Filter
          resource={resource}
          searchEnabled={searchEnabled}
          filter={filter} setFilter={setFilter}
          durationSliderValue={durationFilter} setDurationSliderValue={setDurationFilter}
          memorySliderValue={memoryFilter} setMemorySliderValue={setMemoryFilter}
          editorState={editorState} setEditorState={setEditorState} onSearch={onSearch} />
      )}
      <InvocationsList
        loading={loading}
        type={service}
        items={invocations}
        onSelect={onSelect}
        url={url}
        itemClassName={itemClassName}
      />
      <Route path={service === INVENTORY_SERVICES.AppSyncGraphQLApi.id ? requestPath : logMatch ? logMatchPath : invocationPath}>
        <Drawer
          open
          onClose={onClose}
          mask={false}
          closable={false}
          size='large'
        >
          <InvocationDetails
            type={service}
            resource={resource}
            onClose={onClose}
            hash={hash}
          />
        </Drawer>
      </Route>
    </Section>
  )
}

export default InvocationsContainer
