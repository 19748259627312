import React from 'react'
import { Tooltip } from 'antd'

import { LockIcon, KeyIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const EndpointItem = (props) => {
  const { title, icon, method, authorizer, iconTitle, apiKey } = props.item
  return (
    <div className={styles.endpoint}>
      <p className={styles.endpoint_text}>{method}{title}</p>
      <div className={styles.icons}>
        {apiKey && <Tooltip title='API key required'><KeyIcon /></Tooltip>}
        {authorizer && <Tooltip title={`Auth: ${authorizer}`}><LockIcon /></Tooltip>}
        {icon && <Tooltip title={iconTitle}>{icon}</Tooltip>}
      </div>
    </div>
  )
}

export default EndpointItem
