import React from 'react'
import { merge, isEmpty } from 'lodash'
import { format } from 'date-fns'
import classnames from 'classnames'

import { faPennant } from '@fortawesome/pro-light-svg-icons/faPennant'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft'
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser'

import Metrics from 'components/metrics'
import { formatDuration } from 'components/charts/utils'
import { BugIcon, SpinnerIcon } from 'components/icons/font-awesome'

import styles from './styles.less'

const metrics = {
  time: {
    icon: faArrowToLeft,
    title: 'Time'
  },
  latency: {
    icon: faClock,
    title: 'Latency'
  },
  statusCode: {
    icon: faBrowser,
    title: 'StatusCode'
  },
  flags: {
    icon: faPennant,
    title: 'Flags'
  }
}

const Loading = <SpinnerIcon size='sm' spin />

const SeverityFlag = ({ className }) => {
  return <div className={styles.flag}>
    <BugIcon className={className} />
  </div>
}

const StatusCode = ({ item, loading }) => {
  if (loading || !item || !item.statusCode) return Loading

  return (
    <div className={classnames(item.statusCode < 400 ? styles.success : styles.error)}>
      {item.statusCode}
    </div>
  )
}

const Flags = ({ resolvers }) => {
  if (!resolvers) return Loading

  const showFlags = {
    warning: resolvers?.find(resolver => resolver.errors.find(error => error.severity === 'WARNING')),
    error: resolvers?.find(resolver => resolver.errors.find(error => error.severity === 'CRITICAL'))
  }

  if (!showFlags.warning && !showFlags.error) return 'none'

  return (
    <React.Fragment>
      {showFlags.error && <SeverityFlag key="error" className={styles.error} />}
      {showFlags.warning && <SeverityFlag key='warning' className={styles.warning} />}
    </React.Fragment>
  )
}

const Latency = ({ item, loading }) => {
  if (loading || !item || isEmpty(item)) return null

  return formatDuration(item?.latency / 1000000)
}

const getItems = (loading, item, resolvers) => {
  const latency = Latency({ item, loading })
  const flags = Flags({ resolvers })
  const statusCode = StatusCode({ item, loading })

  return [
    merge({}, metrics.time, {
      value: format(item.timestamp, 'HH:mm'),
      subvalue: format(item.timestamp, 'dd.MM.yyyy')
    }),
    merge({}, metrics.latency, { value: latency }),
    merge({}, metrics.statusCode, { value: statusCode }),
    merge({}, metrics.flags, { value: flags })
  ]
}

const RequestMetrics = ({ loading, item, resolvers, tabNarrow, drawer }) => {
  const items = getItems(loading, item, resolvers)
  return <Metrics items={items} tabNarrow={tabNarrow} drawer={drawer} />
}

export default RequestMetrics
