import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { times } from 'lodash'
import numeral from 'numeral'
import { Skeleton } from 'antd'

import Empty from './empty'
import { Item } from 'components/items'

import { formatDuration } from 'components/charts/utils'
import { ScrollContext } from 'components/layout/content'
import styles from './styles.less'

const Container = ({ onSelect, item, children }) => {
  if (onSelect) return <div onClick={() => onSelect(item)}>{children}</div>
  else return <>{children}</>
}

const LambdaInvocations = ({ items, loading, url, onSelect }) => {
  const { pathname } = useLocation()
  const { width } = useContext(ScrollContext)

  if (loading) {
    return times(6).map(index => (
      <Skeleton key={index} active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />
    ))
  }

  if (!items?.length) return <Empty />

  const urlParams = pathname?.split('/')
  const hash = urlParams[urlParams.length - 1]

  return items.map(item => {
    return (
      <Container onSelect={onSelect} item={item} key={item.eventId}>
        <Item.Execution
          key={item.eventId}
          identity={item.requestId}
          memory={numeral(item?.data?.maxMemoryUsed / item?.data?.memorySize).format('0%')}
          duration={formatDuration(item?.data?.duration)}
          timestamp={item?.timestamp}
          coldstart={item?.data?.initDuration}
          retry={item?.isRetry}
          bug={item?.hasCriticalErrors}
          warning={item?.hasWarningErrors}
          gap='sm'
          url={url(item)}
          selected={item.eventId === hash || item.logEventId === hash}
          responsive={width}
        />
      </Container>)
  }

  )
}

export default LambdaInvocations
