import React from 'react'
import { isEmpty, uniqBy } from 'lodash'
import classnames from 'classnames'

import { useAccountRoutes } from 'containers/routes'
import { useOrganization } from 'hooks/context/organization-context'

import Section from 'components/layout/content/section'
import IconButton from 'components/buttons/icon'
import { AddIcon, BugIcon, SlidersIcon, AlarmIcon } from 'components/icons'
import { SearchIcon, SuggestionIcon } from 'components/icons/font-awesome'
import { Item } from 'components/items'
import { useNotificationChannelsQuery } from 'hooks/api'

import styles from './styles.less'

const Type = ({ type }) => {
  switch (type) {
    case 'error':
      return <BugIcon className={styles.condition_type} />
    case 'alarm':
      return <AlarmIcon size='sm' className={classnames(styles.condition_type, styles.alarm_type)} />
    case 'insight':
      return <SuggestionIcon className={styles.condition_type} />
    case 'log-match':
      return <SearchIcon className={styles.condition_type} />
    default:
      return null
  }
}

export const ConditionTypes = ({ policy }) => {
  return <div className={styles.condition_types}>{uniqBy(policy?.conditions, 'type')?.map((item, index) => <Type key={index} type={item.type} />)}</div>
}

const Policies = ({ loading, policies, handlers, creating }) => {
  const routes = useAccountRoutes()
  const { account } = useOrganization()

  const IS_OWNER = account.role === 'owner'
  const IS_ADMIN = account.role === 'admin'

  const { data: channels } = useNotificationChannelsQuery()

  return (
    <Section
      title='policies'
      titleUpperCase
      loading={loading}
      actions={
        (IS_OWNER || IS_ADMIN) ? <IconButton icon={<AddIcon />} title='Add new policy' onClick={handlers.create} loading={creating} className={styles.icon} /> : null
      }
    >
      {policies?.map(item => {
        const connectedChannels = channels?.filter(channel => item.channels?.includes(channel.id))
        const status = item.disabled || (!item.disabled && connectedChannels?.length === 0) ? 'error' : 'success'
        return (
          <Item.Filter
            key={item.id}
            title={item.name}
            description={!isEmpty(connectedChannels) ? connectedChannels.map(item => item?.name)?.join(', ') : 'No channels connected'}
            icon={<SlidersIcon className={styles.icon} />}
            url={routes.settings.notifications.policies.policy.url({ policyId: item.id })}
            extra={<ConditionTypes policy={item} />}
            statusBar={status}
          />
        )
      })}
    </Section>
  )
}

export default Policies
