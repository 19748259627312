import React from 'react'

import MFASetup from './mfa-setup'
import ChangePassword from './change-password'
import Content from 'components/layout/content'

import styles from './styles.less'

const Security = () => {
  return (
    <Content item={{}} title={'Security'} breadcrumbs={['Settings', 'Security']}>
      <div className={styles.security}>
        <ChangePassword />
        <MFASetup />
      </div>
    </Content>
  )
}

export default Security
