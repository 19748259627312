import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { organizationsPaths as paths, ACCOUNTS_LIST, ORGANIZATIONS_LIST, ORGANIZATIONS_ACTIVE } from '../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'
import { useParams } from 'react-router-dom'

const updateAccount = async (organization, account, payload) => {
  const data = await apiFetch(paths.account(organization, account), { body: JSON.stringify(payload), method: 'PUT' })

  return data
}

export const useUpdateAccountQuery = () => {
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { organization } = useOrganization()
  const { user } = useUser()

  return useMutation(
    (payload) => updateAccount(organization.id, id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...ACCOUNTS_LIST, organization.id])
        queryClient.invalidateQueries([...ORGANIZATIONS_LIST, user.attributes.email])
        queryClient.invalidateQueries([...ORGANIZATIONS_ACTIVE, organization.id])
      }
    }
  )
}
