import React, { useRef, useState } from 'react'
import { times } from 'lodash'
import { Skeleton } from 'antd'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import styles from './styles.less'

const renderLoading = ({ search, header }) => {
  return <div>
    {search}
    {header}
    <div className={styles.loading}>
      {times(6).map(index => (
        <Skeleton key={index} active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />
      ))}
    </div>
  </div>
}

const List = ({ loading, search, header, items, renderItem, loadMore, renderEmpty, selected, onSelect, onBulkSelect, bulkSelected, itemHeight, loadingMetrics }) => {
  const [scroll, setScroll] = useState(0)
  const didMount = useRef(false)

  const callbackRef = (node) => {
    if (!items || !node) return

    if (!didMount.current) {
      if (!selected) return
      const selectedIndex = items?.findIndex(item => item?.id === selected)
      node.scrollToItem(selectedIndex, 'smart')
    }
    didMount.current = true
  }

  const handleScroll = ({ scrollOffset, scrollUpdateWasRequested }) => {
    setScroll(scrollOffset)
  }

  if (loading) return renderLoading({ search, header })
  if (!items || items.length === 0) {
    return <div>
      {search}
      {header}
      {renderEmpty}
    </div>
  }

  return <div className={styles.container}>
    {search}
    {header}

    <div className={styles.list}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            initialScrollOffset={scroll}
            ref={callbackRef}
            onScroll={handleScroll}
            height={height}
            itemCount={items.length}
            itemData={{ items, onSelect, selected, bulkSelected, onBulkSelect, loadingMetrics }}
            itemSize={itemHeight || 53}
            width={width}
          >
            {renderItem}
          </FixedSizeList>
        )}
      </AutoSizer>
      {loadMore}
    </div>
  </div>
}

export default List
