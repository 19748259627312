// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XUmLVq1gZIVw5UlfGBDw {\n  color: #4a46c6;\n  border-color: #4a46c6;\n}\n.NHVoBRza86xFEAmYeruM {\n  height: 25px;\n  padding: 0 9px;\n  font-size: 20px;\n}\n.NHVoBRza86xFEAmYeruM svg {\n  transform: translate(0, -5px);\n}\n.efflEEieaBYgczc6PSBl svg {\n  margin-right: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/buttons/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,qBAAA;AADF;AAIA;EACE,YAAA;EACA,cAAA;EACA,eAAA;AAFF;AADA;EAMI,6BAAA;AAFJ;AAMA;EAEI,iBAAA;AALJ","sourcesContent":["@import '../../styles/main';\n\n.dropdown {\n  color: @colors[primary];\n  border-color: @colors[primary];\n}\n\n.actions {\n  height: 25px;\n  padding: 0 @spacing[small];\n  font-size: 20px;\n\n  svg {\n    transform: translate(0, -5px);\n  }\n}\n\n.link_icon {\n  svg {\n    margin-right: @spacing[smaller];\n  }\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "XUmLVq1gZIVw5UlfGBDw",
	"actions": "NHVoBRza86xFEAmYeruM",
	"link_icon": "efflEEieaBYgczc6PSBl"
};
export default ___CSS_LOADER_EXPORT___;
