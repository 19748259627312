// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LioSLPnV3zo0fm9L__IA {\n  text-align: center;\n  margin-top: 12px;\n  height: 32px;\n  line-height: 32px;\n}\n.VxheH9u7GAgRGW4wHEC8 {\n  border: 1px solid #e9e9e9;\n  background-color: #fff;\n  padding: 0 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/buttons/load-more/styles.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AADF;AAIA;EACE,yBAAA;EACA,sBAAA;EACA,eAAA;AAFF","sourcesContent":["@import '../../../styles/main';\n\n.wrapper {\n  text-align: center;\n  margin-top: 12px;\n  height: 32px;\n  line-height: 32px;\n}\n\n.button {\n  border: 1px solid @border-colors[primary];\n  background-color: @background-colors[secondary];\n  padding: 0 @spacing[normal];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LioSLPnV3zo0fm9L__IA",
	"button": "VxheH9u7GAgRGW4wHEC8"
};
export default ___CSS_LOADER_EXPORT___;
