import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Form } from 'antd'
import Input from 'components/antd/input'
import { Typography } from 'components/typography'

const submit = (values, handlers) => {
  handlers.update({ name: values.name })
}

const Connect = ({ history }) => {
  const redirectUri = window.location.href

  return (
    <>
      <Typography.Paragraph>
        Connect with your Slack account:
      </Typography.Paragraph>
      <Typography.Paragraph>
        <a href={`https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=150752968164.479385330279&redirect_uri=${redirectUri}`}>
          <img alt='Add to Slack' height='32' width='111' src='https://platform.slack-edge.com/img/add_to_slack.png' srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x' />
        </a>
      </Typography.Paragraph>
    </>
  )
}

const Slack = ({ channel, handlers, saving }) => {
  const history = useHistory()

  if (!channel) return <Connect history={history} />

  return (
    <Form onFinish={(values) => submit(values, handlers)} layout='vertical' initialValues={{ name: channel?.name, uri: channel?.uri }}>
      <Form.Item name='name' label='Name' rules={[{ required: true }]}>
        <Input placeholder='name' />
      </Form.Item>
      <Form.Item name='uri' label='URI' rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
        <Button type='link' onClick={history.goBack}>Cancel</Button>
      </Form.Item>
    </Form>
  )
}

export default Slack
