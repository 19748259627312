import APIGatewayChart from './apigateway/chart'
import AppSyncChart from './appsync/chart'
import AppSyncResolverChart from './appsync/resolvers/chart'
import AppSyncTotal from './appsync/total'
import DynamoDBChart from './dynamodb/chart'
import ECSClusterChart from './ecs/cluster/chart'
import ECSServiceChart from './ecs/service/chart'
import ELBApplicationLoadBalancerChart from './elb/application-load-balancer/chart'
import ELBGatewayLoadBalancerChart from './elb/gateway-load-balancer/chart'
import ELBNetworkLoadBalancerChart from './elb/network-load-balancer/chart'
import ELBTargetGroupChart from './elb/target-group/chart'
import KinesisAnalyticsChart from './kinesis/analytics/chart'
import KinesisDataStreamChart from './kinesis/data-stream/chart'
import KinesisFirehoseChart from './kinesis/firehose/chart'
import LambdaChart from './lambda/chart'
import LambdaTotal from './lambda/total'
import SQSChart from './sqs/chart'
import StepFunctionsChart from './step-functions/chart'
import SNSChart from './sns/chart'
import RDSClusterChart from './rds/cluster/chart'
import RDSProxyChart from './rds/proxy/chart'
import RDSInstanceChart from './rds/instance/chart'
import OpenSearchChart from './opensearch/chart'

export const ChartMetrics = {
  APIGatewayRest: APIGatewayChart,
  APIGatewayHttp: APIGatewayChart,
  AppSyncAPI: AppSyncChart,
  AppSyncResolver: AppSyncResolverChart,
  DynamoDB: DynamoDBChart,
  ECSCluster: ECSClusterChart,
  ECSService: ECSServiceChart,
  ELBApplicationLoadBalancer: ELBApplicationLoadBalancerChart,
  ELBGatewayLoadBalancer: ELBGatewayLoadBalancerChart,
  ELBNetworkLoadBalancer: ELBNetworkLoadBalancerChart,
  ELBTargetGroup: ELBTargetGroupChart,
  KinesisAnalytics: KinesisAnalyticsChart,
  KinesisDataStream: KinesisDataStreamChart,
  KinesisFirehose: KinesisFirehoseChart,
  Lambda: LambdaChart,
  SQS: SQSChart,
  StepFunctions: StepFunctionsChart,
  SNS: SNSChart,
  RDSInstance: RDSInstanceChart,
  RDSCluster: RDSClusterChart,
  RDSProxy: RDSProxyChart,
  OpenSearch: OpenSearchChart
}

export const TotalMetrics = {
  AppSyncAPI: AppSyncTotal,
  Lambda: LambdaTotal
}
