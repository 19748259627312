// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LKKDf77MRxUyWkrQNLtQ {\n  margin-top: 18px;\n}\n.cgbR_8Cn6gaxyBUjxw9w {\n  margin: 0 3px;\n}\n.zDZYvKgxUDkShUR6hIPA {\n  display: flex;\n  justify-content: center;\n}\n.sP6vSX9rZyXfwhCNqj4A {\n  font-size: 24px;\n  margin-left: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/auth/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAIA;EACE,aAAA;AAFF;AAKA;EACE,aAAA;EACA,uBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;AAJF","sourcesContent":["@import '../../styles/main';\n\n.submit_wrapper {\n  margin-top: @spacing[large];\n}\n\n.link {\n  margin: 0 @spacing[smallest];\n}\n\n.confirm_row {\n  display: flex;\n  justify-content: center;\n}\n\n.spin {\n  font-size: 24px;\n  margin-left: @spacing[larger];\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit_wrapper": "LKKDf77MRxUyWkrQNLtQ",
	"link": "cgbR_8Cn6gaxyBUjxw9w",
	"confirm_row": "zDZYvKgxUDkShUR6hIPA",
	"spin": "sP6vSX9rZyXfwhCNqj4A"
};
export default ___CSS_LOADER_EXPORT___;
