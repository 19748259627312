import React, { useState } from 'react'
import { orderBy, get, sumBy, head } from 'lodash'

import { LineChart, Line, YAxis, XAxis, ResponsiveContainer, Tooltip, Customized } from 'recharts'

import { yAxisProps } from 'components/charts/utils.js'
import format from 'components/charts/format'

import styles from './styles.less'

const calculateMetric = (values) => {
  const aggregator = get(values, '[0].stat')
  if (aggregator === 'sum') {
    return sumBy(values, 'value')
  }
}

const RefLine = ({ activeCoordinate }) => {
  if (!activeCoordinate) return null
  return <>
    <line x1={activeCoordinate.x} x2={activeCoordinate.x} y1='0' y2='85' stroke={'#4a46c6'} />
    <circle r='5' cx={activeCoordinate.x} cy={5} fill={'#4a46c6'} />
  </>
}

const Empty = ({ name }) => {
  return (
    <div className={styles.trendline_empty}>
      <h4 className={styles.metric_header}>No {name} to show right now</h4>
    </div>
  )
}

const TrendlineChart = ({ data, _, allOpts, height, span }) => {
  if (!data?.length) return <Empty name={allOpts[0]?.name} />

  const [refLine, setRefLine] = useState(false)

  const opts = head(allOpts)
  const ordered = orderBy(data, 'date')

  const tooltipprops = { position: { x: 0, y: -48 } }

  return (<div className={styles.trendline_chartContainer}>
    {!refLine && <p className={styles.trendline_totalValue}>{(opts.format || ((item) => item))(calculateMetric(data))}</p>}
    <ResponsiveContainer height={height} width='100%' debounce={300}>
      <LineChart
        data={ordered}
        margin={{ top: 10, right: 0, left: 0, bottom: -15 }}
        onMouseOver={e => setRefLine(true)}
        onMouseLeave={e => setRefLine(false)}
      >
        <Tooltip
          wrapperStyle={{ right: 4 }}
          contentStyle={{ border: 'none', padding: '0 8px 0', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', background: 'transparent' }}
          itemStyle={{ margin: 0, padding: 0, fontSize: '12px' }}
          labelStyle={{ margin: 0, padding: 0, fontSize: '12px' }}
          {...tooltipprops}
          labelFormatter={date => format(date, span)}
          formatter={(value) => [opts.format(value), undefined]}
        />
        <YAxis domain={[0, 'dataMax']} interval={'preserveEnd'} mirror {...yAxisProps} orientation={'right'} tickFormatter={opts.format} />
        <XAxis dataKey='date' tickFormatter={date => format(date, 60)} {...yAxisProps} padding={{ left: 8, right: 8 }} interval={'preserveStartEnd'} />
        <Line dataKey='value' stroke={opts.color || '#4a46c6'} dot={false} type='monotone' />
        {refLine && <Customized component={<RefLine />} key={''} />}
      </LineChart>
    </ResponsiveContainer>
  </div>)
}

export default TrendlineChart
