import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const sensitiveKeys = ['password', 'confirmPassword']

const obfuscateObject = (object) => {
  for (const key in object) {
    if (typeof object[key] === 'object' && object[key] !== null) {
      object[key] = obfuscateObject(object[key])
      continue
    }

    if (sensitiveKeys.includes(key)) object[key] = 'xxx'
  }

  return object
}

const obfuscateEvent = (event) => {
  for (const key in event) {
    if (typeof event[key] === 'object' && event[key] !== null) {
      event[key] = obfuscateEvent(event[key])
      continue
    }

    if (sensitiveKeys.find(sensitiveKey => {
      const isString = typeof event[key] === 'string' || event[key] instanceof String
      return isString && event[key].includes(sensitiveKey)
    })) {
      try {
        const tempObj = JSON.parse(event[key])
        const obfuscatedObj = obfuscateObject(tempObj)
        event[key] = JSON.stringify(obfuscatedObj)
      } catch (e) {
        // couldn't parse string to object
      }
    }
  }

  return event
}

export default function () {
  if (process.env.CONFIG.APP_VERSION) {
    Sentry.init({
      beforeSend: obfuscateEvent,
      dsn: 'https://5d91c3abc0ad46cca1885de65c062ca8@sentry.io/1310587',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.25,
      release: `frontend@${process.env.CONFIG.APP_VERSION}`,
      environment: process.env.NODE_ENV
    })
  }
}
