import React from 'react'

import ListItem from 'features/side-list/item'
import { ResourceGroupIcon } from 'components/icons'

const DashboardListItem = ({ data, index, style }) => {
  const item = data?.items[index]

  return (
    <div style={style}>
      <ListItem
        key={item.id}
        icon={<ResourceGroupIcon size='2x' />}
        title={item.name}
        selected={item.id.toString() === data?.selected}
        onClick={() => data?.onSelect(item)}
      />
    </div>
  )
}

export default DashboardListItem
