import React from 'react'

import AccountsTable from './accounts-table'
import DangerZone from './danger-zone'
import Content from 'components/layout/content'
import { useOrganizationQuery } from 'hooks/api'

import styles from './styles.less'

const ManageAccounts = () => {
  const { data: org, isLoading } = useOrganizationQuery()

  const Title = () => {
    return <>Manage <span className={styles.account_name}>{org?.organization?.name}</span> accounts</>
  }

  return (
    <Content item={{}} title={<Title />} loading={isLoading} breadcrumbs={['settings', 'manage-accounts']}>
      {org?.role === 'owner'
        ? <p>Create, edit and remove accounts</p>
        : <p>Only organisation owners can create, edit and remove accounts</p>}
      <AccountsTable />
      {org?.role === 'owner' && <DangerZone className={styles.suspend_container} />}
    </Content>
  )
}

export default ManageAccounts
