import React from 'react'
import classnames from 'classnames'
import { Button, Form } from 'antd'

import styles from './styles.less'

const Submit = ({ loading, text, icon, disabled, hidden, titleUpperCase, className }) => {
  return (
    <Form.Item className={classnames(styles.item, className)} hidden={hidden}>
      <Button type='primary' htmlType='submit' loading={loading} disabled={disabled} className={classnames(styles.button, { [styles.uppercase]: titleUpperCase })}>
        {text} {icon}
      </Button>
    </Form.Item>
  )
}

export default Submit
