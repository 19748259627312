import React, { useEffect, useState } from 'react'

import Metadata from 'components/inventory/metadata'

const TargetGroupMetadata = ({ attributes = [], inverse }) => {
  const [items, setItems] = useState([])

  const loadBalancersFinder = item => item.name === 'loadBalancers'
  const stickinessFinder = item => item.name.includes('stickiness')
  const healthFinder = item => item.name.includes('health')

  useEffect(() => {
    const tempItems = []

    const filtered = attributes.filter(
      attribute => !loadBalancersFinder(attribute) &&
        !stickinessFinder(attribute) &&
        !healthFinder(attribute)
    )
    tempItems.push({
      title: 'General',
      children: filtered.map(item => ({
        key: item.name,
        value: item.value
      }))
    })

    const stickinessAttributes = attributes.filter(stickinessFinder)
    tempItems.push({
      title: 'Stickiness',
      children: stickinessAttributes.map(attribute => {
        const parts = attribute.name.split('.')
        parts.shift()
        const key = parts.join('.')

        return {
          key,
          value: attribute.value
        }
      })
    })

    const healthAttributes = attributes.filter(healthFinder)
    tempItems.push({
      title: 'Health',
      children: healthAttributes.map(attribute => ({
        key: attribute.name,
        value: attribute.value
      }))
    })

    setItems(tempItems)
  }, [attributes])

  return <Metadata items={items} inverse={inverse} />
}

export default TargetGroupMetadata
