import React from 'react'
import { Row, Col } from 'antd'

import InventoryTags from 'components/inventory/tags'
import Events from 'components/inventory/events'
import InventoryResourceContainer from '../../container'
import LoadBalancerMetadata from './application-load-balancer/metadata'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { CONNECTIONS } from '../../metrics-tabs/services/elb-application-load-balancer'
import Tabs from 'components/antd/tabs'
import Section from 'components/layout/content/section'

const Config = ({ item }) => {
  return (<>
    {item.tags.length && <InventoryTags tags={item.tags} inverse />}
    <LoadBalancerMetadata attributes={item.attributes} inverse />
  </>)
}

const ELBLoadBalancer = ({ item }) => {
  return (
    <>
      <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.ELBNetworkLoadBalancer.id} initialChart={CONNECTIONS}>
        <Row gutter={12}>
          <Col span={16}>
            <Section>
              <Tabs defaultActiveKey="configuration" items={[{ key: 'configuration', label: 'Configuration', children: <Config item={item} /> }]} />
            </Section>
          </Col>
          <Col span={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default ELBLoadBalancer
