import React from 'react'
import { Form } from 'antd'
import Input from 'components/antd/input'

const ConfirmationCode = () => {
  return <Form.Item
    name='code'
    label='email confirmation code'
    rules={[{ required: true, message: 'Please submit the confirmation code sent to your email' }]}>
    <Input solid />
  </Form.Item>
}

export default ConfirmationCode
