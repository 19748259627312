import { getDispatch, apiFetch } from 'lib/action-helpers'
import queryString from 'query-string'

const paths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  logSources: (account, delegation) => `${paths.account(account)}/log-sources/subscription/${delegation}`,
  logSourcesRetry: (account, delegation) => `${paths.logSources(account, delegation)}/retry`
}

export const api = (account) => {
  return {
    id: (delegation) => {
      return {
        get: (region, name, retry) => getLogSources(account, delegation, region, name, retry)
      }
    }
  }
}

export const GET_LOG_SOURCES = 'GET_LOG_SOURCES'
export const GET_LOG_SOURCES_ERROR = 'GET_LOG_SOURCES_ERROR'
export const getLogSources = (account, delegation, region, logGroupName, retry = false) => {
  const query = queryString.stringify({ region, logGroupName })

  const path = retry ? paths.logSourcesRetry : paths.logSources

  return getDispatch(
    apiFetch(paths.filter(path(account, delegation), query)),
    GET_LOG_SOURCES,
    GET_LOG_SOURCES_ERROR
  )
}
