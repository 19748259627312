import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import UsersTable from './users-table/index'
import AddUserModal from './add-user-modal/index'
import Content from 'components/layout/content'
import { API } from 'actions/api'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationInvitationsQuery, useOrganizationUsersQuery, useOrganizationQuery } from 'hooks/api'

import styles from './styles.less'

const UserPermissions = () => {
  const dispatch = useDispatch()

  const { organization: currentOrganization } = useOrganization()
  const { user } = useUser()
  const { data: invitations, refetch, isLoading: invitationsLoading } = useOrganizationInvitationsQuery()
  const { data: users, isLoading } = useOrganizationUsersQuery()
  const { data: org } = useOrganizationQuery()

  const [userModalVisible, setUserModalVisible] = useState(false)
  const [mergedUsers, setMergedUsers] = useState([])

  const sendInvite = (invitation) => {
    dispatch(API.organizations.id(org.organization.id).invitations.post(invitation))
    ReactGA.event({ category: 'Invitation', action: 'Invitation successful', label: user?.attributes?.email.replace('@', '.at.') })
    refetch()
  }

  const mergeUsersInvites = () => {
    if (!users) return
    setMergedUsers(users?.concat(invitations))
  }

  useEffect(mergeUsersInvites, [invitations, users])

  const Title = () => {
    return <><span className={styles.account_name}>{currentOrganization.name}</span> users</>
  }

  return (
    <Content item={{}} title={<Title />} breadcrumbs={['Settings', 'Users']} loading={isEmpty(org)}>
      <p className={styles.subtitle}>Create new users, customize user permissions, and remove users from your account.</p>

      <UsersTable users={mergedUsers} org={org} setModalVisible={setUserModalVisible} loading={isLoading || invitationsLoading} />
      <AddUserModal visible={userModalVisible} setVisible={setUserModalVisible} sendInvite={sendInvite} />
    </Content>
  )
}

export default UserPermissions
