import React from 'react'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { formatDistanceToNowStrict } from 'date-fns'
import { last } from 'lodash'
import { Tooltip } from 'antd'
import classnames from 'classnames'

import { BugIcon, IconWithText } from 'components/icons'

import { formatDuration } from 'components/charts/utils'
import { ClockIcon } from 'components/icons/font-awesome'
import styles from './styles.less'

const RequestMetrics = ({ latency, hasErrors }) => {
  return (
    <div className={styles.requestMetrics}>
      <IconWithText icon={<ClockIcon />} text={formatDuration(parseInt(latency / 1000000))} className={styles.latency} />
      {hasErrors && <BugIcon className={styles.bug} />}
    </div>
  )
}

const StatusCode = ({ value = 0 }) => {
  const style = value >= 400 ? styles.error : value >= 200 ? styles.ok : null
  return <div className={classnames(styles.status_code, style)}>{value}</div>
}

const RequestId = ({ value = '' }) => {
  const lastDigits = last(value.split('-'))
  return (
    <Tooltip title={value} className={styles.requestId}><span>{lastDigits}</span></Tooltip>
  )
}

const Timestamp = ({ value = 0 }) => {
  const timestamp = formatDistanceToNowStrict(value)
  return (
    <div className={styles.timestamp}>{timestamp} ago</div>
  )
}

const AppsyncRequest = ({ item, selectedRequestId }) => {
  const match = useRouteMatch()
  const { search } = useLocation()
  const requestUrl = `${match.url}/requests/${item.requestId}${search || ''}`

  const operation = item.operation || item.query
  return (
    <Link to={requestUrl} className={classnames(styles.item, item.requestId === selectedRequestId && styles.selected)}>
      <div className={styles.left}>
        <StatusCode value={item.statusCode} />
        <div className={styles.operation}>{operation}</div>
      </div>
      <div className={styles.right}>
        <RequestId value={item.requestId} />
        <RequestMetrics latency={item.latency} hasErrors={item.hasErrors} />
        <Timestamp value={item.timestamp} />
      </div>
    </Link>
  )
}

export default AppsyncRequest
