import React from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Skeleton, Table } from 'antd'
import { Typography } from 'components/typography'
import { AwsServiceIcon } from 'components/icons'

import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'

import styles from './styles.less'
import { useAccountRoutes } from 'containers/routes'

const columns = [{
  title: 'Service',
  key: 'serviceKey',
  sorter: (a, b) => a.service.localeCompare(b.service),
  sortDirections: ['descend', 'ascend'],
  render: (record) => {
    return (
      <div className={styles.column}>
        <AwsServiceIcon service={record?.service} />
        <span className={styles.text}>{INVENTORY_SERVICE_GROUPS[record.service]?.title || 'Service not implemented'}</span>
      </div>
    )
  }
}, {
  title: 'AWS account',
  dataIndex: 'name',
  key: 'name',
  sorter: (a, b) => a.name.localeCompare(b.name),
  sortDirections: ['descend', 'ascend']
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: (a, b) => a.status.localeCompare(b.status),
  sortDirections: ['descend', 'ascend']
}, {
  title: 'Interval',
  dataIndex: 'interval',
  key: 'interval'
}, {
  title: 'Insights',
  dataIndex: 'insights',
  key: 'insights',
  sorter: (a, b) => a.insights.localeCompare(b.insights),
  sortDirections: ['descend', 'ascend']
}]

const transform = (integrations, delegations) => {
  if (isEmpty(integrations) || isEmpty(delegations)) return []
  return integrations.map(integration => {
    const delegation = delegations.find(item => item.id === integration.delegation.id)
    if (!delegation) return null
    const name = delegation?.name ? `${delegation?.name} (${delegation?.provider?.account})` : delegation?.provider?.account
    return {
      ...integration,
      name: name,
      serviceKey: `${delegation.id}-${integration.service}`,
      service: integration.service,
      status: integration.status,
      interval: `${integration.interval} mins`,
      insights: integration.insights ? 'enabled' : 'disabled'
    }
  }).filter(item => item)
}

const Integrations = ({ loading, integrations, delegations }) => {
  const routes = useAccountRoutes()
  const history = useHistory()

  if (loading) return <Skeleton />
  const data = transform(integrations, delegations)

  const handleIntegrationClick = (e, record) => {
    e.preventDefault()

    if (!INVENTORY_SERVICE_GROUPS[record.service]) return

    history.push(routes.settings.integrations.integration.url({ delegationId: record.delegation.id, integrationId: record.id }))
  }

  return (
    <>
      <Typography.Title level={2}>Integrated services</Typography.Title>
      <Typography.Paragraph>Manage your AWS integrations - control polling cycle, enable/disable regions, turn on insights and filter monitored resources by tags</Typography.Paragraph>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data}
        onRow={(record) => ({ onClick: event => handleIntegrationClick(event, record) })}
        pagination={false}
        bordered={false}
        className={styles.table}
        rowClassName={record => (record.status === 'disabled' || !INVENTORY_SERVICE_GROUPS[record.service]) ? styles.disabled : styles.enabled}
      />
    </>
  )
}

export default Integrations
