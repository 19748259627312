// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q6u_x50PlTS78wxYHz82 {\n  color: #7ed321;\n}\n.kcTcRxUTjpoAAMFhT4An {\n  color: #faad14;\n}\n.ONH1ewq_ydchTCUldmVD {\n  color: #f91111;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/inventory/details/resource/services/opensearch/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAIA;EACE,cAAA;AAFF;AAKA;EACE,cAAA;AAHF","sourcesContent":["@import '../../../../../../styles/main';\n\n.success {\n  color: @colors[success]\n}\n\n.warning {\n  color: @colors[warning]\n}\n\n.error {\n  color: @colors[alert]\n}\n\n@color-white: #fff;@color-wild-sand: #f5f5f5;@color-apple-green: #7ed321;@color-perrywinkle: #8b91e3;@color-vermillion: #f91111;@color-blue-with-a-hint: #4a46c6;@color-very-light-pink: #e9e8e8;@color-sapphire: #3633ab;@color-black: #000;@main: @color-blue-with-a-hint;@main-accent: @color-sapphire;@main-accent-shade1: @color-perrywinkle;@main-accent-shade1-active: rgba(@color-perrywinkle, 0.8);@background-light: @color-wild-sand;@text-color: lighten(@color-black, 40%);@text-light: lighten(@color-black, 57%);@primary-color: @main;@link-color: @main;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "q6u_x50PlTS78wxYHz82",
	"warning": "kcTcRxUTjpoAAMFhT4An",
	"error": "ONH1ewq_ydchTCUldmVD"
};
export default ___CSS_LOADER_EXPORT___;
