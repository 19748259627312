import { apiFetch, getDispatch } from '../lib/action-helpers'

const paths = {
  filter: (path, query) => `${path}?${query}`,
  organization: (idenitity) => `/v1/organizations/${idenitity}`,
  analytics: (organization) => `${paths.organization(organization)}/analytics`,
  onboardingSurvey: (organization) => `${paths.analytics(organization)}/surveys/onboarding`
}

export const api = (organization) => {
  return {
    surveys: {
      onboarding: {
        post: (payload) => postOnboardingSurvey(organization, payload)
      }
    }
  }
}

export const POST_SURVEY_ONBOARDING = 'POST_SURVEY_ONBOARDING'
export const POST_SURVEY_ONBOARDING_ERROR = 'POST_SURVEY_ONBOARDING_ERROR'
export const postOnboardingSurvey = (organization, { answer }) => {
  return getDispatch(
    apiFetch(paths.onboardingSurvey(organization), { method: 'POST', body: JSON.stringify({ answer }) }),
    POST_SURVEY_ONBOARDING,
    POST_SURVEY_ONBOARDING_ERROR
  )
}
